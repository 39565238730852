import { useState } from "react";
import { Link } from "react-router-dom";
import FromToDatePicker from "../../components/Search/FromToDatePicker";
import TableInstance from "../../components/Table/TableInstance";
import useGetPurchaseOrders from "../../hooks/purchase/useGetPurchaseOrders";
import { convertDate, getStartAndEndDate } from "../../utils/helpers";

const CompletedPurchases = () => {
  const PURCHASES_COLUMNS = [
    {
      Header: "Sr. No",
      accessor: (_row: unknown, index: number) => index + 1,
    },
    {
      Header: "Order No",
      accessor: "order_number",
    },
    {
      Header: "Amount",
      accessor: "amount",
      Cell: ({ value }: any) => (value ? value : "__"),
    },
    {
      Header: "Supplier",
      accessor: "supplier.name",
    },
    {
      Header: "Supplier Contact No.",
      accessor: "supplier.contact_number",
    },
    {
      Header: "Purchase Date",
      accessor: "date",
      Cell: ({ value }: any) => convertDate(value),
    },
    {
      Header: "Created At",
      accessor: "createdAt",
      Cell: ({ value }: any) => convertDate(value),
    },
    {
      Header: "Remarks",
      accessor: "remark",
    },
    {
      Header: "",
      accessor: "confirmBtn",
      Cell: ({ row }: any) => {
        return (
          <Link
            to={`/purchases/${row.original.id}/view`}
            state={{ purchaseDetails: row.original }}
            className="btn-primary"
          >
            View
          </Link>
        );
      },
    },
  ];

  const [cPageSize, cSetPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [cSortBy, cSetSortBy] = useState("order_number");
  const [desc, setDesc] = useState(true);
  // const [startDate, setStartDate] = useState(new Date().toISOString());
  const [q, setQ] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);
  let { startDate, endDate } = getStartAndEndDate();

  const [searchDates, setSearchDates] = useState({
    fromDate: startDate.toISOString(),
    toDate: endDate.toISOString(),
  });

  let component = null;

  const { data, error, isLoading } = useGetPurchaseOrders({
    status: "completed",
    ...searchDates,
  });

  const handleSubmit = ({
    fromDate,
    toDate,
  }: {
    fromDate: string;
    toDate: string;
  }) => {
    let endOfToday = new Date(toDate);
    endOfToday.setUTCHours(23, 59, 59, 999);
    setSearchDates({
      fromDate: new Date(fromDate).toISOString(),
      toDate: new Date(endOfToday).toISOString(),
    });
  };

  if (error) {
    component = (
      <p className="mt-6 ml-4 text-center">
        An error has occurred: {error.message}
      </p>
    );
  } else if (isLoading) {
    component = <p className="mt-6 ml-4 text-center">Loading...</p>;
  } else {
    component = (
      <TableInstance
        tableData={data || []}
        columnName={PURCHASES_COLUMNS}
        cPageSize={cPageSize}
        cSetPageSize={cSetPageSize}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageCount={-1} // do calculation here
        cSortBy={cSortBy}
        cSetSortBy={cSetSortBy}
        desc={desc}
        setDesc={setDesc}
        q={q}
        setQ={setQ}
        setSelectedRows={setSelectedRows}
        selectRow={false}
      />
    );
  }

  return (
    <div className="allPurchases">
      <header className="flex items-center justify-between mb-8">
        <div className="left">
          {/* <div className="search-box mr-4">
            <SearchBox
              searchValue={q}
              setSearchValue={setQ}
              handleSearch={() => {}}
            />
          </div> */}
          <div className="filters">
            <FromToDatePicker
              handleSearch={handleSubmit}
              isLoading={isLoading}
              startDate={startDate}
              endDate={endDate}
            />
          </div>
        </div>
      </header>
      {component && component}
    </div>
  );
};

export default CompletedPurchases;
