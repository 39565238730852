import { Formik } from "formik";
import { useCallback } from "react";
import * as Yup from "yup";
import useUpdateDiscountConfig from "../../../hooks/discounts/customerWise/useUpdateDiscountConfig";
import CustomSubmitBtn from "../../FormikComponents/CustomSubmitBtn";
import Input from "../../FormikComponents/Input";

interface CustomerDiscountObj {
  id: string;
  discount: number;
}

const UpdateDiscountConfig = ({ data, handleClose }: any) => {
  const initialValues: any = {
    id: data.id,
    head: data.item.head.head,
    company: data.item.company.name,
    potency: data.item.head.potency,
    size: data.item.head.size,
    item_name: data.item.product_name,
    discount: data.value,
  };

  const validationSchema = Yup.object({
    head: Yup.string().required("Head is required"),
    company: Yup.string().required("Company is required"),
    potency: Yup.string(),
    size: Yup.string(),
    item_name: Yup.string(),
    discount: Yup.number()
      .positive()
      .required("Discount is Required")
      .min(0)
      .max(100),
  });

  const { mutate, isLoading: isSubmitting } = useUpdateDiscountConfig();

  const handleSubmit = useCallback((values: CustomerDiscountObj) => {
    mutate(
      { discountId: values.id, value: values.discount },
      {
        onSuccess() {
          handleClose();
        },
      }
    );
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {(formik) => {
        return (
          <div className="head-form flex flex-col justify-center px-4 py-4 rounded border border-gray-300 gap-x-4 relative mb-14">
            <div className="row-1 flex items-center mb-2 gap-x-4">
              <Input
                classes="w-full"
                id="head"
                name="head"
                label="Head"
                type="text"
                readonly
              />
              <Input
                id="company"
                name="company"
                label="Company"
                classes="w-full"
                type="text"
                readonly
              />

              <Input
                classes="w-full"
                id="potency"
                name="potency"
                label="Potency"
                type="text"
                readonly
              />
              <Input
                classes="w-full"
                id="size"
                name="size"
                label="Size"
                type="text"
                readonly
              />
            </div>

            <div className="row-2 flex items-center gap-x-4">
              <Input
                id="item_name"
                name="item_name"
                label="Item Name"
                classes="w-full"
                type="text"
                readonly
              />
              <Input
                id="discount"
                name="discount"
                label="Discount (%)"
                type="number"
                step="0.1"
              />
            </div>

            <div className="buttons flex w-full items-center justify-center">
              <CustomSubmitBtn
                text={"Update"}
                containerClasses="w-44 my-3"
                enterToFocus
                disabled={isSubmitting}
                onSubmit={(e) => {
                  formik.handleSubmit();
                }}
              />
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default UpdateDiscountConfig;
