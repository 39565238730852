import { Form, Formik } from "formik";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import useMutateStateCity from "../../hooks/Conifg/state-city/useMutateStateCity";
import { hideModal } from "../../redux/features/modalSlice";
import { StateCity } from "../../types";
import CustomSelect from "../FormikComponents/CustomSelect";
import SubmitBtn from "../FormikComponents/SubmitBtn";
type Props = {
  prevValue: StateCity;
};
function UpdateSateCityModal({ prevValue }: Props) {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hideModal());
  };

  const initialValues = {
    state: {
      label: prevValue?.state || "",
      value: prevValue?.id || "",
    },
    cities:
      prevValue?.cities?.map((city: any) => ({
        label: city,
        value: city,
      })) || "",
    id: prevValue?.id || "",
  };

  const validationSchema = Yup.object({
    state: Yup.object().required("State is required"),
    cities: Yup.array().of(Yup.object()).min(1).required("City(s) is required"),
  });
  const { mutate, isLoading: isSubmitting } = useMutateStateCity();

  const handleSubmit = useCallback((values, resetForm: any) => {
    const value = {
      state: values.state.label,
      cities: values.cities.map((city: any) => city.value),
      id: values.id,
    };
    mutate(value, {
      onSuccess() {
        handleClose();
      },
    });
  }, []);

  return (
    <div className="update-state-city-modal ">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
      >
        {({ values }) => (
          <Form
            className="px-8 py-6  items-center gap-x-12 "
            style={{
              minWidth: "760px",
            }}
          >
            <div className="row flex gap-x-8">
              <CustomSelect
                createAble
                id="state"
                name="state"
                label="State"
                options={[]}
                classes="w-full"
                placeholder="Select / Create State"
              />
            </div>
            <div className="row flex gap-x-8">
              <CustomSelect
                isMulti
                createAble
                id="cities"
                name="cities"
                label="Cities"
                options={[]}
                classes="w-full"
                placeholder="Select City(s)"
              />
            </div>
            <div className="row flex gap-x-8 py-2">
              <SubmitBtn
                text="Update"
                isSubmitting={isSubmitting}
                classes="text-base h-10 w-full"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default UpdateSateCityModal;
