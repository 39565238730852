import { AxiosError } from "axios";
import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { HiCash, HiUserGroup } from "react-icons/hi";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { DebtorOption } from "../../types";
import { ERROR, SUCCESS } from "../../types/constants";
import DeptorInput from "../FormikComponents/DebtorInput";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";

interface DebtorObj {
  id: string;
  fullName: string;
  place: string;
  profession: string;
  contactNo: string;
  gstin: string;
  address: string;

  amount: string;
  purchaseDate: string;

  particulars: string;
}

const AddToDebtorsModal = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const getCustomers = async () => {
    const response = await axiosInstance.get("/customers");

    const { data } = response.data;

    return data;
  };

  const { data, isLoading, isFetching, error } = useQuery<
    DebtorOption[] | undefined,
    any,
    DebtorOption[],
    string[]
  >(["getCustomersDebtor"], getCustomers);

  const handleClose = () => {
    dispatch(hideModal());
  };

  const initialValues: DebtorObj = {
    id: "",
    fullName: "",
    place: "",
    profession: "",
    contactNo: "",
    gstin: "",
    address: "",
    amount: "",
    particulars: "",
    purchaseDate: "",
  };

  const validationSchema = Yup.object({
    fullName: Yup.string().required("Full name is required"),
    place: Yup.string().required("Place is required"),
    profession: Yup.string().required("Profession is required"),
    contactNo: Yup.string().required("Contact No. is required"),
    gstin: Yup.string().required("GSTIN is required"),
    address: Yup.string().required("Address is required"),

    amount: Yup.string().required("Amount is required"),

    purchaseDate: Yup.date()
      .max(new Date(), "Purchase Date cannot be a future date")
      .required("Purchase Date is required"),

    particulars: Yup.string().required("Particulars is required"),
  });

  const handleSubmit = useCallback((debtor: DebtorObj, resetForm: any) => {
    const value = {
      customer: debtor.id,
      amount: debtor.amount,
      sale_date: debtor.purchaseDate,
      particulars: debtor.particulars,
    };

    setIsSubmitting(true);

    console.log(value);

    axiosInstance
      .post(`/debit/`, value, {
        headers: {
          ContentType: "application/json",
        },
      })
      .then((response) => {
        const { msg } = response.data;

        setIsSubmitting(false);

        queryClient.invalidateQueries(["getDebtors"]);

        dispatch(
          addToast({
            kind: SUCCESS,
            msg: msg,
          })
        );

        resetForm();
        handleClose();
      })
      .catch((error: AxiosError) => {
        setIsSubmitting(false);

        if (error.response) {
          const response = error.response;
          const { msg } = response.data;

          switch (response.status) {
            // bad request or invalid format or unauthorized
            case 400:
            case 500:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: msg,
                })
              );
              break;
            default:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: "Oops, something went wrong",
                })
              );
              break;
          }
        } else if (error.request) {
          dispatch(
            addToast({
              kind: ERROR,
              msg: "Oops, something went wrong",
            })
          );
        } else {
          dispatch(
            addToast({
              kind: ERROR,
              msg: `Error: ${error.message}`,
            })
          );
        }
      });
  }, []);

  return (
    <div className="add-to-debtors-modal">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
      >
        <Form>
          <div className="p-5">
            <div className="customer-form flex flex-col justify-center px-4 py-4 rounded border border-gray-300 gap-x-4 relative mb-14">
              <div className="row-1 flex items-center mb-2 gap-x-4">
                <DeptorInput
                  label="Full Name"
                  name="fullName"
                  id="fullName"
                  error={error}
                  isFetching={isFetching}
                  isLoading={isLoading}
                  data={data || []}
                  queryKey={["getCustomersDebtor"]}
                  dependencies={{
                    id: "id",
                    place: "place",
                    contactNo: "contactNo",
                    gstin: "gstin",
                    address: "address",
                  }}
                />
                <Input
                  label="City/Village"
                  name="place"
                  id="place"
                  type="text"
                  readonly={true}
                />

                <Input
                  label="Profession"
                  name="profession"
                  id="profession"
                  type="text"
                />
              </div>
              <div className="row-2 flex items-center gap-x-4">
                <Input
                  label="Contact No."
                  name="contactNo"
                  id="contactNo"
                  type="text"
                  readonly={true}
                />
                <Input
                  label="GSTIN"
                  name="gstin"
                  id="gstin"
                  type="text"
                  readonly={true}
                />
                <Input
                  label="Address"
                  name="address"
                  id="address"
                  type="text"
                  readonly={true}
                />
              </div>
              <div className="label absolute right-5 -bottom-4 bg-gray-300 rounded text-sm flex items-center font-semibold p-1">
                <HiUserGroup size={16} className="mr-1" />
                Customer
              </div>
            </div>
          </div>
          <div className="p-5">
            <div className="payment-form flex items-center px-4 py-4 rounded border border-gray-300 gap-x-4 relative mb-8">
              <Input label="Amount" name="amount" id="amount" type="text" />

              <Input
                label="Purchase Date"
                name="purchaseDate"
                id="purchaseDate"
                type="date"
              />

              <Input
                label="Particulars"
                name="particulars"
                id="particulars"
                type="text"
              />
              <div className="label absolute right-5 -bottom-4 bg-gray-300 rounded text-sm flex items-center font-semibold p-1">
                <HiCash size={16} className="mr-1" />
                Payment
              </div>
            </div>
          </div>

          <div className="buttons flex items-center w-full justify-center my-4">
            <div className="w-96 h-10 my-4">
              <SubmitBtn
                text="Save"
                isSubmitting={isSubmitting}
                classes="text-sm h-full w-full"
              />
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default AddToDebtorsModal;
