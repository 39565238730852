import { useCallback } from "react";
import { useQuery } from "react-query";
import axiosInstance from "../../axiosInstance";
import queryKeys from "../../types/queryKeys";
import {
  calculateTaxableAmount,
  convertDateToStringForForm,
} from "../../utils/helpers";

type Response = {
  id: string;
  head: string;
  company: { value: string; label: string };
  potency: string;
  size: string;
  item_name: {
    label: string;
    value: string;
  };
  batch_number: string;
  qty: string;

  ordered_qty: string;
  mrp: string;
  mfg_date: string;
  exp_date: string;
  d1: string;
  d2: string;
  d3: string;
  d4: string;
  d5: string;
  hsn_number: string;
  taxable_amount: string;
  gst: string;
  total: string;
  gst_amount: string;
};
function useGetItemsFromPurchaseOrder(
  orderId: string,
  isOrdered_qtyTaxble: boolean = false
) {
  const getOrder = useCallback(
    async ({ queryKey }: any) => {
      const [_key, orderId] = queryKey;
      const response = await axiosInstance.get(`/purchase/${orderId}/items`);

      const { data } = response.data;

      const modified = data?.map((item: any) => {
        const { mrp, qty, d1, d2, d3, d4, d5, head, ordered_qty } = item;
        const taxable_amount = calculateTaxableAmount(
          mrp,
          isOrdered_qtyTaxble ? ordered_qty : qty,
          {
            d1,
            d2,
            d3,
            d4,
            d5,
          }
        ).toFixed(2);
        let gstAmount = (
          (Number(taxable_amount || 0) * Number(head.gst)) /
          100
        ).toFixed(2);

        const totalAmount = (
          Number(gstAmount || 0) + Number(taxable_amount || 0)
        ).toFixed(2);

        return {
          id: item.id,
          head: item.head.head,
          company: { value: item.company.id, label: item.company.name },
          potency: item.head.potency,
          size: item.head.size,
          item_name: {
            label: item.item_name,
            value: item.id,
          },
          batch_number: item.batch_number,
          qty: item.qty,
          ordered_qty: item.ordered_qty,
          mrp: item.mrp,
          mfg_date: convertDateToStringForForm(item.mfg_date, true),
          exp_date: convertDateToStringForForm(item.exp_date, true),
          d1: item.d1,
          d2: item.d2,
          d3: item.d3,
          d4: item.d4,
          d5: item.d5,
          hsn_number: item.head.hsn_number,
          taxable_amount: taxable_amount,
          gst: item.head.gst,
          total: totalAmount,
          gst_amount: gstAmount,
        };
      });

      return modified;
    },
    [isOrdered_qtyTaxble]
  );
  return useQuery<Response[], any, Response[], string[]>(
    [queryKeys.getItemsFromPurchaseOrder, orderId],
    getOrder
  );
}

export default useGetItemsFromPurchaseOrder;
