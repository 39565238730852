import { useFormikContext } from "formik";
import GetErrors from "../../utils/GetErrors";

interface Props {
  text: string;
  isSubmitting: boolean;
  classes?: string;
  isSubmittingClasses?: string;
  containerClasses?: string;
  shouldNotValidate?: boolean;
  isDisabled?: boolean;
}

const SubmitBtn = ({
  text,
  isSubmitting,
  classes,
  isSubmittingClasses,
  containerClasses,
  shouldNotValidate,
  isDisabled,
}: Props) => {
  const { isValid, dirty, errors } = useFormikContext();

  const errObj = new GetErrors();
  errObj.makeErrorsArr(errors);

  return (
    <div
      className={`relative w-full flex justify-items-center justify-center ${containerClasses}`}
      data-tooltip-target="tooltip-default"
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      title={`${
        errObj.errorsArr.length > 0 ? errObj.errorsArr.join("\n") : "No errors"
      }`}
    >
      <button
        type="submit"
        className={`btn-primary w-full disabled:opacity-40 disabled:cursor-not-allowed flex justify-center focus:bg-sky-700 ${classes}`}
        disabled={
          (shouldNotValidate ? false : !isValid || !dirty) || isDisabled
        }
      >
        {isSubmitting === true ? (
          <svg
            className="animate-spin h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        ) : (
          text
        )}
      </button>
    </div>
  );
};

export default SubmitBtn;
