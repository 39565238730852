import { Form, Formik } from "formik";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import ViewProductTable from "../../components/NestedFormComponents/Sales/ViewProductTable";
import useGetItemsFromSalesOrder from "../../hooks/sales/useGetItemsFromSalesOrder";
import useGetDebt from "../../hooks/useGetDebt";
import useGetOrderById from "../../hooks/useGetOrderById";
import { ORDER_TYPE_SALES, SalesOrderObj } from "../../types/sales.types";
import { SalesOrderValidation } from "../../utils/constants/salesConstants";
import { convertDateToStringForForm } from "../../utils/helpers";
import SalesCalculationForm from "./SalesCalculationForm";
import SalesCustomerForm from "./SalesCustomerForm";

const ViewSalesOrder = () => {
  const params = useParams();
  const { data: dataOrder } = useGetOrderById(params.id!, "sales");

  const customerBalance = useGetDebt(dataOrder?.customer?.id!);
  const {
    data: dataItems,
    isFetching: isFetchingOrder,
    isLoading: isLoadingOrder,
    isSuccess: isSuccessOrder,
  } = useGetItemsFromSalesOrder(params.id!);

  const initialValues: SalesOrderObj = {
    customer: dataOrder?.customer,

    heads: dataItems ? dataItems : [],
    total: dataOrder?.amount || 0,
    sp_discount: dataOrder?.sp_discount || 0,
    // gst: "",

    orderBy: dataOrder?.medrepId
      ? {
          value: dataOrder?.medrepId,
          label: dataOrder?.medrep.name,
        }
      : { label: "", value: "" },
    bal: customerBalance?.toString() || "",
    cartons: dataOrder?.cartons || "",
    gstType: dataOrder?.gst_type || "",
    remarks: dataOrder?.remark || "",
    sales_gst_amount: dataOrder?.sp_discount + "" || "0",
    discount: 0,
    date: convertDateToStringForForm(dataOrder?.date!),
    order_type_sales: dataOrder?.order_type_sales || ORDER_TYPE_SALES.Invoiced,
    number_of_pages: dataOrder?.number_of_pages || 0,
    taxable_amount: dataOrder?.taxable_amount || "",
  };

  const handleSubmit = useCallback((order: SalesOrderObj, resetForm: any) => {},
  []);

  return (
    <div className="create-order">
      <h3 className="text-xl font-bold mb-4">View Order</h3>
      {isSuccessOrder && !isLoadingOrder && !isFetchingOrder ? (
        <Formik
          initialValues={initialValues}
          validationSchema={SalesOrderValidation}
          onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
        >
          <Form>
            <SalesCustomerForm readonly />

            <ViewProductTable fieldName="heads" />

            <SalesCalculationForm readonly />
          </Form>
        </Formik>
      ) : (
        <div>loading.....</div>
      )}
    </div>
  );
};

export default ViewSalesOrder;
