import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ConfigurationCompanyObj, HeadObj } from "../../types";

// export interface Company {
//   name: string;
//   sr_number: number;
//   id: string;
//   createdAt: Date;
//   updatedAt: Date;
// }

// export interface Head {
//   sr_number: number;
//   head: string;
//   id: string;
//   hsn_number: string;
//   gst: number;
//   size: string;
//   potency: string;
//   createdAt: Date;
//   updatedAt: Date;
// }
interface Product {
  ordered_qty: number;
  product_id: string;
  head: HeadObj;
  product_name: string;
  quantity: number;
  mrp: number;
  discount?: any;
  batch_number: string;

  mfg_date?: any;
  exp_date?: any;
  createdAt: Date;
  updatedAt: Date;
  headId: string;
  companyId: string;
  company: ConfigurationCompanyObj;
}

type Products = {
  products: Product[];
};

const initialState: Products = {
  products: [],
};
const productList = createSlice({
  name: "productList",
  initialState,
  reducers: {
    setProductList: (state, action: PayloadAction<Product[]>) => {
      state.products = [...action.payload];
    },
    clearProductList: (state) => {
      state.products = [];
    },
  },
});

export const { setProductList, clearProductList } = productList.actions;

export default productList.reducer;
