import { useField, useFormikContext } from "formik";
import { useRef } from "react";
import { HiOutlineRefresh } from "react-icons/hi";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Select from "react-select";
import axiosInstance from "../../../axiosInstance";
import useGetGroupedProducts from "../../../hooks/Inventory/useGetGroupedProducts";
import useKeyToFocus from "../../../hooks/useKeyDown";
import { showModal } from "../../../redux/features/modalSlice";
import { SHOW_PRODUCT_LIST } from "../../../types/constants";
import queryKeys from "../../../types/queryKeys";
import ReactSelectCustomStyle from "../../../utils/reactSelectCustomStyle";
import UtilityBtn from "../../UtilityBtn/UtilityBtn";
import ErrorBox from "../ErrorBox";

type Response = {
  product_name: string;
};

interface Props {
  label: string;
  id: string;
  name: string;
  inline?: boolean;
  classes?: string;

  searchParams: {
    head?: string;
    potency?: string;
    size?: string;
    companyId?: string;
  };
}

function ProductSelect({
  label,
  id,
  name,
  classes,
  inline,
  searchParams,
  ...props
}: Props) {
  const queryClient = useQueryClient();
  const params = useParams();

  const { data, isFetching, isLoading, error } = useGetGroupedProducts({
    companyId: searchParams.companyId,
    "$head.head$": searchParams.head,
    "$head.potency$": searchParams.potency,
    "$head.size$": searchParams.size,
  });
  const refItem = useRef<any>(null);
  const handlerCtrlI = (e: any) => {
    if (e.ctrlKey) {
      refItem.current.focus();
    }
  };

  useKeyToFocus("i", handlerCtrlI);

  // props -> every props except label and options -> { name: 'value', id: 'value' }
  const { setFieldValue, values } = useFormikContext();
  const [field, meta, helpers] = useField(name);
  const dispatch = useDispatch();
  const searchProducts = async (product_name: string) => {
    const { data } = await axiosInstance.get("/inventory/products", {
      params: {
        product_name,
        companyId: searchParams.companyId,
        "$head.head$": searchParams.head,
        "$head.potency$": searchParams.potency,
        "$head.size$": searchParams.size,
      },
    });

    dispatch(
      showModal({
        modalType: SHOW_PRODUCT_LIST,
        modalTitle: "AVAILABLE PRODUCTS",
        modalProps: {
          data: data.data,
          id: params.id,
        },
      })
    );

    return data.data;
  };

  return (
    <div className={`flex flex-col mb-6 ${classes}`}>
      <div
        className={`form-group flex ${
          inline ? " flex-row items-center" : "flex-col justify-center"
        }`}
      >
        <label
          className={`font-semibold ${inline ? "mr-4 w-28" : "mb-2"}`}
          htmlFor={id}
        >
          {label}
        </label>
        <div className="flex">
          <Select
            ref={refItem}
            openMenuOnFocus
            value={field.value}
            isClearable
            defaultValue={{ label: "Select an Item", value: "" }}
            styles={ReactSelectCustomStyle}
            onChange={async (op) => {
              await searchProducts(op?.value);
              // helpers.setValue(op);
            }}
            options={[
              { label: "Search All Items", value: "" },
              ...(data?.map((o) => {
                return {
                  label: o.product_name,
                  value: o.product_name,
                };
              }) || []),
            ]}
            isSearchable={true}
            isLoading={isLoading}
            isDisabled={isLoading && !data}
            className={`flex-1 ${
              meta.touched && meta.error
                ? "border border-red-400"
                : "border-coolGray-200 "
            } mr-2`}
          />

          <UtilityBtn
            Icon={HiOutlineRefresh}
            label="Retry"
            iconClasses={isFetching ? "animate-spin" : ""}
            onClick={() => {
              queryClient.invalidateQueries(queryKeys.getGroupedProducts);
            }}
          />
        </div>
      </div>
      {error ? (
        <ErrorBox msg={"Something went wrong, retry"} />
      ) : (
        meta.touched &&
        meta.error &&
        !isFetching && <ErrorBox msg={meta.error} />
      )}
    </div>
  );
}

export default ProductSelect;
