import { useField } from "formik";

import { ForwardedRef, forwardRef } from "react";
import CustomDatePicker from "./CustomDatePicker";
import ErrorBox from "./ErrorBox";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  type: string;
  id?: string;
  name: string;
  autoFocus?: boolean;
  optional?: boolean;
  inline?: boolean;
  readonly?: boolean;
  classes?: string;
  step?: string;
  textCenter?: boolean;
  notCheckTouched?: boolean;
}

const Input = forwardRef(
  (
    {
      label,
      type,
      id,
      name,
      classes,
      readonly = false,
      autoFocus = false,
      optional = false,
      inline = false,
      textCenter,
      notCheckTouched = false,
      ...props
    }: Props,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    //   field -> { name: string, value: string, onChange: () => {}, onBlur: () => {} }
    //   meta -> { touched: boolean, error: string, ... }

    const [field, meta] = useField({ name, type });
    // const [field, meta] = useField(name);

    return (
      <div
        className={`flex flex-col w-full h-16   mb-6
        ${classes}`}
      >
        <div
          className={`form-group flex ${
            inline ? " flex-row items-center" : "flex-col justify-center"
          }`}
        >
          <label
            className={`font-semibold text-sm ${
              inline ? "mr-4 w-min" : "mb-2"
            }`}
            htmlFor={id}
          >
            {label}{" "}
            {optional && <span className="optional text-slate-400">(O)</span>}
          </label>

          {type === "number" ? (
            <input
              ref={ref}
              disabled={readonly}
              readOnly={readonly}
              autoFocus={autoFocus}
              className={` focus:border-primary  hover:border-primary ${
                meta.touched && meta.error
                  ? "border-red-400 "
                  : "border-slate-200 "
              }   placeholder-transparent  disabled:opacity-80 disabled:cursor-not-allowed ${
                textCenter ? "text-center" : ""
              }`}
              {...field}
              {...props}
              id={id}
              type={type}
              min="0"
            />
          ) : type === "date" ? (
            <CustomDatePicker name={name} />
          ) : (
            <input
              ref={ref}
              disabled={readonly}
              readOnly={readonly}
              autoFocus={autoFocus}
              className={`focus:border-primary hover:border-primary ${
                meta.touched && meta.error
                  ? "border-red-400 "
                  : "border-slate-200 focus:border-primary"
              }   placeholder-transparent  disabled:opacity-80 disabled:cursor-not-allowed ${
                textCenter ? "text-center" : ""
              }`}
              {...field}
              {...props}
              id={id}
              type={type}
            />
          )}
        </div>
        {(meta.touched || notCheckTouched) && meta.error && (
          <ErrorBox msg={meta.error} />
        )}
      </div>
    );
  }
);

export default Input;
