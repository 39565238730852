import { HiOutlineChevronDoubleLeft, HiOutlineMenu } from "react-icons/hi";
import {
  MdAnalytics,
  MdArchive,
  MdBarChart,
  MdDashboard,
  MdGroup,
  MdInventory,
  MdManageAccounts,
  MdSentimentVerySatisfied,
  MdSettings,
  MdStars,
  MdSupervisorAccount,
  MdTag,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/app";
import { hideSidebar, showSidebar } from "../../redux/features/sidebarSlice";
import pathnameRegex from "../../types/pathnameRegex";
import Logo from "../Logo/Logo";
import SideBarLink from "./SidebarLink";

const Sidebar = () => {
  const dispatch = useDispatch();
  const { show } = useSelector((state: RootState) => state.sidebar);

  return (
    <aside
      className={`sidebar z-20 shadow-xl fixed left-0 top-0 bottom-0 overflow-x-hidden box-border ${
        show ? "w-60" : "w-20"
      } bg-neutral-800 h-screen flex flex-col text-white transition-all print:hidden `}
    >
      <header
        className={` ${
          show ? "justify-between" : "justify-center"
        } flex  items-center px-4 py-5 mb-2 noselect`}
      >
        {
          <div className={`${show ? "opacity-100" : "opacity-0 hidden"}`}>
            <Logo />
          </div>
        }

        {show ? (
          <button
            onClick={() => dispatch(hideSidebar())}
            className="text-white"
          >
            <HiOutlineChevronDoubleLeft size={21} />
          </button>
        ) : (
          <button
            className="text-white"
            onClick={() => dispatch(showSidebar())}
          >
            <HiOutlineMenu size={21} />
          </button>
        )}
      </header>

      <nav className="sidebar-links" id="sidebar">
        <ul>
          <SideBarLink
            to="/"
            Icon={MdDashboard}
            text="Dashboard"
            smallText="Dashboard"
            key="Dashboard"
          />
          <SideBarLink
            to="/users"
            list={[
              "/users",
              "/users/accounts",
              "/users/sales",
              "/users/purchases",
              "/users/market",
            ]}
            Icon={MdGroup}
            text="Users"
            smallText="Users"
            key="Users"
          />
          <SideBarLink
            to="/inventory"
            Icon={MdInventory}
            text="Inventory"
            smallText="Inventory"
            key="Inventory"
          />
          <SideBarLink
            to="/accounts"
            list={[
              "/accounts",
              "/accounts/debtors",
              "/accounts/creditors",
              "/accounts/orders/pending-orders",
              "/accounts/orders/approved-orders",
              "/accounts/orders/declined-orders",
            ]}
            Icon={MdManageAccounts}
            regexList={[pathnameRegex.debtors, pathnameRegex.creditors]}
            text="Accounts"
            smallText="Accounts"
            key="Accounts"
          />
          <SideBarLink
            to="/sales"
            list={[
              "/sales",
              "/sales/created",
              "/sales/create-order",
              "/sales/challaans",
              "/sales/invoices",
              "/sales/initiated",
              "/sales/initiate-order",
            ]}
            regexList={[
              pathnameRegex.sales.sales,
              pathnameRegex.sales.viewSales,
              pathnameRegex.sales.viewSalesChallan,
              pathnameRegex.sales.viewSalesInvoice,
              pathnameRegex.sales.editInitiatedSalesOrder,
              pathnameRegex.sales.editSalesOrder,
            ]}
            Icon={MdBarChart}
            text="Sales"
            smallText="Sales"
            key="Sales"
          />
          <SideBarLink
            to="/purchases"
            list={[
              "/purchases/create",
              "/purchases/created",
              "/purchases/completed",
              "/purchases/create-confirm",
              "/purchases/:id/confirm",
            ]}
            regexList={[
              pathnameRegex.confirmPurchase,
              pathnameRegex.editPurchase,
            ]}
            Icon={MdTag}
            text="Purchase"
            smallText="Purchase"
            key="Purchase"
          />
          <SideBarLink
            to="/mr"
            Icon={MdSupervisorAccount}
            text="M.R."
            smallText="M.R."
            key="M.R."
          />
          <SideBarLink
            to="/customers"
            Icon={MdSentimentVerySatisfied}
            text="Customer"
            smallText="Customer"
            key="Customer"
          />
          <SideBarLink
            to="/configuration"
            list={[
              "/configuration",
              "/configuration/heads",
              "/configuration/company",
              "/configuration/bank-account",
              "/configuration/transport",
              "/configuration/item",
            ]}
            regexList={[pathnameRegex.configration]}
            Icon={MdSettings}
            text="Configuration"
            smallText="Configuration"
            key="Configuration"
          />
          <SideBarLink
            to="/discount"
            regexList={[pathnameRegex.discount]}
            Icon={MdStars}
            text="Discount"
            smallText="Discount"
            key="Discount"
          />
          <SideBarLink
            to="/supplier"
            Icon={MdArchive}
            text="Suppliers"
            smallText="Suppliers"
            key="Suppliers"
          />
          <SideBarLink
            to="/reports"
            list={[
              "/reports",
              "/reports/gst",
              "/reports/gst/gstr-3b",
              "/reports/gst/gstr-1",
              "/reports/gst/gstr-2",
              "/reports/gst/gstr-3",
              "/reports/gst/hsn-report",
              "/reports/gst/purchase-report",
              "/reports/gst/sale-report",
              "/reports/out-of-stock",
              "/reports/customer-orders",
              "/reports/collection",
            ]}
            regexList={[pathnameRegex.reports]}
            Icon={MdAnalytics}
            text="Reports"
            smallText="Reports"
            key="Reports"
          />
        </ul>
      </nav>
    </aside>
  );
};

export default Sidebar;
