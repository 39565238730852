import { useField, useFormikContext } from "formik";
import React, { useEffect } from "react";
import { HiOutlineRefresh } from "react-icons/hi";
import { useQueryClient } from "react-query";
import { CreditorOption, DebtorOption, Option } from "../../types";
import UtilityBtn from "../UtilityBtn/UtilityBtn";
import ErrorBox from "./ErrorBox";

interface DependencyObj {
  id: "id";
  place: "place";
  contactNo: "contactNo";
  gstin: "gstin";
  address: "address";
}

interface Props {
  label: string;
  id: string;
  name: string;
  isLoading: boolean;
  dependencies: DependencyObj;
  isFetching: boolean;
  queryKey: string[];
  error: any;
  data: CreditorOption[];
  inline?: boolean;
  classes?: string;
}

const CreditorInput = ({
  label,
  id,
  name,
  queryKey,
  isFetching,
  isLoading,
  dependencies,
  error,
  data = [],
  classes,
  inline,
  ...props
}: Props) => {
  const queryClient = useQueryClient();

  // props -> every props except label and options -> { name: 'value', id: 'value' }
  const { setFieldValue } = useFormikContext();
  const [field, meta, helpers] = useField(name);

  useEffect(() => {
    if (data.length > 0 && !field.value) {
      helpers.setValue(data[0].name);

      setFieldValue(dependencies.id, data[0].id);
      setFieldValue(dependencies.place, data[0].city);
      setFieldValue(dependencies.address, data[0].address);
      setFieldValue(dependencies.contactNo, data[0].contact_number);
      setFieldValue(dependencies.gstin, data[0].gstin);
    }
  }, [data]);

  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const name = e.target.value;

    const d = data.find((c) => c.name === name);

    if (d) {
      helpers.setValue(d.name);

      setFieldValue(dependencies.id, d.id);
      setFieldValue(dependencies.place, d.city);
      setFieldValue(dependencies.address, d.address);
      setFieldValue(dependencies.contactNo, d.contact_number);
      setFieldValue(dependencies.gstin, d.gstin);
    }
  };

  return (
    <div className={`flex w-full flex-col mb-6 ${classes}`}>
      <div
        className={`form-group flex ${
          inline ? " flex-row items-center" : "flex-col justify-center"
        }`}
      >
        <label
          className={`font-medium ${inline ? "mr-4 w-28" : "mb-2"}`}
          htmlFor={id}
        >
          {label}
        </label>
        <div className="flex">
          <select
            {...field}
            {...props}
            onChange={(e) => handleSelect(e)}
            disabled={isLoading && data.length === 0}
            className={`border ${
              meta.touched && meta.error
                ? "border-red-400"
                : "border-coolGray-200 "
            } mr-2`}
          >
            {data.map((customer) => (
              <option key={customer.id} value={customer.name}>
                {customer.name}
              </option>
            ))}
          </select>

          <UtilityBtn
            Icon={HiOutlineRefresh}
            label="Retry"
            iconClasses={isFetching ? "animate-spin" : ""}
            onClick={() => {
              queryClient.invalidateQueries(queryKey);
            }}
          />
        </div>
      </div>
      {error ? (
        <ErrorBox msg={"Something went wrong, retry"} />
      ) : (
        meta.touched &&
        meta.error &&
        !isFetching && <ErrorBox msg={meta.error} />
      )}
    </div>
  );
};

export default CreditorInput;
