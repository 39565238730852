import { QueryKey, useQuery } from "react-query";
import axiosInstance from "../../axiosInstance";

import { Customer, GetCustomerParams } from "../../types/customer.types";
import queryKeys from "../../types/queryKeys";
import { groupDiscountByItem } from "../../utils/helpers";

const getCustomers = async ({ queryKey }: any) => {
  const [_, params] = queryKey;
  const response = await axiosInstance.get("/customers", { params });

  let { data } = response.data;

  if (params.getDiscounts && params.populateItems && params.id) {
    const { discounts } = data[0];
    data[0].discounts = groupDiscountByItem(discounts);
  }

  return data;
};
function useGetCustomers(parmas: GetCustomerParams = {}) {
  const {
    data: dataCustomers,
    isLoading: isLoadingCustomers,
    isFetching: isFetchingCustomers,
    error: errorCustomers,
    isSuccess: isSuccessCustomers,
  } = useQuery<Customer[] | undefined, any, Customer[], QueryKey>(
    [queryKeys.getCustomers, parmas],

    getCustomers
  );

  return {
    dataCustomers,
    isLoadingCustomers,
    isFetchingCustomers,
    errorCustomers,
    queryKeyCustomer: queryKeys.getCustomers,
    isSuccessCustomers,
  };
}

export default useGetCustomers;
