import { InventoryObj } from '../types/index';

const dummyInventory: InventoryObj[] = [
  {
    head: 'Dilution',
    company: 'AVTEST',
    productName: 'Dummy',
    potency: '0.1',
    size: '10ml',
    quantity: '4',
    mrp: '999',
    batchNo: 'BA001',
    manufactureDate: '24/01/2022',
    expiryDate: '24/01/2022',
  },
  {
    head: 'Dilution',
    company: 'AVTEST',
    productName: 'Dummy',
    potency: '0.1',
    size: '10ml',
    quantity: '4',
    mrp: '999',
    batchNo: 'BA001',
    manufactureDate: '24/01/2022',
    expiryDate: '24/01/2022',
  },
  {
    head: 'Dilution',
    company: 'AVTEST',
    productName: 'Dummy',
    potency: '0.1',
    size: '10ml',
    quantity: '4',
    mrp: '999',
    batchNo: 'BA001',
    manufactureDate: '24/01/2022',
    expiryDate: '24/01/2022',
  },
  {
    head: 'Dilution',
    company: 'AVTEST',
    productName: 'Dummy',
    potency: '0.1',
    size: '10ml',
    quantity: '4',
    mrp: '999',
    batchNo: 'BA001',
    manufactureDate: '24/01/2022',
    expiryDate: '24/01/2022',
  },
  {
    head: 'Dilution',
    company: 'AVTEST',
    productName: 'Dummy',
    potency: '0.1',
    size: '10ml',
    quantity: '4',
    mrp: '999',
    batchNo: 'BA001',
    manufactureDate: '24/01/2022',
    expiryDate: '24/01/2022',
  },
  {
    head: 'Dilution',
    company: 'AVTEST',
    productName: 'Dummy',
    potency: '0.1',
    size: '10ml',
    quantity: '4',
    mrp: '999',
    batchNo: 'BA001',
    manufactureDate: '24/01/2022',
    expiryDate: '24/01/2022',
  },
  {
    head: 'Dilution',
    company: 'AVTEST',
    productName: 'Dummy',
    potency: '0.1',
    size: '10ml',
    quantity: '4',
    mrp: '999',
    batchNo: 'BA001',
    manufactureDate: '24/01/2022',
    expiryDate: '24/01/2022',
  },
  {
    head: 'Dilution',
    company: 'AVTEST',
    productName: 'Dummy',
    potency: '0.1',
    size: '10ml',
    quantity: '4',
    mrp: '999',
    batchNo: 'BA001',
    manufactureDate: '24/01/2022',
    expiryDate: '24/01/2022',
  },
  {
    head: 'Dilution',
    company: 'AVTEST',
    productName: 'Dummy',
    potency: '0.1',
    size: '10ml',
    quantity: '4',
    mrp: '999',
    batchNo: 'BA001',
    manufactureDate: '24/01/2022',
    expiryDate: '24/01/2022',
  },
];

export default dummyInventory;
