import { FieldHelperProps, useField, useFormikContext } from "formik";
import { HiOutlineRefresh } from "react-icons/hi";
import { useQueryClient } from "react-query";
import Select from "react-select";
import useGetSuppliers from "../../hooks/supplier/useGetSuppliers";
import { Option, SupplierObj } from "../../types";
import ReactSelectCustomStyle from "../../utils/reactSelectCustomStyle";
import UtilityBtn from "../UtilityBtn/UtilityBtn";
import ErrorBox from "./ErrorBox";

interface DependencyObj {
  address: "address";
  contact_number: string;
  state: string;
  city: string;
  contact_number2: string;
  gstin: string;
  gstType: string;
}

interface Props {
  label: string;
  id: string;
  name: string;

  inline?: boolean;
  classes?: string;
}

const SupplierInput = ({
  label,
  id,
  name,
  classes,
  inline,
  ...props
}: Props) => {
  const queryClient = useQueryClient();

  // props -> every props except label and options -> { name: 'value', id: 'value' }
  const { setFieldValue } = useFormikContext();
  const [field, meta, helpers] = useField(name);
  const {
    errorSuppliers,
    isFetchingSuppliers,
    isLoadingSuppliers,
    querKeySuppliers,
    optionSuppliers,
    dataSuppliers,
  } = useGetSuppliers();

  // useEffect(() => {
  //   console.log(field.value);
  //   handleSelect(
  //     field.value,
  //     dependencies,
  //     helpers,
  //     setFieldValue,
  //     dataSuppliers!
  //   );
  // }, [field.value]);

  return (
    <div className={`flex w-full flex-col mb-6 ${classes}`}>
      <div
        className={`form-group flex ${
          inline ? " flex-row items-center" : "flex-col justify-center"
        }`}
      >
        <label
          className={`font-semibold ${inline ? "mr-4 w-28" : "mb-2"}`}
          htmlFor={id}
        >
          {label}
        </label>
        <div className="flex">
          <Select
            placeholder={"Select Supplier"}
            value={field.value}
            onBlur={() => helpers.setTouched(true)}
            onChange={(op) => {
              helpers.setTouched(true);
              helpers.setValue(op);
            }}
            openMenuOnFocus
            styles={ReactSelectCustomStyle}
            options={dataSuppliers}
            getOptionLabel={(d) => d.name}
            getOptionValue={(o) => o.id}
            isSearchable={true}
            className={`w-full ${
              meta.touched && meta.error
                ? "border border-red-400"
                : "border-coolGray-200 "
            } mr-2`}
          />

          <UtilityBtn
            Icon={HiOutlineRefresh}
            label="Retry"
            iconClasses={
              isFetchingSuppliers || isLoadingSuppliers ? "animate-spin" : ""
            }
            onClick={() => {
              queryClient.invalidateQueries(querKeySuppliers);
            }}
          />
        </div>
      </div>
      {errorSuppliers ? (
        <ErrorBox msg={"Something went wrong, retry"} />
      ) : (
        meta.touched &&
        meta.error &&
        !isFetchingSuppliers && <ErrorBox msg={meta.error} />
      )}
    </div>
  );
};

export default SupplierInput;

const handleSelect = (
  value: Option,
  dependencies: DependencyObj,
  helpers: FieldHelperProps<any>,
  setFieldValue: Function,
  data: SupplierObj[]
) => {
  const id = value.value;
  const {
    address,
    contact_number,
    contact_number2,
    city,
    state,
    gstType,
    gstin,
  } = dependencies;

  const setEmpty = () => {
    helpers.setValue("");

    setFieldValue(address, "");
    setFieldValue(contact_number, "");
    setFieldValue(city, "");
    setFieldValue(state, "");
    setFieldValue(gstType, "");
    setFieldValue(gstin, "");
    setFieldValue(contact_number2, "");
  };
  if (!id) {
    setEmpty();
    return;
  }
  const d = data.find((c) => c.id === id);

  if (d) {
    helpers.setValue(d.id);
    setFieldValue(address, d.address);
    setFieldValue(contact_number, d.contact_number);
    setFieldValue(city, d.city);
    setFieldValue(state, d.state);
    setFieldValue(gstType, d.gstType);
    setFieldValue(gstin, d.gstin);
    setFieldValue(contact_number2, d.contact_number2);
  } else {
    setEmpty();
  }
};
