import { useQuery } from "react-query";
import axiosInstance from "../axiosInstance";
import { Debtor } from "../types";
import { getBalance } from "../utils/helpers";

function useGetDebt(customerId: string) {
  const getDebtors = async ({ queryKey }: any) => {
    const res = await axiosInstance.get(`/debtors`, {
      params: {
        customerId,
      },
    });

    return res.data.data;
  };

  const { data: debtorsData } = useQuery<
    Debtor[] | undefined,
    any,
    Debtor[],
    any[]
  >(["getDebtors"], getDebtors);

  if (debtorsData) {
    return getBalance(customerId, debtorsData as []);
  }
}

export default useGetDebt;
