import { AxiosError } from "axios";
import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { MRObj } from "../../types";
import { ERROR, SUCCESS } from "../../types/constants";
import queryKeys from "../../types/queryKeys";
import FileInput from "../FormikComponents/FileInput";
import Input from "../FormikComponents/Input";
import StateCitySelect from "../FormikComponents/StateCitySelect";
import SubmitBtn from "../FormikComponents/SubmitBtn";

const AddMRModal = (props: any) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hideModal());
  };

  const salutationValues = [
    {
      value: "Mr",
      label: "Mr",
    },
    {
      value: "Mrs",
      label: "Mrs",
    },
  ];

  const initialValues: MRObj = {
    name: props?.name || "",
    address: props?.address || "",
    city: props?.city,
    state: props?.state,
    referenceBy: props?.referenceBy || "",
    contactOne: props?.contactOne || "",
    altContact: props?.altContact || "",
    panNo: props?.panNo || "",
    email: props?.email || "",
    bankAc: props?.bankAc || "",
    empId: props?.empId || "",
    dob: props?.dob || "",
    uid: props?.uid || "",
    profile: "",
    profile_pic_url: props?.profile_pic_url || "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    address: Yup.string().required("Address is required"),

    referenceBy: Yup.string().required("Reference is required"),
    contactOne: Yup.string()
      .matches(
        /^((\+*)((0[ -]*)*|((91 )*))((\d{12})+|(\d{10})+))|\d{5}([- ]*)\d{6}$/,
        "Invalid phone number"
      )
      .required("Contact 1 is required"),
    altContact: Yup.string().matches(
      /^((\+*)((0[ -]*)*|((91 )*))((\d{12})+|(\d{10})+))|\d{5}([- ]*)\d{6}$/,
      "Invalid phone number"
    ),
    panNo: Yup.string(),
    email: Yup.string().email("Invalid email"),
    bankAc: Yup.string(),

    // empId: Yup.string(),
    profile_pic_url: Yup.string(),
    dob: Yup.date().max(new Date(), "DOB cannot be a future date"),
    profile: Yup.mixed()
      .test("fileSize", "File Size is too large", (value) =>
        value ? value.size <= 1024 * 1024 * 5 : true
      )
      .test("fileType", "Unsupported File Format", (value) =>
        value
          ? ["image/jpg", "image/jpeg", "image/png"].includes(value.type)
          : true
      ),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
  });

  const handleSubmit = useCallback((value, resetForm: Function) => {
    setIsSubmitting(true);
    console.log(value);
    const postData = {
      ...value,
    };
    axiosInstance
      .post(`/medreps/`, postData)
      .then((response) => {
        const { msg } = response.data;

        setIsSubmitting(false);

        queryClient.invalidateQueries([queryKeys.getMrs]);

        dispatch(
          addToast({
            kind: SUCCESS,
            msg: msg,
          })
        );

        resetForm();
        handleClose();
      })
      .catch((error: AxiosError) => {
        setIsSubmitting(false);

        if (error.response) {
          const response = error.response;
          const { msg } = response.data;

          switch (response.status) {
            // bad request or invalid format or unauthorized
            case 400:
            case 500:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: msg,
                })
              );
              break;
            default:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: "Oops, something went wrong",
                })
              );
              break;
          }
        } else if (error.request) {
          dispatch(
            addToast({
              kind: ERROR,
              msg: "Oops, something went wrong",
            })
          );
        } else {
          dispatch(
            addToast({
              kind: ERROR,
              msg: `Error: ${error.message}`,
            })
          );
        }
      });
  }, []);
  const handleUpdate = (value: MRObj, resetForm: any) => {
    setIsSubmitting(true);
    console.log(value);
    console.log("updateing MR");
    const postData = {
      ...value,
    };
    axiosInstance
      .patch(`/medreps/${props.id}`, postData)
      .then((response) => {
        const { msg } = response.data;

        setIsSubmitting(false);

        queryClient.invalidateQueries([queryKeys.getMrs]);

        dispatch(
          addToast({
            kind: SUCCESS,
            msg: msg,
          })
        );

        resetForm();
        handleClose();
      })
      .catch((error: AxiosError) => {
        setIsSubmitting(false);

        if (error.response) {
          const response = error.response;
          const { msg } = response.data;

          switch (response.status) {
            // bad request or invalid format or unauthorized
            case 400:
            case 500:
            case 403:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: msg,
                })
              );
              break;
            default:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: "Oops, something went wrong",
                })
              );
              break;
          }
        } else if (error.request) {
          dispatch(
            addToast({
              kind: ERROR,
              msg: "Oops, something went wrong",
            })
          );
        } else {
          dispatch(
            addToast({
              kind: ERROR,
              msg: `Error: ${error.message}`,
            })
          );
        }
      });
  };

  return (
    <div className="add-mr-modal">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          props?.id
            ? handleUpdate(values, resetForm)
            : handleSubmit(values, resetForm);
        }}
      >
        <Form className="px-8 py-3 flex items-center gap-x-12">
          <div className="left">
            <div className="row-1 flex items-center gap-x-8 mb-6">
              <Input label="Name" name="name" id="name" type="text" />
              <Input label="Address" name="address" id="address" type="text" />
            </div>
            <div className="row-2 grid grid-cols-3 items-center gap-x-8 mb-6">
              <StateCitySelect stateName="state" cityName="city" />

              <Input
                label="Company"
                name="referenceBy"
                id="referenceBy"
                type="text"
              />
            </div>
            <div className="row-3 flex items-center gap-x-8 mb-6">
              <Input
                label="Contact 1"
                name="contactOne"
                id="contactOne"
                type="text"
              />
              <Input
                label="Contact 2"
                name="altContact"
                id="altContact"
                type="text"
                optional={true}
              />
              <Input label="PAN No." name="panNo" id="panNo" type="text" />
            </div>
            <div className="row-4 flex items-center gap-x-8 mb-6">
              <Input label="Email" name="email" id="email" type="email" />
              <Input label="Bank A/C" name="bankAc" id="bankAc" type="text" />
              {/* <Input label="Employee ID" name="empId" id="empId" type="text" /> */}
            </div>
            <div className="row-5 flex items-center gap-x-8 mb-6">
              <Input label="DOB" name="dob" id="dob" type="date" />
              <Input label="UID" name="uid" id="uid" type="text" />
            </div>
          </div>
          <div className="right h-full">
            <FileInput
              id="profile"
              name="profile"
              label="Profile Img"
              setUrl="profile_pic_url"
              preview={true}
            />

            <SubmitBtn
              classes="mt-2"
              text={props?.id ? "Update" : "Save"}
              isSubmitting={isSubmitting}
            />
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default AddMRModal;
