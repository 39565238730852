import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";

function useUpdatePurchaseOrder() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const handleMutation = (postData: any) => {
    return axiosInstance.post("/purchase", postData);
  };

  return useMutation(handleMutation, {
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries("getAllPurchases");
    },
    onError(err: AxiosError, variables, context) {
      console.log(err);
    },
  });
}

export default useUpdatePurchaseOrder;
