import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../../axiosInstance";
import { addToast } from "../../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../../types/constants";
import queryKeys from "../../../types/queryKeys";
type PostData = {
  discountId: string;
  value: number;
};

function useUpdateDiscountConfig() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const handleMutation = ({ discountId, value }: PostData) => {
    return axiosInstance.patch(`/discounts/${discountId}`, { value });
  };

  return useMutation(handleMutation, {
    onSuccess(data, payload, context) {
      queryClient.invalidateQueries(queryKeys.getCustomers);
      queryClient.invalidateQueries(queryKeys.getDiscountCategory);
      dispatch(
        addToast({
          kind: SUCCESS,
          msg: `Discount Updated  Successfully`,
        })
      );
    },
    onError(data: AxiosError, payload, context) {
      try {
        const msg = data.response?.data?.msg;
        dispatch(
          addToast({
            kind: ERROR,
            msg: msg,
          })
        );
      } catch (error) {
        dispatch(
          addToast({
            kind: ERROR,
            msg: `Unable to Update Discount`,
          })
        );
      }
    },
  });
}

export default useUpdateDiscountConfig;
