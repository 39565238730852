import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useField } from "formik";
import { convertDateToStringForForm } from "../../utils/helpers";

type Props = {
  //   id: string;
  name: string;
};

function CustomDatePicker({ name }: Props) {
  const [field, meta, helper] = useField({ name, type: "object" });
  const handleChange = (value: any) => {
    let formatedVal = "";
    if (value) {
      formatedVal = convertDateToStringForForm(value.$d);
    }
    helper.setValue(formatedVal);
  };

  return (
    <DatePicker
      sx={{
        ".MuiOutlinedInput-root": {
          paddingY: "8px",
          paddingX: "8px",
          backgroundColor: "#E2E8F0",
          border: "none",
        },
      }}
      {...field}
      value={dayjs(field.value)}
      onChange={handleChange}
    />
  );
}

export default CustomDatePicker;
