import { Form, Formik } from "formik";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import CustomSubmitBtn from "../../../components/FormikComponents/CustomSubmitBtn";
import UpdateProductForm from "../../../components/NestedFormComponents/Sales/UpdateProductForm";
import HandleSave from "../../../components/Save/HandleSave";
import SaveButton from "../../../components/Save/SaveButton";
import useGetItemsFromSalesOrder from "../../../hooks/sales/useGetItemsFromSalesOrder";
import useUpdateSalesOrder from "../../../hooks/sales/useUpdateSalesOrder";
import useGetOrderById from "../../../hooks/useGetOrderById";
import { addToast } from "../../../redux/features/toastSlice";
import { SUCCESS } from "../../../types/constants";
import {
  ORDER_STATUS,
  ORDER_TYPE_SALES,
  SalesOrderObj,
} from "../../../types/sales.types";
import { gstTypes } from "../../../utils/constants/orderConstants";
import { convertDateToStringForForm } from "../../../utils/helpers";
import SalesCalculationForm from "../SalesCalculationForm";
import SalesCustomerForm from "../SalesCustomerForm";

type Props = {
  isInitiated?: boolean;
};

type PostData = {
  data: {
    productProductId: any;
    sale_qty: any;
    d1: any;
    d2: any;
  }[];
  id: string | undefined;
  date: string;
  remark: string;
  customerId: string;
  medrepId: string;
  sp_discount: number;
  bal: string;
  cartons: string;
  gst_type: string;
  order_type_sales: ORDER_TYPE_SALES;
  status: ORDER_STATUS;
  sales_gst_amount: string;
  taxable_amount: number;
  amount: number;
};

const EditSalesOrder = ({ isInitiated }: Props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const { data: dataOrder } = useGetOrderById(params.id!, "sales");
  const { mutate, isLoading: isSubmitting } = useUpdateSalesOrder();

  const { data: dataItems } = useGetItemsFromSalesOrder(params.id!);

  const initialValues: SalesOrderObj = {
    customer: dataOrder?.customer,

    heads: dataItems || [],
    total: 0,
    sp_discount: dataOrder?.sp_discount || 0,
    sales_gst_amount: "",
    date: convertDateToStringForForm(dataOrder?.date || new Date()),
    orderBy: dataOrder?.medrepId
      ? {
          value: dataOrder?.medrepId,
          label: dataOrder.medrep.name,
        }
      : { label: "", value: "" },
    bal: "0",
    cartons: "0",
    gstType: dataOrder?.gst_type || "",
    remarks: dataOrder?.remark || "",
    discount: 0,
    order_type_sales: dataOrder?.order_type_sales || ORDER_TYPE_SALES.Invoiced,
    number_of_pages: dataOrder?.number_of_pages || 0,
    taxable_amount: dataOrder?.taxable_amount || "",
  };

  const validationSchema = Yup.object({
    customer: Yup.object({
      id: Yup.string().required("Full name is required"),
    }),

    heads: Yup.array()
      .of(Yup.object())
      .min(1, "Please provide atleast one Item")
      .required("Please provide atleast one Item"),
    taxable_amount: Yup.number()
      .required("Total Taxable is required")
      .positive(),
    sp_discount: Yup.string().required("Special Dis is required"),
    sales_gst_amount: Yup.string().required("GST is required"),
    total: Yup.number().required("Net total is required").positive(),
    date: Yup.date()
      .max(new Date(), "Sale Date cannot be a future date")
      .required("Sale Date is required"),
    orderBy: Yup.object({
      label: Yup.string().required("Order By is required"),
      value: Yup.string(),
    }),
    cartons: Yup.number().required("Cartons is required"),
    gstType: Yup.string()
      .oneOf(gstTypes.map((g) => g.value))
      .required("GST Type is required"),
    remarks: Yup.string(),
    order_type_sales: Yup.string().required("Order Type is required"),
  });

  const handleSubmit = useCallback(
    (order: SalesOrderObj, isNavigate?: boolean) => {
      mutate(
        {
          data: order.heads.map((h) => {
            return {
              productProductId: h.product_name.value,
              sale_qty: h.sale_qty,
              d1: h.d1 || 0,
              d2: h.d2 || 0,
            };
          }),
          id: params.id,
          status: ORDER_STATUS.created,
          amount: order.total,
          date: new Date(order.date).toISOString(),
          remark: order.remarks,
          customerId: order.customer?.id!,
          medrepId: order.orderBy.value,
          sp_discount: order.sp_discount,
          cartons: order.cartons,
          gst_type: order.gstType,
          sales_gst_amount: Number(order.sales_gst_amount),
          taxable_amount: Number(order.taxable_amount),
          order_type_sales: order.order_type_sales,
          number_of_pages: order.number_of_pages,
        },
        {
          onSuccess() {
            dispatch(
              addToast({
                kind: SUCCESS,
                msg: "Order has been Saved successfully",
              })
            );
            if (isNavigate && isInitiated) navigate("/sales/created");
          },
        }
      );
    },
    []
  );

  return (
    <div className="create-order">
      <h3 className="text-xl font-bold mb-4">Edit Order</h3>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => handleSubmit(values)}
        enableReinitialize
      >
        {({ values }) => (
          <Form>
            <SalesCustomerForm />

            <UpdateProductForm fieldName="heads" />
            <SalesCalculationForm />

            <div className="buttons flex w-full mt-12 items-center justify-end gap-x-4">
              <HandleSave handleSave={() => handleSubmit(values)} />

              <SaveButton disabled={isSubmitting} />
              {isInitiated ? (
                <div className="w-44">
                  <CustomSubmitBtn
                    onSubmit={() => handleSubmit(values, true)}
                    text="Create Order"
                    classes="w-44"
                    disabled={isSubmitting}
                  />
                </div>
              ) : null}
              {/* <div className="w-44">
                <SubmitBtn
                  text="Save Order"
                  classes="w-44"
                  isSubmitting={isSubmitting}
                />
              </div> */}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditSalesOrder;
