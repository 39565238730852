import { useState } from "react";
import { HiTrash } from "react-icons/hi";
import useDropDiscountConfig from "../../../hooks/discounts/customerWise/useDropDiscountConfig";
import TableInstance from "../../Table/TableInstance";

function ShowAllDiscountItemsModal({ data, handleClose }: any) {
  console.log(data);
  const { mutate: dropDiscount, isLoading } = useDropDiscountConfig();

  const DISCOUNT_COLUMNS = [
    {
      Header: "Company Name",
      accessor: "company.name",
    },
    {
      Header: "Head",
      accessor: "head.head",
    },
    {
      Header: "Item",
      accessor: "product_name",
    },

    {
      Header: "Potency",
      accessor: "head.potency",
    },
    {
      Header: "Size",
      accessor: "head.size",
    },

    {
      Header: "Discount (%)",
      accessor: () => data.value,
    },

    {
      Header: "",
      accessor: "editBtn",
      Cell: ({ row }: any) => {
        return (
          <div className="btns flex items-center justify-center gap-4">
            <button
              className="btn-danger flex items-center"
              disabled={isLoading}
              onClick={() => {
                dropDiscount(row.original.discountId, {
                  onSuccess() {
                    handleClose();
                  },
                });
              }}
            >
              <span className="mr-1"> Remove </span>
              <HiTrash />
            </button>
          </div>
        );
      },
    },
  ];

  const [cPageSize, cSetPageSize] = useState(5);
  const [pageIndex, setPageIndex] = useState(0);
  const [cSortBy, cSetSortBy] = useState("company");
  const [desc, setDesc] = useState(false);
  const [q, setQ] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);

  return (
    <div className="discount my-12 mx-10">
      {data && (
        <TableInstance
          tableData={data?.products || []}
          columnName={DISCOUNT_COLUMNS}
          cPageSize={cPageSize}
          cSetPageSize={cSetPageSize}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          pageCount={-1} // do calculation here
          cSortBy={cSortBy}
          cSetSortBy={cSetSortBy}
          desc={desc}
          setDesc={setDesc}
          q={q}
          setQ={setQ}
          setSelectedRows={setSelectedRows}
          selectRow={false}
        />
      )}
    </div>
  );
}

export default ShowAllDiscountItemsModal;
