import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import queryKeys from "../../types/queryKeys";
type payLoad = {
  name: string;
  id?: string;
};
function useMutateDiscountCategory() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const handleMutation = (postData: payLoad) => {
    return axiosInstance.post(`/discounts/category`, postData);
  };

  return useMutation(handleMutation, {
    onSuccess(data, payload, context) {
      queryClient.invalidateQueries(queryKeys.getDiscountCategory);
      dispatch(
        addToast({
          kind: SUCCESS,
          msg: `Category ${payload?.id ? "Updated" : "Created"} Successfully`,
        })
      );
    },
    onError(data: AxiosError, payload, context) {
      try {
        const msg = data.response?.data?.msg;
        dispatch(
          addToast({
            kind: ERROR,
            msg: msg,
          })
        );
      } catch (error) {
        dispatch(
          addToast({
            kind: ERROR,
            msg: `Unable to  ${payload?.id ? "Updated" : "Created"} Category`,
          })
        );
      }
    },
  });
}

export default useMutateDiscountCategory;
