import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import useDropDiscountConfig from "../../hooks/discounts/customerWise/useDropDiscountConfig";
import { showModal } from "../../redux/features/modalSlice";
import { MODALS } from "../../types/constants";
import TableInstance from "../Table/TableInstance";

type Props = {
  data: any[];
};
function CustomerDiscountItemsList({ data }: Props) {
  const params = useParams();
  const dispatch = useDispatch();
  const { mutate: dropDiscount, isLoading } = useDropDiscountConfig();

  const DISCOUNT_COLUMNS = [
    {
      Header: "Company Name",
      accessor: "company",
    },
    {
      Header: "Head",
      accessor: "head",
    },
    {
      Header: "Item",
      accessor: "products",
      Cell: ({ value }: any) => {
        return value.length === 1
          ? value.map((v: any) => (
              <span className="bg-blue-400  rounded py-1 px-1.5">
                {v.product_name}
              </span>
            ))
          : value.length + " items";
      },
    },

    {
      Header: "Discount (%)",
      accessor: "value",
    },

    {
      Header: "",
      accessor: "editBtn",
      Cell: ({ row }: any) => {
        return (
          <div className="btns flex items-center justify-center gap-4">
            {/* <button
              onClick={() => {
                dispatch(
                  showModal({
                    modalType: UPDATE_CONFIG_DISCOUNT_MODAL,
                    modalTitle: "Update Discount",
                    modalProps: {
                      data: row.original,
                    },
                  })
                );
              }}
              className="btn-primary flex items-center"
            >
              <span className="mr-1">Update </span>
            </button> */}
            <button
              className="btn-primary flex items-center"
              disabled={isLoading}
              onClick={() => {
                dispatch(
                  showModal({
                    modalType: MODALS.showAllDiscountItems,
                    modalTitle: "All Item's Discount",
                    modalProps: {
                      data: row.original,
                    },
                  })
                );
                // navigate(`/discount/category/${row.original.id}/update`);
              }}
            >
              <span className="mr-1"> Show Items </span>
            </button>
          </div>
        );
      },
    },
  ];

  const [cPageSize, cSetPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [cSortBy, cSetSortBy] = useState("company");
  const [desc, setDesc] = useState(false);
  const [q, setQ] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);

  return (
    <div className="discount mt-12">
      {data && (
        <TableInstance
          tableData={data || []}
          columnName={DISCOUNT_COLUMNS}
          cPageSize={cPageSize}
          cSetPageSize={cSetPageSize}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          pageCount={-1} // do calculation here
          cSortBy={cSortBy}
          cSetSortBy={cSetSortBy}
          desc={desc}
          setDesc={setDesc}
          q={q}
          setQ={setQ}
          setSelectedRows={setSelectedRows}
          selectRow={false}
        />
      )}
    </div>
  );
}

export default CustomerDiscountItemsList;
