import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../../axiosInstance";
import { addToast } from "../../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../../types/constants";
import queryKeys from "../../../types/queryKeys";

type PostData = {
  data: {
    products: string[];
    order_id: string;
  };
  idsToDelete: string[];
};
function useShortItems() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  // const { mutate: deleteItemFromList } = useDropProductFromSalesOrder();

  const handleMutation = (orderId: string) => {
    return axiosInstance.post(`/short/order/${orderId}`);
  };

  return useMutation(handleMutation, {
    onSuccess(data, payload, context) {
      // payload.idsToDelete.forEach((p: string) => {
      //   deleteItemFromList(p);
      // });
      dispatch(addToast({ kind: SUCCESS, msg: "Shorting Successful" }));

      queryClient.invalidateQueries(queryKeys.getItemsFromSalesOrder);
    },
    onError(data, variables, context) {
      dispatch(addToast({ kind: ERROR, msg: "Shorting Failed" }));
    },
  });
}

export default useShortItems;
