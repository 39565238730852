import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import axiosInstance from "../../../axiosInstance";
import queryKeys from "../../../types/queryKeys";
type postData = {
  room_no: string;
  room_id: string;
};
function useUpdateRoomNo() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ room_id, ...postData }: postData) =>
      axiosInstance.patch(`/room/${room_id}`, postData),
    {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries(queryKeys.getRoomNo);
        toast.success("Room No Updated successfully");
      },
      onError(err: AxiosError, variables, context) {
        try {
          const { msg } = err?.response?.data;
          toast.error(msg);
        } catch (error) {
          toast.error("Something went wrong");
        }
      },
    }
  );
}

export default useUpdateRoomNo;
