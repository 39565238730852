import { Form, Formik } from "formik";
import { useCallback } from "react";
import { HiUserGroup } from "react-icons/hi";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import useMutateDebit from "../../hooks/Accounts/debtors/useMutateDebit";
import useGetMRs from "../../hooks/useGetMRs";
import { hideModal } from "../../redux/features/modalSlice";
import { Deposit, Option } from "../../types";
import { convertDateToStringForForm } from "../../utils/helpers";
import Input from "../FormikComponents/Input";
import RemoteSelect from "../FormikComponents/RemoteSelect";
import Select from "../FormikComponents/Select";
import SubmitBtn from "../FormikComponents/SubmitBtn";

interface PurchaseObj {
  paymentDetail: string;
  amount: string;
  discount: string;
  transaction_date: string;
  modeOfPayment: string;
  medrepId?: Option;
  fromBank: string;
  accountId?: Option;
  customerId?: string;
  expected_date: string;
  chequeNo: string;
}

const AddReceiptForm = ({ customerId }: { customerId?: string }) => {
  const dispatch = useDispatch();
  const param = useParams();

  const MODE_OF_PAYMENT = [
    {
      label: "Cash",
      value: "Cash",
    },
    {
      label: "Cheque",
      value: "Cheque",
    },
    {
      label: "Online",
      value: "Online",
    },
    {
      label: "NEFT",
      value: "NEFT",
    },

    // {
    //   label: "Credit",
    //   value: "Credit",
    // },

    {
      label: "UPI",
      value: "UPI",
    },
    {
      label: "Difference Adjustment",
      value: "Difference Adjustment",
    },
    {
      label: "Return Goods",
      value: "Return Goods",
    },
  ];

  const initialValues: PurchaseObj = {
    paymentDetail: "",
    amount: "",
    discount: "",
    transaction_date: convertDateToStringForForm(new Date()),
    modeOfPayment: "",
    medrepId: undefined,
    fromBank: "",
    accountId: undefined,
    customerId: "",
    expected_date: "",
    chequeNo: "",
  };

  const { isFetchingMRs, isLoadingMRs, queryKeyMrs, optionMRs, errorMRs } =
    useGetMRs();

  const getDeposit = async ({ queryKey }: any) => {
    const { data } = await axiosInstance.get(`/accounts`);
    //console.log("Dev test",data.data)
    return data.data.map((r: any) => {
      return {
        value: r.id,
        label: r.bank_name,
      };
    });
  };

  const {
    data: depositData,
    isFetching: depositIsFetching,
    error: depositIsError,
    isLoading: depositIsLoading,
  } = useQuery<Deposit[] | undefined, any, Deposit[], any[]>(
    ["getDeposit"],
    getDeposit
  );
  // console.log(depositData);

  const validationSchema = Yup.object().shape({
    paymentDetail: Yup.string().required("Payment Detail is required"),
    amount: Yup.string().required("Received is required"),
    transaction_date: Yup.date()
      .max(new Date(), "Transaction Date cannot be a future date")
      .required("Date is required"),
    modeOfPayment: Yup.string()
      .oneOf(
        MODE_OF_PAYMENT.map((v) => v.value),
        "Invalid value"
      )
      .required("Mode of payment is required"),
    medrepId: Yup.object({
      label: Yup.string().required("MR is required"),
      value: Yup.string(),
    }),
    // fromBank: Yup.string().required("fromBank is required"),
    accountId: Yup.object().when("modeOfPayment", {
      is: (modeOfPayment: string) => modeOfPayment !== "Cash",
      then: Yup.object({
        label: Yup.string().required("Bank is required"),
        value: Yup.string(),
      }),
    }),
    expected_date: Yup.date().when("modeOfPayment", {
      is: (modeOfPayment: string) => modeOfPayment !== "Cash",
      then: Yup.date().required("Expected Date is required"),
    }),
    chequeNo: Yup.string().when("modeOfPayment", {
      is: (modeOfPayment: string) => modeOfPayment !== "Cash",
      then: Yup.string().required("Cheque No/ transaction No. is required"),
    }),
  });

  const { mutate, isLoading } = useMutateDebit();

  const handleSubmit = useCallback((value: PurchaseObj, resetForm: any) => {
    console.log(value);

    mutate(
      {
        particulars: value.paymentDetail,
        payment_details: value.paymentDetail,
        amount: -parseInt(value.amount),
        discount: Number(value.discount),
        transaction_date: value.transaction_date,
        mode: value.modeOfPayment,
        medrepId: value.medrepId?.value!,
        fromBank: value.fromBank,
        bankaccountId: value.accountId?.value!,
        customerId: customerId || param.id!,
        expected_date: value.expected_date || undefined,
        transaction_id: value.chequeNo,
      },
      {
        onSuccess() {
          if (customerId) {
            dispatch(hideModal());
          } else resetForm();
        },
      }
    );
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
    >
      <Form>
        <div className="receipt-form px-4 py-4 rounded border border-gray-300 gap-x-4 relative mb-12">
          <div className="row-1 flex items-center mb-6 gap-x-4">
            <Input
              id="paymentDetail"
              name="paymentDetail"
              label="Payment Detail"
              type="text"
            />
            <Input
              id="discount"
              name="discount"
              label="Reciept No."
              type="text"
            />

            {/* <Input id="discount" name="discount" label="Discount" type="text" /> */}
            <Input
              id="transaction_date"
              name="transaction_date"
              label="Transaction Date"
              type="date"
            />
            <Input
              id="amount"
              name="amount"
              label="Received (Rs)"
              type="number"
            />

            <RemoteSelect
              label="Received By MR"
              name="medrepId"
              id="medrepId"
              error={errorMRs}
              isFetching={isFetchingMRs}
              isLoading={isLoadingMRs}
              options={optionMRs || []}
              queryKey={[queryKeyMrs]}
              classes="w-full"
            />
          </div>
          <div className="row-2 flex items-center mb-6 gap-x-4">
            <Select
              label="Mode Of Payment"
              name="modeOfPayment"
              id="modeOfPayment"
              classes="w-full"
              options={MODE_OF_PAYMENT}
            />

            <Input
              id="chequeNo"
              name="chequeNo"
              label="Cheque No./Transaction ID"
              type="text"
            />
            {/* <Input
              id="fromBank"
              name="fromBank"
              label="From Bank"
              type="text"
            /> */}
            <Input
              id="expected_date"
              name="expected_date"
              label="Expected Date"
              type="date"
            />
            <RemoteSelect
              label="Deposit in Bank"
              name="accountId"
              id="accountId"
              error={depositIsError}
              isFetching={depositIsFetching}
              isLoading={depositIsLoading}
              options={depositData || []}
              queryKey={["getDeposit"]}
              classes="w-full"
              isClearable
            />
          </div>

          <div className="label absolute right-5 -bottom-4 bg-gray-300 rounded text-sm flex items-center font-semibold p-1">
            <HiUserGroup size={16} className="mr-1" />
            Receipt
          </div>
        </div>

        <div className="buttons flex items-center justify-center gap-x-5 pb-12">
          <SubmitBtn text="Update" classes="w-44" isSubmitting={isLoading} />
          {/* <button type="button" className="btn-danger w-44">
            Cancel
          </button> */}
        </div>
      </Form>
    </Formik>
  );
};

export default AddReceiptForm;
function getTransaction() {
  throw new Error("Function not implemented.");
}
