import { useEffect, useState } from "react";
import { IconType } from "react-icons";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { RootState } from "../../redux/app";

interface Props {
  to: string;
  Icon: IconType;
  text: string;
  list?: string[];
  regexList?: RegExp[];
  smallText: string;
}

const SideBarLink = ({ to, Icon, text, smallText, list, regexList }: Props) => {
  const { pathname } = useLocation();

  let { show } = useSelector((state: RootState) => state.sidebar);

  const [isActive, setIsActive] = useState<boolean>();

  useEffect(() => {
    setIsActive(pathname === to || list?.includes(pathname));
    if (regexList) {
      regexList.forEach((regex) => {
        if (regex.test(pathname)) {
          setIsActive(true);
        }
      });
    }
  }, [pathname, list]);

  return (
    <li>
      <NavLink
        to={to}
        end
        className={`sidebar-link w-full ${
          isActive ? "bg-primary" : "hover:bg-neutral-600"
        } flex ${
          show
            ? "flex-row justify-start items-center px-5 pl-6 h-14"
            : "flex-col items-center justify-center"
        } py-3`}
      >
        <Icon
          size={24}
          className={`icon-lg ${show ? "mr-2" : "mr-0"}  sidebar-link__icon`}
        />

        <span className={`text-base ${show ? "block" : "hidden"}`}>{text}</span>
        <span
          className={`mt-1.5 ${
            show ? "hidden" : "block"
          } font-semibold text-xs`}
        >
          {smallText}
        </span>
      </NavLink>
    </li>
  );
};

export default SideBarLink;
