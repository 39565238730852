import { forwardRef, useEffect, useState } from "react";
import useGetItemsFromSalesOrder from "../../../hooks/sales/useGetItemsFromSalesOrder";
import { ORDER_STATUS } from "../../../types/sales.types";
import { localestringToDate } from "../../../utils/helpers";

type Props = {
  data: any;
};

const PrintChallan = forwardRef(({ data }: Props, ref: any) => {
  const [totals, setTotals] = useState({
    qty: 0,
    feeQty: 0,
  });

  const { data: dataItems, isSuccess } = useGetItemsFromSalesOrder(data.id!);

  const details = [
    {
      label: "ORDER NO",
      value: data.order_number,
    },
    {
      label: "ORDER PROCESSED BY",
      value: "",
    },
    {
      label: "CHALLAN NO.",
      value: data.status === ORDER_STATUS.approved ? data.order_number : "",
    },
    {
      label: "ORDER REMARK",
      value: data.remark,
    },
    {
      label: "ORDER DATE",
      value: data.date ? localestringToDate(data.date) : "",
    },
    {
      label: "ACCOUNT REMARK",
      value: data.invoice_date ? localestringToDate(data.invoice_date) : "",
    },
  ];

  const colums = [
    {
      label: "SR.",
    },
    {
      label: "Room No.",
    },
    {
      label: "Description of goods",
    },

    {
      label: "BACH NO.",
    },
    {
      label: "MFG",
    },
    {
      label: "EXP",
    },
    {
      label: "M.R.P.",
    },
    {
      label: "QTY.",
    },
    {
      label: "SCHEME QTY.",
    },
  ];
  const formatDate = (date: string) => {
    if (!date) return "";
    const arr = date.split("-");
    return arr[1] + "-" + arr[0];
  };

  useEffect(() => {
    if (dataItems?.length) {
      let total = 0,
        feeQty = 0;
      dataItems.forEach((el) => {
        if (el.sale_qty) total += Number(el.sale_qty);
        if (el.free_qty) feeQty += Number(el.free_qty);
      });
      setTotals({ qty: total, feeQty });
    }
  }, [dataItems]);

  const footerDetails = [
    "Checked By:",
    "Packed By: ",
    "Date:",
    "Date: ",
    "Time: ",
    "Time: ",
  ];

  return (
    <div ref={ref} className="flex flex-col justify-center gap-10">
      <h1 className=" w-full text-2xl font-bold ">
        NEW LIFE HOMOEO DISTRIBUTORS (ORDER/CHALLAN)
      </h1>

      <div className="border-collapse  border border-black   grid grid-cols-2">
        {details.map((d, i) => (
          <div className="border border-black p-1 " key={i}>
            <span>{d.label}</span>:<span className="ml-2">{d.value}</span>
          </div>
        ))}
      </div>

      {dataItems?.length && (
        <table className="print-table  ">
          <thead>
            <tr>
              {colums.map((h, i) => (
                <th className="">{h.label}</th>
              ))}
            </tr>
          </thead>
          <tbody className="">
            {isSuccess &&
              dataItems.map((e: any, index: number) => (
                <tr key={index} className="">
                  <td className="">{index + 1}</td>
                  <td className="">{e.room_no}</td>
                  <td className="">{e.product.product_name}</td>
                  <td className="">{e.batch_number}</td>
                  <td className="">{formatDate(e.mfg_date)}</td>
                  <td className="">{formatDate(e.exp_date)}</td>
                  <td className="">{e.mrp}</td>
                  <td className="">{e.sale_qty}</td>
                  <td className="">{e.free_qty}</td>
                </tr>
              ))}
            <tr className="font-bold">
              <td colSpan={7} className="">
                Total
              </td>
              <td className="">{totals.qty}</td>
              <td className="">{totals.feeQty}</td>
            </tr>
          </tbody>
        </table>
      )}
      <div className="w-full font-bold grid grid-cols-2 ">
        {footerDetails.map((d, i) => (
          <div key={i}>{d}</div>
        ))}
      </div>
    </div>
  );
});

export default PrintChallan;
