import { FieldArray, Formik, useField, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { HiTag } from "react-icons/hi";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../../axiosInstance";
import { FROM, INFO } from "../../../types/constants";
import CustomSubmitBtn from "../../FormikComponents/CustomSubmitBtn";
import HeadInput from "../../FormikComponents/HeadInput";
import Input from "../../FormikComponents/Input";

import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import useAddItemInPurchaseOrder from "../../../hooks/purchase/useAddItemInPurchaseOrder";
import useGetCompanies from "../../../hooks/useGetCompanies";
import { addToast } from "../../../redux/features/toastSlice";
import { Option } from "../../../types";
import queryKeys from "../../../types/queryKeys";
import focusHead from "../../../utils/focusHead";
import {
  convertDateToStringForForm,
  getStartAndEndDate,
} from "../../../utils/helpers";
import CustomSelect from "../../FormikComponents/CustomSelect";
import EditableCell from "../../FormikComponents/EditableCell";
import SingleValueReactSelect from "../../FormikComponents/SingleValueReactSelect";
import TableInstance from "../../Table/TableInstance";
import ConfirmPurchaseTable from "./ConfirmPurchaseTable";
// import EditableCell from "../FormikComponents/EditableCell";

interface Props {
  fieldName: string;
}

interface PurchaseObj {
  supplier: string;
  purchase_date: string;
  bill_number: string;
  remark: string;
  pNo: string;
  heads: any[];
  amount: number;
  taxable_amount: number;
  gst_amount: number;
  gst_type: string;
  other_charges: number;
  netTotal: number;
  cd_discount_rate: number;
  cdAmount: number;
}

interface HeadObj {
  head: string;
  company: string;
  potency: string;
  size: string;
  fromDate: string;
  toDate: string;
}

const AddItemsInPurchaseOrder = ({ fieldName }: Props) => {
  const dispatch = useDispatch();
  const [field, meta, helpers] = useField(fieldName);
  const [potencies, setPotencies] = useState<string[]>([]);
  const [sizes, setSizes] = useState<string[]>([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchItemsData, setSearchItemsData] = useState<any>([]);
  const [orderedItems, setOrderedItems] = useState<any>([]);
  const { mutate } = useAddItemInPurchaseOrder();
  const queryClient = useQueryClient();
  const params = useParams();

  const addOrderedItems = (data: any) => {
    setOrderedItems([...data]);
  };

  const SEARCH_ITEMS = [
    {
      Header: "Sr",
      accessor: (_row: any, index: number) => index + 1,
    },
    {
      Header: "Head",
      accessor: "head.head",
    },
    {
      Header: "Company",
      accessor: "company.name",
    },
    {
      Header: "Item Name",
      accessor: "product_name",
    },
    {
      Header: "Potency",
      accessor: "head.potency",
    },
    {
      Header: "Size",
      accessor: "head.size",
    },
    {
      Header: "Sale Qty",
      accessor: "salesQuantity",
    },
    {
      Header: "Stock",
      accessor: "stock",
    },
    {
      Header: "Order Qty ",
      accessor: (row: any, index: number) => {
        return row.salesQuantity - row.stock;
      },
    },
    {
      Header: "Order Qty",
      accessor: "order_qty",
      editable: true,
      Cell: (props: any) => {
        return (
          <div>
            <EditableCell
              value={props.value}
              row={props.row}
              column={props.column}
              setOrderedItems={addOrderedItems}
              orderedItems={orderedItems}
              from={FROM.CREATE_PURCHASE}
            />
          </div>
        );
      },
    },
  ];

  const [cPageSize, cSetPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [cSortBy, cSetSortBy] = useState("");
  const [desc, setDesc] = useState(true);
  // const [startDate, setStartDate] = useState(new Date().toISOString());
  const [q, setQ] = useState("");

  const [currentMaxQty, setCurrentMaxQty] = useState<number | null>(null);

  const { setFieldValue, setFieldError, setFieldTouched, values } =
    useFormikContext<PurchaseObj>();

  useEffect(() => {
    const {
      taxable_amount,
      other_charges,
      cd_discount_rate,
      netTotal,
      cdAmount,
      gst_amount,
    } = values;
    setFieldValue(
      "cdAmount",
      ((Number(taxable_amount) * Number(cd_discount_rate)) / 100).toFixed(2)
    );
    setFieldValue(
      "netTotal",
      (
        Number(taxable_amount) +
        Number(other_charges) +
        Number(gst_amount)
      ).toFixed(2)
    );
    setFieldValue("amount", (Number(netTotal) - Number(cdAmount)).toFixed(2));
  }, [
    values.taxable_amount,
    values.other_charges,
    values.cd_discount_rate,
    values.netTotal,
    values.cdAmount,
    values.gst_amount,
  ]);

  const initialValues: HeadObj = {
    head: "",
    company: "",
    potency: "",
    size: "",
    fromDate: convertDateToStringForForm(getStartAndEndDate(90).startDate),
    toDate: convertDateToStringForForm(new Date()),
  };

  const { dataCompany, isLoadingCompany, isFetchingCompany, errorCompany } =
    useGetCompanies();

  const validationSchema = Yup.object({
    head: Yup.array(),
    company: Yup.object().nullable(),
    fromDate: Yup.date()
      .required("From date is required")
      .max(new Date(), "From date cannot be a future date"),
    toDate: Yup.date()
      .required("To date is required")
      .min(Yup.ref("fromDate"), "To date must be after From date")
      .max(new Date(), "To date cannot be a future date"),
  });

  const handleMulitpleOrdersSubmit = (values: HeadObj | any) => {
    console.log("handle", values);

    const postData: any = {
      type: "purchase",
      orderId: params.id!,
      items: values.map((v: any) => ({
        ordered_qty: v.orderedQty,
        item_name: v.product.product_name,
        headId: v.product.headId,
        companyId: v.product.companyId,
      })),
    };

    mutate(postData, {
      onSuccess: () => {
        queryClient.invalidateQueries([
          queryKeys.getItemsFromPurchaseOrder,
          params.id!,
        ]);
        setSearchItemsData([]);
        focusHead();
      },
    });
  };

  const searchItems = (
    values: any,
    resetForm: Function,
    setSubmitting: Function
  ) => {
    setSearchItemsData([]);
    console.log("searchItems", values);
    const { company, head, potency, size, fromDate, toDate } = values;
    axiosInstance
      .get(`/items`, {
        params: {
          "$head.head$": head?.map((v: Option) => v.value),
          "$head.potency$": potency,
          "$head.size$": size,
          companyId: company?.value,
          start_date: new Date(fromDate).toISOString(),
          end_date: new Date(toDate).toISOString(),
          getSales: true,
        },
      })
      .then((res) => {
        // console.log("res", res.data);
        const data = res.data.data.map((item: any) => ({
          ...item,
          order_qty: 0,
        }));
        if (data.length === 0) {
          dispatch(addToast({ kind: INFO, msg: "No Item Found" }));
        }
        setSearchItemsData(data);
        // resetForm();
        setSubmitting(false);
      })
      .catch((err) => {
        setSubmitting(false);
        console.log("err", err);
        setSearchItemsData([]);
      });
  };

  return (
    <div>
      <FieldArray name="heads">
        {(fieldArrayProps) => {
          return (
            <>
              <div className=" search-head-form flex flex-col justify-center px-4 py-4 rounded border border-gray-300 gap-x-4 relative mb-14">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(values, { resetForm, setSubmitting }) =>
                    searchItems(values, resetForm, setSubmitting)
                  }
                >
                  {(formik) => (
                    <>
                      <div className="row-1 flex items-center mb-2 gap-x-4">
                        <HeadInput
                          isMulti
                          setSizes={setSizes}
                          setPotencies={setPotencies}
                          dependencies={{
                            potency: "potency",
                            size: "size",
                            stock: "stock",
                          }}
                          classes="w-full"
                          id="head"
                          name="head"
                          label="Head"
                        />
                        <CustomSelect
                          options={dataCompany || []}
                          id="company"
                          name="company"
                          label="Company"
                          classes="w-full"
                          isClearable
                          isDisabled={dataCompany?.length === 0}
                          placeholder="Select Company"
                        />

                        <SingleValueReactSelect
                          id="potency"
                          name="potency"
                          label="Potency"
                          isClearable
                          isDisabled={potencies.length === 0}
                          options={potencies}
                          classes="w-full"
                          placeholder="Select Potency"
                        />

                        <SingleValueReactSelect
                          classes="w-full"
                          id="size"
                          name="size"
                          label="Size"
                          isClearable
                          placeholder="Select Size"
                          isDisabled={sizes.length === 0}
                          options={sizes}
                        />

                        <Input
                          label="From"
                          name="fromDate"
                          id="fromDate"
                          type="date"
                        />
                        <Input
                          label="To"
                          name="toDate"
                          id="toDate"
                          type="date"
                        />

                        <CustomSubmitBtn
                          text="Search"
                          classes="w-44 my-3"
                          disabled={formik.isSubmitting}
                          onSubmit={(e) => {
                            e.preventDefault();

                            formik.handleSubmit();
                          }}
                        />
                      </div>

                      {/* <div className="buttons flex w-full items-center justify-center"></div> */}

                      <div className="label absolute right-5 -bottom-4 bg-gray-300 rounded text-sm flex items-center font-semibold p-1">
                        <HiTag size={16} className="mr-1" />
                        Purchase Items
                      </div>
                    </>
                  )}
                </Formik>
              </div>

              {searchItemsData.length > 0 && (
                <>
                  <TableInstance
                    tableData={searchItemsData || []}
                    columnName={SEARCH_ITEMS}
                    cPageSize={cPageSize}
                    cSetPageSize={cSetPageSize}
                    pageIndex={pageIndex}
                    setPageIndex={setPageIndex}
                    pageCount={-1} // do calculation here
                    cSortBy={cSortBy}
                    cSetSortBy={cSetSortBy}
                    desc={desc}
                    setDesc={setDesc}
                    q={q}
                    setQ={setQ}
                    setSelectedRows={setSelectedRows}
                    selectRow={false}
                  />
                  {orderedItems.length > 0 && (
                    <div className="flex justify-center my-9 ">
                      <button
                        type="button"
                        onClick={() => handleMulitpleOrdersSubmit(orderedItems)}
                        className={`btn-primary w-full disabled:opacity-40 disabled:cursor-not-allowed focus:bg-sky-700 max-w-xs `}
                      >
                        Add
                      </button>
                    </div>
                  )}
                </>
              )}

              <ConfirmPurchaseTable isOrdered_qtyTaxble fieldName="heads" />
            </>
          );
        }}
      </FieldArray>
    </div>
  );
};

export default AddItemsInPurchaseOrder;
