import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../axiosInstance";
import { addToast } from "../redux/features/toastSlice";
import { ItemObj } from "../types";
import { INFO } from "../types/constants";
import queryKeys from "../types/queryKeys";
type Parmas = {
  headId?: string;
  "$head.head$"?: string;
  "$head.potency$"?: string;
  "$head.size$"?: string;
  "$head.hsn_number$"?: string;
  "$head.gst$"?: string;
  product_name?: string;
  companyId?: string;
  start_date?: string;
  end_date?: string;
  getSales?: boolean;
};

function useGetItems(params: Parmas = {}) {
  const dispatch = useDispatch();

  const getItems = async ({ queryKey }: any) => {
    const [_key, params] = queryKey;
    const { data } = await axiosInstance.get(`/items`, {
      params,
    });

    return data.data;
  };
  const queryKeyItems = queryKeys.getItems;

  const {
    data: dataItems,
    error: errorItems,
    isLoading: isLoadingItems,
    isFetching: isFetchingItems,
  } = useQuery<ItemObj[] | undefined, any, ItemObj[], any[]>(
    [queryKeyItems, params],
    getItems,
    {
      onSuccess(data) {
        if (data.length === 0) {
          dispatch(addToast({ kind: INFO, msg: "No Item Found" }));
        }
      },
    }
  );

  return {
    dataItems: dataItems,
    errorItems,
    isLoadingItems,
    queryKeyItems,
    isFetchingItems,
  };
}

export default useGetItems;
