import { useParams } from "react-router-dom";
import AddCustomersInCategoryForm from "../../components/discounts/AddCustomersInCategoryForm";
import AddDiscountCategoryForm from "../../components/discounts/AddDiscountCategoryForm";
import CustomersInCategoryList from "../../components/discounts/CustomersInCategoryList";
import useGetDiscountCategories from "../../hooks/discounts/useGetDiscountCategories";

function UpdateCustomerToDiscountCategory() {
  const params = useParams();

  const { data } = useGetDiscountCategories({ id: params.categoryId });

  return data?.length ? (
    <div className="discount-category">
      {/* <h3 className="text-xl font-bold mb-4">Update Customer in Category</h3> */}
      <AddDiscountCategoryForm categoryName={data[0].name} />

      <AddCustomersInCategoryForm />
      <CustomersInCategoryList data={data[0]?.customers} />
    </div>
  ) : null;
}

export default UpdateCustomerToDiscountCategory;
