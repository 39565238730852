import { Option } from "../../types";

export const gstTypes: Option[] = [
  {
    label: "GST",
    value: "GST",
  },
  {
    label: "IGST",
    value: "IGST",
  },
];
