import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import queryKeys from "../../types/queryKeys";

type payload = {
  orderId: string;
  items: any;
};
function useAddProductInSalesOrder() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const handleMutation = (postData: payload) => {
    return axiosInstance.post(`/sales/${postData.orderId}/items`, {
      ...postData,
    });
  };

  return useMutation(handleMutation, {
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries(queryKeys.getItemsFromSalesOrder);
      dispatch(
        addToast({
          kind: SUCCESS,
          msg: "Item Added Successfully",
        })
      );
    },
    onError(data, variables, context) {
      dispatch(
        addToast({
          kind: ERROR,
          msg: "Unable to Add Item",
        })
      );
    },
  });
}

export default useAddProductInSalesOrder;
