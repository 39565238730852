import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import axiosInstance from "../../../axiosInstance";

type postData = {
  amount: number;
  discount: number;
  mode: string;
  transaction_date: string;
  transaction_id: string;
  expected_date?: string;
  particulars: string;
  payment_details: string;
  customerId: string;
  medrepId: string;
  bankaccountId: string;

  fromBank?: string;
};

function useMutateDebit() {
  const queryClient = useQueryClient();

  return useMutation(
    (postData: postData) => axiosInstance.post(`/debit`, postData),
    {
      onSuccess(data, variables, context) {
        toast.success("Transaction successfully done");
        // getTransaction();
        queryClient.invalidateQueries("getDebtors");
        queryClient.invalidateQueries("getTransaction");
      },
      onError(data, variables, context) {
        toast.error("Oops, something went wrong!");
      },
    }
  );
}

export default useMutateDebit;
