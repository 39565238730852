class GetErrors {
  errorsArr: string[] = [];
  makeErrorsArr = (errors: any) => {
    for (const [_key, value] of Object.entries(errors)) {
      //@ts-ignore
      if (Array.isArray(value)) {
        //@ts-ignore
        value.forEach((item: any, index: number) => {
          if (item)
            this.errorsArr.push(`Row ${index + 1}: ${Object?.values(item)[0]}`);
        });
      } else if (typeof value === "object") {
        this.makeErrorsArr(value);
      } else {
        //@ts-ignore
        this.errorsArr.push(value);
      }
    }
  };
}

export default GetErrors;
