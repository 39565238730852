import { forwardRef, useMemo } from "react";
import { MONTHS } from "../../../types/constants";
import { localestringToDate } from "../../../utils/helpers";

type Props = {
  data: any;
};

const PrintLedgerDebtor = forwardRef(({ data }: Props, ref: any) => {
  let totalDr = 0,
    totalCr = 0;
  let balance = 0;
  if (data?.carry_forward) balance = data.carry_forward;

  const formatedDateHeading = useMemo(() => {
    return `Transaction for month: 
          ${MONTHS[new Date(data.start_date).getMonth()]} ${new Date(
      data.start_date
    ).getFullYear()} to
           ${MONTHS[new Date(data.end_date).getMonth()]} 
    ${new Date(data.end_date).getFullYear()}
  -
  ${localestringToDate(data.start_date)} to ${localestringToDate(
      data.end_date
    )} `;
  }, [data]);

  return (
    <div ref={ref} className="flex flex-col justify-center gap-10">
      <h1 className=" w-full text-xl font-bold ">
        NEW LIFE HOMOEO DISTRIBUTORS, BHOPAL.
      </h1>

      <div>
        <h1 className="font-bold text-lg">To,</h1>
        <h1 className="font-bold text-lg">{data.customer.name}</h1>
        <h1 className=" text-lg">
          {data.customer.area}, {data.customer.city}, Contact :{" "}
          {data.customer.contactOne}
        </h1>
      </div>
      <div>
        <h3 className="font-semibold text-lg"> {formatedDateHeading}</h3>
      </div>
      <table className="print-table">
        <thead>
          <tr>
            <th className="text-left">Order By/Payment By</th>
            <th className="text-left">Date</th>
            <th className="text-left">Particular</th>

            <th className="text-left">Mode of Payment</th>
            <th className="text-left">Cheque No./Transaction ID</th>
            <th className="text-left">Expected Date</th>
            <th className="text-left">Deposit in Bank</th>

            <th className="">Dr (+)</th>
            <th className="">Cr(-)</th>
            <th className="text-right">Balance</th>
          </tr>
        </thead>
        <tbody>
          <tr className="">
            <td className="text-left"></td>

            <td className="text-left">{localestringToDate(data.start_date)}</td>
            <td className="text-left">Opening Balance</td>
            {[1, 2, 3, 4].map((d, i) => (
              <td></td>
            ))}

            <td className="text-right">
              {Number(data.carry_forward).toFixed(2)}
            </td>
            <td className="text-right">0</td>
            <td className="text-right">{balance?.toFixed(2)}</td>
          </tr>

          {data.data.map((e: any, index: number) => {
            if (e.amount > 0) {
              // case of debit
              totalDr += Number(e.amount);
              balance += Number(e.amount);
            } else {
              // case of credit
              totalCr += Number(-parseFloat(e.amount));
              balance -= Number(-parseFloat(e.amount));
            }
            return (
              <tr className="text-left" key={index}>
                {e.medrep !== null ? <td>{e.medrep.name}</td> : <td></td>}

                <td>{localestringToDate(e.transaction_date)}</td>
                <td>{e.particulars}</td>

                <td>{e.mode}</td>
                <td>{e.transaction_id}</td>
                <td>{localestringToDate(e.expected_date)}</td>
                <td>
                  {e.bankaccount?.bank_name
                    ? `${e.bankaccount?.bank_name} (${e.bankaccount?.account_number}) `
                    : ""}
                </td>

                <td className="text-right">
                  {e.amount > 0 ? e.amount?.toFixed(2) : "0"}
                </td>
                <td className="text-right">
                  {e.amount < 0 ? -parseFloat(e.amount?.toFixed(2)) : "0"}
                </td>
                <td className="text-right">{balance?.toFixed(2)}</td>
              </tr>
            );
          })}

          <tr className="font-semibold bg-slate-200">
            <td colSpan={7}>Total</td>
            <td className="text-right">{totalDr.toFixed(2)}</td>
            <td className="text-right">{totalCr.toFixed(2)}</td>
            <td className="text-right">{balance?.toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
});

export default PrintLedgerDebtor;
