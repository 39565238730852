import { HiCash } from "react-icons/hi";
import Input from "../../components/FormikComponents/Input";
import RadioButtons from "../../components/FormikComponents/RadioButton";
import { gstTypes } from "../../utils/constants/orderConstants";
type Props = {
  readonly?: boolean;
};
function SalesCalculationForm({ readonly }: Props) {
  return (
    <div className="payment-form flex flex-col justify-center px-4 py-4 rounded border border-gray-300 gap-x-4 relative mb-8">
      <div className="row-1 flex items-center mb-6 gap-x-4">
        {/* <ReadOnlyInputBox value={ values.heads?.total} /> */}
        <Input
          label="Total Taxable Amt."
          name="taxable_amount"
          id="taxable_amount"
          type="text"
          step="0.01"
          readonly
        />
        <Input
          label="Sp. Dis"
          name="sp_discount"
          id="sp_discount"
          type="number"
          readonly={readonly}
        />
        <Input
          label="GST"
          name="sales_gst_amount"
          id="sales_gst_amount"
          type="number"
          readonly
        />
        <Input label="Net Total" name="total" id="total" type="text" readonly />
      </div>
      <div className="row-2 flex items-center justify-end">
        <div className="flex-1"></div>
        <div className="row-2-content flex-1 flex items-center gap-x-4">
          <Input label="Bal" name="bal" id="bal" type="number" readonly />
          <Input
            label="Cartons"
            name="cartons"
            id="cartons"
            type="number"
            readonly={readonly}
          />
          <RadioButtons
            id="gstType"
            name="gstType"
            options={gstTypes}
            disabled={readonly}
            initialChecked="GST"
          />
        </div>
      </div>

      <div className="label absolute right-5 -bottom-4 bg-gray-300 rounded text-sm flex items-center font-semibold p-1">
        <HiCash size={16} className="mr-1" />
        Payment
      </div>
    </div>
  );
}

export default SalesCalculationForm;
