import { useQuery } from "react-query";
import axiosInstance from "../axiosInstance";
import { MRObj } from "../types";
import { GetMRsParams } from "../types/mrs.types";
import queryKeys from "../types/queryKeys";

const getMrsSales = async ({ queryKey }: any) => {
  const [_, params] = queryKey;

  const { data } = await axiosInstance.get(`/medreps`, { params });

  return data.data;
};
const queryKeyMrs = queryKeys.getMrs;

function useGetMRs(parmas: GetMRsParams = {}) {
  const {
    data: dataMRs,
    isFetching: isFetchingMRs,
    error: errorMRs,
    isLoading: isLoadingMRs,
  } = useQuery<MRObj[] | undefined, any, MRObj[], any[]>(
    [queryKeyMrs, parmas],
    getMrsSales
  );

  return {
    dataMRs,
    isFetchingMRs,
    errorMRs,
    isLoadingMRs,
    queryKeyMrs,
    optionMRs: dataMRs?.map((data) => {
      return {
        label: data.name,
        value: data.id!,
      };
    }) || [
      {
        label: "",
        value: "",
      },
    ],
  };
}

export default useGetMRs;
