import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import queryKeys from "../../types/queryKeys";

function useUpdateItemsInPurchaseOrder() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const handleMutation = (postData: any) => {
    return axiosInstance.patch(`/orders`, postData);
  };

  return useMutation(handleMutation, {
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries(queryKeys.getItemsFromPurchaseOrder);
      dispatch(
        addToast({
          kind: SUCCESS,
          msg: "Item Updated Successfully",
        })
      );
    },
    onError(data, variables, context) {
      dispatch(
        addToast({
          kind: ERROR,
          msg: "Unable to  Update Item",
        })
      );
    },
  });
}

export default useUpdateItemsInPurchaseOrder;
