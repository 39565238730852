import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { HiTag } from "react-icons/hi";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import CustomSelect from "../../components/FormikComponents/CustomSelect";
import HeadInput from "../../components/FormikComponents/HeadInput";
import Input from "../../components/FormikComponents/Input";
import SingleValueReactSelect from "../../components/FormikComponents/SingleValueReactSelect";
import SubmitBtn from "../../components/FormikComponents/SubmitBtn";
import TableInstance from "../../components/Table/TableInstance";
import useAddRoomNo from "../../hooks/Inventory/RoomNo/useAddRoomNo";
import useGetRoomNo from "../../hooks/Inventory/RoomNo/useGetRoomNo";
import useGetCompanies from "../../hooks/useGetCompanies";
import useGetHeads from "../../hooks/useGetHeads";
import { showModal } from "../../redux/features/modalSlice";
import { FROM, GET_CONFIRMATION, UPDATE_ROOM_NO } from "../../types/constants";
import { getHeadId } from "../../utils/helpers";

function RoomNoConfigration() {
  const { dataCompany } = useGetCompanies();
  const [potencies, setPotencies] = useState<string[]>([]);
  const [sizes, setSizes] = useState<string[]>([]);
  const { allHeads } = useGetHeads();
  const { mutate, isLoading } = useAddRoomNo();
  const { data, isLoading: isLoadingTable, error } = useGetRoomNo();
  const [cPageSize, cSetPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [cSortBy, cSetSortBy] = useState(null);
  const [desc, setDesc] = useState(true);
  const [q, setQ] = useState("");
  const dispatch = useDispatch();

  const Room_No_Columns = [
    {
      Header: "Sr",
      accessor: (r: any, i: number) => i + 1,
    },

    {
      Header: "Head",
      accessor: "head.head",
    },
    {
      Header: "Company",
      accessor: "company.name",
    },
    {
      Header: "Potency",
      accessor: "head.potency",
    },
    {
      Header: "Size",
      accessor: "head.size",
    },
    {
      Header: "Room No",
      accessor: "room_no",
    },

    {
      Header: "",
      accessor: "editBtn",
      Cell: ({ row }: any) => {
        return (
          <div className="btns flex items-center justify-center gap-4">
            <button
              className="btn-primary flex items-center"
              onClick={() => {
                dispatch(
                  showModal({
                    modalType: UPDATE_ROOM_NO,
                    modalTitle: "Update Room No",
                    modalProps: row.original,
                  })
                );
              }}
            >
              <span className="mr-1"> Edit</span>
            </button>
            <button
              className="btn-danger flex items-center"
              onClick={() => {
                dispatch(
                  showModal({
                    modalType: GET_CONFIRMATION,
                    modalTitle: "Delete Room",
                    modalProps: {
                      id: row.original.room_id,
                      from: FROM.DELETE_ROOM_NO,
                      value: "Are so sure you want to drop the Room?",
                    },
                  })
                );
              }}
            >
              <span className=""> Delete</span>
            </button>
          </div>
        );
      },
    },
  ];

  const initialValues: any = {
    head: "",
    company: "",
    potency: "",
    size: "",
    room_no: "",
  };

  const validationSchema = Yup.object({
    company: Yup.object().required("Company name is required"),
    head: Yup.string().required("Head is required"),
    potency: Yup.string().required("Potency is required"),
    size: Yup.string().required("Size is required"),
    room_no: Yup.string().required("Room number is required"),
  });

  const handleAddRoomNo = useCallback(
    (values: any, resetForm: Function) => {
      const headId = getHeadId(
        values.head,
        values.potency,
        values.size,
        allHeads!
      );
      const postValue = {
        headId,
        room_no: values.room_no,
        companyId: values.company.value,
      };
      // console.log(postValue, values, allHeads, headId);
      mutate(postValue, {
        onSuccess(data, variables, context) {
          resetForm();
        },
      });
    },
    [getHeadId, allHeads]
  );
  let component = null;
  if (error) {
    component = (
      <p className="mt-6 ml-4 text-center">
        An error has occurred: {error.message}
      </p>
    );
  } else if (isLoadingTable) {
    component = <p className="mt-6 ml-4 text-center">Loading...</p>;
  } else {
    component = (
      <TableInstance
        tableData={data || []}
        columnName={Room_No_Columns}
        cPageSize={cPageSize}
        cSetPageSize={cSetPageSize}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageCount={-1} // do calculation here
        cSortBy={cSortBy}
        cSetSortBy={cSetSortBy}
        desc={desc}
        setDesc={setDesc}
        q={q}
        setQ={setQ}
        setSelectedRows={(f: any) => f}
        selectRow={false}
      />
    );
  }
  return (
    <div>
      <div className="item-configuration-column mt-12 head-form flex flex-col justify-center px-4 py-4 rounded border border-gray-300 gap-x-4 relative mb-14">
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) =>
              handleAddRoomNo(values, resetForm)
            }
            enableReinitialize
          >
            {({}) => (
              <Form className="px-6 py-4 mt-2">
                <div className="row-1 flex item-center justify-center gap-x-6 mb-3">
                  <HeadInput
                    setSizes={setSizes}
                    setPotencies={setPotencies}
                    dependencies={{
                      potency: "potency",
                      size: "size",
                      hsn_number: "hsn_number",
                      gst: "gst",
                      stock: "stock",
                      item: "item_name",
                    }}
                    classes="w-full"
                    id="head"
                    name="head"
                    label="Head"
                  />
                  <CustomSelect
                    options={dataCompany || []}
                    id="company"
                    name="company"
                    label="Company"
                    classes="w-full"
                    isClearable
                    isDisabled={dataCompany?.length === 0}
                    placeholder="Select Company"
                  />

                  <SingleValueReactSelect
                    classes="w-full"
                    id="potency"
                    name="potency"
                    label="Potency"
                    options={potencies}
                  />
                  <SingleValueReactSelect
                    classes="w-full"
                    id="size"
                    name="size"
                    label="Size"
                    options={sizes}
                  />

                  <Input
                    id="room_no"
                    name="room_no"
                    label="Room No"
                    type="text"
                  />
                  <div className="pt-6">
                    <SubmitBtn
                      text={"Submit"}
                      isDisabled={!allHeads?.length}
                      isSubmitting={isLoading}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>

        <div className="label absolute right-5 -bottom-4 bg-gray-300 rounded text-sm flex items-center font-semibold p-1">
          <HiTag size={16} className="mr-1" />
          Add Room
        </div>
      </div>

      {/* Filter and table */}
      {component}
    </div>
  );
}

export default RoomNoConfigration;
