import { FieldArray, Formik } from "formik";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import useAddProductInSalesOrder from "../../../hooks/sales/useAddProductInSalesOrder";
import { hideModal } from "../../../redux/features/modalSlice";
import { setProductList } from "../../../redux/features/productList";
import focusHead from "../../../utils/focusHead";
import CustomSubmitBtn from "../../FormikComponents/CustomSubmitBtn";
import Input from "../../FormikComponents/Input";

type Props = {
  data: any[];
  id?: string;
};
function ProductListModal({ data, id }: Props) {
  const dispatch = useDispatch();
  const handleClose = () => dispatch(hideModal());
  const queryClient = useQueryClient();
  const [isUpdating, setIsUpdating] = useState(false);
  const { mutate } = useAddProductInSalesOrder();
  const columns: {
    Header: string;
    className?: string;
  }[] = [
    { Header: "MRP" },
    { Header: "Product" },
    { Header: "Head" },
    { Header: "Potency" },
    { Header: "Size" },
    { Header: "Company" },
    { Header: "Batch No." },
    { Header: "MFG" },
    { Header: "EXP" },
    { Header: "Stock" },
    { Header: "Qty" },
    { Header: "Free Qty" },
  ];

  const initialValues = {
    products: data,
  };

  const validationSchema = Yup.object({
    products: Yup.array()
      .of(
        Yup.object().shape({
          quantity: Yup.number(),
          ordered_qty: Yup.number().positive("Must be Positive no."),
        })
      )
      .min(1, "Add atleast one Product")
      .required("Product is required"),
  });

  const handleSubmit = (values: any, resetForm: any) => {
    const selectedProducts = values.products.filter(
      (p: any) => p.ordered_qty > 0
    );
    console.log("Selected products: ", selectedProducts);
    if (id) {
      const postData = {
        orderId: id,
        items: selectedProducts.map((h: any) => {
          return {
            productProductId: h.product_id,
            sale_qty: h.ordered_qty,
            free_qty: h.free_qty,
          };
        }),
      };

      console.log("submit order values", postData);
      mutate(postData, {
        onSuccess() {
          resetForm();
          setIsUpdating(false);
          focusHead();
          handleClose();
        },
        onError() {
          setIsUpdating(false);
          resetForm();
        },
      });
    } else {
      dispatch(setProductList([...selectedProducts]));
      focusHead();

      handleClose();
    }
  };

  return (
    <div className="mb-5 mx-5 relative ">
      <div className=" ">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
          enableReinitialize
        >
          {(formik) => {
            return (
              <>
                <div className=" overflow-y-auto  max-h-[80vh]   ">
                  <FieldArray name="heads">
                    {(fieldArrayProps) => {
                      return (
                        <table className="table overflow-y-scroll h-full">
                          <thead className="sticky top-0">
                            <tr>
                              {columns.map((c, i) => (
                                <th
                                  className={`text-left ${c.className}`}
                                  key={i}
                                >
                                  {c.Header}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody className="overflow-y-hidden max-h-96 box-border">
                            {data &&
                              data.length &&
                              data.map((v, i) => {
                                return (
                                  <tr key={i} className="text-left">
                                    <td className="text-right px-1">{v.mrp}</td>
                                    <td className="px-1">{v.product_name}</td>
                                    <td className="px-1">{v.head.head}</td>
                                    <td className="px-1">{v.head.potency}</td>
                                    <td className="px-1">{v.head.size}</td>
                                    <td className="px-1">{v.company.name}</td>
                                    <td className="px-1">{v.batch_number}</td>
                                    <td className="px-1">{v.mfg_date}</td>
                                    <td className="px-1">{v.exp_date}</td>
                                    <td className="text-right px-1 ">
                                      {v.quantity}
                                    </td>
                                    <td className="">
                                      <Input
                                        autoFocus={i === 0}
                                        id="ordered_qty"
                                        name={`products[${i}].ordered_qty`}
                                        type="number"
                                        classes="mb-0 w-22 h-auto"
                                      />
                                    </td>
                                    <td className="">
                                      <Input
                                        autoFocus={false}
                                        id="free_qty"
                                        name={`products[${i}].free_qty`}
                                        type="number"
                                        classes="mb-0 w-22 h-auto"
                                        tabIndex={-1}
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      );
                    }}
                  </FieldArray>
                </div>
                <div className="buttons  left-0 right-0  flex items-center justify-around mt-6">
                  <CustomSubmitBtn
                    text="Add"
                    classes=" my-3"
                    containerClasses="w-44"
                    onSubmit={(e) => {
                      e.preventDefault();

                      formik.handleSubmit();
                    }}
                  />

                  <button
                    className="btn-danger  rounded  w-44 mx-3 focus:bg-red-500 hover:bg-red-500"
                    type="button"
                    disabled={isUpdating}
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default ProductListModal;
