import { AxiosError } from "axios";
import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { BankAccountObj } from "../../types";
import { ERROR, phoneRegExp, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";

function UpdateBankAccModal(props: any) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hideModal());
  };

  const initialValues: BankAccountObj = {
    account_number: props.account_number || "",
    bank_name: props.bank_name || "",
    holder_name: props.holder_name || "",
    branch: props.branch || "",
    ifsc: props.ifsc || "",
    contact_number: props.contact_number || "",
  };

  const validationSchema = Yup.object({
    account_number: Yup.number()
      .typeError("Please enter valid Account number")
      .required("Account No. is required"),
    bank_name: Yup.string().required("Bank name is required"),
    holder_name: Yup.string().required("Holder name is required"),
    branch: Yup.string().required("Branch is required"),
    ifsc: Yup.string().required("IFSC Code is required"),
    contact_number: Yup.string().matches(
      phoneRegExp,
      "Phone number is not valid"
    ),
  });

  const handleSubmit = useCallback(
    (values: BankAccountObj, resetForm: any, setSubmitting: any) => {
      setIsSubmitting(true);
      axiosInstance
        .patch(`/accounts/${props.id}`, values)
        .then((response) => {
          const { msg } = response.data;

          setSubmitting(false);

          queryClient.invalidateQueries(["getBankAccounts"]);

          dispatch(
            addToast({
              kind: SUCCESS,
              msg: msg,
            })
          );
          resetForm();
          dispatch(hideModal());
        })
        .catch((error: AxiosError) => {
          setIsSubmitting(false);

          if (error.response) {
            const response = error.response;
            const { msg } = response.data;

            switch (response.status) {
              // bad request or invalid format or unauthorized
              case 400:
              case 500:
                dispatch(
                  addToast({
                    kind: ERROR,
                    msg: msg,
                  })
                );
                break;
              default:
                dispatch(
                  addToast({
                    kind: ERROR,
                    msg: "Oops, something went wrong",
                  })
                );
                break;
            }
          } else if (error.request) {
            dispatch(
              addToast({
                kind: ERROR,
                msg: "Oops, something went wrong",
              })
            );
          } else {
            dispatch(
              addToast({
                kind: ERROR,
                msg: `Error: ${error.message}`,
              })
            );
          }
        });
    },
    []
  );

  return (
    <div className="">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm, setSubmitting }) => {
          handleSubmit(values, resetForm, setSubmitting);
        }}
        validateOnMount={true}
      >
        <Form className="px-8 py-3 flex items-center gap-x-12">
          <div className="left">
            <div className="row-1 flex items-center gap-x-8 mb-6">
              <Input
                label="Holder Name"
                id="holder_name"
                name="holder_name"
                type="text"
                classes="h-16"
              />
              <Input
                label="Account No."
                id="account_number"
                name="account_number"
                type="text"
                classes="h-16"
              />
              <Input
                label="Contact No."
                id="contact_number"
                name="contact_number"
                type="text"
                classes="h-16"
              />
            </div>
            <div className="row-2 flex items-center gap-x-8 mb-6">
              <Input
                label="Bank Name"
                id="bank_name"
                name="bank_name"
                type="text"
                classes="h-16"
              />
              <Input
                label="Branch"
                id="branch"
                name="branch"
                type="text"
                classes="h-16"
              />
              <Input
                label="IFSC Code"
                id="ifsc"
                name="ifsc"
                type="text"
                classes="h-16"
              />
            </div>

            <SubmitBtn
              classes="mt-2"
              text={props?.id ? "Update" : "Save"}
              isSubmitting={isSubmitting}
            />
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default UpdateBankAccModal;
