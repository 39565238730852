import { useState } from "react";
import { HiTrash } from "react-icons/hi";
import { useParams } from "react-router-dom";
import useRemoveCustomerFromDiscountCategory from "../../hooks/discounts/useRemoveCustomerFromDiscountCategory";
import { Customer } from "../../types/customer.types";
import TableInstance from "../Table/TableInstance";
type props = {
  data: Customer[];
};
function CustomersInCategoryList({ data }: props) {
  const params = useParams();
  const { mutate, isLoading } = useRemoveCustomerFromDiscountCategory();

  const DISCOUNT_COLUMNS = [
    {
      Header: "Customer's Name",
      accessor: "name",
    },
    {
      Header: "Address",
      accessor: "address",
    },
    {
      Header: "Area",
      accessor: "area",
    },

    {
      Header: "City",
      accessor: "city",
    },
    {
      Header: "State",
      accessor: "state",
    },
    {
      Header: "Contact 1",
      accessor: "contactOne",
    },

    {
      Header: "Email",
      accessor: "email",
    },

    {
      Header: "",
      accessor: "editBtn",
      Cell: ({ row }: any) => {
        return (
          <div className="btns flex items-center justify-center gap-4">
            <button
              className="btn-danger flex items-center"
              disabled={isLoading}
              onClick={() => {
                mutate({
                  customerId: row.original.id,
                  categoryId: params.categoryId!,
                });
                // navigate(`/discount/category/${row.original.id}/update`);
              }}
            >
              <span className="mr-1"> Remove </span>
              <HiTrash />
            </button>
          </div>
        );
      },
    },
  ];

  const [cPageSize, cSetPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [cSortBy, cSetSortBy] = useState(null);
  const [desc, setDesc] = useState(true);
  const [q, setQ] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);

  return (
    <div className="discount mt-12">
      {data && (
        <TableInstance
          tableData={data || []}
          columnName={DISCOUNT_COLUMNS}
          cPageSize={cPageSize}
          cSetPageSize={cSetPageSize}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          pageCount={-1} // do calculation here
          cSortBy={cSortBy}
          cSetSortBy={cSetSortBy}
          desc={desc}
          setDesc={setDesc}
          q={q}
          setQ={setQ}
          setSelectedRows={setSelectedRows}
          selectRow={false}
        />
      )}
    </div>
  );
}

export default CustomersInCategoryList;
