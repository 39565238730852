import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../../axiosInstance";
import { addToast } from "../../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../../types/constants";
import queryKeys from "../../../types/queryKeys";

type PostDate = {
  value: number;
  customerId: string;
  itemItemId?: string;

  head?: string;
  potency?: string;
  size?: string;
  companyId?: string;
};

function useAddCustomerSpecificDiscount() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const handleMutation = (postData: PostDate) => {
    return axiosInstance.post(`/discounts/customer`, postData);
  };

  return useMutation(handleMutation, {
    onSuccess(data, payload, context) {
      queryClient.invalidateQueries(queryKeys.getCustomers);
      try {
        const msg = data.data?.msg;
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: msg,
          })
        );
      } catch (error) {
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: `Customer Sp. Discount Added`,
          })
        );
      }
    },
    onError(data: AxiosError, payload, context) {
      try {
        const msg = data.response?.data?.msg;
        dispatch(
          addToast({
            kind: ERROR,
            msg: msg,
          })
        );
      } catch (error) {
        dispatch(
          addToast({
            kind: ERROR,
            msg: `Unable to  add Discount`,
          })
        );
      }
    },
  });
}

export default useAddCustomerSpecificDiscount;
