import { QueryKey, useQuery } from "react-query";
import axiosInstance from "../../axiosInstance";
import { DiscountObj } from "../../types";
import { Customer } from "../../types/customer.types";
import queryKeys from "../../types/queryKeys";
import { groupDiscountByItem } from "../../utils/helpers";

export interface Category {
  id: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;
  discounts: DiscountObj[];
  customers: Customer[];
}
type GetCategoryParams = {
  id?: string;
  populateItems?: boolean;
};

function useGetDiscountCategories(
  params: GetCategoryParams = {},
  isGrouped: boolean = false
) {
  const getResponse = async ({ queryKey }: any) => {
    const [_key, params, isGrouped] = queryKey;
    const response = await axiosInstance.get(`/discounts/category`, { params });
    const { discounts } = response.data.data[0];
    if (isGrouped) {
      return groupDiscountByItem(discounts);
    }

    return response.data.data;
  };
  return useQuery<Category[], any, Category[], QueryKey>(
    [queryKeys.getDiscountCategory, params, isGrouped],
    getResponse
  );
}

export default useGetDiscountCategories;
