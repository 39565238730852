import { AxiosError } from "axios";
import { useField, useFormikContext } from "formik";
import { url } from "inspector";
import { useEffect } from "react";
import { HiOutlineX } from "react-icons/hi";
import axiosInstance from "../../axiosInstance";
import { BASE_URL } from "../../config";
import ErrorBox from "./ErrorBox";

interface Props {
  label?: string;
  id: string;
  name: string;
  setUrl: string;
  preview?: boolean;
  autoFocus?: boolean;
  optional?: boolean;
  inline?: boolean;
  classes?: string;
}

const FileInput = ({
  label,
  id,
  name,
  setUrl,
  classes,
  preview = false,
  autoFocus = false,
  optional = false,
  inline = false,
  ...props
}: Props) => {
  //   field -> { name: string, value: string, onChange: () => {}, onBlur: () => {} }
  //   meta -> { touched: boolean, error: string, ... }
  const [field, meta, helpers] = useField({ name, type: "file" });
  const [fieldS, metaS, helpersS] = useField(setUrl);
  const { setFieldValue, values } = useFormikContext();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target && e.target.files && e.target.files[0];

    if (file) {
      reader.readAsDataURL(file);
      helpers.setValue(file);
    }
  };

  useEffect(() => {
    if (field.value && !meta.error) {
      const formData = new FormData();
      console.log(field.value);
      formData.append("img", field.value);
      //console.log(formData)
      axiosInstance
        .post(`/images/`, formData, {})
        .then((response) => {
          //console.log(response.data[0].mediaSource)
          helpersS.setValue(response.data[0].mediaName);
        })
        .catch((error: AxiosError) => {
          helpers.setError("Something Went Wrong");
        });
    }
  }, [field.value]);

  return (
    <div className={`flex flex-col w-full mb-6 ${classes}`}>
      <div
        className={`form-group flex ${
          inline ? " flex-row items-center" : "flex-col justify-center"
        }`}
      >
        <label
          className={`font-semibold text-sm ${inline ? "mr-2 w-28" : "mb-2"}`}
          htmlFor={id}
        >
          {label}{" "}
          {optional && (
            <span className="optional text-slate-400">(Optional)</span>
          )}
        </label>

        {preview && (
          <div className="preview w-64 h-72 border rounded mb-4 p-4">
            {(field.value || fieldS.value) && (
              <img
                alt="profile preview"
                className="w-full h-full object-cover object-center"
                src={
                  fieldS.value
                    ? `${BASE_URL}/images/${fieldS.value}`
                    : `${URL.createObjectURL(field.value)}`
                }
              />
            )}
          </div>
        )}

        <div className="flex items-center">
          <input
            autoFocus={autoFocus}
            className={`${
              meta.touched && meta.error
                ? "border-red-400 "
                : "border-slate-200 focus:border-primary"
            } mr-4 placeholder-transparent  disabled:opacity-80`}
            {...field}
            {...props}
            value={field.value ? field.value.current : ""}
            onChange={handleFileChange}
            id={id}
            type="file"
            accept="image/png, image/jpg, image/jpeg"
          />

          {field.value && (
            <button onClick={() => helpers.setValue("")}>
              <HiOutlineX size={18} />
            </button>
          )}
        </div>
      </div>
      {meta.touched && meta.error && <ErrorBox msg={meta.error} />}
    </div>
  );
};

export default FileInput;
