import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AddDiscountCategoryForm from "../../components/discounts/AddDiscountCategoryForm";
import TableInstance from "../../components/Table/TableInstance";
import useGetDiscountCategories from "../../hooks/discounts/useGetDiscountCategories";
import { DiscountObj } from "../../types";
import { Customer } from "../../types/customer.types";

function DiscountCategories() {
  const navigate = useNavigate();
  //   const dispatch = useDispatch();
  const { data } = useGetDiscountCategories();
  const DISCOUNT_COLUMNS = [
    {
      Header: "Category Name",
      accessor: "name",
    },
    {
      Header: "Customers",
      accessor: "customers",
      Cell: ({ value }: { value: Customer[] }) => {
        return value?.reduce((a, b) => a + (a ? ", " : "") + b.name, "") || "";
      },
    },
    {
      Header: "No of Discounts",
      accessor: "discounts",
      Cell: ({ value }: { value: DiscountObj[] }) => {
        return value.length || 0;
      },
    },
    {
      Header: "",
      accessor: "editBtn",
      Cell: ({ row }: any) => {
        return (
          <div className="btns flex items-center justify-center gap-4">
            <button
              className="btn-primary flex items-center"
              onClick={() => {
                navigate(`/discount/category/${row.original.id}/customers`);
              }}
            >
              <span className="mr-1"> Update Customer</span>
            </button>
            <button
              className="btn-primary flex items-center"
              onClick={() => {
                navigate(`/discount/category/${row.original.id}/items`);
              }}
            >
              <span className="mr-1"> Update Discounts</span>
            </button>
          </div>
        );
      },
    },
  ];

  const [cPageSize, cSetPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [cSortBy, cSetSortBy] = useState(null);
  const [desc, setDesc] = useState(true);
  const [q, setQ] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);

  return (
    <div className="discount mt-12">
      <AddDiscountCategoryForm />

      {data && (
        <TableInstance
          tableData={data || []}
          columnName={DISCOUNT_COLUMNS}
          cPageSize={cPageSize}
          cSetPageSize={cSetPageSize}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          pageCount={-1} // do calculation here
          cSortBy={cSortBy}
          cSetSortBy={cSetSortBy}
          desc={desc}
          setDesc={setDesc}
          q={q}
          setQ={setQ}
          setSelectedRows={setSelectedRows}
          selectRow={false}
        />
      )}
    </div>
  );
}

export default DiscountCategories;
