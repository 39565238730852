import React from "react";
import { NavLink } from "react-router-dom";

interface Props {
  title: string;
  path: string;
  text: string;
  end: boolean;
}

const AccountsNavCard = ({ title, path, text, end }: Props) => {
  return (
    <li className="flex-1 bg-white text-center flex border h-32 first:rounded-l last:rounded-r first:border-r-0 last:border-l-0">
      <NavLink
        end={end}
        to={path}
        className={({ isActive }) => {
          return `px-4 py-8 w-full font-sans ${
            isActive ? "border-b-4 border-primary" : ""
          }`;
        }}
      >
        <h3 className="font-semibold text-2xl">{title}</h3>
        <span className="text-lg font-normal">{text}</span>
      </NavLink>
    </li>
  );
};

export default AccountsNavCard;
