import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../../axiosInstance";
import { addToast } from "../../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../../types/constants";
import queryKeys from "../../../types/queryKeys";

function useDropStateCity() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const handleMutation = (id: any) => {
    return axiosInstance.delete(`/statecity/${id}`);
  };

  return useMutation(handleMutation, {
    onSuccess(data, payload, context) {
      queryClient.invalidateQueries(queryKeys.getStateCities);
      const { msg } = data.data;

      dispatch(
        addToast({
          kind: SUCCESS,
          msg: msg,
        })
      );
    },
    onError(data, payload, context) {
      dispatch(
        addToast({
          kind: ERROR,
          msg: "Unable to Drop State & Cities",
        })
      );
    },
  });
}

export default useDropStateCity;
