import { Form, Formik } from "formik";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import SubmitBtn from "../../../components/FormikComponents/SubmitBtn";
import useUpdateSalesOrder from "../../../hooks/sales/useUpdateSalesOrder";
import { addToast } from "../../../redux/features/toastSlice";
import { SUCCESS } from "../../../types/constants";
import {
  ORDER_STATUS,
  ORDER_TYPE_SALES,
  SalesOrderObj,
} from "../../../types/sales.types";
import { convertDateToStringForForm } from "../../../utils/helpers";
import SalesCustomerForm from "../SalesCustomerForm";

const InitiateSalesOrder = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialValues: SalesOrderObj = {
    customer: undefined,
    heads: [],
    total: 0,
    sp_discount: 0,
    sales_gst_amount: "",
    date: convertDateToStringForForm(new Date()),
    orderBy: { label: "", value: "" },
    bal: "0",
    cartons: "0",
    gstType: "",
    remarks: "",
    discount: 0,
    order_type_sales: ORDER_TYPE_SALES.Invoiced,
    number_of_pages: 0,
    taxable_amount: "",
  };

  const validationSchema = Yup.object({
    customer: Yup.object({
      id: Yup.string().required("Full name is required"),
    }),
    // state: Yup.string().required("State is required"),
    // pincode: Yup.string().required("Pincode is required"),
    // profession: Yup.string().required("Profession is required"),
    // contactNo1: Yup.string().required("Contact No. is required"),
    // address: Yup.string().required("Address is required"),
    // transport: Yup.string().required("Transport is required"),

    date: Yup.date()
      .max(new Date(), "Sale Date cannot be a future date")
      .required("Sale Date is required"),
    orderBy: Yup.object({
      label: Yup.string().required("Order By is required"),
      value: Yup.string(),
    }),

    remarks: Yup.string(),
  });
  const { mutate, isLoading: isSubmitting } = useUpdateSalesOrder();

  const handleSubmit = useCallback((order: SalesOrderObj, resetForm: any) => {
    mutate(
      {
        data: [],
        type: "sales",
        status: ORDER_STATUS.initiated,
        date: new Date(order.date).toISOString(),
        remark: order.remarks,
        customerId: order.customer?.id!,
        medrepId: order.orderBy.value,
        sp_discount: order.sp_discount,
        cartons: order.cartons,
        gst_type: order.gstType,
        sales_gst_amount: Number(order.sales_gst_amount),
        amount: 0,
        order_type_sales: order.order_type_sales,
        number_of_pages: order.number_of_pages,
        taxable_amount: 0,
      },
      {
        onSuccess() {
          dispatch(
            addToast({
              kind: SUCCESS,
              msg: "Order has been Initiated successfully",
            })
          );
          navigate("/sales/initiated", { replace: true });
        },
      }
    );
  }, []);

  return (
    <div className="create-order">
      <h3 className="text-xl font-bold mb-4">Initiate Order</h3>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
      >
        {({ values }) => (
          <Form>
            <SalesCustomerForm />
            <div className="buttons flex w-full mt-12 items-center justify-end gap-x-4">
              <div className="w-44">
                <SubmitBtn
                  text="Initiate Order"
                  classes="w-44"
                  isSubmitting={isSubmitting}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default InitiateSalesOrder;
