import { useFormikContext } from "formik";
import React, { forwardRef, useRef } from "react";
import useKeyDown from "../../hooks/useKeyDown";
import GetErrors from "../../utils/GetErrors";

interface Props {
  text: string;
  classes?: string;
  disabled?: boolean;
  containerClasses?: string;
  enterToFocus?: boolean;
  onSubmit: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const CustomSubmitBtn = forwardRef(
  (
    {
      text,
      onSubmit,
      classes,
      disabled,
      containerClasses,
      enterToFocus,
      ...props
    }: Props,
    ref: any
  ) => {
    const refsubmit = useRef<any>(null);
    const handlerCtrlEnter = (e: any) => {
      if (enterToFocus && e.ctrlKey) {
        refsubmit.current.focus();
      }
    };
    useKeyDown("Enter", handlerCtrlEnter);
    const { isValid, dirty, errors } = useFormikContext();

    const errObj = new GetErrors();
    errObj.makeErrorsArr(errors);
    return (
      <div
        className={`relative w-full flex my-2  justify-center items-center ${containerClasses}`}
        data-tooltip-target="tooltip-default"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title={`${
          errObj.errorsArr.length > 0
            ? errObj.errorsArr.join("\n")
            : "No errors"
        }`}
      >
        <button
          type="button"
          ref={ref || refsubmit}
          onClick={(e) => onSubmit(e)}
          className={`btn-primary w-full disabled:opacity-40 disabled:cursor-not-allowed focus:bg-sky-700 ${classes}`}
          disabled={!isValid || !dirty || disabled}
          {...props}
        >
          {text}
        </button>
      </div>
    );
  }
);

export default CustomSubmitBtn;
