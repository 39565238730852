import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import useUpdatePurchaseOrder from "../../hooks/purchase/useUpdatePurchaseOrder";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, FROM, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";

interface Props {
  id: string;
  value: string;
  from: string;
}

const RemarkModal = ({ id, value, from }: Props) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { mutate: updatePurchaseOrder } = useUpdatePurchaseOrder();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues = {
    remark: "",
  };

  const validationSchema = Yup.object({
    remark: Yup.string(),
  });

  const handleSubmit = useCallback(
    ({ remark }: { remark: string }, resetForm: any) => {
      console.log(remark);

      switch (from) {
        case FROM.ALL_PURCHASE:
          updatePurchaseOrder(
            { id, remark },
            {
              onSuccess() {
                dispatch(
                  addToast({
                    kind: SUCCESS,
                    msg: "Remark Updated Successfully",
                  })
                );
                dispatch(hideModal());
              },
              onError() {
                dispatch(
                  addToast({
                    kind: ERROR,
                    msg: "Unable update Remark",
                  })
                );
              },
            }
          );

          break;
        case FROM.ALL_SALES:
          axiosInstance
            .post("/sales", { id, remark })
            .then((res) => {
              console.log(res);
              queryClient.invalidateQueries("getAllOrders");
              dispatch(
                addToast({
                  kind: SUCCESS,
                  msg: "Remark Updated Successfully",
                })
              );
              dispatch(hideModal());
            })
            .catch((err) => {
              console.log(err);
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: "Unable update Remark",
                })
              );
            });
          break;
        case FROM.PENDING_ORDERS:
          axiosInstance
            .post("/sales", { id, remark })
            .then((res) => {
              console.log(res);
              queryClient.invalidateQueries("getDeptors");
              dispatch(hideModal());
            })
            .catch((err) => {
              console.log(err);
            });
          break;

        default:
          dispatch(hideModal());

          break;
      }
    },
    []
  );

  return (
    <div className="remark-modal">
      <Formik
        initialValues={{ remark: value } || initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
      >
        <Form
          className="px-6 py-4 mt-2"
          style={{
            minWidth: "450px",
            maxWidth: "760px",
          }}
        >
          {/* <p>{value}</p> */}
          <div className="flex item-center gap-x-6 mb-3">
            <Input label="Remark" id="remark" name="remark" type="text" />
          </div>

          <div className="buttons flex items-center justify-center w-full">
            <SubmitBtn
              text={value ? "Update" : "Add"}
              isSubmitting={isSubmitting}
              containerClasses="text-sm w-96 mt-4 mb-2"
            />
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default RemarkModal;
