import { Form, Formik } from "formik";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import useMutateCustomer from "../../hooks/customer/useMutateCustomer";
import useGetMRs from "../../hooks/useGetMRs";
import useGetTransports from "../../hooks/useGetTransports";
import { hideModal } from "../../redux/features/modalSlice";
import { Option } from "../../types";
import { Customer } from "../../types/customer.types";
import { vsStateCity } from "../../types/validations";
import { convertDateToStringForForm } from "../../utils/helpers";
import CustomSelect from "../FormikComponents/CustomSelect";
import Input from "../FormikComponents/Input";
import StateCitySelect from "../FormikComponents/StateCitySelect";
import SubmitBtn from "../FormikComponents/SubmitBtn";

interface AddCustomer
  extends Omit<
    Customer,
    | "transportId"
    | "transport"
    | "mr1"
    | "mr2"
    | "sr_number"
    | "createdAt"
    | "updatedAt"
    | "mr1Id"
    | "mr2Id"
  > {
  transport?: Option;
  mr1?: Option;
  mr2?: Option;
}

const AddCustomerModal = (props: any) => {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(hideModal());
  };

  const initialValues: AddCustomer = {
    id: props.id,
    gstin: props?.gstin || "",
    registration_no: props?.registration_no || "",
    drug_license: props?.drug_license || "",
    profession: props?.profession || "",
    email: props?.email || "",
    uid: props?.uid || "",
    name: props?.name || "",
    area: props?.area || "",
    address: props?.address || "",
    state: props.state,
    city: props.city,
    pincode: props?.pincode || "",
    contactOne: props?.contactOne || "",
    altContact: props?.altContact || "",
    credit_limit: props?.credit_limit || "",
    ledger_number: props?.ledger_number || "",
    transport: props?.transportId
      ? { label: props.transport.transporter, value: props?.transportId }
      : undefined,
    dob: props?.dob ? convertDateToStringForForm(props?.dob) : "",
    anniversary: props?.anniversary
      ? convertDateToStringForForm(props?.anniversary)
      : "",
    mr1: props?.mr1Id
      ? { label: props.mr1.name, value: props?.mr1Id }
      : undefined,
    mr2: props?.mr2Id
      ? { label: props.mr2.name, value: props?.mr2Id }
      : undefined,
    discount: props?.discount || 0,
    credit_days: props?.credit_days || "",
  };

  const { optionTransports } = useGetTransports();
  const { optionMRs } = useGetMRs();

  const validationSchema = Yup.object().shape(
    {
      name: Yup.string().required("User name is required"),
      area: Yup.string().required("Area is required"),
      address: Yup.string().required("Address is required"),
      pincode: Yup.string().required("PIN code is required"),

      contactOne: Yup.string()
        .matches(
          /^((\+*)((0[ -]*)*|((91 )*))((\d{12})+|(\d{10})+))|\d{5}([- ]*)\d{6}$/,
          "Invalid phone number"
        )
        .required("Contact is required"),
      altContact: Yup.string().matches(
        /^((\+*)((0[ -]*)*|((91 )*))((\d{12})+|(\d{10})+))|\d{5}([- ]*)\d{6}$/,
        "Invalid phone number"
      ),
      credit_limit: Yup.string().required("Credit Limit is required"),
      credit_days: Yup.string().required("Credit Days Limit is required"),
      email: Yup.string().email("Invalid email").trim(),
      gstin: Yup.string()
        .trim()
        .when(["registration_no", "drug_license"], {
          is: (g: string, r: string) => {
            return (!g && !r) || (!g?.trim()?.length && !r?.trim()?.length);
          },
          then: Yup.string().required(
            "Provide one of drug license no, gstin or registration_no"
          ),
        }),
      registration_no: Yup.string()
        .trim()
        .when(["gstin", "drug_license"], {
          is: (g: string, r: string) => {
            return (!g && !r) || (!g?.trim()?.length && !r?.trim()?.length);
          },
          then: Yup.string().required(
            "Provide one of drug license no, gstin or registration_no"
          ),
        }),
      drug_license: Yup.string()
        .trim()
        .when(["gstin", "registration_no"], {
          is: (g: string, r: string) => {
            return (!g && !r) || (!g?.trim()?.length && !r?.trim()?.length);
          },
          then: Yup.string().required(
            "Provide one of drug license no, gstin or registration_no"
          ),
        }),
      ledger_number: Yup.string().required("Ledger is required"),
      uid: Yup.string(),
      transport: Yup.object({
        label: Yup.string().required("Transport is required"),
        value: Yup.string().required("Transport is required"),
      }),
      dob: Yup.date().max(new Date(), "DOB cannot be a future date"),
      anniversary: Yup.date().max(
        new Date(),
        "Anniversary cannot be a future date"
      ),

      mr1: Yup.object().required("MR is required"),
      mr2: Yup.object().required("2nd MR is required"),
      discount: Yup.number().required("Discount is required").min(0),
      ...vsStateCity,
    },
    [
      ["registration_no", "drug_license"],
      ["gstin", "drug_license"],
      ["gstin", "registration_no"],
    ]
  );
  const { mutate, isLoading } = useMutateCustomer();

  const handleSubmit = useCallback((user: AddCustomer) => {
    const value = {
      ...user,
      gstin: user.gstin,
      email: user.email,
      uid: user.uid,
      name: user.name,
      address: user.address,
      city: user.city,
      state: user.state,
      pincode: user.pincode,
      contactOne: user.contactOne,
      altContact: user.altContact,
      credit_limit: user.credit_limit,
      ledger_number: user.ledger_number,
      transport: user.transport?.value,
      dob: user.dob,
      anniversary: user.anniversary,
      mr1: user.mr1?.value,
      mr2: user.mr2?.value,
      discount: user.discount,
      area: user.area,
      credit_days: user.credit_days,
    };

    mutate(value, {
      onSuccess() {
        handleClose();
      },
    });
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      <Form
        className="px-6 py-4 mt-2"
        style={{
          minWidth: "360px",
          maxWidth: "800px",
        }}
      >
        <div className="flex item-center gap-x-6 mb-3">
          <Input label="Name" id="name" name="name" type="text" />
          <Input label="Address" id="address" name="address" type="text" />
          <Input label="Area" id="area" name="area" type="text" />

          <Input label="PIN Code" id="pincode" name="pincode" type="text" />
        </div>
        <div className="grid grid-cols-5 item-center gap-x-6 mb-3">
          <StateCitySelect stateName="state" cityName="city" />

          <Input
            label="Contact"
            name="contactOne"
            id="contactOne"
            type="text"
          />
          <Input
            label="Alternate Contact "
            name="altContact"
            id="altContact"
            type="text"
            optional={true}
          />
          <Input
            label="Profession "
            name="profession"
            id="profession"
            type="text"
            optional={true}
          />
        </div>
        <div className="flex item-center gap-x-6 mb-3">
          <Input label="Email" name="email" id="email" type="email" />
          <Input label="GSTIN" name="gstin" id="gstin" type="text" />
          <Input
            label="Drug License no"
            name="drug_license"
            id="drug_license"
            type="text"
          />
          <Input
            label="Registration no"
            name="registration_no"
            id="registration_no"
            type="text"
          />
          <Input
            label="Ledger No."
            name="ledger_number"
            id="ledger_number"
            type="text"
          />
        </div>
        <div className="flex item-center gap-x-6 mb-3">
          <CustomSelect
            label="Transport"
            name="transport"
            id="transport"
            options={optionTransports}
            classes="w-full"
          />
          <Input
            label="Credit Limit"
            name="credit_limit"
            id="credit_limit"
            type="text"
          />
          <Input
            label="Credit Days"
            name="credit_days"
            id="credit_days"
            type="number"
          />
          <Input label="UID" name="uid" id="uid" type="text" />
          <Input label="Discount" id="discount" name="discount" type="number" />
        </div>
        <div className="flex item-center gap-x-6 mb-3">
          <Input label="DOB" name="dob" id="dob" type="date" />
          <Input
            label="Anniversary"
            name="anniversary"
            id="anniversary"
            type="date"
            optional={true}
          />
          <CustomSelect
            label="MR 1 Link"
            name="mr1"
            id="mr1"
            options={optionMRs}
            classes="w-full"
          />
          <CustomSelect
            label="MR 2 Link"
            name="mr2"
            id="mr2"
            options={optionMRs}
            classes="w-full"
          />
        </div>

        <div className="buttons flex items-center w-full justify-center my-4">
          <div className="w-96 h-10 my-4">
            <SubmitBtn
              text={props?.id ? "Update" : "Save"}
              isSubmitting={isLoading}
              classes="text-sm h-full w-full"
            />
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default AddCustomerModal;
