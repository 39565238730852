import { useQuery } from "react-query";
import axiosInstance from "../../../axiosInstance";
import { ShortItemObj } from "../../../types";
import queryKeys from "../../../types/queryKeys";

function useGetShortItems() {
  const getShortItems = async () => {
    const response = await axiosInstance.get("/short");

    const { data } = response.data;

    return data;
  };

  return useQuery<ShortItemObj[] | undefined, any, ShortItemObj[], string[]>(
    [queryKeys.getShortItems],
    getShortItems
  );
}

export default useGetShortItems;
