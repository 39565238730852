const queryKeys = {
  getMrs: "getMrs",
  getCompanies: "getCompanies",
  getOrder: "getOrderById",
  getSuppliers: "getSuppliers",
  getItems: "getItems",
  getPurchase: "getPurchases",
  getAccountOrders: "getAccountOrders",
  getProducts: "getProducts",
  getHeads: "getHeads",
  getAllHeads: "getAllHeads",
  getGroupedProducts: "getGroupedProducts",
  getItemsFromSalesOrder: "getItemsFromSalesOrder",
  getItemsFromPurchaseOrder: "getItemsFromPurchaseOrder",
  getRoomNo: "getRoomNo",
  getCustomers: "getCustomers",
  getStateCities: "getStateCities",
  getDiscountCategory: "getDiscountCategory",
  getDebitTransactions: "getDebitTransactions",
  getDebitByMr: "getDebitByMr",
  getShortItems: "getShortItems",
};

export default queryKeys;
