import { ToastContainer } from "react-toastify";
import Toasts from "./components/Toasts/Toasts";
import { RootState } from "./redux/app";

import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import PrivateRoute from "./PrivateRoute";
import Modal from "./components/Modal/Modal";
import AuthLayout from "./components/layouts/AuthLayout";
import MainLayout from "./components/layouts/MainLayout";
import useKeyDown from "./hooks/useKeyDown";
import Login from "./pages/auth/Login";

const App = () => {
  const modal = useSelector((state: RootState) => state.modal);

  useKeyDown(83, (e: KeyboardEvent) => {
    // preventDefault ctrl + s
    if (e.ctrlKey) {
      e.preventDefault();
    }
  });

  return (
    <div>
      <Toasts />
      <ToastContainer position="top-right" />

      <BrowserRouter>
        <Routes>
          {/* /auth */}
          <Route path="auth" element={<AuthLayout />}>
            <Route path="login" element={<Login />} />

            <Route
              path="*"
              element={<Navigate to="/auth/login" replace={true} />}
            />
          </Route>

          {/* /* */}
          <Route
            path="/*"
            element={
              <PrivateRoute>
                <MainLayout />
              </PrivateRoute>
            }
          />
        </Routes>
      </BrowserRouter>

      {/* modal */}
      {modal.modalType !== null && <Modal {...modal} />}
    </div>
  );
};

export default App;
