import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR } from "../../types/constants";
import queryKeys from "../../types/queryKeys";
import { ORDER_STATUS, ORDER_TYPE_SALES } from "../../types/sales.types";
type products = {
  productProductId: string;
  sale_qty: number;
  d1?: number;
  d2?: number;
};
type postData = {
  data: products[];
  amount: number;
  taxable_amount: number;
  // gst_amount: number;
  // other_charges: number;
  type?: "sales";
  order_type_sales: ORDER_TYPE_SALES;
  status: ORDER_STATUS;
  // invoice_number: string;
  // invoice_date: string;
  date: string;
  remark?: string;
  // cd_discount_rate: number;
  sp_discount: number;
  cartons: string;
  gst_type: string;
  sales_gst_amount: number;
  id?: string;
  customerId: string;
  medrepId: string;
  number_of_pages: number;
};

type challanPostData = {
  amount: number;
  taxable_amount: number;
  sp_discount: number;
  sales_gst_amount: number;
  id: string;
};

function useUpdateSalesOrder() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const handleMutation = (postData: postData | challanPostData) => {
    return axiosInstance.post(`/sales`, postData);
  };

  return useMutation(handleMutation, {
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries(queryKeys.getOrder);
    },
    onError(error: AxiosError, variables, context) {
      try {
        const msg = error.response?.data?.msg;
        dispatch(
          addToast({
            kind: ERROR,
            msg: msg,
          })
        );
      } catch (error) {
        dispatch(
          addToast({
            kind: ERROR,
            msg: "Oops, something went wrong",
          })
        );
      }
    },
  });
}

export default useUpdateSalesOrder;
