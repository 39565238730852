import { useQuery } from "react-query";
import axiosInstance from "../../axiosInstance";
import { SalesObj } from "../../types";

function useGetSalesOrders(status: string, fromDate: string, toDate: string) {
  const getAllOrders = async ({ queryKey }: any) => {
    const { data } = await axiosInstance.get(
      `/sales?start_date=${queryKey[2]}&end_date=${queryKey[3]}&status=${queryKey[1]}`
    );

    return data.data;
  };

  const {
    data: dataGetSalesOrders,
    error: errorGetSalesOrders,
    isLoading: isLoadingGetSalesOrders,
    isSuccess: isSuccessGetSalesOrders,
  } = useQuery<SalesObj[] | undefined, any, SalesObj[], any[]>(
    ["getAllOrders", status, fromDate, toDate],

    getAllOrders
  );

  return {
    dataGetSalesOrders,
    errorGetSalesOrders,
    isLoadingGetSalesOrders,
    isSuccessGetSalesOrders,
  };
}

export default useGetSalesOrders;
