import { Option } from "../../types";

interface Props {
  msg: string | Option;
}

const ErrorBox = ({ msg }: Props) => {
  if (typeof msg === "object") {
    msg = msg.label;
  }
  return <p className="text-sm mb-1 text-red-500 max-w-xs ">{msg}</p>;
};

export default ErrorBox;
