import { forwardRef, useEffect, useState } from "react";
import { ProductObj } from "../../../types";
import { toMMYYYY } from "../../../utils/formatDate";

type Props = {
  data: any;
};

const PrintAvailableStocksReport = forwardRef(({ data }: Props, ref: any) => {
  const [totals, setTotals] = useState({
    qty: 0,
  });

  const colums = [
    {
      label: "SR.",
    },
    {
      label: "Head",
    },
    {
      label: "Company",
    },
    {
      label: "Product Name",
    },
    {
      label: "Pot.",
    },
    {
      label: "Size",
    },
    {
      label: "BACH NO.",
    },
    {
      label: "QTY.",
    },
    {
      label: "M.R.P.",
    },
    {
      label: "MFG",
    },
    {
      label: "EXP",
    },
  ];

  useEffect(() => {
    if (data?.length) {
      let qty = 0;

      data.forEach((el: ProductObj) => {
        if (el.quantity) qty += Number(el.quantity);
      });
      setTotals({ qty });
    }
  }, [data]);

  return (
    <div ref={ref} className="flex flex-col justify-center gap-10">
      <h1 className="text-center w-full text-xl font-bold ">Available Stock</h1>

      {data?.length && (
        <table className="print-table   ">
          <thead>
            <tr>
              {colums.map((h, i) => (
                <th className="">{h.label}</th>
              ))}
            </tr>
          </thead>
          <tbody className="">
            {data?.map((e: ProductObj, index: number) => (
              <tr key={index} className="">
                <td className="">{index + 1}</td>
                <td className="">{e.head}</td>
                <td className="">{e.company.label}</td>
                <td className="">{e.product_name}</td>
                <td className="">{e.potency}</td>
                <td className="">{e.size}</td>
                <td className="">{e.batch_number}</td>
                <td className="">{e.quantity}</td>
                <td className="">{e.mrp}</td>
                <td className="">{toMMYYYY(e.mfg_date)}</td>
                <td className="">{toMMYYYY(e.exp_date)}</td>
              </tr>
            ))}
            <tr className="font-bold  ">
              <td colSpan={7} className="">
                Total
              </td>
              <td className="">{totals.qty}</td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
});

export default PrintAvailableStocksReport;
