import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../../axiosInstance";
import { addToast } from "../../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../../types/constants";
import queryKeys from "../../../types/queryKeys";

function useMutateStateCity() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const handleMutation = (postData: any) => {
    if (postData.id) {
      return axiosInstance.patch(`/statecity/${postData.id}`, postData);
    }
    return axiosInstance.post(`/statecity`, postData);
  };

  return useMutation(handleMutation, {
    onSuccess(data, payload, context) {
      queryClient.invalidateQueries(queryKeys.getStateCities);
      const { msg } = data.data;

      dispatch(
        addToast({
          kind: SUCCESS,
          msg: msg,
        })
      );
    },
    onError(data, payload, context) {
      if (payload.id) {
        dispatch(
          addToast({
            kind: ERROR,
            msg: "Unable to Update State & Cities",
          })
        );
      } else {
        dispatch(
          addToast({
            kind: ERROR,
            msg: "Unable to Add State & Cities",
          })
        );
      }
    },
  });
}

export default useMutateStateCity;
