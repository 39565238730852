import { Formik, useField, useFormikContext } from "formik";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import useUpdateItemsInPurchaseOrder from "../../../hooks/purchase/useUpdateItemsInPurchaseOrder";
import { showModal } from "../../../redux/features/modalSlice";
import { addToast } from "../../../redux/features/toastSlice";
import { HeadObjPurchase } from "../../../types";
import {
  ADD_DISCOUNTS_MODAL,
  ERROR,
  FROM,
  SUCCESS,
} from "../../../types/constants";
import { removeNullDiscounts } from "../../../utils/helpers";
import ErrorBox from "../../FormikComponents/ErrorBox";
import HandleSave from "../../Save/HandleSave";
import SingleRowConfirmPurchase from "./SingleRowConfirmPurchase";

type props = {
  fieldName: string;
  isOrdered_qtyTaxble?: boolean;
};

function ConfirmPurchaseTable({ fieldName, isOrdered_qtyTaxble }: props) {
  const [field, meta, helpers] = useField(fieldName);
  const [isUpdating, setIsUpdating] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();
  const { mutate: updateItem } = useUpdateItemsInPurchaseOrder();
  const { setFieldValue } = useFormikContext();

  const addDiscounts = (discounts: any) => {
    discounts = removeNullDiscounts(discounts);
    let newValues: any = [];

    for (let value of field.value) {
      newValues.push({ ...value, ...discounts });
    }
    helpers.setValue(newValues);
  };
  const addGlobalDiscount = () => {
    dispatch(
      showModal({
        modalType: ADD_DISCOUNTS_MODAL,
        modalTitle: "ADD DISCOUNTS",
        modalProps: {
          addDiscounts: addDiscounts,
          from: FROM.CONFIRM_PURCHASE,
        },
      })
    );
  };
  const handleUpdate = (values: any, setSubmitting: any) => {
    const discounts = removeNullDiscounts(values);

    const postData = {
      type: "purchase",
      items: [
        {
          id: values.item_name.value,
          qty: values.qty,
          ordered_qty: values.ordered_qty,
          exp_date: values.exp_date
            ? new Date(values.exp_date).toISOString()
            : null,
          mfg_date: values.mfg_date
            ? new Date(values.mfg_date).toISOString()
            : null,
          mrp: values.mrp,

          batch_number: values.batch_number,
          ...discounts,
        },
      ],
    };

    updateItem(postData, {
      onSuccess(data, variables, context) {
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: "Item Updated Successfully",
          })
        );
      },
      onSettled: () => {
        setSubmitting(false);
      },
    });
  };

  const handleSaveAll = useCallback(() => {
    let heads: any = [];
    let total = 0,
      gst_amount = 0;
    let valid = true;
    for (let value of field.value) {
      if (!valid) break;
      total += Number(value.total);
      gst_amount += Number(value.gst_amount * value.qty);
      const discounts = removeNullDiscounts(value);
      heads.push({
        id: value.id,
        qty: value.qty,
        ordered_qty: value.ordered_qty,
        exp_date: value.exp_date
          ? new Date(value.exp_date).toISOString()
          : null,
        mfg_date: value.mfg_date
          ? new Date(value.mfg_date).toISOString()
          : null,
        mrp: value.mrp,
        ...discounts,

        batch_number: value.batch_number,
        orderId: params.id,
      });

      setFieldValue("taxable_amount", total);
      setFieldValue("gst_amount", gst_amount);
    }
    if (!valid) {
      dispatch(
        addToast({
          kind: ERROR,
          msg: "One or More rows are invalid",
        })
      );
      return;
    }

    const postData = { type: "purchase", items: heads };
    setIsUpdating(true);

    updateItem(postData, {
      onSuccess: () => {
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: "All Items Updated Successfully",
          })
        );
      },
      onSettled: () => {
        setIsUpdating(false);
      },
    });
  }, [field.value]);

  useEffect(() => {
    calTotalTaxAbleAndGSTAmount(field.value, setFieldValue);
  }, [field.value]);

  return (
    <>
      <table className="head-info w-full mb-8 border rounded border-collapse text-sm text-center">
        <thead className="border">
          <tr className="border p-1">
            <th className="p-1">S No</th>
            <th className="p-1">Name</th>
            <th className="p-1">Batch No.</th>
            <th className="p-1">Ordered Qty</th>
            {!isOrdered_qtyTaxble && <th className="p-1">Quantity</th>}
            <th className="p-1">MRP</th>
            <th className="p-1">MFG-Date</th>
            <th className="p-1">Expiry</th>
            <th className="p-1">Dis 1(%)</th>
            <th className="p-1">Dis 2(%)</th>
            <th className="p-1">Dis 3(%)</th>
            <th className="p-1">Dis 4(%)</th>
            <th className="p-1">Dis 5(%)</th>
            <th className="p-1">Taxable Amt.</th>
            <th className="p-1">GST %</th>
            <th className="p-1">GST ₹</th>
            <th className="p-1">Total</th>
          </tr>
        </thead>
        <tbody
          className="border overflow-y-auto"
          style={{
            height: "100px",
            maxHeight: "100px",
          }}
        >
          {field.value && field.value.length > 0 ? (
            field.value.map((v: HeadObjPurchase, index: number) => {
              const initialValues: HeadObjPurchase = {
                id: v.id,
                head: v.head,
                company: v.company,
                qty: v.qty,
                ordered_qty: v.ordered_qty,
                potency: v.potency,
                size: v.size,
                item_name: v.item_name,
                mfg_date: v.mfg_date,
                exp_date: v.exp_date,
                hsn_number: v.hsn_number,
                batch_number: v.batch_number || "",
                gst: v.gst,
                mrp: v.mrp,
                d1: v.d1,
                d2: v.d2,
                d3: v.d3,
                d4: v.d4,
                d5: v.d5,
                gst_amount: v.gst_amount || 0,
                taxable_amount: v.taxable_amount || 0,
                total: v.total || 0,
              };

              return (
                <tr className="border p-1" key={index}>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={
                      isOrdered_qtyTaxble
                        ? rowValidationOrderedQtySchema
                        : rowValidationSchema
                    }
                    onSubmit={(values, { setSubmitting }) =>
                      handleUpdate(values, setSubmitting)
                    }
                    enableReinitialize={true}
                  >
                    {(formik) => {
                      return (
                        <>
                          <HandleSave handleSave={formik.handleSubmit} />

                          <SingleRowConfirmPurchase
                            isOrdered_qtyTaxble={isOrdered_qtyTaxble}
                            v={v}
                            id={index}
                            formik={formik}
                          />
                        </>
                      );
                    }}
                  </Formik>
                </tr>
              );
            })
          ) : (
            <tr className="p-2">
              <td>{meta.error && <ErrorBox msg={meta.error} />}</td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="buttons flex w-full my-12 items-center justify-end gap-x-4">
        <button
          className="btn-primary  rounded  w-44 mx-3   focus:bg-sky-700 "
          disabled={isUpdating}
          onClick={() => addGlobalDiscount()}
          type="button"
        >
          {"Add Discounts "}
        </button>

        <button
          className="btn-primary  rounded  w-44 mx-3   focus:bg-sky-700 "
          disabled={isUpdating}
          onClick={() => handleSaveAll()}
          type="button"
        >
          {"Update All"}
        </button>
      </div>
    </>
  );
}

export default ConfirmPurchaseTable;

const rowValidationSchema = Yup.object({
  item_name: Yup.object().required("Item Name is required"),
  batch_number: Yup.string()
    .typeError("Batch Number is not valid")
    .required("Batch Number is required"),
  qty: Yup.number().required("Quantity is required").min(1).integer(),
  taxable_amount: Yup.number().required("Rate is required"),
  mrp: Yup.number()
    .typeError("MRP is not valid")
    .required("MRP is required")
    .min(1, "MRP should be greater than 0"),
  mfg_date: Yup.date(),
  exp_date: Yup.date(),
  d1: Yup.number().nullable().min(0),
  d2: Yup.number().nullable().min(0),
  d3: Yup.number().nullable().min(0),
  d4: Yup.number().nullable().min(0),
  d5: Yup.number().nullable().min(0),
  gst: Yup.number().required("GST is required"),
});
const rowValidationOrderedQtySchema = Yup.object({
  item_name: Yup.object().required("Item Name is required"),
  batch_number: Yup.string()
    .typeError("Batch Number is not valid")
    .required("Batch Number is required"),
  ordered_qty: Yup.number().required("Quantity is required").min(1).integer(),
  taxable_amount: Yup.number().required("Rate is required"),
  mrp: Yup.number()
    .typeError("MRP is not valid")
    .required("MRP is required")
    .min(1, "MRP should be greater than 0"),
  mfg_date: Yup.date(),
  exp_date: Yup.date(),
  d1: Yup.number().nullable().min(0),
  d2: Yup.number().nullable().min(0),
  d3: Yup.number().nullable().min(0),
  d4: Yup.number().nullable().min(0),
  d5: Yup.number().nullable().min(0),
  gst: Yup.number().required("GST is required"),
});

const calTotalTaxAbleAndGSTAmount = (items: any, setFieldValue: Function) => {
  let total: number = 0;
  let totalGst = 0;
  for (let value of items) {
    total += Number(value.taxable_amount);
    totalGst += Number(value.gst_amount);
  }
  setFieldValue("taxable_amount", Number(total.toFixed(2)));
  setFieldValue("gst_amount", Number(totalGst.toFixed(2)));
};
