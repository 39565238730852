import { Form, Formik } from "formik";
import { HiUserGroup } from "react-icons/hi";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import useAddCustomerInDiscountCategory from "../../hooks/discounts/useAddCustomerInDiscountCategory";
import { Customer } from "../../types/customer.types";
import CustomerInput from "../FormikComponents/CustomerInput";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";
type postData = {
  customer?: Customer;
};
type props = {
  customerData?: Customer;
  readonly?: boolean;
};
function AddCustomersInCategoryForm({ customerData, readonly }: props) {
  const params = useParams();
  const initialValues: postData = {
    customer: customerData,
  };

  const validationSchema = Yup.object({
    customer: Yup.object({
      id: Yup.string().required("Please Select the Customer"),
    }),
  });
  const { mutate, isLoading } = useAddCustomerInDiscountCategory();
  const handleSubmit = (values: postData, resetForm: Function) => {
    console.log(values);
    mutate(
      { categoryId: params.categoryId!, customerId: values?.customer?.id! },
      {
        onSuccess() {
          resetForm();
        },
      }
    );
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
    >
      {({ values }) => (
        <Form>
          <div className="customer-form flex flex-col justify-center px-4 py-4 rounded border border-gray-300 gap-x-4 relative mb-14">
            <div className="row-1 grid grid-cols-5 items-center mb-6 gap-x-4">
              <CustomerInput
                label="Full Name"
                name="customer"
                id="customer"
                dependencies={{
                  bal: "bal",
                }}
                isDisabled={readonly}
              />
              <Input
                label="Address"
                name="customer.address"
                id="address"
                type="text"
                readonly
              />
              <Input
                label="City/Village"
                name="customer.city"
                id="city"
                type="text"
                readonly
              />
              <Input
                label="Pincode"
                name="customer.pincode"
                id="pincode"
                type="text"
                readonly
              />
              <Input
                label="State "
                name="customer.state"
                id="state"
                type="text"
                readonly
              />
            </div>
            <div className="row-2 flex items-center gap-x-4">
              <Input
                label="Contact No. 1"
                name="customer.contactOne"
                id="contactNo1"
                type="text"
                readonly
              />

              <Input
                label="GSTIN"
                name="customer.gstin"
                id="gstin"
                type="text"
                readonly
              />
              <Input
                label="Reg. No."
                name="customer.regNo"
                id="regNo"
                type="text"
                readonly
              />
              <Input
                label="UID"
                name="customer.uid"
                id="uid"
                type="text"
                readonly
              />

              <Input
                label="Transport"
                name="customer.transport.transporter"
                id="transport"
                type="text"
                readonly
              />
              {readonly ? (
                ""
              ) : (
                <div className="w-44">
                  <SubmitBtn
                    text="Add Customer"
                    classes="w-44"
                    isSubmitting={isLoading}
                  />
                </div>
              )}
            </div>
            <div className="label absolute right-5 -bottom-4 bg-gray-300 rounded text-sm flex items-center font-semibold p-1">
              <HiUserGroup size={16} className="mr-1" />
              Customer
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default AddCustomersInCategoryForm;
