import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import queryKeys from "../../types/queryKeys";
type PostData = {
  orderId: string;
  items: {
    ordered_qty: string;
    item_name: string;
    headId: string;
    companyId: string;
  }[];
};
function useAddItemInPurchaseOrder() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const handleMutation = (postData: PostData) => {
    return axiosInstance.post(`/purchase/${postData.orderId}/items`, postData);
  };

  return useMutation(handleMutation, {
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries(queryKeys.getItemsFromPurchaseOrder);
      dispatch(
        addToast({
          kind: SUCCESS,
          msg: "Item Added Successfully",
        })
      );
    },
    onError(data, variables, context) {
      dispatch(
        addToast({
          kind: ERROR,
          msg: "Unable to  Add Item",
        })
      );
    },
  });
}

export default useAddItemInPurchaseOrder;
