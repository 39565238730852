import { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import Error404 from "../../pages/Error404";
import { RootState } from "../../redux/app";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";

const Dashboard = lazy(() => import("../../pages/Dashboard"));

const SalesLayout = () => {
  const { show } = useSelector((state: RootState) => state.sidebar);

  return (
    <div className="relative">
      <div className="flex">
        <Sidebar />

        <main className={`main w-full ${show ? "ml-60" : ""} transition-all`}>
          <Header />

          <div>
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route path="/" element={<Dashboard />} />

                {/* /404 */}
                <Route path="/404" element={<Error404 />} />
                <Route
                  path="*"
                  element={<Navigate to="/404" replace={true} />}
                />
              </Routes>
            </Suspense>
          </div>
        </main>
      </div>
    </div>
  );
};

export default SalesLayout;
