interface Props {
  label?: string;
  type?: string;
  id: string;
  inline?: boolean;
  classes?: string;
  value: string | number;
}
function InputDisplayBox({
  label,
  type = "text",
  id,
  classes,
  value,
  inline = false,
  ...props
}: Props) {
  return (
    <div className={`flex flex-col w-full h-16 mb-6 ${classes}`}>
      <div
        className={`form-group flex ${
          inline ? " flex-row items-center" : "flex-col justify-center"
        }`}
      >
        <label
          className={`font-semibold text-sm ${inline ? "mr-4 w-min" : "mb-2"}`}
          htmlFor={id}
        >
          {label}{" "}
        </label>

        <input
          disabled={true}
          readOnly={true}
          className={`focus:border-primary hover:border-primary border-slate-200 placeholder-transparent  disabled:opacity-80 disabled:cursor-not-allowed `}
          id={id}
          type={type}
          value={value}
        />
      </div>
    </div>
  );
}

export default InputDisplayBox;
