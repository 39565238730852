import { Outlet } from "react-router-dom";
import AccountsNavCard from "../AccountsNavCard/AccountsNavCard";

const AdminAccountsLayout = () => {
  return (
    <div className="admin-accounts h-full w-full">
      <nav className="mb-8">
        <ul className="flex items-center">
          <AccountsNavCard
            title="Orders"
            path="/accounts/orders"
            text="Approve or Decline Orders"
            end={false}
          />
          <AccountsNavCard
            title="Debtors"
            path="/accounts/debtors"
            text="View Debtors ( Customers )"
            end={true}
          />
          <AccountsNavCard
            title="Creditors"
            path="/accounts/creditors"
            text="View Creditors ( Supplier )"
            end={true}
          />
        </ul>
      </nav>

      <Outlet />
    </div>
  );
};

export default AdminAccountsLayout;
