import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import useUpdateRoomNo from "../../../hooks/Inventory/RoomNo/useUpdateRoomNo";
import { hideModal } from "../../../redux/features/modalSlice";
import Input from "../../FormikComponents/Input";
import SubmitBtn from "../../FormikComponents/SubmitBtn";

function UpdateRoomNoModal(props: any) {
  const { mutate, isLoading } = useUpdateRoomNo();

  const dispatch = useDispatch();

  const initialValues: any = {
    companyId: props?.companyId || "",
    headId: props?.headId || "",
    company: props?.company.name,
    head: props?.head?.head || "",
    potency: props?.head?.potency || "",
    size: props?.head?.size || "",
    room_no: props?.room_no || "",
  };

  const validationSchema = Yup.object({
    company: Yup.string().required("Company name is required"),
    head: Yup.string().required("Head is required"),
    potency: Yup.string().required("Potency is required"),
    size: Yup.string().required("Size is required"),
    room_no: Yup.string(),
  });

  const handleSubmit = (value: any) => {
    const postData = {
      room_id: props?.room_id,
      room_no: value.room_no,
    };

    mutate(postData, {
      onSuccess(data, variables, context) {
        dispatch(hideModal());
      },
    });
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        <Form
          className="px-6 py-4 mt-2"
          style={{
            minWidth: "400px",
            maxWidth: "760px",
          }}
        >
          <div className="row-1 flex item-center gap-x-6 mb-3">
            <Input label="Head " id="head" name="head" type="text" readonly />
            <Input
              label="Company "
              id="company"
              name="company"
              type="text"
              readonly
            />
          </div>

          <div className="row-2 flex item-center gap-x-6 mb-3">
            <Input
              label="Potency "
              id="potency"
              name="potency"
              type="text"
              readonly
            />
            <Input label="Size " id="size" name="size" type="text" readonly />
          </div>

          <div className="row-3 flex item-center gap-x-6 mb-3">
            <Input label="Room  No" id="room_no" name="room_no" type="text" />
          </div>

          <div className="buttons flex items-center w-full justify-center my-4">
            <SubmitBtn
              text={"Update"}
              isSubmitting={isLoading}
              classes="text-sm h-10  mt-4"
            />
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default UpdateRoomNoModal;
