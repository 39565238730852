import { useState } from "react";
import { MdAdd } from "react-icons/md";
import { useDispatch } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import FromToDatePicker from "../../../components/Search/FromToDatePicker";
import TableInstance from "../../../components/Table/TableInstance";
import useGetSalesOrders from "../../../hooks/sales/useGetSalesOrders";
import { showModal } from "../../../redux/features/modalSlice";
import { REMARK_MODAL } from "../../../types/constants";
import { getStartAndEndDate, localestringToDate } from "../../../utils/helpers";

const AllInitiatedSalesOrders = () => {
  const dispatch = useDispatch();

  const SALES_COLUMNS = [
    {
      Header: "Sr. No",
      accessor: (_row: any, index: number) => index + 1,
    },
    {
      Header: "Order Number",
      accessor: "order_number",
    },
    {
      Header: "Number of Pages",
      accessor: "number_of_pages",
    },
    {
      Header: "Ledger Number",
      accessor: "customer.ledger_number",
    },
    {
      Header: "Customer Name",
      accessor: "customer.name",
    },
    {
      Header: "City",
      accessor: "customer.city",
    },
    {
      Header: "Contact",
      accessor: "customer.contactOne",
    },
    // {
    //   Header: "Invoice",
    //   accessor: "invoice",
    // },
    {
      Header: "Date",
      accessor: "date",
      Cell: ({ value }: any) => localestringToDate(value),
    },

    // {
    //   Header: "Bilty No.",
    //   accessor: "biltyNo",
    // },
    // {
    //   Header: "Courier Name",
    //   accessor: "courierName",
    // },
    // {
    //   Header: "Courier No.",
    //   accessor: "courierNo",
    // },
    // {
    //   Header: "Transport",
    //   accessor: "transportId", // changed from transport
    // },
    {
      Header: "Order By",
      accessor: "medrep.name",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }: any) => {
        return (
          <div className="status bg-lime-500 rounded px-2 py-1 text-white font-semibold text-center">
            Initiated
          </div>
        );
      },
    },
    {
      Header: "Remarks",
      accessor: "remark",
      Cell: ({ value, row }: any) => (
        <div className={`remarks flex items-center justify-center`}>
          <button
            className={`text-white p-4 py-3 w-max flex items-center justify-center rounded font-bold text-center  ${
              value && value !== "" ? "bg-blue-500" : "bg-blue-400"
            }`}
            onClick={() =>
              dispatch(
                showModal({
                  modalType: REMARK_MODAL,
                  modalTitle: value ? "Update Remark" : "Add Remark",
                  modalProps: {
                    id: row.original.id,
                    value: value,
                    // from: FROM.ALL_SALES,
                  },
                })
              )
            }
          >
            {/* {value && value !== "" ? <FaCommentAlt size={16} /> : "+"} */}
            {value && value !== "" ? <p>{value}</p> : "+"}
          </button>
        </div>
      ),
    },
    {
      Header: "",
      accessor: "btns-action",
      Cell: ({ row }: any) => {
        return (
          <div className="flex items-center gap-x-5">
            <Link
              className="btn-primary text-sm"
              to={`/sales/${row.original.id}/initiated/edit`}
              state={{ customerDetails: row.original }}
            >
              Edit
            </Link>
            {/* <button className="btn-primary text-sm">Print</button> */}
          </div>
        );
      },
    },
  ];

  const [cPageSize, cSetPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [cSortBy, cSetSortBy] = useState("order_number");
  const [desc, setDesc] = useState(true);
  const [q, setQ] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);

  let { startDate, endDate } = getStartAndEndDate();

  const [searchDates, setSearchDates] = useState({
    fromDate: startDate.toISOString(),
    toDate: endDate.toISOString(),
  });

  const handleSubmit = ({
    fromDate,
    toDate,
  }: {
    fromDate: string;
    toDate: string;
  }) => {
    let endOfToday = new Date(toDate);
    endOfToday.setHours(23, 59, 59, 999);
    setSearchDates({
      fromDate: new Date(fromDate).toISOString(),
      toDate: new Date(endOfToday).toISOString(),
    });
  };

  const { dataGetSalesOrders, isLoadingGetSalesOrders } = useGetSalesOrders(
    "initiated",
    searchDates.fromDate,
    searchDates.toDate
  );
  return (
    <div className="sales">
      <header className="flex items-center justify-between mb-8">
        <div className="left flex items-center">
          <div className="filters">
            <FromToDatePicker
              handleSearch={handleSubmit}
              isLoading={isLoadingGetSalesOrders}
              startDate={startDate}
              endDate={endDate}
            />
          </div>
        </div>
        <div className="right">
          <NavLink
            to="/sales/initiate-order"
            className="btn-primary flex items-center"
          >
            <span className="mr-1">Initiate Order</span> <MdAdd size={17} />
          </NavLink>
        </div>
      </header>

      <TableInstance
        tableData={dataGetSalesOrders || []}
        columnName={SALES_COLUMNS}
        cPageSize={cPageSize}
        cSetPageSize={cSetPageSize}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageCount={-1} // do calculation here
        cSortBy={cSortBy}
        cSetSortBy={cSetSortBy}
        desc={desc}
        setDesc={setDesc}
        q={q}
        setQ={setQ}
        setSelectedRows={setSelectedRows}
        selectRow={false}
      />
    </div>
  );
};

export default AllInitiatedSalesOrders;
