import { localestringToDate } from "../../utils/helpers";

type Props = {
  transactionIsLoading: boolean;
  transactionSuccess: boolean;
  transactionData: any;
  fromDate: string;
};

function CreditorTransactionsDetailsTable({
  transactionData,
  transactionIsLoading,
  transactionSuccess,
  fromDate,
}: Props) {
  let totalDr = 0,
    totalCr = 0;
  let balance = 0;
  if (!transactionIsLoading) balance = transactionData.carry_forward;

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th className="text-left">Order By</th>
            <th className="text-left">Date</th>
            <th className="text-left">Particular</th>
            <th className="text-right">Dr</th>
            <th className="text-right">Cr</th>
            <th className="text-right">Balance</th>
          </tr>
        </thead>
        <tbody>
          {!transactionIsLoading && transactionSuccess && (
            <>
              <tr className="">
                <td className="text-left"></td>

                <td className="text-left">{localestringToDate(fromDate)}</td>
                <td className="text-left">Opening Balance</td>
                <td className="text-right">
                  {Number(transactionData.carry_forward).toFixed(2)}
                </td>
                <td className="text-right">0</td>
                <td className="text-right">{balance?.toFixed(2)}</td>
              </tr>

              {transactionData.data.map((e: any, index: number) => {
                if (e.amount > 0) {
                  // case of debit
                  totalDr += Number(e.amount);
                  balance += Number(e.amount);
                } else {
                  // case of credit
                  totalCr += Number(-parseFloat(e.amount));
                  balance -= Number(-parseFloat(e.amount));
                }
                return (
                  <tr className="text-left" key={index}>
                    {/* <td>{e.medrep.name == null? e.medrep.name:''}</td> */}
                    <td></td>
                    <td>{localestringToDate(e.transaction_date)}</td>
                    <td>{e.particulars}</td>
                    <td className="text-right">
                      {e.amount > 0 ? e.amount?.toFixed(2) : "0"}
                    </td>
                    <td className="text-right">
                      {e.amount < 0 ? -parseFloat(e.amount?.toFixed(2)) : "0"}
                    </td>
                    <td className="text-right">{balance?.toFixed(2)}</td>
                  </tr>
                );
              })}
            </>
          )}
          <tr className="font-semibold bg-slate-200">
            <td colSpan={3}>Total</td>
            <td className="text-right">{totalDr.toFixed(2)}</td>
            <td className="text-right">{totalCr.toFixed(2)}</td>
            <td className="text-right">{balance?.toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

export default CreditorTransactionsDetailsTable;
