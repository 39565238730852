import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import queryKeys from "../../types/queryKeys";

type postData = {
  customerId: string;
  categoryId: string;
};
function useMutateCustomerInDiscountCategory() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const handleMutation = ({ customerId, categoryId }: postData) => {
    return axiosInstance.post(`/discounts/category/${categoryId}/customer`, {
      customerId,
    });
  };

  return useMutation(handleMutation, {
    onSuccess(data, payload, context) {
      queryClient.invalidateQueries(queryKeys.getDiscountCategory);
      dispatch(
        addToast({
          kind: SUCCESS,
          msg: `Customer Added to the Category  Successfully`,
        })
      );
    },
    onError(data: AxiosError, payload, context) {
      try {
        const msg = data.response?.data?.msg;
        dispatch(
          addToast({
            kind: ERROR,
            msg: msg,
          })
        );
      } catch (error) {
        dispatch(
          addToast({
            kind: ERROR,
            msg: `Unable to  add Customer to  Category`,
          })
        );
      }
    },
  });
}

export default useMutateCustomerInDiscountCategory;
