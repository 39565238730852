import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Formik } from "formik";
import { HiTag } from "react-icons/hi";
import * as Yup from "yup";
import CustomSelect from "../../components/FormikComponents/CustomSelect";
import CustomSubmitBtn from "../../components/FormikComponents/CustomSubmitBtn";
import HeadInput from "../../components/FormikComponents/HeadInput";
import SingleValueReactSelect from "../../components/FormikComponents/SingleValueReactSelect";
import HandlePrint, { PRINT_PAGE } from "../../components/Print/HandlePrint";
import TableInstance from "../../components/Table/TableInstance";
import useGetCompanies from "../../hooks/useGetCompanies";
import useGetItemsFromInventory from "../../hooks/useGetItemsFromInventory";
import { toMMYYYY } from "../../utils/formatDate";

const AvailableStocksReport = () => {
  const INVENTORY_COLUMNS = [
    {
      Header: "Head",
      accessor: "head",
    },
    {
      Header: "Company",
      accessor: "company.label",
    },
    {
      Header: "Product Name",
      accessor: "product_name",
    },
    {
      Header: "Potency",
      accessor: "potency",
    },
    {
      Header: "Size",
      accessor: "size",
    },
    {
      Header: "Quantity",
      accessor: "quantity",
    },
    {
      Header: "MRP",
      accessor: "mrp",
    },
    {
      Header: "Batch No.",
      accessor: "batch_number",
    },
    {
      Header: "Manufactured",
      accessor: "mfg_date",
      Cell: ({ value }: any) => <span>{toMMYYYY(value)}</span>,
    },
    {
      Header: "Expiry",
      accessor: "exp_date",
      Cell: ({ value }: any) => (
        <span className={new Date(value) < new Date() ? "text-red-500" : ""}>
          {toMMYYYY(value)}
        </span>
      ),
    },
  ];

  const [cPageSize, cSetPageSize] = useState(20);
  const [pageIndex, setPageIndex] = useState(0);
  const [cSortBy, cSetSortBy] = useState(null);
  const [desc, setDesc] = useState(true);
  const [q, setQ] = useState("");
  const [searchParmas, setSearchParmas] = useState({
    "$head.head$": "",
    "$head.potency$": "",
    "$head.size$": "",
    companyId: "",
  });

  const [potencies, setPotencies] = useState<string[]>([]);
  const [sizes, setSizes] = useState<string[]>([]);

  const [selectedRows, setSelectedRows] = useState([]);

  const navigate = useNavigate();

  let component = null;

  const refHead = useRef<any>(null);

  const { dataItems, isLoadingItems, errorItems, isFetchingItems } =
    useGetItemsFromInventory({
      ...searchParmas,
      filters: {
        quantity: { gt: 0 },
      },
    });

  const { dataCompany } = useGetCompanies();

  const searchItems = (values: any) => {
    console.log(values);
    setSearchParmas({
      "$head.head$": values.head,
      "$head.potency$": values.potency,
      "$head.size$": values.size,
      companyId: values.company?.value,
    });
  };

  const initialValues: any = {
    head: "",
    company: {
      value: "",
      label: "",
    },
    potency: "",
    size: "",
  };

  const validationSchema = Yup.object({
    head: Yup.string(),
    company: Yup.object().nullable(),

    potency: Yup.string(),
    size: Yup.string(),
  });

  if (errorItems) {
    component = (
      <p className="mt-6 ml-4 text-center">
        An error has occurred: {errorItems.message}
      </p>
    );
  } else if (isLoadingItems) {
    component = <p className="mt-6 ml-4 text-center">Loading...</p>;
  } else {
    component = (
      <TableInstance
        tableData={dataItems || []}
        columnName={INVENTORY_COLUMNS}
        cPageSize={cPageSize}
        cSetPageSize={cSetPageSize}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageCount={-1} // do calculation here
        cSortBy={cSortBy}
        cSetSortBy={cSetSortBy}
        desc={desc}
        setDesc={setDesc}
        q={q}
        setQ={setQ}
        setSelectedRows={setSelectedRows}
        selectRow={false}
      />
    );
  }

  return (
    <div className="all-users mt-12">
      <div className="inventory-filter flex flex-col justify-center px-4 py-4 rounded border border-gray-300 gap-x-4 relative mb-14">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => searchItems(values)}
        >
          {(formik) => (
            <>
              <div className="row-1 flex items-center mb-2 gap-x-4">
                <HeadInput
                  ref={refHead}
                  setSizes={setSizes}
                  setPotencies={setPotencies}
                  dependencies={{
                    potency: "potency",
                    size: "size",
                  }}
                  classes="w-full"
                  id="head"
                  name="head"
                  label="Head"
                />

                <SingleValueReactSelect
                  id="potency"
                  name="potency"
                  label="Potency"
                  isClearable
                  isDisabled={potencies.length === 0}
                  options={potencies}
                  classes="w-full"
                  placeholder="Select Potency"
                />

                <SingleValueReactSelect
                  classes="w-full"
                  id="size"
                  name="size"
                  label="Size"
                  isClearable
                  placeholder="Select Size"
                  isDisabled={sizes.length === 0}
                  options={sizes}
                />
                <CustomSelect
                  options={dataCompany || []}
                  id="company"
                  name="company"
                  label="Company"
                  classes="w-full"
                  isClearable
                  isDisabled={dataCompany?.length === 0}
                  placeholder="Select Company"
                />

                <CustomSubmitBtn
                  text="Search"
                  classes="w-44 my-3"
                  disabled={isLoadingItems || isFetchingItems}
                  onSubmit={(e) => {
                    e.preventDefault();

                    formik.handleSubmit();
                  }}
                />
              </div>

              <div className="label absolute right-5 -bottom-4 bg-gray-300 rounded text-sm flex items-center font-semibold p-1">
                <HiTag size={16} className="mr-1" />
                Search Product
              </div>
            </>
          )}
        </Formik>
      </div>
      <header className="flex items-center justify-between -mb-12">
        <div className="left">
          <div className="search-box mr-4"></div>
          <div className="filters"> </div>
        </div>
        <div className="right">
          <HandlePrint
            data={dataItems}
            page={PRINT_PAGE.availableStocksReport}
          />
        </div>
      </header>
      {component !== null && component}
    </div>
  );
};

export default AvailableStocksReport;
