import CustomSubmitBtn from "../FormikComponents/CustomSubmitBtn";
interface Props {
  label?: string;
  classes?: string;
  disabled: boolean;
  containerClasses?: string;
}
function SaveButton({ label, containerClasses, ...props }: Props) {
  // this button trigerres ctlr + s keyboard Event
  return (
    <CustomSubmitBtn
      text={"Save" || label}
      containerClasses={"w-44 " + containerClasses}
      {...props}
      onSubmit={() => {
        document.dispatchEvent(
          new KeyboardEvent("keydown", {
            key: "s",
            ctrlKey: true,
            keyCode: 83,
          })
        );
      }}
    />
  );
}

export default SaveButton;
