import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import axiosInstance from "../../../axiosInstance";
import queryKeys from "../../../types/queryKeys";

function useDropRoom() {
  const queryClient = useQueryClient();

  return useMutation(
    (room_id: string) => axiosInstance.delete(`/room/${room_id}`),
    {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries(queryKeys.getRoomNo);
        toast.success("Room No dropped successfully");
      },
      onError(err: AxiosError, variables, context) {
        try {
          const { msg } = err?.response?.data;
          toast.error(msg);
        } catch (error) {
          toast.error("Something went wrong");
        }
      },
    }
  );
}

export default useDropRoom;
