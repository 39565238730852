import { Form, Formik } from "formik";
import * as Yup from "yup";
import { convertDateToStringForForm } from "../../utils/helpers";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";

type Props = {
  handleSearch: Function;
  startDate: Date;
  endDate: Date;
  isLoading: boolean;
};
function FromToDatePicker({
  handleSearch,
  startDate,
  endDate,
  isLoading,
}: Props) {
  const initialValues = {
    fromDate: convertDateToStringForForm(startDate),
    toDate: convertDateToStringForForm(endDate),
  };

  const validationSchema = Yup.object({
    fromDate: Yup.date()
      .required("From date is required")
      .max(new Date(), "From date cannot be a future date"),
    toDate: Yup.date()
      .required("To date is required")
      .min(Yup.ref("fromDate"), "To date must be after From date")
      .max(new Date(), "To date cannot be a future date"),
  });
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => handleSearch(values, resetForm)}
    >
      <Form className="">
        <div className="date flex items-center gap-x-5 mr-5">
          <div className="from flex items-center  mr-2">
            <Input label="From" name="fromDate" id="fromDate" type="date" />
          </div>
          <div className="to  ">
            <Input label="To" name="toDate" id="toDate" type="date" />
          </div>
          <div className="buttons flex items-center justify-center">
            <SubmitBtn
              text={"Search"}
              isSubmitting={isLoading}
              classes="font-medium "
              shouldNotValidate
            />
          </div>
        </div>
      </Form>
    </Formik>
  );
}

export default FromToDatePicker;
