import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";

import axiosInstance from "../../axiosInstance";
import useDropStateCity from "../../hooks/Conifg/state-city/useDropStateCity";
import useDropRoom from "../../hooks/Inventory/RoomNo/useDropRoom";
import useDropProductFromSalesOrder from "../../hooks/sales/useDropProductFromSalesOrder";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, FROM, SUCCESS } from "../../types/constants";
import queryKeys from "../../types/queryKeys";

interface Props {
  id: string;
  value: string;
  from: string;
}

const GetConfirmationModal = ({ id, value, from }: Props) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { mutate: dropProduct } = useDropProductFromSalesOrder();
  const { mutate: dropState } = useDropStateCity();

  const { mutate: dropRoom } = useDropRoom();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = useCallback(() => {
    setIsSubmitting(true);
    switch (from) {
      case FROM.CONFIRM_PURCHASE:
        axiosInstance
          .delete(`/purchase/items/${id}`)
          .then((res) => {
            console.log(res);
            dispatch(
              addToast({
                kind: SUCCESS,
                msg: "Item Dropped Successfully",
              })
            );
            queryClient.invalidateQueries(queryKeys.getItemsFromPurchaseOrder);
            setIsSubmitting(false);

            dispatch(hideModal());
          })
          .catch((err) => {
            console.log(err);
            dispatch(
              addToast({
                kind: ERROR,
                msg: "Unable to Drop Item",
              })
            );
            setIsSubmitting(false);
          });
        break;
      case FROM.VIEW_SALES_ORDER:
        axiosInstance
          .delete(`/sales/${id}`)
          .then((res) => {
            console.log(res);
            dispatch(
              addToast({
                kind: SUCCESS,
                msg: "Item Dropped Successfully",
              })
            );
            queryClient.invalidateQueries("getItemsFromSalesOrder");
            setIsSubmitting(false);

            dispatch(hideModal());
          })
          .catch((err) => {
            console.log(err);
            dispatch(
              addToast({
                kind: ERROR,
                msg: "Unable to Drop Item",
              })
            );
            setIsSubmitting(false);
          });
        break;
      case FROM.ITEM_CONFIG:
        axiosInstance
          .delete(`/items/${id}`)
          .then((res) => {
            console.log(res);
            dispatch(
              addToast({
                kind: SUCCESS,
                msg: "Item Dropped Successfully",
              })
            );
            queryClient.invalidateQueries("getItems");
            setIsSubmitting(false);

            dispatch(hideModal());
          })
          .catch((err) => {
            console.log(err);
            dispatch(
              addToast({
                kind: ERROR,
                msg: "Unable to Drop Item",
              })
            );
            setIsSubmitting(false);
            dispatch(hideModal());
          });
        break;

      case FROM.CREATE_PURCHASE:
        dispatch(hideModal());
        break;
      case FROM.DELETE_SALES_PRODUCT:
        dropProduct(id, {
          onSuccess() {
            dispatch(hideModal());
          },
        });
        break;
      case FROM.DELETE_ROOM_NO:
        dropRoom(id, {
          onSuccess() {
            dispatch(hideModal());
          },
          onError() {
            setIsSubmitting(false);
          },
        });
        break;
      case FROM.STATE_CITY:
        dropState(id, {
          onSuccess() {
            dispatch(hideModal());
          },
          onError() {
            setIsSubmitting(false);
          },
        });
        break;

      default:
        dispatch(hideModal());
        setIsSubmitting(false);

        break;
    }
  }, []);

  return (
    <div className="remark-modal">
      <div
        className="px-6 py-4 mt-2"
        style={{
          minWidth: "450px",
          maxWidth: "760px",
        }}
      >
        <div className="flex  gap-x-6 mb-3 font-semibold text-center">
          {value}
        </div>

        <div className="buttons flex items-center justify-around mt-6">
          <button
            disabled={isSubmitting}
            className="btn-danger focus:bg-red-500 hover:bg-red-500  rounded  w-44 mx-3 "
            onClick={() => {
              handleSubmit();
            }}
          >
            Yes
          </button>
          <button
            disabled={isSubmitting}
            className="  btn-primary rounded  w-44 mx-3 "
            onClick={() => {
              dispatch(hideModal());
            }}
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default GetConfirmationModal;
