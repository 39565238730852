import { useQuery } from "react-query";
import axiosInstance from "../axiosInstance";
import { ConfigurationTransportObj } from "../types";

function useGetTransports() {
  const getTransports = async ({ queryKey }: any) => {
    const { data } = await axiosInstance.get(`/transport`);

    return data.data;
  };
  const queryKeyTransport = "getTransports";

  const {
    data: dataTransports,
    error: errorTransports,
    isLoading: isLoadingTransports,
  } = useQuery<
    ConfigurationTransportObj[] | undefined,
    any,
    ConfigurationTransportObj[],
    any[]
  >([queryKeyTransport], getTransports);

  return {
    dataTransports,
    errorTransports,
    isLoadingTransports,
    queryKeyTransport,
    optionTransports:
      dataTransports?.map((data) => {
        return {
          label: data.transporter,
          value: data.id!,
        };
      }) || [],
  };
}

export default useGetTransports;
