import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import TableInstance from "../../components/Table/TableInstance";
import useGetCustomers from "../../hooks/customer/useGetCustomers";

function DiscountCustomerSpecific() {
  const navigate = useNavigate();
  const MR_COLUMNS = [
    {
      Header: "Sr.No",
      accessor: (_row: any, index: number) => index + 1,
      sortable: false,
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Address",
      accessor: "address",
    },
    {
      Header: "Area",
      accessor: "area",
    },

    {
      Header: "City",
      accessor: "city",
    },
    {
      Header: "State",
      accessor: "state",
    },
    {
      Header: "Contact 1",
      accessor: "contactOne",
    },

    {
      Header: "Email",
      accessor: "email",
    },

    {
      Header: "MR 1",
      accessor: "mr1.name",
    },
    {
      Header: "MR 2",
      accessor: "mr2.name",
    },
    {
      Header: "Transport",
      accessor: "transport.transporter",
    },
    {
      Header: "Discount Category",
      accessor: "discount_category",
      Cell: ({ value }: any) => value?.name || "------",
    },
    {
      Header: "No. of Special Discounts",
      accessor: "discounts",
      Cell: ({ value }: any) => value.length,
    },
    {
      Header: "",
      accessor: "editBtn",
      Cell: ({ row }: any) => {
        return (
          <div className="btns flex items-center justify-center gap-4">
            <button
              className="btn-primary flex items-center"
              onClick={() => {
                navigate(`/discount/customer/${row.original.id}/update`);
              }}
            >
              <span className="mr-1"> Update Discounts</span>
            </button>
          </div>
        );
      },
    },
  ];

  const dispatch = useDispatch();

  const [cPageSize, cSetPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [cSortBy, cSetSortBy] = useState("");
  const [desc, setDesc] = useState(false);
  const [q, setQ] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);

  let component = null;

  const { dataCustomers, errorCustomers, isLoadingCustomers } = useGetCustomers(
    { getDiscounts: true }
  );

  if (errorCustomers) {
    component = (
      <p className="mt-6 ml-4 text-center">
        An error has occurred: {errorCustomers.message}
      </p>
    );
  } else if (isLoadingCustomers) {
    component = <p className="mt-6 ml-4 text-center">Loading...</p>;
  } else {
    component = (
      <TableInstance
        tableData={dataCustomers || []}
        columnName={MR_COLUMNS}
        cPageSize={cPageSize}
        cSetPageSize={cSetPageSize}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageCount={-1} // do calculation here
        cSortBy={cSortBy}
        cSetSortBy={cSetSortBy}
        desc={desc}
        setDesc={setDesc}
        q={q}
        setQ={setQ}
        setSelectedRows={setSelectedRows}
        selectRow={false}
      />
    );
  }

  return (
    <div className="customer-page mt-12">{component !== null && component}</div>
  );
}

export default DiscountCustomerSpecific;
