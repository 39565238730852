import { useQuery } from "react-query";
import axiosInstance from "../../../axiosInstance";
import { Option, StateCity } from "../../../types";
import queryKeys from "../../../types/queryKeys";

function useGetStateCity() {
  const getStateCities = async () => {
    const { data } = await axiosInstance.get(`/statecity`);

    return data.data;
  };

  const {
    data: dataStateCity,
    error: errorStateCity,
    isLoading: isLoadingStateCity,
    isSuccess: isSuccessStateCity,
  } = useQuery<StateCity[] | undefined, any, StateCity[], any>(
    [queryKeys.getStateCities],
    getStateCities
  );

  return {
    dataStateCity,
    errorStateCity,
    isLoadingStateCity,
    isSuccessStateCity,
    optionStates: dataStateCity?.map((data) => data.state) || [],

    optionCities: dataStateCity?.reduce(
      (o, key) => ({
        ...o,
        [key.state]: key.cities,
      }),
      {}
    ),

    optionAllCities: dataStateCity?.reduce((o, key): any => {
      return [
        ...o,
        ...key.cities.map((c) => {
          return {
            label: c,
            value: c,
          };
        }),
      ];
    }, []) as unknown as Option[],
  };
}

export default useGetStateCity;
