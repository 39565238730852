const pathnameRegex = {
  sales: {
    sales: /\/sales\/*/,
    viewSales: /\/sales\/[\S\s]*\/sales-order$/,
    viewSalesChallan: /\/sales\/[\S\s]*\/challaan$/,
    editSalesOrder: /\/sales\/[\S\s]*\/order\/edit$/,
    viewSalesInvoice: /\/sales\/[\S\s]*\/add-invoice$/,
    editInitiatedSalesOrder: /\/sales\/[\S\s]*\/initiated\/edit$/,
  },
  confirmPurchase: /\/purchases\/[\S\s]*\/(confirm|view)$/,
  editPurchase: /\/purchases\/[\S\s]*\/(edit)$/,
  reports: /reports/,
  configration: /configuration/,
  debtors: /\/accounts\/debtors\/[\S\s]*\/(edit)$/,
  creditors: /\/accounts\/creditors\/[\S\s]*\/(edit)$/,
  discount: /\/discount\/*/,
};

export default pathnameRegex;
