import * as Yup from "yup";
import { gstTypes } from "./orderConstants";

export const SalesOrderValidation = Yup.object({
  customer: Yup.object({
    id: Yup.string().required("Full name is required"),
  }),

  heads: Yup.array()
    .of(Yup.object())
    .min(1, "Please provide atleast one Item")
    .required("Please provide atleast one Item"),
  taxable_amount: Yup.number().required("Total Taxable is required").positive(),
  sp_discount: Yup.string().required("Special Dis is required"),
  sales_gst_amount: Yup.string().required("GST is required"),
  total: Yup.number().required("Net total is required").positive(),
  date: Yup.date()
    .max(new Date(), "Sale Date cannot be a future date")
    .required("Sale Date is required"),
  orderBy: Yup.object({
    label: Yup.string().required("Order By is required"),
    value: Yup.string(),
  }),
  cartons: Yup.number().required("Cartons is required"),
  gstType: Yup.string()
    .oneOf(gstTypes.map((g) => g.value))
    .required("GST Type is required"),
  remarks: Yup.string(),
  order_type_sales: Yup.string().required("Order Type is required"),
});
