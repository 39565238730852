import { useField, useFormikContext } from "formik";
import React, { useEffect } from "react";
import { Option } from "../../types";
import ErrorBox from "./ErrorBox";

interface Props {
  label?: string;
  id: string;
  name: string;
  options: Option[];
  classes?: string;
  disabled?: boolean;
  initialChecked?: string;
}

const RadioButtons = ({
  options,
  id,
  name,
  label,
  classes,
  disabled,
  initialChecked,
  ...props
}: Props) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta, helpers] = useField(name);

  useEffect(() => {
    !field.value && helpers.setValue(options[0].value);
  }, [options]);

  return (
    <div className={`flex flex-col justify-center h-16 ${classes}`}>
      <div className={`form-group flex flex-col items-start`}>
        {label && (
          <label className={`font-semibold text-sm mb-2`} htmlFor={id}>
            {label}
          </label>
        )}

        <div className="options ml-4 w-full flex">
          {options.map((option) => (
            <div
              key={option.value}
              className="option flex items-center mr-3"
              style={{
                minWidth: "50px",
              }}
            >
              <input
                type="radio"
                {...field}
                {...props}
                // checked={field.value === option.value}
                checked = {field.value === option.value}
                id={option.value}
                value={option.value}
                // onSelect={(e) => {
                //   setFieldValue(field.name, option.value);
                // }}
                onChange={() => {
                  setFieldValue(field.name, option.value);
                }}
                className="w-max mr-1"
                disabled={disabled}
              />
              <label
                key={option.value}
                htmlFor={option.value}
                className="w-full text-base mb-0"
              >
                {option.label}
              </label>
            </div>
          ))}
        </div>
      </div>
      {meta.touched && meta.error && <ErrorBox msg={meta.error} />}
    </div>
  );
};

export default RadioButtons;
