import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import queryKeys from "../../types/queryKeys";

type item = {
  id: string;
  d1?: number;
  d2?: number;
  sale_qty: number;
  free_qty?: number;
};
type payload = {
  items: item[];
};

function useUpdateProductInSalesOrder() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const handleMutation = (postData: payload) => {
    return axiosInstance.patch(`/orders`, { ...postData, type: "sales" });
  };

  return useMutation(handleMutation, {
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries(queryKeys.getItemsFromSalesOrder);
      dispatch(
        addToast({
          kind: SUCCESS,
          msg: "Item Updated Successfully",
        })
      );
    },
    onError(data, variables, context) {
      dispatch(
        addToast({
          kind: ERROR,
          msg: "Unable to  Update Item",
        })
      );
    },
  });
}

export default useUpdateProductInSalesOrder;
