import { QueryKey, useQuery } from "react-query";
import axiosInstance from "../../axiosInstance";
import queryKeys from "../../types/queryKeys";

type Parmas = {
  //   headId?: string;
  companyId?: string;
  "$head.head$"?: string;
  "$head.potency$"?: string;
  "$head.size$"?: string;
};

type Response = {
  product_name: string;
};

function useGetGroupedProducts(params: Parmas = {}) {
  const getItems = async ({ queryKey }: any) => {
    const [_key, params] = queryKey;
    const { data } = await axiosInstance.get("/inventory/products/unique", {
      params,
    });
    return data.data;
  };

  return useQuery<Response[], any, Response[], QueryKey>(
    [queryKeys.getGroupedProducts, params],
    getItems
  );
}

export default useGetGroupedProducts;
