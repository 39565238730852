import { useQuery } from "react-query";
import axiosInstance from "../axiosInstance";
import { Option } from "../types";
import queryKeys from "../types/queryKeys";

const getCompanies = async () => {
  const response = await axiosInstance.get("/company");

  const { data } = response.data;

  return data.map((supplier: any) => ({
    value: supplier.id,
    label: supplier.name,
  }));
};
function useGetCompanies() {
  const {
    data: dataCompany,
    isLoading: isLoadingCompany,
    isFetching: isFetchingCompany,
    error: errorCompany,
    isSuccess: isSuccessCompany,
  } = useQuery<Option[] | undefined, any, Option[], string[]>(
    [queryKeys.getCompanies],
    getCompanies
  );

  return {
    dataCompany,
    isLoadingCompany,
    isFetchingCompany,
    errorCompany,
    isSuccessCompany,
  };
}

export default useGetCompanies;
