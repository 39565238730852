import { NavLink, Outlet } from "react-router-dom";

function ReportsLayout() {
  return (
    <div className="configuration h-screen w-full">
      <nav className="configuration-nav mb-12 noselect">
        <ul className="flex gap-x-6 items-center">
          <li>
            <NavLink
              className={({ isActive }) =>
                `flex-1 text-lg rounded font-semibold px-2 py-1 shadow ${
                  isActive ? "bg-primary text-white" : "bg-white"
                }`
              }
              end
              to=""
            >
              Sold Stock
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                `flex-1 text-lg rounded font-semibold px-2 py-1 shadow ${
                  isActive ? "bg-primary text-white" : "bg-white"
                }`
              }
              to="gst"
            >
              GST Report
            </NavLink>
          </li>

          <li>
            <NavLink
              className={({ isActive }) =>
                `flex-1 text-lg rounded font-semibold px-2 py-1 shadow ${
                  isActive ? "bg-primary text-white" : "bg-white"
                }`
              }
              end
              to="out-of-stock"
            >
              Out of Stock Items
            </NavLink>
          </li>

          <li>
            <NavLink
              className={({ isActive }) =>
                `flex-1 text-lg rounded font-semibold px-2 py-1 shadow ${
                  isActive ? "bg-primary text-white" : "bg-white"
                }`
              }
              end
              to="customer-orders"
            >
              Customer Orders
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                `flex-1 text-lg rounded font-semibold px-2 py-1 shadow ${
                  isActive ? "bg-primary text-white" : "bg-white"
                }`
              }
              end
              to="available-stocks"
            >
              Available Stocks
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                `flex-1 text-lg rounded font-semibold px-2 py-1 shadow ${
                  isActive ? "bg-primary text-white" : "bg-white"
                }`
              }
              end
              to="collection"
            >
              Collection Reports
            </NavLink>
          </li>
        </ul>
      </nav>

      <div className="content">
        <Outlet />
      </div>
    </div>
  );
}

export default ReportsLayout;
