import React from "react";
import { NavLink } from "react-router-dom";

interface Props {
  path: string;
  text: string;
  end: boolean;
  bgColor: string;
  textColor: string;
}

const AccountsOrdersNav = ({ path, text, end, bgColor, textColor }: Props) => {
  return (
    <li className="flex-1 flex text-center shadow">
      <NavLink
        className={({ isActive }) =>
          `p-3 w-full rounded font-bold ${isActive ? `${bgColor} text-white` : `${textColor} bg-white`}`
        }
        end={end}
        to={path}
      >
        {text}
      </NavLink>
    </li>
  );
};

export default AccountsOrdersNav;
