import { QueryKey, useQuery } from "react-query";
import axiosInstance from "../axiosInstance";
import { ProductObj } from "../types";
import queryKeys from "../types/queryKeys";
type Parmas = {
  headId?: string;
  companyId?: string;
  "$head.head$"?: string;
  "$head.potency$"?: string;
  "$head.size$"?: string;
  filters?: {
    quantity?: {
      gt?: number;
      lt?: number;
    };
  };
};

function useGetItemsFromInventory(params: Parmas = {}) {
  const getItems = async ({ queryKey }: any) => {
    const [_key, params] = queryKey;
    const response = await axiosInstance.get("/inventory", {
      params,
    });

    const { data } = response.data;

    return data.map((item: any) => ({
      product_id: item.product_id,
      head: item.head.head,
      product_name: item.product_name,
      potency: item.head.potency,
      company: {
        value: item.company.id,
        label: item.company.name,
      },
      size: item.head.size,
      name: item.product_name,
      quantity: item.quantity,
      mrp: item.mrp,
      mfg_date: item.mfg_date,
      exp_date: item.exp_date,
      gst: item.head.gst,
      hsn_number: item.head.hsn_number,
      batch_number: item.batch_number,
      discount: item.discount || 0,
    }));
  };

  const {
    data: dataItems,
    isLoading: isLoadingItems,
    isFetching: isFetchingItems,
    error: errorItems,
  } = useQuery<ProductObj[] | undefined, any, ProductObj[], QueryKey>(
    [queryKeys.getProducts, params],
    getItems
  );
  return { dataItems, isFetchingItems, isLoadingItems, errorItems };
}

export default useGetItemsFromInventory;
