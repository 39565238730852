import { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import CreditorDetails from "../../pages/accounts/creditors/CreditorDetails";
import ItemConfiguration from "../../pages/configuration/ItemConfiguration";
import StateCityConfiure from "../../pages/configuration/StateCityConfigure";
import Error404 from "../../pages/Error404";
import CompletedPurchases from "../../pages/purchases/CompletedPurchases";
import EditPurchaseOrder from "../../pages/purchases/EditPurchaseOrder";
import EditSalesOrder from "../../pages/sales/created/EditSalesOrder";

import ViewPurchase from "../../pages/purchases/ViewPurchase";
import CollectionReports from "../../pages/reports/CollectionReports";
import CustomerOrdersReport from "../../pages/reports/CustomerOrdersReport";
import GstReport from "../../pages/reports/GstReport";
import OutOfStockItemsReport from "../../pages/reports/OutOfStockItemsReport";
import SoldStockReport from "../../pages/reports/SoldStockReport";
import AllInitiatedSalesOrders from "../../pages/sales/initiated/AllInitiatedSalesOrders";

import RoomNoConfigration from "../../pages/configuration/RoomNoConfigration";
import DiscountCategories from "../../pages/discounts/DiscountCategories";
import DiscountCustomerSpecific from "../../pages/discounts/DiscountCustomerSpecific";
import UpdateCustomerSpecificDiscounts from "../../pages/discounts/UpdateCustomerSpecificDiscounts";
import UpdateCustomerToDiscountCategory from "../../pages/discounts/UpdateCustomerToDiscountCategory";
import UpdateItemsToDiscountCategory from "../../pages/discounts/UpdateItemsToDiscountCategory";
import AvailableStocksReport from "../../pages/reports/AvailableStocksReport";
import InitiateSalesOrder from "../../pages/sales/initiated/InitiateSalesOrder";
import ViewSalesOrder from "../../pages/sales/ViewSalesOrder";
import { RootState } from "../../redux/app";
import ErrorBoundary from "../ErrorBoundary";
import Header from "../Header/Header";
import Loader from "../Loader/Loader";
import Sidebar from "../Sidebar/Sidebar";
import AdminAccountsLayout from "./AdminAccountsLayout";
import AdminAccountsOrdersLayout from "./AdminAccountsOrdersLayout";
import AdminSalesLayout from "./AdminSalesLayout";
import ConfigurationLayout from "./ConfigurationLayout";
import DiscountsLayout from "./DiscountsLayout";
import GstReportsLayout from "./GstReportsLayout";
import PurchaseLayout from "./PurchaseLayout";
import ReportsLayout from "./ReportsLayout";
import UsersLayout from "./UsersLayout";

const Dashboard = lazy(() => import("../../pages/Dashboard"));
const Inventory = lazy(() => import("../../pages/Inventory"));
const Creditors = lazy(
  () => import("../../pages/accounts/creditors/Creditors")
);

const AccountDetail = lazy(() => import("../../pages/accounts/AccountDetail"));
const Debtors = lazy(() => import("../../pages/accounts/Debtors"));
const ApprovedOrders = lazy(
  () => import("../../pages/accounts/orders/ApprovedOrders")
);
const DeclinedOrders = lazy(
  () => import("../../pages/accounts/orders/DeclinedOrders")
);
const PendingOrders = lazy(
  () => import("../../pages/accounts/orders/PendingOrders")
);

const Sales = lazy(() => import("../../pages/sales/Sales"));
const Challaans = lazy(() => import("../../pages/sales/Challaans"));
const Invoices = lazy(() => import("../../pages/sales/Invoices"));

const AddInvoice = lazy(() => import("../../pages/sales/AddInvoice"));
const CreateOrder = lazy(() => import("../../pages/sales/CreateOrder"));

const AllPurchases = lazy(() => import("../../pages/purchases/AllPurchases"));
const ConfirmPurchase = lazy(
  () => import("../../pages/purchases/ConfirmPurchase")
);
const CreatePurchase = lazy(
  () => import("../../pages/purchases/CreatePurchase")
);

const GenerateChallaan = lazy(
  () => import("../../pages/sales/GenerateChallaan")
);
const ShortItems = lazy(() => import("../../pages/sales/ShortItems"));

const MR = lazy(() => import("../../pages/MR"));
const Customers = lazy(() => import("../../pages/Customers"));
const Supplier = lazy(() => import("../../pages/Supplier"));

const AllUsers = lazy(() => import("../../pages/users/AllUsers"));
const UserAccounts = lazy(() => import("../../pages/users/UserAccounts"));
const UserMarket = lazy(() => import("../../pages/users/UserMarket"));
const UserPurchase = lazy(() => import("../../pages/users/UserPurchase"));
const UserSales = lazy(() => import("../../pages/users/UserSales"));

const HeadConfiguration = lazy(
  () => import("../../pages/configuration/HeadConfiguration")
);
const CompanyConfiguration = lazy(
  () => import("../../pages/configuration/CompanyConfiguration")
);
const BankAccountConfiguration = lazy(
  () => import("../../pages/configuration/BankAccountConfiguration")
);
const TransportConfiguration = lazy(
  () => import("../../pages/configuration/TransportConfiguration")
);

const AdminLayout = () => {
  const { show } = useSelector((state: RootState) => state.sidebar);

  return (
    <div className="layout relative">
      <div className="layout__content flex">
        <Sidebar />

        <main
          className={`main flex-1 overflow-x-auto flex flex-col ${
            show ? "ml-60" : "ml-20"
          } transition-all`}
          style={{
            minHeight: "100vh",
          }}
        >
          <Header />

          <div className="page-wrapper flex-1 mt-4 px-12 pl-8 pb-24 box-border">
            <Suspense fallback={<Loader />}>
              <ErrorBoundary>
                <Routes>
                  <Route path="/" element={<Dashboard />} />

                  <Route path="/users" element={<UsersLayout />}>
                    <Route index element={<AllUsers />} />
                    <Route path="accounts" element={<UserAccounts />} />
                    <Route path="sales" element={<UserSales />} />
                    <Route path="purchases" element={<UserPurchase />} />
                    <Route path="market" element={<UserMarket />} />
                  </Route>

                  <Route path="/inventory" element={<Inventory />} />

                  {/* ----------- Accounts ----------- */}
                  <Route
                    path="/accounts/debtors/:id/edit"
                    element={<AccountDetail />}
                  />
                  <Route
                    path="/accounts/creditors/:id/edit"
                    element={<CreditorDetails />}
                  />
                  <Route path="/accounts" element={<AdminAccountsLayout />}>
                    <Route
                      index
                      element={<Navigate to="orders" replace={true} />}
                    />
                    <Route path="debtors" element={<Debtors />} />
                    <Route path="creditors" element={<Creditors />} />

                    <Route
                      path="orders"
                      element={<AdminAccountsOrdersLayout />}
                    >
                      <Route
                        index
                        element={
                          <Navigate to="pending-orders" replace={true} />
                        }
                      />
                      <Route
                        path="pending-orders"
                        element={<PendingOrders />}
                      />
                      <Route
                        path="approved-orders"
                        element={<ApprovedOrders />}
                      />
                      <Route
                        path="declined-orders"
                        element={<DeclinedOrders />}
                      />
                    </Route>
                  </Route>

                  {/* sales */}
                  <Route path="/sales" element={<AdminSalesLayout />}>
                    <Route
                      index
                      element={<Navigate to="initiated" replace={true} />}
                    />
                    <Route
                      path="initiated"
                      element={<AllInitiatedSalesOrders />}
                    />
                    <Route path="created" element={<Sales />} />
                    <Route path="challaans" element={<Challaans />} />
                    <Route path="invoices" element={<Invoices />} />
                  </Route>
                  <Route path="/sales">
                    <Route path="create-order" element={<CreateOrder />} />
                    <Route
                      path="initiate-order"
                      element={<InitiateSalesOrder />}
                    />
                    <Route path=":id">
                      <Route
                        path="initiated/edit"
                        element={<EditSalesOrder isInitiated />}
                      />
                      <Route path="sales-order" element={<ViewSalesOrder />} />
                      <Route path="order/edit" element={<EditSalesOrder />} />

                      <Route path="add-invoice" element={<AddInvoice />} />
                      <Route path="challaan" element={<GenerateChallaan />} />
                      <Route
                        path="challaan/view"
                        element={<ViewSalesOrder />}
                      />
                    </Route>
                    <Route path="short-items" element={<ShortItems />} />
                  </Route>

                  {/* purchase */}
                  <Route path="/purchases" element={<PurchaseLayout />}>
                    <Route
                      index
                      element={<Navigate to="created" replace={true} />}
                    />
                    <Route path="created" element={<AllPurchases />} />
                    <Route path="completed" element={<CompletedPurchases />} />
                  </Route>
                  <Route
                    path="/purchases/create"
                    element={<CreatePurchase />}
                  />
                  <Route
                    path="/purchases/:id/confirm"
                    element={<ConfirmPurchase />}
                  />
                  <Route
                    path="/purchases/:id/edit"
                    element={<EditPurchaseOrder />}
                  />
                  <Route
                    path="/purchases/:id/view"
                    element={<ViewPurchase />}
                  />
                  <Route
                    path="/purchases/create-confirm"
                    element={<CreatePurchase createConfirmPurchase={true} />}
                  />

                  <Route path="/mr" element={<MR />} />
                  <Route path="/customers" element={<Customers />} />
                  <Route path="/supplier" element={<Supplier />} />

                  <Route
                    path="/configuration"
                    element={<ConfigurationLayout />}
                  >
                    <Route index element={<HeadConfiguration />} />
                    <Route path="company" element={<CompanyConfiguration />} />
                    <Route
                      path="bank-account"
                      element={<BankAccountConfiguration />}
                    />
                    <Route
                      path="transport"
                      element={<TransportConfiguration />}
                    />
                    <Route path="item" element={<ItemConfiguration />} />
                    <Route path="room-no" element={<RoomNoConfigration />} />
                    <Route path="state-city" element={<StateCityConfiure />} />
                  </Route>

                  {/* ---------  Discount -------- */}

                  <Route path="/discount" element={<DiscountsLayout />}>
                    <Route
                      index
                      element={<Navigate to="category" replace={true} />}
                    />
                    <Route path="category" element={<DiscountCategories />} />
                    <Route
                      path="customer"
                      element={<DiscountCustomerSpecific />}
                    />
                  </Route>
                  <Route
                    path="/discount/category/:categoryId/customers"
                    element={<UpdateCustomerToDiscountCategory />}
                  />
                  <Route
                    path="/discount/category/:categoryId/items"
                    element={<UpdateItemsToDiscountCategory />}
                  />
                  <Route
                    path="/discount/customer/:customerId/update"
                    element={<UpdateCustomerSpecificDiscounts />}
                  />

                  {/* ---------  Reports -------- */}
                  <Route path="/reports" element={<ReportsLayout />}>
                    <Route index element={<SoldStockReport />} />

                    <Route path="gst" element={<GstReportsLayout />}>
                      <Route
                        index
                        element={<Navigate to="gstr-3b" replace={true} />}
                      />
                      <Route path="gstr-3b" element={<GstReport />} />
                      <Route path="gstr-3" element={<GstReport />} />
                      <Route path="gstr-2" element={<GstReport />} />
                      <Route path="gstr-1" element={<GstReport />} />
                      <Route path="hsn-report" element={<GstReport />} />
                      <Route path="purchase-report" element={<GstReport />} />
                      <Route path="sale-report" element={<GstReport />} />
                    </Route>

                    <Route
                      path="out-of-stock"
                      element={<OutOfStockItemsReport />}
                    />
                    <Route
                      path="customer-orders"
                      element={<CustomerOrdersReport />}
                    />
                    <Route path="collection" element={<CollectionReports />} />
                    <Route
                      path="available-stocks"
                      element={<AvailableStocksReport />}
                    />
                  </Route>
                  {/* /404 */}
                  <Route path="/404" element={<Error404 />} />
                  <Route
                    path="*"
                    element={<Navigate to="/404" replace={true} />}
                  />
                </Routes>
              </ErrorBoundary>
            </Suspense>
          </div>
        </main>
      </div>
    </div>
  );
};

export default AdminLayout;
