import { Form, Formik } from "formik";
import { useCallback } from "react";
import { HiCash, HiHashtag, HiUserGroup } from "react-icons/hi";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import Input from "../../components/FormikComponents/Input";
import SubmitBtn from "../../components/FormikComponents/SubmitBtn";
import SupplierInput from "../../components/FormikComponents/SupplierInput";
import AddItemsInPurchaseOrder from "../../components/NestedFormComponents/Purchase/AddItemsInPurchaseOrder";
import HandleSave from "../../components/Save/HandleSave";
import SaveButton from "../../components/Save/SaveButton";
import useGetItemsFromPurchaseOrder from "../../hooks/purchase/useGetItemsFromPurchaseOrder";
import useUpdatePurchaseOrder from "../../hooks/purchase/useUpdatePurchaseOrder";
import useGetOrderById from "../../hooks/useGetOrderById";
import { addToast } from "../../redux/features/toastSlice";
import { SUCCESS } from "../../types/constants";
import { ConfirmPurchaseObj } from "../../types/purchase.types";
import { convertDateToStringForForm } from "../../utils/helpers";

const EditPurchaseOrder = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const params = useParams();

  const {
    data: dataOrder,
    isLoading,
    isError,
  } = useGetOrderById(params.id!, "purchase");
  const { data: dataItems } = useGetItemsFromPurchaseOrder(params.id!, true);
  const { mutate, isLoading: isSubmitting } = useUpdatePurchaseOrder();
  const navigate = useNavigate();

  const initialValues: ConfirmPurchaseObj & { order_number: string } = {
    supplier: dataOrder?.supplier,
    date: convertDateToStringForForm(dataOrder?.date!),
    invoice_number: dataOrder?.invoice_number || "",
    remark: dataOrder?.remark || "",
    heads: dataItems || [],
    amount: dataOrder?.amount || 0,
    taxable_amount: String(dataOrder?.taxable_amount || 0),
    gst_amount: String(dataOrder?.gst_amount || 0),
    gst_type: dataOrder?.gst_type || "",
    other_charges: String(dataOrder?.other_charges || 0),
    netTotal: "",
    cd_discount_rate: dataOrder?.cd_discount_rate || 0,
    cdAmount: "",
    order_number: String(dataOrder?.order_number || ""),
    id: dataOrder?.id || "",
  };

  const handleSubmit = useCallback(
    (value: ConfirmPurchaseObj, shouldNavigate?: boolean) => {
      const postData = {
        id: params.id!,
        taxable_amount: value.taxable_amount,
        gst_amount: value.gst_amount,
        amount: value.amount,
        cd_discount_rate: value.cd_discount_rate,
        invoice_number: value.invoice_number,
        other_charges: value.other_charges,
        supplierId: value.supplier?.id,
        date: new Date(value.date).toISOString(),
        remark: value.remark,
      };

      mutate(postData, {
        onSuccess: () => {
          dispatch(
            addToast({
              kind: SUCCESS,
              msg: "Updated Successfully",
            })
          );
          if (shouldNavigate) navigate("/purchases/created", { replace: true });
        },
      });
    },
    []
  );

  return (
    <div className="new-purchase">
      <header className="flex items-center justify-between bg-white p-3 rounded shadow mb-8">
        <h3 className="text-lg font-semibold flex">
          <HiHashtag size={21} className="mr-2" />
          Edit Purchase
        </h3>
      </header>

      {isLoading ? (
        <div>Loading....</div>
      ) : isError ? (
        <div>Something went wrong</div>
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => handleSubmit(values, true)}
          enableReinitialize
        >
          {({ values }) => (
            <Form>
              <div className="supplier-form flex items-center px-4 py-4 rounded border border-gray-300 gap-x-4 relative mb-8">
                <SupplierInput
                  id="supplier"
                  name="supplier"
                  label="Supplier"
                  classes="w-full"
                />

                <Input
                  label="Order No"
                  name="order_number"
                  id="order_number"
                  type="text"
                  readonly={true}
                />
                <Input label="Order Date" name="date" id="date" type="date" />
                <Input
                  id="invoice_number"
                  name="invoice_number"
                  label="Invoice Number"
                  type="text"
                />
                <Input id="remark" name="remark" label="Remark" type="text" />
                {/* <Input
                id="pNo"
                name="pNo"
                label="P.No."
                type="text"
                readonly={true}
              /> */}

                <div className="label absolute right-5 -bottom-4 bg-gray-300 rounded text-sm flex items-center font-semibold p-1">
                  <HiUserGroup size={16} className="mr-1" />
                  Supplier
                </div>
              </div>

              <AddItemsInPurchaseOrder fieldName="heads" />

              <div className="payment-form flex flex-col justify-center px-4 py-4 rounded border border-gray-300 gap-x-4 relative">
                <div className="row-1 mb-2 flex items-center gap-x-4">
                  <Input
                    id="taxable_amount"
                    name="taxable_amount"
                    label="Taxable Amount"
                    type="text"
                    readonly={true}
                  />
                  <Input
                    id="gst_amount"
                    name="gst_amount"
                    label="GST"
                    type="number"
                    readonly
                  />
                  <Input
                    id="other_charges"
                    name="other_charges"
                    label="FOR/Other Charges"
                    type="number"
                  />
                  <Input
                    id="netTotal"
                    name="netTotal"
                    label="Net Total"
                    type="number"
                    readonly
                  />
                  <Input
                    id="cd_discount_rate"
                    name="cd_discount_rate"
                    label="CD(%)"
                    type="number"
                    step="0.01"
                  />
                  <Input
                    id="cdAmount"
                    name="cdAmount"
                    label="CD Amount"
                    type="number"
                    readonly
                  />
                  <Input
                    id="amount"
                    name="amount"
                    label="Grand Total"
                    type="text"
                    readonly
                  />
                </div>

                <div className="label absolute right-5 -bottom-4 bg-gray-300 rounded text-sm flex items-center font-semibold p-1">
                  <HiCash size={16} className="mr-1" />
                  Payment
                </div>
              </div>

              <div className="buttons flex w-full mt-12 items-center justify-end gap-x-4">
                <HandleSave handleSave={() => handleSubmit(values)} />
                <SaveButton disabled={isSubmitting} />
                <SubmitBtn
                  text={"Update"}
                  containerClasses="w-44"
                  isSubmitting={isSubmitting}
                  isSubmittingClasses={"w-44"}
                />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};
export default EditPurchaseOrder;

const rowValidationOrderedQtySchema = Yup.object({
  item_name: Yup.object().required("Item Name is required"),
  batch_number: Yup.string()
    .typeError("Batch Number is not valid")
    .required("Batch Number is required"),
  ordered_qty: Yup.number().required("Quantity is required").min(1).integer(),
  taxable_amount: Yup.number().required("Rate is required"),
  mrp: Yup.number()
    .typeError("MRP is not valid")
    .required("MRP is required")
    .min(1, "MRP should be greater than 0"),
  mfg_date: Yup.date(),
  exp_date: Yup.date(),
  d1: Yup.number().nullable().min(0),
  d2: Yup.number().nullable().min(0),
  d3: Yup.number().nullable().min(0),
  d4: Yup.number().nullable().min(0),
  d5: Yup.number().nullable().min(0),
  gst: Yup.number().required("GST is required"),
});

const validationSchema = Yup.object({
  supplier: Yup.object({
    id: Yup.string().required("Supplier is required"),
  }),
  date: Yup.date()
    .max(new Date(), "Order Date cannot be a future date")
    .required("Order Date is required"),
  invoice_number: Yup.string().required("Invoice No. is required"),
  remark: Yup.string(),
  // pNo: Yup.string().required("P No. is required"),
  heads: Yup.array()
    .of(rowValidationOrderedQtySchema)
    .min(1, "Please provide atleast one Item")
    .required("Please provide atleast one Item"),
  taxable_amount: Yup.number().required("Taxable Amount is required").min(1),
  gst_amount: Yup.string().required("GST is required"),
  other_charghes: Yup.number().min(0),
  netTotal: Yup.number().required("Net Total is required"),
  cd_discount_rate: Yup.number().required("CD is required").min(0),
  cdAmount: Yup.string().required("CD Amount is required"),
  amount: Yup.string().required("Grand Total is required"),
});
