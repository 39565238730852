import React from "react";
import { Outlet } from "react-router-dom";
import AccountsOrdersNav from "../AccountsOrdersNav/AccountsOrdersNav";

function GstReportsLayout() {
  return (
    <div className="admin-accounts-orders-layout">
      <nav className="mb-10">
        <ul className="flex items-center gap-x-6">
          <AccountsOrdersNav
            path="/reports/gst/gstr-3b"
            end={true}
            text="GSTR-3B"
            bgColor="bg-orange-400"
            textColor="text-orange-400"
          />
          <AccountsOrdersNav
            path="/reports/gst/gstr-1"
            end={true}
            text="GSTR-1"
            bgColor="bg-green-500"
            textColor="text-green-500"
          />
          <AccountsOrdersNav
            path="/reports/gst/gstr-2"
            end={true}
            text="GSTR-2"
            bgColor="bg-cyan-500"
            textColor="text-cyan-500"
          />
          <AccountsOrdersNav
            path="/reports/gst/gstr-3"
            end={true}
            text="GSTR-3"
            bgColor="bg-violet-500"
            textColor="text-violet-500"
          />
          <AccountsOrdersNav
            path="/reports/gst/sale-report"
            end={true}
            text="SALE REPORT"
            bgColor="bg-fuchsia-500"
            textColor="text-fuchsia-500"
          />
          <AccountsOrdersNav
            path="/reports/gst/purchase-report"
            end={true}
            text="PURCHASE REPORT"
            bgColor="bg-pink-500"
            textColor="text-pink-500"
          />
          <AccountsOrdersNav
            path="/reports/gst/hsn-report"
            end={true}
            text="HSN REPORT"
            bgColor="bg-red-500"
            textColor="text-red-500"
          />
        </ul>
      </nav>

      <Outlet />
    </div>
  );
}

export default GstReportsLayout;
