import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import PrintLedgerDebtor from "./accounts/PrintLedgerDebtor";
import PrintPurchaseOrder from "./purchase/PrintPurchaseOrder";
import PrintAvailableStocksReport from "./reports/PrintAvailableStocksReport";
import PrintCollectionReport from "./reports/PrintCollectionReport";
import PrintChallan from "./sales/PrintChallan";
type Props = {
  data: any;
  page: PRINT_PAGE;
};

export enum PRINT_PAGE {
  purchaseOrder = "Purchase Order",
  challan = "Challan",
  accountLedger = "AccountLedger",
  availableStocksReport = "Available Stocks Report",
  collectionReport = "Collection Report",
}

function HandlePrint({ data, page }: Props) {
  const reportRef = useRef(null as any);

  const handlePrintReport = useReactToPrint({
    content: () => reportRef.current,
    pageStyle: "body { margin: 20 }",
  });
  let printComponent = null;
  switch (page) {
    case PRINT_PAGE.purchaseOrder:
      printComponent = <PrintPurchaseOrder ref={reportRef} data={data} />;
      break;
    case PRINT_PAGE.challan:
      printComponent = <PrintChallan ref={reportRef} data={data} />;
      break;
    case PRINT_PAGE.accountLedger:
      if (data)
        printComponent = <PrintLedgerDebtor ref={reportRef} data={data} />;
      break;
    case PRINT_PAGE.availableStocksReport:
      if (data)
        printComponent = (
          <PrintAvailableStocksReport ref={reportRef} data={data} />
        );
      break;
    case PRINT_PAGE.collectionReport:
      if (data)
        printComponent = <PrintCollectionReport ref={reportRef} data={data} />;
      break;

    default:
      break;
  }

  return (
    <>
      <button
        type="button"
        className="btn-primary text-sm"
        onClick={handlePrintReport}
      >
        Print
      </button>
      <div className="hidden">{printComponent}</div>
    </>
  );
}

export default HandlePrint;
