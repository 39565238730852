import { Option } from "../types";
import { Customer } from "./customer.types";

export interface GET_BALANCE {
  sum: number;
  aboveLimitBalance: number;
  customerId: string;
  name: string;
  sr_number: number;
  address: string;
  city: string;
  area: string;
  state: string;
  pincode: string;
  contactOne: string;
  altContact: string;
  credit_limit: number;
  credit_days: number;
  gstin: string;
  profession?: any;
  ledger_number: string;
  email: string;
  dob: string;
  anniversary: string;
  discount: number;
  uid: string;
  id: string;
  createdAt: Date;
  updatedAt: Date;
  transportId: string;
  mr1Id: string;
  mr2Id: string;
}

export enum ORDER_STATUS {
  initiated = "initiated",
  created = "created",
  approved = "approved",
  declined = "declined",
  invoiced = "invoiced",
}

export enum ORDER_TYPE_SALES {
  Invoiced = "Invoiced",
  Estimated = "Estimated",
}

export interface SalesOrderObj {
  customer?: Customer;

  heads: any[];

  orderBy: Option;
  bal: string;
  cartons: string;
  gstType: string;
  remarks: string;
  discount: number;
  order_type_sales: ORDER_TYPE_SALES;
  date: string;
  number_of_pages: number;
  sp_discount: number;
  taxable_amount: string;
  sales_gst_amount: string;
  total: number;
}

export interface ChallanOrder extends SalesOrderObj {
  cash: string;
  card: string;
  cheque: string;
}
