import { useParams } from "react-router-dom";
import AddCustomersInCategoryForm from "../../components/discounts/AddCustomersInCategoryForm";
import CustomerDiscountItemsList from "../../components/discounts/CustomerDiscountItemsList";
import useGetCustomers from "../../hooks/customer/useGetCustomers";
import AddDiscountToCustomerForm from "./AddDiscountToCustomerForm";

function UpdateCustomerSpecificDiscounts() {
  const params = useParams();

  const { dataCustomers } = useGetCustomers({
    getDiscounts: true,
    id: params.customerId,
    populateItems: true,
  });

  return dataCustomers ? (
    <div>
      <AddCustomersInCategoryForm customerData={dataCustomers[0]} readonly />
      <AddDiscountToCustomerForm isCustomerWise />

      <CustomerDiscountItemsList data={dataCustomers[0]?.discounts!} />
    </div>
  ) : (
    <div></div>
  );
}

export default UpdateCustomerSpecificDiscounts;
