import { useField } from "formik";
import { useEffect } from "react";

type props = {
  value: string | number;
  name: string | "";
  label?: string;
  classes?: string;
  inputClass?: string;
};

function ReadOnlyInputBox({ value, name, label, classes, inputClass }: props) {
  const [field, meta, helpers] = useField({ name });
  useEffect(() => {
    if (name) {
      helpers.setValue(value);
    }
  }, [value]);

  return (
    <div className={`flex flex-col w-full h-16 ${classes} `}>
      <div className={`form-group flex   flex-col justify-center `}>
        <label className="font-semibold text-sm ">{label || ""}</label>
        <input
          readOnly={true}
          disabled={true}
          value={value}
          className={` placeholder-transparent    disabled:opacity-80 disabled:cursor-not-allowed  text-center ${inputClass}`}
        />
      </div>
    </div>
  );
}

export default ReadOnlyInputBox;
