import { useState } from "react";
import { useQuery } from "react-query";
import axiosInstance from "../axiosInstance";
import { HeadObj } from "../types";
import queryKeys from "../types/queryKeys";
interface Heads {
  head: string;
  hsn_number: string;
  potencies: string[];
  sizes: string[];
  gst: string;
}

const getHeads = async () => {
  const response = await axiosInstance.get("/heads");

  const { data } = response.data;

  let finalData: Heads[] = [];

  data.forEach((h: any) => {
    if (finalData.find((heads) => heads.head === h.head)) {
      const index = finalData.findIndex((heads) => heads.head === h.head);

      finalData[index] = {
        ...finalData[index],
        potencies: !finalData[index].potencies.includes(h.potency)
          ? [...finalData[index].potencies, h.potency]
          : finalData[index].potencies,
        sizes: !finalData[index].sizes.includes(h.size)
          ? [...finalData[index].sizes, h.size]
          : finalData[index].sizes,
      };
    } else {
      finalData.push({
        head: h.head,
        hsn_number: h.hsn_number,
        potencies: [h.potency],
        sizes: [h.size],
        gst: h.gst,
      });
    }
  });

  return { finalData, allHeads: data };
};

function useGetHeads() {
  const [queryKeyHeads, setqueryKeyHeads] = useState(queryKeys.getHeads);
  const {
    data: dataHeads,
    isLoading: isLoadingHeads,
    isFetching: isFetchingHeads,
    isError: errorHeads,
    isSuccess: isSuccessHeads,
    refetch,
  } = useQuery<
    { finalData: Heads[]; allHeads: HeadObj[] } | undefined,
    any,
    { finalData: Heads[]; allHeads: HeadObj[] },
    string[]
  >([queryKeyHeads], () => getHeads());

  return {
    dataHeads: dataHeads?.finalData,
    isLoadingHeads,
    isFetchingHeads,
    errorHeads,
    isSuccessHeads,
    allHeads: dataHeads?.allHeads,
    queryKeyHeads,
    refetch,
  };
}

export default useGetHeads;
