import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";

import { hideModal } from "../../redux/features/modalSlice";
import { FROM } from "../../types/constants";

interface Props {
  id: string;
  value: string;
  from: string;
}

const ShowOrderNoModal = ({ value, from }: Props) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = useCallback(() => {
    setIsSubmitting(true);
    switch (from) {
      case FROM.CREATE_PURCHASE:
        dispatch(hideModal());
        break;

      default:
        dispatch(hideModal());
        setIsSubmitting(false);

        break;
    }
  }, []);

  return (
    <div className="remark-modal">
      <div
        className="px-6 py-4 mt-2"
        style={{
          minWidth: "450px",
          maxWidth: "760px",
        }}
      >
        <div className="flex  gap-x-6 mb-3 font-semibold text-center">
          {value}
        </div>

        <div className="buttons flex items-center justify-around mt-6">
          <button
            disabled={isSubmitting}
            className="btn-danger  rounded  w-44 mx-3 focus:bg-red-500 hover:bg-red-500"
            onClick={() => {
              console.log("No");
              dispatch(hideModal());
            }}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShowOrderNoModal;
