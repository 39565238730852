import { Form, Formik } from "formik";
import { HiCash, HiHashtag, HiUserGroup } from "react-icons/hi";
import { useLocation, useParams } from "react-router-dom";
import ReadOnlyInputBox from "../../components/FormikComponents/ReadOnlyInputBox";
import useGetItemsFromPurchaseOrder from "../../hooks/purchase/useGetItemsFromPurchaseOrder";
import { localestringToDate } from "../../utils/helpers";

const Purchase = () => {
  const loaction = useLocation();
  const { purchaseDetails }: any = loaction.state || {};
  const netTotal = (
    purchaseDetails.taxable_amount +
    purchaseDetails.gst_amount +
    purchaseDetails.other_charghes
  ).toFixed(2);
  const params = useParams();

  console.log("purchaseDetails", purchaseDetails);
  const { data: dataOrder } = useGetItemsFromPurchaseOrder(params.id!, true);

  const initialValues = {
    supplier: "",
    purchase_date: "",
    bill_number: "",
    remark: "",
    pNo: "1",
    heads: [],
    amount: "",
    taxable_amount: "",
    gst_amount: "",
    gst_type: "",
    other_charghes: 0,
    netTotal: 0,
    cd_discount_rate: 0,
    cdAmount: "",
  };
  return (
    <div className="new-purchase">
      <header className="flex items-center justify-between bg-white p-3 rounded shadow mb-8">
        <h3 className="text-lg font-semibold flex">
          <HiHashtag size={21} className="mr-2" />
          View Purchase
        </h3>
      </header>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => console.log(values)}
      >
        {(values) => (
          <Form>
            <div className="supplier-form flex flex-col justify-center px-4 py-4 rounded border border-gray-300 gap-x-4 relative mb-10">
              <div className="row-1 mb-2 flex items-center gap-x-4">
                <ReadOnlyInputBox
                  name="supplier.name"
                  value={purchaseDetails.supplier.name}
                  label="Supplier"
                />
                <ReadOnlyInputBox
                  name="contact_person"
                  value={purchaseDetails.supplier.contact_person}
                  label="Contact Person"
                />
                <ReadOnlyInputBox
                  name="supplier.contact_number"
                  value={purchaseDetails.supplier.contact_number}
                  label="Contact No"
                />
              </div>
              <div className="row-2 mb-2 flex items-center gap-x-4">
                <ReadOnlyInputBox
                  name="supplier.address"
                  value={purchaseDetails.supplier.address}
                  label="Address"
                />
                <ReadOnlyInputBox
                  name="supplier.gst_type"
                  value={purchaseDetails.supplier.gstType}
                  label="GST Type"
                />
                <ReadOnlyInputBox
                  name="supplier.gstin"
                  value={purchaseDetails.supplier.gstin}
                  label="GST No"
                />
                <ReadOnlyInputBox
                  name="supplier.sr_number"
                  value={purchaseDetails.supplier.sr_number}
                  label="Sr No"
                />
              </div>

              <div className="label absolute right-5 -bottom-4 bg-gray-300 rounded text-sm flex items-center font-semibold p-1">
                <HiUserGroup size={16} className="mr-1" />
                Supplier
              </div>
            </div>

            {/* <AddHeadForm
          fieldName="heads"
          taxable_amount="taxable_amount"
          items={items}
        /> */}

            <table className="head-info w-full mb-8 border rounded border-collapse text-sm text-center">
              <thead className="border">
                <tr className="border p-1">
                  <th className="p-1">S No</th>
                  <th className="p-1">Item Name</th>
                  <th className="p-1">Head</th>
                  <th className="p-1">Potency</th>
                  <th className="p-1">Size</th>
                  <th className="p-1">Company</th>

                  <th className="p-1">Batch No.</th>
                  <th className="p-1">Quantity</th>
                  <th className="p-1">MRP</th>
                  <th className="p-1">MFG-Date</th>
                  <th className="p-1">Expiry</th>
                  <th className="p-1">Dis 1(%)</th>
                  <th className="p-1">Dis 2(%)</th>
                  <th className="p-1">Dis 3(%)</th>
                  <th className="p-1">Dis 4(%)</th>
                  <th className="p-1">Dis 5(%)</th>
                  <th className="p-1">GST %</th>
                  <th className="p-1">GST ₹</th>
                  {/* <th className="p-1">Rate</th> */}
                  <th className="p-1">Total</th>
                </tr>
              </thead>
              <tbody
                className="border overflow-y-auto"
                style={{
                  height: "100px",
                  maxHeight: "100px",
                }}
              >
                {dataOrder?.map((v, index) => (
                  <tr className="border p-1" key={index}>
                    <td className="p-1">{index + 1}</td>

                    <td className="p-1">{v.item_name.label}</td>
                    <td className="p-1">{v.head}</td>
                    <td className="p-1">{v.potency}</td>
                    <td className="p-1">{v.size}</td>
                    <td className="p-1">{v.company.label}</td>

                    <td className="p-1">{v.batch_number}</td>
                    <td className="p-1">{v.qty} </td>
                    <td className="p-1">{v.mrp}</td>
                    <td className="p-1">{v.mfg_date} </td>
                    <td className="p-1">{v.exp_date} </td>
                    <td className="p-1">{v.d1}</td>
                    <td className="p-1">{v.d2} </td>
                    <td className="p-1">{v.d3} </td>
                    <td className="p-1">{v.d4} </td>
                    <td className="p-1">{v.d5} </td>
                    <td className="p-1">{v.gst} </td>
                    <td className="p-1"> {v.gst_amount}</td>
                    {/* <td className="p-1">{v.rate}</td> */}
                    <td className="p-1">{v.total}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="payment-form flex flex-col justify-center px-4 py-4 rounded border border-gray-300 gap-x-4 relative">
              <div className="row-1 mb-2 flex items-center gap-x-4">
                <ReadOnlyInputBox
                  name="date"
                  value={localestringToDate(purchaseDetails.date)}
                  label={"Purchase Date"}
                />

                <ReadOnlyInputBox
                  name="bill_number"
                  label={"Bill No"}
                  value={purchaseDetails.bill_number}
                />
                <ReadOnlyInputBox
                  name="order_number"
                  label={"Order No"}
                  value={purchaseDetails.order_number}
                />
                <ReadOnlyInputBox
                  name="remark"
                  label={"Remark"}
                  value={purchaseDetails.remark}
                />
              </div>
              <div className="row-1 mb-2 flex items-center gap-x-4">
                <ReadOnlyInputBox
                  name="taxable_amount"
                  label={"Taxable Amount"}
                  value={purchaseDetails.taxable_amount}
                />
                <ReadOnlyInputBox
                  name="gst_amount"
                  label={"GST Amount"}
                  value={purchaseDetails.gst_amount}
                />
                <ReadOnlyInputBox
                  name="other_charges"
                  label={"Other Charghes"}
                  value={purchaseDetails.other_charghes || 0}
                />
                <ReadOnlyInputBox
                  name="netTotal"
                  value={netTotal}
                  label="Net Total"
                />

                <ReadOnlyInputBox
                  name="cd_percentage"
                  value={purchaseDetails.cd_discount_rate}
                  label="CD %"
                />
                <ReadOnlyInputBox
                  name="cdAmount"
                  value={(
                    (netTotal * purchaseDetails.cd_discount_rate) /
                    100
                  ).toFixed(2)}
                  label="CD Amount"
                />
                <ReadOnlyInputBox
                  name="amount"
                  label={"Amount"}
                  value={purchaseDetails.amount}
                />
              </div>
              <div className="label absolute right-5 -bottom-4 bg-gray-300 rounded text-sm flex items-center font-semibold p-1">
                <HiCash size={16} className="mr-1" />
                Payment
              </div>
            </div>

            <div className="buttons flex w-full mt-12 items-center justify-end gap-x-4">
              <button className="btn-primary w-44">Print</button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Purchase;
