import { useField, useFormikContext } from "formik";
import React, { forwardRef, useEffect, useRef } from "react";
import Select from "react-select";
import useGetHeads from "../../hooks/useGetHeads";
import useKeyDown from "../../hooks/useKeyDown";
import { Option } from "../../types";
import ReactSelectCustomStyle from "../../utils/reactSelectCustomStyle";
import ErrorBox from "./ErrorBox";

interface DependencyObj {
  potency: "potency";
  size: "size";
  hsn_number?: "hsn_number";
  gst?: "gst";
  stock?: "stock";
  item?: string;
}

interface Props {
  label: string;
  id: string;
  name: string;
  setSizes: React.Dispatch<React.SetStateAction<string[]>>;
  setPotencies: React.Dispatch<React.SetStateAction<string[]>>;
  dependencies: DependencyObj;
  inline?: boolean;
  classes?: string;
  isMulti?: boolean;
}

const HeadInput = forwardRef(
  (
    {
      label,
      id,
      name,
      setSizes,
      setPotencies,
      dependencies,
      classes,
      inline,
      isMulti,
      ...props
    }: Props,
    ref: React.Ref<any> | undefined
  ) => {
    // props -> every props except label and options -> { name: 'value', id: 'value' }
    const { setFieldValue, setFieldError, setFieldTouched } =
      useFormikContext();

    const { dataHeads } = useGetHeads();
    const [field, meta, helpers] = useField(name);

    const refHead = useRef<any>(null);

    const handlerCtrlA = (e: any) => {
      if (e.ctrlKey) {
        e.preventDefault();

        refHead.current.focus();
      }
    };

    useKeyDown("h", handlerCtrlA);

    useEffect(() => {
      let head: string;
      dependencies?.item && setFieldValue(dependencies.item, "");
      if (Array.isArray(field.value)) {
        console.log(field.value);
        if (field.value.length > 1 || field.value.length === 0) {
          setSizes([]);
          setPotencies([]);
          type keys = keyof DependencyObj;
          Object.keys(dependencies)?.map((key: string, index: number) =>
            // @ts-ignore
            setFieldValue(dependencies[key], "")
          );

          setFieldError(dependencies.potency, "");
          dependencies.hsn_number && setFieldError(dependencies.hsn_number, "");

          setFieldTouched(dependencies.size, false);
          setFieldTouched(dependencies.potency, false);
          dependencies.hsn_number &&
            setFieldTouched(dependencies.hsn_number, false);

          return;
        }

        head = field.value[0].value;
      } else head = field.value;

      const d = dataHeads?.find((c) => c.head === head);
      if (!Array.isArray(field.value)) {
        helpers.setValue("");
      }

      dependencies.hsn_number && setFieldValue(dependencies.hsn_number, "");

      setSizes([]);
      setPotencies([]);
      setFieldError(dependencies.size, "");

      setFieldError(dependencies.potency, "");
      dependencies.hsn_number && setFieldError(dependencies.hsn_number, "");

      setFieldTouched(dependencies.size, false);
      setFieldTouched(dependencies.potency, false);
      dependencies.hsn_number &&
        setFieldTouched(dependencies.hsn_number, false);

      if (d) {
        if (!Array.isArray(field.value)) helpers.setValue(d.head);
        dependencies.hsn_number &&
          setFieldValue(dependencies.hsn_number, d.hsn_number);
        dependencies.gst && setFieldValue(dependencies.gst, d.gst);
        setSizes(d.sizes);
        setPotencies(d.potencies);
      }
    }, [field.value]);

    return (
      <div className={`flex flex-col mb-6 cursor-pointer w-full ${classes}`}>
        <div
          className={`form-group flex ${
            inline ? " flex-row items-center" : "flex-col justify-center"
          }`}
        >
          <label
            className={`font-semibold ${inline ? "mr-4 w-28" : "mb-2"}`}
            htmlFor={id}
          >
            {label}
          </label>
          <div className="flex">
            <Select
              isClearable
              ref={refHead}
              isMulti={isMulti}
              placeholder={"Select Head"}
              value={
                field.value
                  ? Array.isArray(field.value)
                    ? field.value
                    : { label: field.value, value: field.value }
                  : ""
              }
              onChange={(op) => {
                setFieldValue(dependencies.size, "");
                setFieldValue(dependencies.potency, "");
                if (Array.isArray(op)) {
                  helpers.setValue(op);
                } else helpers.setValue((op as Option)?.value);
              }}
              openMenuOnFocus
              styles={ReactSelectCustomStyle}
              options={dataHeads?.map((h) => ({
                label: h.head,
                value: h.head,
              }))}
              isSearchable={true}
              className={`flex-1 ${
                meta.touched && meta.error
                  ? "border border-red-400"
                  : "border-coolGray-200 "
              } mr-2`}
            />
          </div>
        </div>
        {/* @ts-ignore */}
        {meta.touched && meta.error && <ErrorBox msg={meta.error.label} />}
      </div>
    );
  }
);

export default HeadInput;
