import { ORDER_TYPE_SALES } from "../types/sales.types";

export const calTotalTaxAbleAndGSTAmount = (
  items: any,
  setFieldValue: Function,
  order_type_sales: string
) => {
  let taxable_amount: number = 0;
  let totalGst = 0;

  for (let value of items) {
    taxable_amount += Number(value.taxable_amount);
    totalGst += Number(value.gst_amount);
  }

  setFieldValue("taxable_amount", Number(taxable_amount.toFixed(2)));
  const gstAmount =
    order_type_sales === ORDER_TYPE_SALES.Invoiced
      ? Number(totalGst.toFixed(2))
      : 0;

  setFieldValue("sales_gst_amount", gstAmount);
};
