import { useQuery } from "react-query";
import axiosInstance from "../axiosInstance";
import { Customer } from "../types/customer.types";
import queryKeys from "../types/queryKeys";
import { ORDER_TYPE_SALES } from "../types/sales.types";
interface Medrep {
  name: string;
  address: string;
  city: string;
  state: string;
  referenceBy: string;
  contactOne: string;
  altContact: string;
  panNo: string;
  email: string;
  bankAc: string;
  empId: string;
  dob: string;
  uid: string;
  profile_pic_url: string;
  id: string;
  createdAt: Date;
  updatedAt: Date;
}

interface Order {
  amount: number;
  taxable_amount?: any;
  gst_amount?: any;
  other_charges?: any;
  order_number: number;
  type: string;
  order_type_sales: ORDER_TYPE_SALES;
  status: string;
  invoice_number: string;
  invoice_date: string;
  date: string;
  remark: string;
  cd_discount_rate: any;
  sp_discount: number;
  cartons: string;
  gst_type: string;
  sales_gst_amount?: any;
  id: string;
  createdAt: Date;
  updatedAt: Date;
  medrepId: string;
  customerId: string;
  supplierId?: any;
  supplier?: any;
  medrep: Medrep;
  customer: Customer;
  number_of_pages?: number;
}

function useGetOrderById(id: string, type: "purchase" | "sales") {
  const getOrder = async ({ queryKey }: any): Promise<Order> => {
    const [_, id, type] = queryKey;

    const { data } = await axiosInstance.get(`/${type}/${id}`);

    return data.order;
  };

  return useQuery([queryKeys.getOrder, id, type], getOrder);
}

export default useGetOrderById;
