import { useQuery } from "react-query";
import axiosInstance from "../../axiosInstance";
import { PurchaseObj } from "../../types/purchase.types";
type Params = {
  status: "completed" | "created";
  fromDate: string;
  toDate: string;
};
function useGetPurchaseOrders(params: Params) {
  const getAllPurchases = async ({ queryKey }: any) => {
    const [_key, { toDate, fromDate, status }] = queryKey;
    const { data } = await axiosInstance.get(`/purchase`, {
      params: {
        status,
        end_date: toDate,
        start_date: fromDate,
      },
    });
    return data.data;
  };

  return useQuery<PurchaseObj[] | undefined, any, PurchaseObj[], any[]>(
    ["getAllPurchases", params],
    getAllPurchases
  );
}

export default useGetPurchaseOrders;
