import { QueryFunctionContext, QueryKey, useQuery } from "react-query";
import axiosInstance from "../../../axiosInstance";
import { RoomNo } from "../../../types/config.types";
import queryKeys from "../../../types/queryKeys";

const getRoomNo = async ({ queryKey }: QueryFunctionContext) => {
  const [_key, params] = queryKey;
  const response = await axiosInstance.get("/room", {
    params,
  });
  const { data } = response.data;
  return data;
};
type Params = {
  headId?: string;
};

function useGetRoomNo(params: Params = {}) {
  return useQuery<RoomNo[], any, RoomNo[], QueryKey>(
    [queryKeys.getRoomNo, params],
    getRoomNo
  );
}

export default useGetRoomNo;
