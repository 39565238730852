import { useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import Table from "./Table";

const TableInstance = ({
  tableData,
  columnName,
  cPageSize,
  cSetPageSize,
  pageIndex,
  setPageIndex,
  pageCount,
  cSortBy,
  cSetSortBy,
  desc,
  setDesc,
  q,
  setSelectedRows,
  setQ,
  selectRow = false,
}) => {
  const columns = useMemo(() => columnName, []);
  const data = useMemo(() => tableData, [tableData]);

  let component = <></>;

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: cPageSize,
        pageIndex,
        sortBy: cSortBy
          ? [
              {
                id: cSortBy,
                desc,
              },
            ]
          : [],
      },
      // disableMultiSort: true,
      // manualPagination: true,
      // manualGlobalFilter: true,
      // pageCount,
    },
    useGlobalFilter,
    useSortBy,
    usePagination

    // selectRow ? useRowSelect : "",
    // selectRow
    //   ? (hooks) => {
    //       hooks.visibleColumns.push((columns) => {
    //         return [
    //           {
    //             id: "selection",
    //             minWidth: 35,
    //             width: 35,
    //             maxWidth: 35,
    //             Header: ({ getToggleAllRowsSelectedProps }) => (
    //               <div>
    //                 <Checkbox {...getToggleAllRowsSelectedProps()} />
    //               </div>
    //             ),
    //             Cell: ({ row }) => (
    //               <div>
    //                 <Checkbox {...row.getToggleRowSelectedProps()} />
    //               </div>
    //             ),
    //           },
    //           ...columns,
    //         ];
    //       });
    //     }
    //   : ""
  );

  if (tableData.length <= 0) {
    component = <p className="ml-4">Oops, no data found.</p>;
  } else {
    component = (
      <Table
        {...tableInstance}
        setPageIndex={setPageIndex}
        cSetPageSize={cSetPageSize}
        cSetSortBy={cSetSortBy}
        setDesc={setDesc}
        q={q}
        // setSelectedRows={selectRow ? setSelectedRows : ""}
        setQ={setQ}
      />
    );
  }
  return <>{component}</>;
};

export default TableInstance;
