import { useCallback } from "react";
import useKeyDown from "../../hooks/useKeyDown";
type Props = {
  handleSave: Function;
};
function HandleSave({ handleSave }: Props) {
  const handleSubmit = useCallback(
    (e: KeyboardEvent) => {
      if (e.ctrlKey) handleSave();
    },
    [handleSave]
  );

  useKeyDown(83, handleSubmit);
  return <></>;
}

export default HandleSave;
