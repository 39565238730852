import { useField } from "formik";
import { HiOutlineRefresh } from "react-icons/hi";
import { useQueryClient } from "react-query";
import { Option } from "../../types";
import UtilityBtn from "../UtilityBtn/UtilityBtn";
import CustomSelect from "./CustomSelect";

interface Props {
  label: string;
  id: string;
  name: string;
  isLoading: boolean;
  isFetching: boolean;
  queryKey: string[];
  error: any;
  options: Option[];
  selected?: string;
  inline?: boolean;
  classes?: string;
  disabled?: boolean;
  isClearable?: true;
}

const RemoteSelect = ({
  label,
  id,
  name,
  queryKey,
  isFetching,
  isLoading,
  error,
  options = [],
  selected,
  classes,
  inline,
  disabled,
  ...props
}: Props) => {
  const queryClient = useQueryClient();

  // props -> every props except label and options -> { name: 'value', id: 'value' }
  const [field, meta] = useField(name);

  // useEffect(() => {
  //   if (options.length > 0 && !field.value) {
  //     const exists = selected
  //       ? options.find((o) => o.value === selected)
  //       : undefined;

  //     // if selected is given and it is also found in the given options
  //     if (exists) {
  //       helpers.setValue(exists.value);
  //     } else {
  //       helpers.setValue(options[0].value);
  //     }
  //   }
  // }, [options]);

  return (
    <div
      className={`flex ${
        disabled ? "disabled-custom cursor-not-allowed " : ""
      }  ${classes}`}
    >
      <CustomSelect
        label={label}
        isDisabled={disabled}
        name={name}
        id={id}
        options={options}
        classes="w-full"
        {...props}
      />

      <UtilityBtn
        Icon={HiOutlineRefresh}
        label="Retry"
        iconClasses={isFetching ? "animate-spin" : ""}
        onClick={() => {
          queryClient.invalidateQueries(queryKey);
        }}
      />
    </div>
  );
};

export default RemoteSelect;
