import { Form, Formik } from "formik";
import { useState } from "react";
import { useQuery } from "react-query";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import CustomerInput from "../../components/FormikComponents/CustomerInput";
import CustomSelect from "../../components/FormikComponents/CustomSelect";
import HeadInput from "../../components/FormikComponents/HeadInput";
import Input from "../../components/FormikComponents/Input";
import RadioButtons from "../../components/FormikComponents/RadioButton";
import RemoteSelect from "../../components/FormikComponents/RemoteSelect";
import SingleValueReactSelect from "../../components/FormikComponents/SingleValueReactSelect";
import SubmitBtn from "../../components/FormikComponents/SubmitBtn";
import TableInstance from "../../components/Table/TableInstance";
import useGetCustomers from "../../hooks/customer/useGetCustomers";
import useGetCompanies from "../../hooks/useGetCompanies";
import useGetHeads from "../../hooks/useGetHeads";
import useGetMRs from "../../hooks/useGetMRs";
import { Option, SalesObj } from "../../types";
import { getHeadId } from "../../utils/helpers";

function SoldStockReport() {
  const [cPageSize, cSetPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [cSortBy, cSetSortBy] = useState(null);
  const [desc, setDesc] = useState(true);
  const [q, setQ] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [potencies, setPotencies] = useState<string[]>([]);
  const [sizes, setSizes] = useState<string[]>([]);
  const reportType: Option[] = [
    { label: "Item wise", value: "Item wise" },
    { label: "Customer wise", value: "Customer wise" },
    { label: "Summary Report", value: "Summary Report" },
  ];

  const initialValues = {
    medrepId: "",
    orderBy: "",
    customerId: "",
    company: "",
    head: "",
    potency: "",
    size: "",
    fromDate: "",
    toDate: "",
  };
  const [searchQueries, setSearchQueries] = useState(initialValues);

  const INVENTORY_COLUMNS = [
    {
      Header: "Sr",
      accessor: "",
    },
    {
      Header: "Company",
      accessor: "company.name",
    },
    {
      Header: "Item Name",
      accessor: "product_name",
    },
    {
      Header: "Potency",
      accessor: "head.potency",
    },
    {
      Header: "Size",
      accessor: "head.size",
    },
    {
      Header: "Quantity",
      accessor: "quantity",
    },
    {
      Header: "Total",
      accessor: "total",
    },
    {
      Header: "Detail",
      accessor: "detailbtn",
    },
  ];

  const validationSchema = Yup.object({
    medrepId: Yup.string().required("MR is Required"),
    orderBy: Yup.string().required("Order By is Required"),
    company: Yup.object().required("Company is required"),
    customerId: Yup.string().required("Customer is Required"),
    head: Yup.string().required("Head is Required"),
    potency: Yup.string().required("Potency is required"),
    size: Yup.string().required("Size is required"),
    fromDate: Yup.date()
      .required("From date is required")
      .max(new Date(), "From date cannot be a future date"),
    toDate: Yup.date()
      .required("To date is required")
      .min(Yup.ref("fromDate"), "To date must be after From date")
      .max(new Date(), "To date cannot be a future date"),
  });

  const { dataCompany, isLoadingCompany, isFetchingCompany, errorCompany } =
    useGetCompanies();

  const {
    dataHeads,
    isLoadingHeads,
    isFetchingHeads,
    errorHeads,

    allHeads,
  } = useGetHeads();

  const {
    dataCustomers,
    isFetchingCustomers,
    isLoadingCustomers,
    errorCustomers,
  } = useGetCustomers();

  const {
    dataMRs,
    isFetchingMRs,
    errorMRs,
    isLoadingMRs,
    optionMRs,
    queryKeyMrs,
  } = useGetMRs();

  const getAllOrders = async ({ queryKey }: any) => {
    const { data } = await axiosInstance.get(
      `/sales?start_date=${queryKey[2]}&end_date=${queryKey[3]}&status=${queryKey[1]}&customerId=${queryKey[4]}&medrepId=${queryKey[5]}`
    );

    return data.data;
  };

  const {
    data: salesApiRes,
    error,
    isLoading: salesIsLoading,
    isSuccess,
  } = useQuery<SalesObj[] | undefined, any, SalesObj[], any[]>(
    [
      "getAllOrders",
      "created",
      searchQueries.fromDate,
      searchQueries.toDate,
      searchQueries.customerId,
      searchQueries.medrepId,
    ],
    getAllOrders
  );

  const handleSubmit = (values: any, resetForm: any) => {
    const postData = {
      medrepId: values.medrepId,
      orderBy: values.orderBy,
      customerId: values.customerId,
      company: values.company,
      head: getHeadId(values.head, values.potency, values.size, allHeads as []),
      fromDate: new Date(values.fromDate).toISOString(),
      toDate: new Date(values.toDate).toISOString(),
    };
    setSearchQueries({
      ...searchQueries,
      medrepId: postData.medrepId,
      customerId: postData.customerId,
      fromDate: postData.fromDate,
      toDate: postData.toDate,
    });
    console.log("values", postData);
    // resetForm();
  };

  return (
    <div className="sales">
      <header className="flex items-center justify-between mb-8"></header>

      <div className="sold-stock flex flex-col justify-center px-4 py-4 rounded border border-gray-300 gap-x-4 relative mb-14">
        {/* <div className="search-box mr-4">
        <SearchBox
          searchValue={q}
          setSearchValue={setQ}
          handleSearch={() => {}}
        />
      </div> */}
        <div className="filters">
          {" "}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) =>
              handleSubmit(values, resetForm)
            }
          >
            <Form className="">
              <div className="row-1 flex items-center mb-2 gap-x-4">
                <RemoteSelect
                  label="MR "
                  name="medrepId"
                  id="medrepId"
                  error={errorMRs}
                  isFetching={isFetchingMRs}
                  isLoading={isLoadingMRs}
                  options={optionMRs || []}
                  queryKey={[queryKeyMrs]}
                  classes="w-full"
                />
                <RemoteSelect
                  label="Order By"
                  name="orderBy"
                  id="orderBy"
                  error={errorMRs}
                  isFetching={isFetchingMRs}
                  isLoading={isLoadingHeads}
                  options={optionMRs || []}
                  queryKey={[queryKeyMrs]}
                  classes="w-full"
                />
                <CustomerInput
                  label="Customer"
                  name="customerId"
                  id="customerId"
                  dependencies={{
                    bal: "bal",
                  }}
                />
                <CustomSelect
                  options={dataCompany || []}
                  id="company"
                  name="company"
                  label="Company"
                  classes="w-full"
                  isClearable
                  isDisabled={dataCompany?.length === 0}
                  placeholder="Select Company"
                />
                <HeadInput
                  setSizes={setSizes}
                  setPotencies={setPotencies}
                  dependencies={{
                    potency: "potency",
                    size: "size",
                    hsn_number: "hsn_number",
                    gst: "gst",
                    stock: "stock",
                  }}
                  classes="w-full"
                  id="head"
                  name="head"
                  label="Head"
                />
                <SingleValueReactSelect
                  id="potency"
                  name="potency"
                  label="Potency"
                  isClearable
                  isDisabled={potencies.length === 0}
                  options={potencies}
                  classes="w-full"
                  placeholder="Select Potency"
                />

                <SingleValueReactSelect
                  classes="w-full"
                  id="size"
                  name="size"
                  label="Size"
                  isClearable
                  placeholder="Select Size"
                  isDisabled={sizes.length === 0}
                  options={sizes}
                />
                <div className="from flex items-center  mr-2">
                  <Input
                    label="From"
                    name="fromDate"
                    id="fromDate"
                    type="date"
                  />
                </div>
                <div className="to  ">
                  <Input label="To" name="toDate" id="toDate" type="date" />
                </div>

                <div className="buttons flex items-center justify-center">
                  <SubmitBtn
                    text={"Search"}
                    isSubmitting={isSubmitting}
                    classes="font-medium "
                    isSubmittingClasses="font-medium w-24  h-10 !mb-0"
                  />
                </div>
              </div>
              <div className="row-2 flex items-center mb-2 gap-x-4">
                <div className=" flex items-center  w-full">
                  <RadioButtons
                    id="reportType"
                    name="reportType"
                    options={reportType}
                  />
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      </div>

      <TableInstance
        tableData={salesApiRes || []}
        columnName={INVENTORY_COLUMNS}
        cPageSize={cPageSize}
        cSetPageSize={cSetPageSize}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageCount={-1} // do calculation here
        cSortBy={cSortBy}
        cSetSortBy={cSetSortBy}
        desc={desc}
        setDesc={setDesc}
        q={q}
        setQ={setQ}
        setSelectedRows={setSelectedRows}
        selectRow={false}
      />
    </div>
  );
}

export default SoldStockReport;
