import jwtDecode, { JwtPayload } from "jwt-decode";
import { HeadObj } from "../types";
import { GET_BALANCE } from "../types/sales.types";

export const checkTokens = (): boolean => {
  try {
    const refreshToken = localStorage.getItem("refreshToken");
    const accessToken = localStorage.getItem("accessToken");

    if (!refreshToken && !accessToken) {
      return false;
    }

    // first check, if you have a valid access_token
    if (accessToken) {
      // accessToken may be invalid, or expired, or no refreshToken or refreshToken present or refreshToken may be invalid
      try {
        // decode the token
        // invalid or malformed token will throw error
        const atoken = jwtDecode<JwtPayload>(accessToken as string);
        let exp = null;

        if (atoken && atoken?.exp) {
          exp = atoken.exp;
        }

        // if no exp date or expired exp date
        if (!exp || exp < new Date().getTime() / 1000) {
          // invalid accessToken
          // now check for refreshToken
          if (refreshToken) {
            const rtoken = jwtDecode<JwtPayload>(refreshToken as string);
            let exp = null;

            if (rtoken && rtoken?.exp) {
              exp = rtoken.exp;
            }

            // if no exp date or expired exp date
            if (!exp || exp < new Date().getTime() / 1000) {
              return false;
            }
          } else {
            return false;
          }
        }
      } catch {
        // invalid accessToken
        // now check for refreshToken
        if (refreshToken) {
          const rtoken = jwtDecode<JwtPayload>(refreshToken as string);
          let exp = null;

          if (rtoken && rtoken?.exp) {
            exp = rtoken.exp;
          }

          // if no exp date or expired exp date
          if (!exp || exp < new Date().getTime() / 1000) {
            return false;
          }
        } else {
          return false;
        }
      }
    } else {
      // we have refreshToken
      // check if refreshToken exists or not
      const rtoken = jwtDecode<JwtPayload>(refreshToken as string);
      let exp = null;

      if (rtoken && rtoken?.exp) {
        exp = rtoken.exp;
      }

      // if no exp date or expired exp date
      if (!exp || exp < new Date().getTime() / 1000) {
        return false;
      }
    }

    // valid token
    return true;
  } catch (e) {
    return false;
  }
};

export const getTokens = () => {
  // check if the user has a valid or a access_token refresh_token
  if (checkTokens()) {
    return {
      accessToken: localStorage.getItem("accessToken"),
      refreshToken: localStorage.getItem("refreshToken"),
    };
  }

  removeTokens();
  return {
    accessToken: null,
    refreshToken: null,
  };
};

export const saveTokens = (accessToken: string, refreshToken: string): void => {
  localStorage.setItem("accessToken", accessToken);
  localStorage.setItem("refreshToken", refreshToken);
};

// fn to save new access token
export const saveAccessTokens = (accessToken: string): void => {
  localStorage.setItem("accessToken", accessToken);
};

// fn to remove tokens
export const removeTokens = (): void => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
};

// slice chars
export const chopChars = (maxLength: number, text: string) => {
  return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
};

//function to convert ISOString Date to readable date and time
export const convertDate = (date: string) => {
  const d = new Date(date);
  const month = d.getMonth() + 1;
  const day = d.getDate();
  const year = d.getFullYear();

  return `${day}/${month}/${year} `;
};

export const getBalance = (id: string, data: []): GET_BALANCE | undefined => {
  const balance = data.find((item: GET_BALANCE) => item.id === id);

  return balance;
};
export const getBalancePending = (id: string, data: []): any => {
  return data.find((item: any) => {
    if (item.customerId === id) {
      return item.customerId;
    } else {
      return null;
    }
  });
};

//localestring to yyyy-mm-dd
export const localestringToDate = (date: string) => {
  if (!date) return "";
  const d = new Date(date);
  const month = d.getMonth() < 9 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
  const day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
  const year = d.getFullYear();

  return `${day}/${month}/${year}`;
};

export const convertDateToStringForForm = (
  date: string | Date,
  isMonthOnly?: boolean
) => {
  if (!date) return "";
  let d = date;
  if (typeof date === "string") d = new Date(date);
  else d = date;
  const month =
    d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
  const day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
  const year = d.getFullYear();

  return isMonthOnly ? `${month}-${year%100}` : `${year}-${month}-${day}`;
};

export const numberToIndianSystem = (amount: number) => {
  const convertedAmount = new Intl.NumberFormat("en-IN", {
    maximumFractionDigits: 2,
    // style: "currency",
    // currency: "INR",
  }).format(amount);

  if (convertedAmount == "₹NaN") return "";
  return convertedAmount;
};

export const getHeadId = (
  head: string,
  potency: string,
  size: string,
  allHeads: HeadObj[]
): string => {
  let id: string = "";
  allHeads.some((h: any) => {
    if (h.head === head && h.potency === potency && h.size === size) {
      id = h.id;
      return true;
    }
  });
  return id;
};

export const getStartAndEndDate = (days?: any) => {
  let endDate = new Date();
  endDate.setHours(23, 59, 59, 999);

  let startDate = new Date(
    new Date().setDate(new Date().getDate() - (days ? days : 30))
  );
  startDate.setHours(0, 0, 0, 0);
  return { endDate, startDate };
};

export const removeNullDiscounts = (values: {
  d1: number | string | undefined;
  d2: number | string | undefined;
  d3: number | string | undefined;
  d4: number | string | undefined;
  d5: number | string | undefined;
}) => {
  let discounts: any = {};

  if (values.d1 || values.d1 === 0) {
    discounts.d1 = values.d1;
  }
  if (values.d2 || values.d2 === 0) {
    discounts.d2 = values.d2;
  }
  if (values.d3 || values.d3 === 0) {
    discounts.d3 = values.d3;
  }
  if (values.d4 || values.d4 === 0) {
    discounts.d4 = values.d4;
  }
  if (values.d5 || values.d5 === 0) {
    discounts.d5 = values.d5;
  }
  return discounts;
};

export const convertIntoLableVale = (v: string | undefined) => {
  return v ? { label: v, value: v } : { label: "", value: "" };
};

export const calculateTaxableAmount = (
  mrp: string | number,
  qty: string | number,
  dis: {
    d1?: string | number;
    d2?: string | number;
    d3?: string | number;
    d4?: string | number;
    d5?: string | number;
  }
) => {
  mrp = Number(mrp);
  const calDiscount = (mrp: number, d: string | number): number => {
    mrp = (mrp * (100 - Number(d))) / 100;
    return mrp;
  };

  Object.values(dis).forEach((value) => {
    if (value) mrp = calDiscount(mrp as number, value);
  });

  return Number((mrp * Number(qty)).toFixed(2));
};

export const groupDiscountByItem = (discounts: any) => {
  const mp: any = {};
  discounts.forEach((d: any) => {
    const key = `${d.value}-${d.item.head.head}-${d.item.company.name}`;
    if (!mp[key]) {
      mp[key] = {
        value: d.value,
        company: d.item.company.name,
        head: d.item.head.head,
        products: [{ ...d.item, discountId: d.id }],
      };
    } else {
      mp[key].products.push({ ...d.item, discountId: d.id });
    }
  });

  return Object.values(mp);
};
