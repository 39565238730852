import { AxiosError } from "axios";
import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import useGetStateCity from "../../hooks/Conifg/state-city/useGetStateCity";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ConfigurationTransportObj } from "../../types";
import { ERROR, SUCCESS } from "../../types/constants";
import { convertIntoLableVale } from "../../utils/helpers";
import CustomSelect from "../FormikComponents/CustomSelect";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";

function UpdateTransportModal(props: any) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hideModal());
  };
  const { optionAllCities } = useGetStateCity();
  const initialValues: ConfigurationTransportObj = {
    transporter: props?.transporter || "",
    head_office: props?.head_office || "",
    branch_office: props?.branch_office || "",
    contact1: props?.contact1 || "",
    contact2: props?.contact2 || "",
    contact_person: props?.contact_person || "",
    gstin: props?.gstin || "",
    cities: props?.cities?.map((city: any) => convertIntoLableVale(city)) || "",
  };

  const validationSchema = Yup.object({
    transporter: Yup.string().required("Transporter is required"),
    head_office: Yup.string().required("Head Office is required"),
    branch_office: Yup.string().required("Branch Office is required"),
    contact1: Yup.string()
      .required("Contact number is required")
      .matches(
        /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
        "Contact Number must be valid"
      ),
    contact2: Yup.string().matches(
      /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
      "Contact Number must be valid"
    ),
    contact_person: Yup.string().required("Contact Person is required"),
    gstin: Yup.string().required("GSTin is required"),
    cities: Yup.array().of(Yup.object()).min(1).required("City(s) is required"),
  });

  const handleSubmit = useCallback(
    (values: ConfigurationTransportObj, resetForm: any) => {
      setIsSubmitting(true);
      const value = {
        transporter: values.transporter,
        head_office: values.head_office,
        branch_office: values.branch_office,
        contact1: values.contact1,
        contact2: values.contact2,
        contact_person: values.contact_person,
        gstin: values.gstin,
        cities: values.cities.map((city: any) => city.value),
      };

      axiosInstance
        .patch(`/transport/${props.id}`, value)
        .then((response) => {
          const { msg } = response.data;
          setIsSubmitting(false);
          dispatch(
            addToast({
              kind: SUCCESS,
              msg: msg,
            })
          );
          queryClient.invalidateQueries("getTransports");
          resetForm();
          handleClose();
        })
        .catch((error: AxiosError) => {
          setIsSubmitting(false);
          if (error.response) {
            const response = error.response;
            const { msg } = response.data;

            switch (response.status) {
              // bad request or invalid format or unauthorized
              case 400:
              case 500:
                dispatch(
                  addToast({
                    kind: ERROR,
                    msg: msg,
                  })
                );
                break;
              default:
                dispatch(
                  addToast({
                    kind: ERROR,
                    msg: "Oops, something went wrong",
                  })
                );
                break;
            }
          } else if (error.request) {
            dispatch(
              addToast({
                kind: ERROR,
                msg: "Oops, something went wrong",
              })
            );
          } else {
            dispatch(
              addToast({
                kind: ERROR,
                msg: `Error: ${error.message}`,
              })
            );
          }
        });
    },
    []
  );

  return (
    <div className="">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values, resetForm);
        }}
        validateOnMount={true}
      >
        <Form
          className="px-8 py-3  items-center gap-x-12 "
          style={{ width: "60rem" }}
        >
          <div className="row flex gap-x-8">
            <Input
              label="Transporter"
              id="transporter"
              name="transporter"
              type="text"
              classes="h-16"
            />
            <Input
              label="Contact Person"
              id="contact_person"
              name="contact_person"
              type="text"
              classes="h-16"
            />
          </div>
          <div className="second-row flex gap-x-8 w-full">
            <Input
              label="Head Office"
              id="head_office"
              name="head_office"
              type="text"
              classes="h-16"
            />
            <Input
              label="Branch Office"
              id="branch_office"
              name="branch_office"
              type="text"
              classes="h-16"
            />
            <Input
              label="GST"
              id="gstin"
              name="gstin"
              type="text"
              classes="h-16"
            />
          </div>
          <div className="third-row flex gap-x-8">
            <div className="left flex flex-auto w-full gap-x-8">
              <Input
                label="Contact 1"
                id="contact1"
                name="contact1"
                type="text"
                classes="h-16"
              />
              <Input
                label="Contact 2"
                id="contact2"
                name="contact2"
                type="text"
                classes="h-16"
              />
            </div>
          </div>
          <div className="fourth-row  flex flex-auto  gap-x-8">
            <CustomSelect
              isMulti
              id="cities"
              name="cities"
              label="Cities"
              options={optionAllCities}
              classes="w-full"
              placeholder="Select City(s)"
              createAble
            />
          </div>
          <div className="my-2">
            <SubmitBtn
              text="Update"
              isSubmitting={isSubmitting}
              classes="text-base h-10 w-full "
            />
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default UpdateTransportModal;
