import { Form, Formik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import Input from "../../components/FormikComponents/Input";
import Select from "../../components/FormikComponents/Select";
import SubmitBtn from "../../components/FormikComponents/SubmitBtn";
import TableInstance from "../../components/Table/TableInstance";
import dummyInventory from "../../data/dummyInventory";
import useGetMRs from "../../hooks/useGetMRs";
import { Option } from "../../types";

function GstReport() {
  const [cPageSize, cSetPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [cSortBy, cSetSortBy] = useState(null);
  const [desc, setDesc] = useState(true);
  const [q, setQ] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const months: Option[] = [
    { label: "January", value: "January" },
    { label: "February", value: "February" },
    { label: "March", value: "March" },
    { label: "April", value: "April" },
    { label: "May", value: "May" },
    { label: "June", value: "June" },
    { label: "July", value: "July" },
    { label: "August", value: "August" },
    { label: "September", value: "September" },
    { label: "October", value: "October" },
    { label: "November", value: "November" },
    { label: "December", value: "December" },
  ];

  const presentYear = new Date().getFullYear();
  let years: Option[] = [];
  for (let i = presentYear; i >= presentYear - 5; i--) {
    years.push({ label: String(i), value: String(i) });
  }

  const INVENTORY_COLUMNS = [
    {
      Header: "Sr",
      accessor: "",
    },
    {
      Header: "Customer",
      accessor: "customer.name",
    },
    {
      Header: "MR",
      accessor: "mr.name",
    },
    {
      Header: "Address",
      accessor: "address",
    },
    {
      Header: "Contacact No",
      accessor: "head.size",
    },
    {
      Header: "Total Orders",
      accessor: "total",
    },
    {
      Header: "Last Order",
      accessor: "last_order",
    },
  ];

  const initialValues = {
    month: "",
    year: "",
    fromDate: "",
    toDate: "",
  };

  const validationSchema = Yup.object({
    month: Yup.string().required("Month is Required"),
    year: Yup.string().required("Year is Required"),
    fromDate: Yup.date()
      .required("From date is required")
      .max(new Date(), "From date cannot be a future date"),
    toDate: Yup.date()
      .required("To date is required")
      .min(Yup.ref("fromDate"), "To date must be after From date")
      .max(new Date(), "To date cannot be a future date"),
  });

  const { dataMRs, isFetchingMRs, errorMRs, isLoadingMRs } = useGetMRs();

  const handleSubmit = (values: any, resetForm: any) => {
    const postData = {
      months: values.month,
      years: values.year,
      fromDate: new Date(values.fromDate).toISOString(),
      toDate: new Date(values.toDate).toISOString(),
    };
    console.log("values", postData);
    resetForm();
  };

  return (
    <div className="sales">
      <header className="flex items-center justify-between mb-8"></header>

      <div className="sold-stock flex flex-col justify-center px-4 py-4 rounded border border-gray-300 gap-x-4 relative mb-14">
        <div className="filters">
          {" "}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) =>
              handleSubmit(values, resetForm)
            }
            validateOnMount
          >
            <Form className="">
              <div className="row-1 flex items-center mb-2 gap-x-4">
                <Select
                  classes="w-full"
                  id="month"
                  name="month"
                  label="Month"
                  options={months}
                />
                <Select
                  classes="w-full"
                  id="year"
                  name="year"
                  label="Years"
                  options={years}
                />

                <Input label="From" name="fromDate" id="fromDate" type="date" />
                <Input label="To" name="toDate" id="toDate" type="date" />
                <div className="buttons flex items-center justify-center">
                  <SubmitBtn
                    text={"Search"}
                    isSubmitting={isSubmitting}
                    classes="font-medium "
                    isSubmittingClasses="font-medium w-24  h-10 !mb-0"
                  />
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      </div>

      <TableInstance
        tableData={dummyInventory || []}
        columnName={INVENTORY_COLUMNS}
        cPageSize={cPageSize}
        cSetPageSize={cSetPageSize}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageCount={-1} // do calculation here
        cSortBy={cSortBy}
        cSetSortBy={cSetSortBy}
        desc={desc}
        setDesc={setDesc}
        q={q}
        setQ={setQ}
        setSelectedRows={setSelectedRows}
        selectRow={false}
      />
    </div>
  );
}

export default GstReport;
