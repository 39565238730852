import { useField, useFormikContext } from "formik";
import { HiOutlineRefresh } from "react-icons/hi";
import { useQueryClient } from "react-query";
import Select from "react-select";
import axiosInstance from "../../axiosInstance";
import useGetCustomers from "../../hooks/customer/useGetCustomers";
import ReactSelectCustomStyle from "../../utils/reactSelectCustomStyle";

import UtilityBtn from "../UtilityBtn/UtilityBtn";
import ErrorBox from "./ErrorBox";

interface DependencyObj {
  bal?: string;
}

interface Props {
  label: string;
  id: string;
  name: string;
  dependencies: DependencyObj;
  inline?: boolean;
  classes?: string;
  isDisabled?: boolean;
}

const CustomerInput = ({
  label,
  id,
  name,
  dependencies,
  classes,
  inline,
  isDisabled,
  ...props
}: Props) => {
  const queryClient = useQueryClient();
  const {
    dataCustomers,
    isFetchingCustomers,
    isLoadingCustomers,
    errorCustomers,
    queryKeyCustomer,
  } = useGetCustomers();

  // props -> every props except label and options -> { name: 'value', id: 'value' }
  const { setFieldValue } = useFormikContext();
  const [field, meta, helpers] = useField(name);

  return (
    <div
      className={`flex w-full flex-col mb-6 ${
        isDisabled ? "disabled-custom cursor-not-allowed " : ""
      } ${classes}`}
    >
      <div
        className={`form-group flex ${
          inline ? " flex-row items-center" : "flex-col justify-center"
        }`}
      >
        <label
          className={`font-semibold ${inline ? "mr-4 w-28" : "mb-2"}`}
          htmlFor={id}
        >
          {label}
        </label>
        <div className="flex">
          <Select
            placeholder={"Select Customer"}
            value={field.value}
            onBlur={() => helpers.setTouched(true)}
            onChange={(op) => {
              helpers.setTouched(true);
              console.log(op);

              helpers.setValue(op);
            }}
            isDisabled={isDisabled}
            openMenuOnFocus
            styles={ReactSelectCustomStyle}
            options={dataCustomers}
            getOptionLabel={(d) => d.name}
            getOptionValue={(o) => o.id}
            isSearchable={true}
            className={`w-full ${
              meta.touched && meta.error
                ? "border border-red-400"
                : "border-coolGray-200 "
            } mr-2   `}
          />

          <UtilityBtn
            Icon={HiOutlineRefresh}
            label="Retry"
            iconClasses={isFetchingCustomers ? "animate-spin" : ""}
            onClick={() => {
              queryClient.invalidateQueries(queryKeyCustomer);
            }}
          />
        </div>
      </div>
      {errorCustomers ? (
        <ErrorBox msg={"Something went wrong, retry"} />
      ) : (
        meta.touched &&
        meta.error &&
        !isFetchingCustomers && <ErrorBox msg={meta.error} />
      )}
    </div>
  );
};

export default CustomerInput;

const getDebtors = async ({ queryKey }: any) => {
  const res = await axiosInstance.get(`/debtors`);

  return res.data.data;
};
