const ReactSelectCustomStyle = {
  control: (provided: any, state: any) => {
    return {
      ...provided,
      backgroundColor: "#e2e8f0",
      boxShadow: "none",
      ":hover": {
        borderColor: "none",
        cursor: "text",
      },
      borderColor: "none",
    };
  },

  multiValueLabel: (provided: any, state: any) => {
    return {
      ...provided,
      color: "black",
      fontSize: "1rem",
      cursor: "pointer",
    };
  },
  clearIndicator: (provided: any, state: any) => {
    return {
      ...provided,

      cursor: "pointer",
      ":hover": {
        color: "red",
      },
    };
  },
};

export default ReactSelectCustomStyle;
