import { useParams } from "react-router-dom";
import AddDiscountCategoryForm from "../../components/discounts/AddDiscountCategoryForm";
import CustomerDiscountItemsList from "../../components/discounts/CustomerDiscountItemsList";
import useGetDiscountCategories from "../../hooks/discounts/useGetDiscountCategories";
import AddDiscountToCustomerForm from "./AddDiscountToCustomerForm";

function UpdateItemsToDiscountCategory() {
  const params = useParams();

  const { data } = useGetDiscountCategories(
    {
      id: params.categoryId,
      populateItems: true,
    },
    true
  );
  return data?.length ? (
    <div>
      <AddDiscountCategoryForm categoryName={data[0].name} />

      <AddDiscountToCustomerForm />
      <CustomerDiscountItemsList data={data!} />
    </div>
  ) : null;
}

export default UpdateItemsToDiscountCategory;
