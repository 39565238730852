import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import CustomSelect from "../../components/FormikComponents/CustomSelect";
import SubmitBtn from "../../components/FormikComponents/SubmitBtn";
import TableInstance from "../../components/Table/TableInstance";
import useGetStateCity from "../../hooks/Conifg/state-city/useGetStateCity";
import useMutateStateCity from "../../hooks/Conifg/state-city/useMutateStateCity";
import { showModal } from "../../redux/features/modalSlice";
import { ValidationStateCity } from "../../types";
import {
  FROM,
  GET_CONFIRMATION,
  UPDATE_CITY_MODAL,
} from "../../types/constants";
import { localestringToDate } from "../../utils/helpers";

function StateCityConfiure() {
  const dispatch = useDispatch();

  const CONFIGURATION_STATE_CITY_COLUMNS = [
    {
      Header: "Sr.No",
      accessor: (_row: any, index: number) => index + 1,
    },
    {
      Header: "State",
      accessor: "state",
    },
    {
      Header: "Cities",
      accessor: "cities",
      Cell: ({ value }: any) => {
        return value?.join(", ") || "";
      },
    },

    {
      Header: "Created At",
      accessor: "createdAt",
      Cell: ({ value }: any) => {
        return localestringToDate(value);
      },
    },
    {
      Header: "Last Update",
      accessor: "updatedAt",
      Cell: ({ value }: any) => {
        return localestringToDate(value);
      },
    },
    {
      Header: "",
      accessor: "editBtn",
      Cell: ({ row }: any) => {
        return (
          <div className="btns flex items-center justify-center gap-4">
            <button
              className="btn-primary flex items-center"
              onClick={() => {
                dispatch(
                  showModal({
                    modalType: UPDATE_CITY_MODAL,
                    modalTitle: "Update City",
                    modalProps: {
                      prevValue: row.original,
                    },
                  })
                );
              }}
            >
              <span className="mr-1"> Edit</span>
            </button>
            <button
              className="btn-danger flex items-center"
              onClick={() => {
                dispatch(
                  showModal({
                    modalType: GET_CONFIRMATION,
                    modalTitle: "Delete State",
                    modalProps: {
                      id: row.original.id,
                      from: FROM.STATE_CITY,
                      value: "Are so sure you want to drop the State?",
                    },
                  })
                );
              }}
            >
              <span className=""> Delete</span>
            </button>
          </div>
        );
      },
    },
  ];

  const [cPageSize, cSetPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [cSortBy, cSetSortBy] = useState("");
  const [desc, setDesc] = useState(false);
  const [q, setQ] = useState("");

  let component = null;
  const { dataStateCity, errorStateCity, isLoadingStateCity, optionStates } =
    useGetStateCity();

  if (errorStateCity) {
    component = (
      <p className="mt-6 ml-4 text-center">
        An error has occurred: {errorStateCity.message}
      </p>
    );
  } else if (isLoadingStateCity) {
    component = <p className="mt-6 ml-4 text-center">Loading...</p>;
  } else {
    component = (
      <TableInstance
        tableData={dataStateCity || []}
        columnName={CONFIGURATION_STATE_CITY_COLUMNS}
        cPageSize={cPageSize}
        cSetPageSize={cSetPageSize}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageCount={-1} // do calculation here
        cSortBy={cSortBy}
        cSetSortBy={cSetSortBy}
        desc={desc}
        setDesc={setDesc}
        q={q}
        setQ={setQ}
        setSelectedRows={(f: any) => f}
      />
    );
  }

  const initialValues: ValidationStateCity = {
    state: {
      label: "",
      value: "",
    },
    cities: [],
  };

  const validationSchema = Yup.object({
    state: Yup.object().required("State is required"),
    cities: Yup.array().of(Yup.object()).min(1).required("City(s) is required"),
  });
  const { mutate, isLoading: isSubmitting } = useMutateStateCity();

  const handleSubmit = useCallback(
    (
      values: ValidationStateCity & { state: {} },
      setErrors: Function,
      resetForm: any
    ) => {
      const isNew = values.state.__isNew__;

      const value = {
        state: values.state.label,
        cities: values.cities.map((city: any) => city.value),
      };
      if (!isNew) {
        return setErrors({ state: "State is Already Present" });
      }
      mutate(value, {
        onSuccess() {
          resetForm();
        },
      });
    },

    []
  );

  return (
    <div className="item-configuration">
      <div className="item-configuration-column mt-12">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm, setErrors }) =>
            handleSubmit(values, setErrors, resetForm)
          }
        >
          {({ values }) => (
            <Form>
              <div className="first-row flex gap-x-8">
                <CustomSelect
                  createAble
                  id="state"
                  name="state"
                  label="State"
                  options={optionStates.map((s) => ({ label: s, value: s }))}
                  classes="w-full"
                  placeholder="Select / Create State"
                />
              </div>
              <div className="second-row flex gap-x-8 w-full">
                <CustomSelect
                  isMulti
                  createAble
                  id="cities"
                  name="cities"
                  label="Cities"
                  options={[]}
                  classes="w-full"
                  placeholder="Select City(s)"
                />
              </div>
              <div className="third-row flex gap-x-8 items-center">
                <div className="left flex flex-auto w-full gap-x-8"></div>
                <div className="right flex flex-auto gap-x-8 w-1/3">
                  <SubmitBtn
                    text="Add"
                    isSubmitting={isSubmitting}
                    classes="text-base h-10 w-full"
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
        {component !== null && component}
      </div>
    </div>
  );
}

export default StateCityConfiure;
