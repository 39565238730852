import { HiUserGroup } from "react-icons/hi";
import CustomerInput from "../../components/FormikComponents/CustomerInput";
import Input from "../../components/FormikComponents/Input";
import RemoteSelect from "../../components/FormikComponents/RemoteSelect";
import SingleValueReactSelect from "../../components/FormikComponents/SingleValueReactSelect";
import useGetMRs from "../../hooks/useGetMRs";
import { ORDER_TYPE_SALES } from "../../types/sales.types";
type Props = {
  readonly?: boolean;
};
function SalesCustomerForm({ readonly }: Props) {
  const { optionMRs, errorMRs, isFetchingMRs, isLoadingMRs, queryKeyMrs } =
    useGetMRs();

  return (
    <div className="customer-form flex flex-col justify-center px-4 py-4 rounded border border-gray-300 gap-x-4 relative mb-14">
      <div className="row-1 grid grid-cols-7 items-center mb-6 gap-x-4">
        <Input
          label="Order Date"
          name="date"
          id="date"
          type="date"
          readonly={readonly}
        />

        <RemoteSelect
          label="Order By"
          name="orderBy"
          id="orderBy"
          error={errorMRs}
          isFetching={isFetchingMRs}
          isLoading={isLoadingMRs}
          options={optionMRs || []}
          queryKey={[queryKeyMrs]}
          classes="w-full"
          disabled={readonly}
        />
        <Input
          label="Remarks"
          name="remarks"
          id="remarks"
          type="text"
          readonly={readonly}
        />
        <CustomerInput
          label="Full Name"
          name="customer"
          id="customer"
          dependencies={{
            bal: "bal",
          }}
          isDisabled={readonly}
        />
        <Input
          label="Address"
          name="customer.address"
          id="address"
          type="text"
          readonly
        />
        <Input
          label="City/Village"
          name="customer.city"
          id="city"
          type="text"
          readonly
        />
        <Input
          label="Pincode"
          name="customer.pincode"
          id="pincode"
          type="text"
          readonly
        />
      </div>
      <div className="row-2 flex items-center gap-x-4">
        <Input
          label="State "
          name="customer.state"
          id="state"
          type="text"
          readonly
        />
        <Input
          label="Contact No. 1"
          name="customer.contactOne"
          id="contactNo1"
          type="text"
          readonly
        />
        <Input
          label="Contact No. 2"
          name="customer.contactNo2"
          id="contactNo2"
          type="text"
          readonly
        />

        <Input
          label="GSTIN"
          name="customer.gstin"
          id="gstin"
          type="text"
          readonly
        />

        <Input
          label="Reg. No."
          name="customer.regNo"
          id="regNo"
          type="text"
          readonly
        />
        <Input label="UID" name="customer.uid" id="uid" type="text" readonly />

        <Input
          label="Transport"
          name="customer.transport.transporter"
          id="transport"
          type="text"
          readonly
        />
        <Input
          label="Number of Pages"
          name="number_of_pages"
          id="number_of_pages"
          type="number"
          readonly={readonly}
        />
        <SingleValueReactSelect
          options={Object.keys(ORDER_TYPE_SALES)}
          id="order_type_sales"
          name="order_type_sales"
          label="Order Type"
          placeholder="Select Type"
          isDisabled={readonly}
        />
      </div>
      <div className="label absolute right-5 -bottom-4 bg-gray-300 rounded text-sm flex items-center font-semibold p-1">
        <HiUserGroup size={16} className="mr-1" />
        Customer
      </div>
    </div>
  );
}

export default SalesCustomerForm;
