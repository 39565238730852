import { useState } from "react";
import { useDispatch } from "react-redux";
import useGetItemsFromInventory from "../../hooks/useGetItemsFromInventory";
import { hideModal } from "../../redux/features/modalSlice";
import { FROM } from "../../types/constants";
import { toMMYYYY } from "../../utils/formatDate";
import { localestringToDate } from "../../utils/helpers";
import EditableCell from "../FormikComponents/EditableCell";
import TableInstance from "../Table/TableInstance";

interface Props {
  id: string;
  replaceItem: Function;
  customerDiscount: number;
  allProductIds: string[];
  sale_qty: number;
}

const ReplaceShortedItemsModal = ({
  id,
  replaceItem,
  customerDiscount,
  allProductIds,

  sale_qty,
}: Props) => {
  const [orderedItems, setOrderedItems] = useState<Map<string, any>>(new Map());

  const addOrderedItems = (data: any) => {
    let total = 0;

    for (let [_key, value] of data) {
      total += Number(value.orderedQty);
    }
    setSelectedQty(total);

    setOrderedItems(data);
  };

  const SHORT_ITEMS_COLUMNS = [
    {
      Header: "S No.",
      Cell: ({ row }: any) => {
        return Number(row.id) + 1;
      },
    },
    {
      Header: "Product",
      accessor: "product_name",
    },
    {
      Header: "Head",
      accessor: "head.head",
    },
    {
      Header: "Potency",
      accessor: "potency",
    },
    {
      Header: "Size",
      accessor: "size",
    },
    {
      Header: "Company",
      accessor: "company.label",
    },

    {
      Header: "Batch No.",
      accessor: "batch_number",
    },
    {
      Header: "MRP",
      accessor: "mrp",
    },
    {
      Header: "Expiry",
      accessor: "exp_date",
      Cell: ({ value }: any) => (
        <span className={new Date(value) < new Date() ? "text-red-500" : ""}>
          {toMMYYYY(value)}
        </span>
      ),
    },

    {
      Header: "GST(%)",
      accessor: "gst",
    },
    {
      Header: "Stock",
      accessor: "quantity",
      Cell: ({ value, row }: any) => {
        const isExists = allProductIds.some(
          (id: string) => id === row.original.product_id
        );

        return (
          <div className={`${isExists ? "text-primary" : ""}`}>{value}</div>
        );
      },
    },
    {
      Header: "Order Qty",
      accessor: "order_qty",
      editable: true,
      Cell: (props: any) => {
        return (
          <div>
            <EditableCell
              value={props.value}
              row={props.row}
              column={props.column}
              setOrderedItems={addOrderedItems}
              orderedItems={orderedItems}
              from={FROM.GENERATE_CHALLAAN}
            />
          </div>
        );
      },
    },
  ];

  const dispatch = useDispatch();

  const { dataItems, isLoadingItems, isFetchingItems, errorItems } =
    useGetItemsFromInventory({
      headId: id,
      filters: {
        quantity: {
          gt: 0,
        },
      },
    });

  const [cPageSize, cSetPageSize] = useState(20);
  const [pageIndex, setPageIndex] = useState(0);
  const [cSortBy, cSetSortBy] = useState(null);
  const [desc, setDesc] = useState(true);
  const [q, setQ] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedQty, setSelectedQty] = useState(0);

  // console.log(selectedRows);

  const handleClose = () => {
    dispatch(hideModal());
  };

  const handleReplace = (orderedItems: any) => {
    let items = [];
    for (let [key, value] of orderedItems) {
      const { product, orderedQty } = value;
      const gst_amount = (
        (Number(product.gst) * Number(product.mrp)) /
        100
      ).toFixed(2);
      const rate = (Number(product.mrp) + Number(gst_amount)).toFixed(2);
      items.push({
        head: product.head,
        id: product.product_id,
        product_name: {
          label: product.product_name,
          value: product.product_id,
        },
        company: product.company.value,
        batch_number: product.batch_number,
        quantity: product.quantity,
        mrp: Number(product.mrp),
        mfg_date: localestringToDate(product.mfg_date),
        exp_date: localestringToDate(product.exp_date),
        d1: customerDiscount,
        d2: 0,

        total: (Number(rate) * Number(orderedQty)).toFixed(2),
        sale_qty: orderedQty,
        gst_amount: gst_amount,
        rate,
      });
    }
    replaceItem(items);
    // console.log(selectedRows);

    handleClose();
  };

  return (
    <div
      className="replace-shorted-items-modal px-4 pt-2 pb-8"
      style={{
        minWidth: "660px",
      }}
    >
      <h2 className=" text-lg flex justify-center gap-x-4 mb-6">
        <span>
          {" "}
          Required Quantity: <span className="font-semibold"> {sale_qty} </span>
        </span>

        <span>
          {" "}
          Selected Quantity:{" "}
          <span className="font-semibold">{selectedQty}</span>
        </span>
        <span>
          {" "}
          Difference:{" "}
          <span className="font-semibold">{sale_qty - selectedQty}</span>
        </span>
      </h2>

      {isLoadingItems && isFetchingItems ? (
        <div>Loading....</div>
      ) : errorItems ? (
        <div>Something went wrong try again.</div>
      ) : (
        <>
          <TableInstance
            tableData={dataItems || []}
            columnName={SHORT_ITEMS_COLUMNS}
            cPageSize={cPageSize}
            cSetPageSize={cSetPageSize}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
            pageCount={-1} // do calculation here
            cSortBy={cSortBy}
            cSetSortBy={cSetSortBy}
            desc={desc}
            setDesc={setDesc}
            q={q}
            setQ={setQ}
            setSelectedRows={setSelectedRows}
            selectRow={false}
          />

          <div className="buttons pt-12 pb-2 flex items-center justify-center gap-x-4">
            <button
              className="btn-primary"
              disabled={!dataItems?.length}
              onClick={() => handleReplace(orderedItems)}
            >
              Replace
            </button>
            <button className="btn-danger" onClick={() => handleClose()}>
              Cancel
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default ReplaceShortedItemsModal;
