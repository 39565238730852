import { Option } from ".";

// toast types
export const ERROR = "ERROR";
export const SUCCESS = "SUCCESS";
export const INFO = "INFO";
export const WARNING = "WARNING";
export const DEFAULT = "DEFAULT";

// modal types
export const ADD_USER_MODAL = "ADD_USER_MODAL";
export const ADD_MR_MODAL = "ADD_MR_MODAL";
export const ADD_CUSTOMER_MODAL = "ADD_CUSTOMER_MODAL";
export const ADD_SUPPLIER_MODAL = "ADD_SUPPLIER_MODAL";
export const ADD_TO_DEBTORS = "ADD_TO_DEBTORS";
export const ADD_TO_CREDITORS = "ADD_TO_CREDITORS";
export const REPLACE_SHORTED_ITEMS = "REPLACE_SHORTED_ITEMS";
export const REMARK_MODAL = "REMARK_MODAL";
export const UPDATE_CONFIG_DISCOUNT_MODAL = "UPDATE_CONFIG_DISCOUNT_MODAL";
export const ADD_RECEIPT_MODAL = "ADD_RECEIPT_MODAL";
export const GET_SALE_QTY = "GET_SALE_QTY";
export const GET_CONFIRMATION = "GET_CONFIRMATION";
export const SHOW_ORDER_NO = "SHOW_ORDER_NO";
export const UPDATE_BANK_ACCOUNT_MODAL = "UPDATE_BANK_ACCOUNT_MODAL";
export const UPDATE_TRANSPORT_MODAL = "UPDATE_TRANSPORT_MODAL";
export const UPDATE_COMPANY_MODAL = "UPDATE_COMPANY_MODAL";
export const ADD_DISCOUNTS_MODAL = "ADD_DISCOUNTS_MODAL";
export const ADD_ITEM_MODAL = "ADD_ITEM_MODAL";
export const UPDATE_CITY_MODAL = "UPDATE_CITY_MODAL";
export const UPDATE_HEAD_MODAL = "UPDATE_HEAD_MODAL";
export const UPDATE_ROOM_NO = "UPDATE_ROOM_NO";
export const SHOW_PRODUCT_LIST = "SHOW_PRODUCT_LIST";
export enum MODALS {
  showAllDiscountItems = "SHOW_ALL_DISCOUNT_ITEMS",
}

// user roles
export const USER_ROLES = {
  ADMIN: "ADMIN",
  ACCOUNT: "ACCOUNT",
  SALES: "SALES",
  FINANCE: "FINANCE",
  MARKETING: "MARKETING",
};

export const USER_ROLES_CREATION = {
  ACCOUNTS: "ACCOUNTS",
  SALES: "SALES",
  PURCHASE: "PURCHASE",
  MARKETING: "MARKETING",
};

export const TRANSPORT = [
  {
    label: "Public",
    value: "public",
  },
  {
    label: "Private",
    value: "private",
  },
  {
    label: "Other",
    value: "other",
  },
];

// Default Page sizes
export const DEFAULT_PAGE_SIZES = [5, 10, 20, 50, 100];

// states
export const STATES: Option[] = [
  {
    label: "Andaman and Nicobar Islands",
    value: "Andaman and Nicobar Islands",
  },
  {
    label: "Andhra Pradesh",
    value: "Andhra Pradesh",
  },
  {
    label: "Arunachal Pradesh",
    value: "Arunachal Pradesh",
  },
  {
    value: "Assam",
    label: "Assam",
  },
  {
    value: "Bihar",
    label: "Bihar",
  },
  {
    value: "Chandigarh",
    label: "Chandigarh",
  },
  {
    value: "Chhattisgarh",
    label: "Chhattisgarh",
  },
  {
    value: "Dadra and Nagar Haveli",
    label: "Dadra and Nagar Haveli",
  },
  {
    value: "Daman and Diu",
    label: "Daman and Diu",
  },
  {
    value: "Delhi",
    label: "Delhi",
  },
  {
    value: "Goa",
    label: "Goa",
  },
  {
    value: "Gujarat",
    label: "Gujarat",
  },
  {
    value: "Haryana",
    label: "Haryana",
  },
  {
    value: "Himachal Pradesh",
    label: "Himachal Pradesh",
  },
  {
    value: "Jammu and Kashmir",
    label: "Jammu and Kashmir",
  },
  {
    value: "Jharkhand",
    label: "Jharkhand",
  },
  {
    value: "Karnataka",
    label: "Karnataka",
  },
  {
    value: "Kerala",
    label: "Kerala",
  },
  {
    value: "Ladakh",
    label: "Ladakh",
  },
  {
    value: "Lakshadweep",
    label: "Lakshadweep",
  },
  {
    value: "Madhya Pradesh",
    label: "Madhya Pradesh",
  },
  {
    value: "Maharashtra",
    label: "Maharashtra",
  },
  {
    value: "Manipur",
    label: "Manipur",
  },
  {
    value: "Meghalaya",
    label: "Meghalaya",
  },
  {
    value: "Mizoram",
    label: "Mizoram",
  },
  {
    value: "Nagaland",
    label: "Nagaland",
  },
  {
    value: "Odisha",
    label: "Odisha",
  },
  {
    value: "Puducherry",
    label: "Puducherry",
  },
  {
    value: "Punjab",
    label: "Punjab",
  },
  {
    value: "Rajasthan",
    label: "Rajasthan",
  },
  {
    value: "Sikkim",
    label: "Sikkim",
  },
  {
    value: "Tamil Nadu",
    label: "Tamil Nadu",
  },
  {
    value: "Telangana",
    label: "Telangana",
  },
  {
    value: "Tripura",
    label: "Tripura",
  },
  {
    value: "Uttar Pradesh",
    label: "Uttar Pradesh",
  },
  {
    value: "Uttarakhand",
    label: "Uttarakhand",
  },
  {
    value: "West Bengal",
    label: "West Bengal",
  },
];
export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const FROM = {
  ALL_PURCHASE: "ALL_PURCHASE",
  ALL_SALES: "ALL_SALES",
  PENDING_ORDERS: "PENDING_ORDERS",
  CONFIRM_PURCHASE: "CONFIRM_PURCHASE",
  CREATE_PURCHASE: "CREATE_PURCHASE",
  VIEW_SALES_ORDER: "VIEW_SALES_ORDER",
  CREATE_SALES_ORDER: "CREATE_SALES_ORDER",
  GENERATE_CHALLAAN: "GENERATE_CHALLAAN",
  ITEM_CONFIG: "ITEM_CONFIG",
  DELETE_SALES_PRODUCT: "DELETE_SALES_PRODUCT",
  DELETE_ROOM_NO: "DELETE_ROOM_NO",
  STATE_CITY: "STATE_CITY",
};

export const defaultAvtar =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAM1BMVEXk5ueutLeqsbTn6eqpr7PJzc/j5ebf4eLZ3N2wtrnBxsjN0NLGysy6v8HT1tissra8wMNxTKO9AAAFDklEQVR4nO2d3XqDIAxAlfivoO//tEOZWzvbVTEpic252W3PF0gAIcsyRVEURVEURVEURVEURVEURVEURVEURVEURVEURflgAFL/AirAqzXO9R7XNBVcy9TbuMHmxjN6lr92cNVVLKEurVfK/zCORVvW8iUBnC02dj+Wpu0z0Y6QlaN5phcwZqjkOkK5HZyPAjkIjSO4fIdfcOwFKkJlX4zPu7Ha1tIcwR3wWxyFhRG6g4Je0YpSPDJCV8a2Sv2zd1O1x/2WMDZCwljH+clRrHfWCLGK8REMiql//2si5+DKWKcWeAGcFMzzNrXC/0TUwQ2s6+LhlcwjTMlYsUIQzPOCb7YBiyHopyLXIEKPEkI/TgeuiidK/R9FniUDOjRDpvm0RhqjMyyXNjDhCfIMYl1gGjIMIuYsnGEYRMRZOMMunaLVwpWRW008v6fYKDIzxCwVAeNSO90BJW6emelYBRF/kHpYGVaoxTDAaxOFsfP9y8hpJ4xd7gOcij7JNGQ1EYFgkPJa1jQEiYZXRaRINKxSDUW9n+FT82lSKadkiru9/4XPqSLWOekGPoY05TAvLm9orm+YWuwHoBHkZKijNBJGmeb61eL6Ff/6q7bLr7yvv3vKGhpDRjvgjGaPz+gUg6YgcvpyAR2FIZ9U6nEEyZRTovmEU32KichpGn7C17XrfyH9gK/c0CMP05HZIM2uf9sEveizKveBy9/6Qt7o89ne33D525cfcIMW6ab+TMEukQbQbu+xu7X3A9bChmWaCeAkG17bpntwXgWxHaMzGPmUaR5dQZiKqRVeUZ3047fi3nAu28h4CHxCsZAgmEH8Y27jJAhm8c+5RQzRQNVGhVFSfxOYIjp/pP7RxzjevYXVGf4eLt+BJ1vCuLuLkrgABgCGXZ2wik5uty+oBvNirI6mkzhAf4Gsb58Hcm67Jzd+KwD10BYPLL3e0MjvKrgAULnOfveF/O4N2Xb9BZom3gJes3F9X5Zze8/6Yt09b4CrqsEjUv8oFBaR2rl+6CZr2xVrp24o/WitBKuGrrpl1+bFkmK2qXTON4VpbdfLa7o7y/WdLxG7lm2Lqh2clOwTegbvc/vj2U78CwhA87Bn8G5Nk3eOb0Nsr9flz3sG78UUtue4kpv1xvjg3TMay62BMlTlP+vrOMnJsRmt/ze0jsfkPPYdAH57hK+34PeOyc8XIXu5xT2HsUkdZz+adwg8HGFfQ3K5jtDvbUiO4Di9/ywHGrL88pDizZ++oTp+an+SMX/ndymUCwmHMdO7yuOx83pUx/eEMU0AvxWndwgidAqOZ8ypCwdEfvvEo6D9HwpA8wzvmOJEqAg9ySu8g4x0Hb9hSB/BANEKJ+LbPBU0lzbAJs4xt1AoshKkUGQmiH8/jJ0gdhTTLmSegHlPE0oOdXALnqDjKYh3px//fSgSWG8UqfrrIICzYYSJXRr9BSPbpNzw7gBjKjKOYI7ReIGqQRIap5+5MdjyvuDkExvGeXSlONWZAP3/AZBwJohU7QJRGU+cTVH18ELmRPNBmibW6MT/k1b0XhdkRBvyT6SB6EYv/GvhSmRNpGngRULsAlxMCGNXp7w3FfdEbTEEDdLI9TdIKRUzUesa3I461ER8cpNT7gMRhpKmYVS9ELOgCUQsa4SsulciKiLbY+AnHD8cpuhISsnxpamI84sbDq9qYJgf8wiiOBrC7Ml7M7ZECCqKoiiKoiiKoiiKoijv5AvJxlZRyNWWLwAAAABJRU5ErkJggg==";

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
