import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import queryKeys from "../../types/queryKeys";

function useMutateCustomer() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const handleMutation = (postData: any) => {
    if (postData.id) {
      return axiosInstance.patch(`/customers/${postData.id}`, postData);
    }
    return axiosInstance.post(`/customers/`, postData);
  };

  return useMutation(handleMutation, {
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries(queryKeys.getCustomers);
      dispatch(
        addToast({
          kind: SUCCESS,
          msg: "Item Updated Successfully",
        })
      );
    },
    onError(error: AxiosError, variables, context) {
      if (error.response) {
        const response = error.response;
        const { msg, error: err } = response.data;

        switch (response.status) {
          // bad request or invalid format or unauthorized
          case 400:
          case 500:
          case 409:
            dispatch(
              addToast({
                kind: ERROR,
                msg: msg || err,
              })
            );
            break;
          default:
            dispatch(
              addToast({
                kind: ERROR,
                msg: "Oops, something went wrong",
              })
            );
            break;
        }
      } else if (error.request) {
        dispatch(
          addToast({
            kind: ERROR,
            msg: "Oops, something went wrong",
          })
        );
      } else {
        dispatch(
          addToast({
            kind: ERROR,
            msg: `Error: ${error.message}`,
          })
        );
      }
    },
  });
}

export default useMutateCustomer;
