import { Form, Formik } from "formik";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR } from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";

interface Props {
  itemId: string;
  setValue: any;
}

const GetSaleQty = ({ itemId, setValue }: Props) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues = {
    fromDate: "",
    toDate: "",
  };

  const validationSchema = Yup.object({
    fromDate: Yup.date()
      .required("From date is required")
      .max(new Date(), "From date cannot be a future date"),
    toDate: Yup.date()
      .required("To date is required")
      .min(Yup.ref("fromDate"), "To date must be after From date")
      .max(new Date(), "To date cannot be a future date"),
  });

  const handleSubmit = (
    { fromDate, toDate }: { fromDate: string; toDate: string },
    resetForm: any
  ) => {
    console.log(fromDate, toDate);
    setIsSubmitting(true);
    axiosInstance
      .get(
        `/sales/quantity/${itemId}?start_date=${new Date(
          fromDate
        ).toISOString()}&end_date=${new Date(toDate).toISOString()}`
      )
      .then((res) => {
        let totalSale = 0;
        res.data.data.forEach((element: any) => {
          totalSale += Number(element.total_sales_quantity);
        });
        console.log(res.data);
        setIsSubmitting(false);
        setValue("saleQty", totalSale);

        dispatch(hideModal());
      })
      .catch((err) => {
        console.log(err);
        setIsSubmitting(false);

        dispatch(
          addToast({
            kind: ERROR,
            msg: "Oops, something went wrong",
          })
        );
      });
  };

  return (
    <div className="remark-modal">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
      >
        <Form className="px-10 py-5  mt-2">
          <div className="date flex items-center gap-x-5 mr-5">
            <div className="from flex items-center  w-64 mr-2">
              <Input label="From" name="fromDate" id="fromDate" type="date" />
            </div>
            <div className="to  w-64">
              <Input label="To" name="toDate" id="toDate" type="date" />
            </div>
          </div>

          <div className="buttons flex items-center justify-center">
            <SubmitBtn
              text={"Fetch Sale Quantity"}
              isSubmitting={isSubmitting}
              containerClasses="text-sm w-96 mt-4 mb-2"
            />
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default GetSaleQty;
