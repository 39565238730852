import { Form, Formik } from "formik";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import useMutateSuppliers from "../../hooks/supplier/useMutateSuppliers";
import useGetCompanies from "../../hooks/useGetCompanies";
import { hideModal } from "../../redux/features/modalSlice";
import { SupplierObj } from "../../types";
import { gstTypes } from "../../utils/constants/orderConstants";
import CustomSelect from "../FormikComponents/CustomSelect";
import Input from "../FormikComponents/Input";
import RadioButtons from "../FormikComponents/RadioButton";
import StateCitySelect from "../FormikComponents/StateCitySelect";
import SubmitBtn from "../FormikComponents/SubmitBtn";

const AddSupplierModal = (props: any) => {
  const dispatch = useDispatch();

  const { dataCompany } = useGetCompanies();

  const handleClose = () => {
    dispatch(hideModal());
  };

  const initialValues: SupplierObj = {
    name: props.name || "",
    contact_person: props.contact_person || "",
    contact_number: props.contact_number || "",
    contact_number2: props.contact_number2 || "",
    address: props.address || "",
    gstin: props.gstin || "",
    gstType: props.gstType || "",
    state: props.state,
    city: props.city,
    companies:
      props?.companies?.map((o: any) => {
        return { label: o.name, value: o.id };
      }) || [],
    email: props?.email || "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    contact_person: Yup.string().required("Contact person is required"),
    contact_number: Yup.string()
      .matches(
        /^((\+*)((0[ -]*)*|((91 )*))((\d{12})+|(\d{10})+))|\d{5}([- ]*)\d{6}$/,
        "Invalid phone number"
      )
      .required("Contact is required"),
    address: Yup.string().required("Address is required"),
    gstin: Yup.string(),
    gstType: Yup.string().oneOf(gstTypes.map((g) => g.value)),

    state: Yup.string().required("State is required"),
    city: Yup.string().required("City is required"),
    companies: Yup.array()
      .required("Companies is required")
      .min(1, "Please select atleast one company for a supplier"),
    email: Yup.string().email("Invalid email"),
  });
  const { mutate, isLoading: isSubmitting } = useMutateSuppliers();
  const handleSubmit = useCallback((user: SupplierObj) => {
    const postData = {
      ...user,
      companies: user.companies.map((c) => c.value),
      state: user.state,
      city: user.city,
      id: props.id,
    };

    mutate(postData, {
      onSuccess() {
        handleClose();
      },
    });
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({}) => {
        return (
          <Form
            className="px-6 py-4 mt-2"
            style={{
              minWidth: "360px",
              maxWidth: "760px",
            }}
          >
            <div className="grid grid-cols-4 item-center gap-x-6 mb-3">
              <Input label="Name" id="name" name="name" type="text" />
              <Input label="Address" name="address" id="address" type="text" />

              <StateCitySelect
                classes="w-full col-span-2 "
                stateName="state"
                cityName="city"
              />
            </div>
            <div>
              <CustomSelect
                id="companies"
                name="companies"
                label="Companies"
                isMulti
                options={dataCompany ? dataCompany : []}
                classes="w-full"
                placeholder="Select Companies(s)"
                isComapnies={true}
              />
            </div>
            <div className="flex item-center gap-x-6 mb-3">
              <Input
                label="Contact Person"
                id="contact_person"
                name="contact_person"
                type="text"
              />
              <Input label="Email" name="email" id="email" type="email" />

              <Input
                label="Contact 1"
                name="contact_number"
                id="contact_number"
                type="text"
              />
              <Input
                label="Contact 2"
                name="contact_number2"
                id="contact_number2"
                type="text"
                optional
              />
            </div>
            <div className="flex item-center gap-x-6 mb-3">
              <Input label="GSTIN" name="gstin" id="gstin" type="text" />
              <RadioButtons
                label="GST Type"
                name="gstType"
                id="gstType"
                options={gstTypes}
                classes="w-full"
              />
            </div>

            <div className="buttons flex items-center w-full justify-center my-4">
              <SubmitBtn
                text={props.id ? "update" : "Save"}
                isSubmitting={isSubmitting}
                classes="text-sm"
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddSupplierModal;
