import React from "react";
import { NavLink, Outlet } from "react-router-dom";

const PurchaseLayout = () => {
  return (
    <div className="configuration h-screen w-full">
      <nav className="configuration-nav mb-12 noselect">
        <ul className="flex gap-x-6 items-center">
          <li>
            <NavLink
              className={({ isActive }) =>
                `flex-1 text-lg rounded font-semibold px-2 py-1 shadow ${
                  isActive ? "bg-primary text-white" : "bg-white"
                }`
              }
              end
              to="created"
            >
              Created
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                `flex-1 text-lg rounded font-semibold px-2 py-1 shadow ${
                  isActive ? "bg-primary text-white" : "bg-white"
                }`
              }
              end
              to="completed"
            >
              Completed
            </NavLink>
          </li>
        </ul>
      </nav>

      <div className="content">
        <Outlet />
      </div>
    </div>
  );
};

export default PurchaseLayout;
