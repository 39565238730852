import { useField, useFormikContext } from "formik";
import { useMemo } from "react";
import { HiTrash } from "react-icons/hi";
import { useDispatch } from "react-redux";
import { showModal } from "../../../redux/features/modalSlice";
import { HeadObjPurchase } from "../../../types";
import { FROM, GET_CONFIRMATION } from "../../../types/constants";
import { calculateTaxableAmount } from "../../../utils/helpers";
import CustomSubmitBtn from "../../FormikComponents/CustomSubmitBtn";
import Input from "../../FormikComponents/Input";
import ReadOnlyInputBox from "../../FormikComponents/ReadOnlyInputBox";

type props = {
  v: any;
  id: any;
  formik: any;
  isOrdered_qtyTaxble?: boolean;
};
function SingleRowConfirmPurchase({
  v,
  id,
  formik,
  isOrdered_qtyTaxble,
}: props) {
  const dispatch = useDispatch();
  const [field, meta, helpers] = useField(`heads`);

  const index = id;

  const {
    values: {
      mrp,
      qty,
      taxable_amount,
      gst,
      d1,
      d2,
      d3,
      d4,
      d5,
      gst_amount,
      ordered_qty,
    },
  } = useFormikContext<HeadObjPurchase>();

  const taxAbleAmount = useMemo(() => {
    const taxAbleAmount = calculateTaxableAmount(
      mrp,
      isOrdered_qtyTaxble ? ordered_qty : qty,
      {
        d1,
        d2,
        d3,
        d4,
        d5,
      }
    );

    return taxAbleAmount.toFixed(2);
  }, [mrp, qty, d1, d2, d3, d4, d5, ordered_qty, isOrdered_qtyTaxble]);

  const gstAmount = useMemo(() => {
    let gstAmount = (Number(taxable_amount || 0) * Number(gst)) / 100;

    return gstAmount.toFixed(2);
  }, [taxable_amount, gst]);

  const totalAmount = useMemo(() => {
    const totalAmount = Number(gst_amount || 0) + Number(taxable_amount || 0);
    return totalAmount.toFixed(2);
  }, [gst_amount, taxable_amount]);
  return (
    <>
      <td className="p-1">{index + 1}</td>

      <td className="p-1">
        {v.item_name.label} {v.potency} {v.size}
      </td>

      <td className="p-1">
        <Input
          id="batch_number"
          name="batch_number"
          label=""
          type="text"
          classes="mb-0"
        />
      </td>
      <td className="p-1">
        <Input
          readonly={!isOrdered_qtyTaxble}
          id="ordered_qty"
          name="ordered_qty"
          label=""
          type="number"
          classes="mb-0"
        />
      </td>
      {!isOrdered_qtyTaxble && (
        <td className="p-1">
          <Input id="qty" name="qty" label="" type="number" classes="mb-0" />
        </td>
      )}
      <td className="p-1">
        <Input id="mrp" name="mrp" label="" type="number" classes="mb-0" />
      </td>
      <td className="p-1">
        <Input
          label=""
          name="mfg_date"
          id="mfg_date"
          type="month"
          classes="mb-0"
        />
      </td>
      <td className="p-1">
        <Input
          label=""
          name="exp_date"
          id="exp_date"
          type="month"
          classes="mb-0"
        />
      </td>
      <td className="p-1">
        <Input
          id="d1"
          name="d1"
          label=""
          type="number"
          classes="mb-0"
          step="0.1"
        />
      </td>

      <td className="p-1">
        <Input
          id="d2"
          name="d2"
          label=""
          type="number"
          classes="mb-0"
          step="0.1"
        />
      </td>
      <td className="p-1">
        <Input
          id="d3"
          name="d3"
          label=""
          type="number"
          classes="mb-0"
          step="0.1"
        />
      </td>
      <td className="p-1">
        <Input
          id="d4"
          name="d4"
          label=""
          type="number"
          classes="mb-0"
          step="0.1"
        />
      </td>
      <td className="p-1">
        <Input
          id="d5"
          name="d5"
          label=""
          type="number"
          classes="mb-0"
          step="0.1"
        />
      </td>
      <td className="p-1">
        {/* name={`heads[${index}].company.label`} */}
        <ReadOnlyInputBox name="taxable_amount" value={taxAbleAmount || ""} />
      </td>
      <td className="p-1">
        <Input
          id="gst"
          name="gst"
          label=""
          type="text"
          classes="mb-0"
          readonly
        />
      </td>
      <td className="p-1">
        <ReadOnlyInputBox name="gst_amount" value={gstAmount || ""} />
      </td>

      <td className="p-1">
        <ReadOnlyInputBox name="total" value={totalAmount || ""} />
      </td>

      <td className="p-1">
        <CustomSubmitBtn
          text={"Update"}
          classes="w-24 mb-6  focus:bg-sky-700 "
          disabled={formik.isSubmitting}
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}
        />
      </td>

      <td className="p-1  ">
        <button
          type="button"
          className=" p-2 rounded-sm bg-red-500 text-white mb-6  focus:bg-red-700 "
          onClick={() => {
            dispatch(
              showModal({
                modalType: GET_CONFIRMATION,
                modalTitle: "Drop Item",
                modalProps: {
                  id: v.id,
                  value: "Are you sure you want to drop the item?",
                  from: FROM.CONFIRM_PURCHASE,
                },
              })
            );
          }}
        >
          <HiTrash />
        </button>
      </td>
    </>
  );
}

export default SingleRowConfirmPurchase;
