import { Form, Formik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import Input from "../../components/FormikComponents/Input";
import RemoteSelect from "../../components/FormikComponents/RemoteSelect";
import SubmitBtn from "../../components/FormikComponents/SubmitBtn";
import HandlePrint, { PRINT_PAGE } from "../../components/Print/HandlePrint";
import TableInstance from "../../components/Table/TableInstance";
import useGetDebitByMR from "../../hooks/Accounts/debtors/useGetDebitByMR";
import useGetMRs from "../../hooks/useGetMRs";
import {
  convertDateToStringForForm,
  getStartAndEndDate,
  localestringToDate,
} from "../../utils/helpers";

function CollectionReports() {
  const [cPageSize, cSetPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [cSortBy, cSetSortBy] = useState(null);
  const [desc, setDesc] = useState(true);
  const [q, setQ] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);

  let { startDate, endDate } = getStartAndEndDate();

  const [searchParams, setSearchParams] = useState({
    medrepId: "",
    start_date: startDate.toISOString(),
    end_date: endDate.toISOString(),
  });

  const COLLECTION_REPORT_COLUMNS = [
    {
      Header: "Sr",
      accessor: (_row: any, i: number) => i + 1,
    },
    {
      Header: "Received By",
      accessor: "medrep.name",
    },
    {
      Header: "Receipt No.",
      accessor: "transaction_id",
    },
    {
      Header: "Date",
      accessor: "expected_date",
      Cell: ({ value }: any) => {
        return localestringToDate(value);
      },
    },

    {
      Header: "Mode of Payment",
      accessor: "mode",
    },
    {
      Header: "Bank / Cash",
      accessor: "bankaccount",
      Cell: ({ value }: any) => {
        return value?.bank_name || "Cash";
      },
    },
    {
      Header: "Particular",
      accessor: "particulars",
    },
    {
      Header: "Customer",
      accessor: "customer",
      Cell: ({ value }: any) => {
        return `${value?.name} (${value.city})`;
      },
    },
    {
      Header: "City",
      accessor: "customer.city",
    },
    {
      Header: "Amount",
      accessor: "amount",
      Cell: ({ value }: any) => {
        return Math.abs(value);
      },
    },
  ];

  const initialValues = {
    mr: "",
    fromDate: convertDateToStringForForm(startDate),
    toDate: convertDateToStringForForm(endDate),
  };

  const validationSchema = Yup.object({
    mr: Yup.object({
      label: Yup.string().required("MR By is required"),
      value: Yup.string(),
    }).nullable(),

    fromDate: Yup.date()
      .required("From date is required")
      .max(new Date(), "From date cannot be a future date"),
    toDate: Yup.date()
      .required("To date is required")
      .min(Yup.ref("fromDate"), "To date must be after From date")
      .max(new Date(), "To date cannot be a future date"),
  });

  const { isFetchingMRs, errorMRs, isLoadingMRs, optionMRs } = useGetMRs();
  const { isLoading, data } = useGetDebitByMR(searchParams);

  const handleSubmit = (values: any, resetForm: any) => {
    const postData = {
      medrepId: values?.mr?.value,
      start_date: new Date(values.fromDate).toISOString(),
      end_date: new Date(values.toDate).toISOString(),
    };

    setSearchParams(postData);
    // resetForm();
  };

  return (
    <div className="sales">
      <header className="flex items-center justify-between mb-8"></header>

      <div className="sold-stock flex flex-col justify-center px-4 py-4 rounded border border-gray-300 gap-x-4 relative mb-14">
        <div className="filters">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) =>
              handleSubmit(values, resetForm)
            }
            validateOnMount
          >
            <Form className="">
              <div className="row-1 flex items-center mb-2 gap-x-4">
                <RemoteSelect
                  label="MR "
                  name="mr"
                  id="mr"
                  error={errorMRs}
                  isFetching={isFetchingMRs}
                  isLoading={isLoadingMRs}
                  options={optionMRs || []}
                  queryKey={["getMrs"]}
                  classes="w-full"
                  isClearable
                />

                <Input label="From" name="fromDate" id="fromDate" type="date" />
                <Input label="To" name="toDate" id="toDate" type="date" />
                <div className="buttons flex items-center justify-center">
                  <SubmitBtn
                    text={"Search"}
                    isSubmitting={isLoading}
                    classes="font-medium "
                    isSubmittingClasses="font-medium w-24  h-10 !mb-0"
                  />
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
      <header className="flex items-center justify-between -mb-12">
        <div className="left">
          <div className="search-box mr-4"></div>
          <div className="filters"> </div>
        </div>
        <div className="right">
          <HandlePrint data={data} page={PRINT_PAGE.collectionReport} />
        </div>
      </header>
      <TableInstance
        tableData={data?.debits || []}
        columnName={COLLECTION_REPORT_COLUMNS}
        cPageSize={cPageSize}
        cSetPageSize={cSetPageSize}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageCount={-1} // do calculation here
        cSortBy={cSortBy}
        cSetSortBy={cSetSortBy}
        desc={desc}
        setDesc={setDesc}
        q={q}
        setQ={setQ}
        setSelectedRows={setSelectedRows}
        selectRow={false}
      />
    </div>
  );
}

export default CollectionReports;
