import { useField } from "formik";
import { useEffect } from "react";
import useGetStateCity from "../../hooks/Conifg/state-city/useGetStateCity";
import SingleValueReactSelect from "./SingleValueReactSelect";

interface Props {
  stateName: string;
  cityName: string;
  classes?: string;
  isClearable?: true;
}

const StateCitySelect = ({
  stateName,
  cityName,
  classes,
  isClearable,
}: Props) => {
  // props -> every props except label and options -> { name: 'value', id: 'value' }
  const [field, meta, helpers] = useField(stateName);
  const [fieldC, metaC, helpersC] = useField(cityName);

  const { optionCities, optionStates } = useGetStateCity();

  useEffect(() => {
    if (meta.touched) {
      helpersC.setValue("");
    }
  }, [field.value]);

  return (
    <div className={`flex col-span-2 ${classes}`}>
      <SingleValueReactSelect
        isClearable={isClearable}
        id="state"
        {...field}
        label="State"
        options={optionStates || []}
        classes="w-full"
        placeholder="Select State"
      />
      <SingleValueReactSelect
        isClearable={isClearable}
        id="city"
        {...fieldC}
        label="City"
        // @ts-ignore
        options={optionCities && field.value ? optionCities[field.value] : []}
        classes="w-full"
        placeholder="Select City(s)"
      />
    </div>
  );
};

export default StateCitySelect;
