import { FieldArray, Formik, useField, useFormikContext } from "formik";
import { useEffect, useRef, useState } from "react";
import { HiTag } from "react-icons/hi";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import useShortItems from "../../../hooks/sales/challan/useShortItems";
import useGetCompanies from "../../../hooks/useGetCompanies";
import useKeyDown from "../../../hooks/useKeyDown";
import { showModal } from "../../../redux/features/modalSlice";
import { ADD_DISCOUNTS_MODAL, FROM } from "../../../types/constants";
import { ORDER_TYPE_SALES, SalesOrderObj } from "../../../types/sales.types";
import { removeNullDiscounts } from "../../../utils/helpers";
import { calTotalTaxAbleAndGSTAmount } from "../../../utils/salesHelper";
import CustomSelect from "../../FormikComponents/CustomSelect";
import ErrorBox from "../../FormikComponents/ErrorBox";
import HeadInput from "../../FormikComponents/HeadInput";
import SingleValueReactSelect from "../../FormikComponents/SingleValueReactSelect";
import ProductSelect from "../../FormikComponents/sales/ProductSelect";
import SingleRowUpdateProduct from "./SingleRowUpdateProduct";

interface Props {
  fieldName: string;
  shortItem?: boolean;
}

function UpdateProductForm({ fieldName, shortItem = false }: Props) {
  const [field, meta, helpers] = useField(fieldName);
  const dispatch = useDispatch();
  const [potencies, setPotencies] = useState<string[]>([]);
  const [sizes, setSizes] = useState<string[]>([]);

  const { setFieldValue, values } = useFormikContext<SalesOrderObj>();
  const params = useParams();

  const refsubmit = useRef<any>(null);
  const handlerCtrlEnter = (e: any) => {
    if (e.ctrlKey) {
      refsubmit.current.focus();
    }
  };

  useKeyDown("Enter", handlerCtrlEnter);

  const initialValues = {
    head: "",
    company: { label: undefined, value: undefined },
    potency: "",
    size: "",
    product_name: "",
    batch_number: "",
    quantity: "",
    mrp: "",
    mfg_date: "",
    exp_date: "",
    d1: values.discount,
    d2: 0,
    gst: "",
    hsn_number: "",
    sale_qty: 0,
    total: 0,
  };
  const columns: {
    Header: string;
    className?: string;
  }[] = [
    { Header: "S No" },
    { Header: "Name" },
    { Header: "Head" },
    { Header: "Potency" },
    { Header: "Size" },
    { Header: "Company" },
    { Header: "Batch No." },
    { Header: "MFG" },
    { Header: "EXP" },
    { Header: "Qty" },
    { Header: "Free Qty" },
    { Header: "Dis 1(%)" },
    { Header: "Dis 2(%)" },
    { Header: "Taxable Amt." },
    { Header: "GST %" },
    { Header: "GST ₹" },
    { Header: "Total" },
    { Header: "" },
  ];

  const validationSchema = Yup.object({
    head: Yup.string().required("Head is required"),
    company: Yup.object().nullable(),
    potency: Yup.string(),
    size: Yup.string(),
    product_name: Yup.object().required("Item Name is required"),
    batch_number: Yup.string(),
    sale_qty: Yup.number()
      .required("Quantity is required")
      .min(1, "Quantity must be atleast 1"),
    mrp: Yup.string().required("MRP is required"),
    mfg_date: Yup.date(),
    exp_date: Yup.date(),
    d1: Yup.string(),
    d2: Yup.string(),
    gst: Yup.string().required("GST is required"),
    hsn_number: Yup.string().required("HSN is required"),
  });

  const { dataCompany } = useGetCompanies();
  const { mutate: mutateShortItem, isLoading: isShorting } = useShortItems();
  const handleShorting = () => {
    mutateShortItem(params.id!);
  };

  const handleSubmit = (
    value: any,
    resetForm: any,
    isReset: boolean = true
  ) => {
    let newVal = value;
    newVal.gst_amount = (
      (Number(newVal.mrp) * Number(value.gst)) /
      100
    ).toFixed(2);
    newVal.total = Number(newVal.gst_amount) * Number(value.sale_qty);

    helpers.setValue([value, ...field.value]);
    if (isReset) resetForm();
  };

  useEffect(() => {
    calTotalTaxAbleAndGSTAmount(
      field.value,
      setFieldValue,
      values.order_type_sales
    );
  }, [field.value, values.order_type_sales]);

  useEffect(() => {
    const { taxable_amount, sp_discount, sales_gst_amount } = values;

    setFieldValue(
      "total",
      (
        Number(taxable_amount) -
        Number(sp_discount) +
        Number(sales_gst_amount)
      ).toFixed(2)
    );
  }, [
    values.taxable_amount,
    values.total,
    values.sp_discount,
    values.sales_gst_amount,
  ]);
  const addDiscounts = (discounts: any) => {
    discounts = removeNullDiscounts(discounts);
    let newValues: any = [];
    for (let element of values.heads) {
      newValues.push({ ...element, ...discounts });
    }
    helpers.setValue(newValues);
  };

  const addGlobalDiscount = () => {
    dispatch(
      showModal({
        modalType: ADD_DISCOUNTS_MODAL,
        modalTitle: "ADD DISCOUNTS",
        modalProps: {
          addDiscounts: addDiscounts,
          from: FROM.CREATE_SALES_ORDER,
        },
      })
    );
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
        enableReinitialize
      >
        {(formik) => {
          return (
            <div className="head-form flex flex-col justify-center px-4 py-4 rounded border border-gray-300 gap-x-4 relative mb-14">
              <div className="row-1 flex items-center mb-2 gap-x-4">
                <HeadInput
                  setSizes={setSizes}
                  setPotencies={setPotencies}
                  dependencies={{
                    potency: "potency",
                    hsn_number: "hsn_number",
                    size: "size",
                    gst: "gst",
                  }}
                  classes="w-full"
                  id="head"
                  name="head"
                  label="Head"
                />
                <CustomSelect
                  options={dataCompany || []}
                  id="company"
                  name="company"
                  label="Company"
                  classes="w-full"
                  isClearable
                  isDisabled={dataCompany?.length === 0}
                  placeholder="Select Company"
                />
                <SingleValueReactSelect
                  id="potency"
                  name="potency"
                  label="Potency"
                  isClearable
                  isDisabled={potencies.length === 0}
                  options={potencies}
                  classes="w-full"
                  placeholder="Select Potency"
                />

                <SingleValueReactSelect
                  classes="w-full"
                  id="size"
                  name="size"
                  label="Size"
                  isClearable
                  placeholder="Select Size"
                  isDisabled={sizes.length === 0}
                  options={sizes}
                />

                <ProductSelect
                  id="product_name"
                  name="product_name"
                  label="Product"
                  classes="w-full"
                  searchParams={{
                    companyId: formik.values?.company?.value,
                    head: formik.values.head,
                    potency: formik.values.potency,
                    size: formik.values.size,
                  }}
                />
              </div>

              <div className="label absolute right-5 -bottom-4 bg-gray-300 rounded text-sm flex items-center font-semibold p-1">
                <HiTag size={16} className="mr-1" />
                Add Product
              </div>
            </div>
          );
        }}
      </Formik>

      <FieldArray name="heads">
        {(fieldArrayProps) => {
          const { form } = fieldArrayProps;
          const { values } = form;
          const { heads } = values;
          return (
            <>
              <table className="head-info w-full mb-8 border rounded border-collapse text-sm text-center">
                <thead className="border">
                  <tr className="border p-1">
                    {columns.map((c, i) => (
                      <th className={`p-1 ${c.className}`} key={i}>
                        {c.Header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody
                  className="border overflow-y-auto"
                  style={{
                    height: "100px",
                  }}
                >
                  {values.heads.length > 0 ? (
                    values.heads.map((v: any, index: any) => (
                      <SingleRowUpdateProduct
                        values={v}
                        key={index}
                        id={index}
                        shortItem={shortItem}
                        isInvoiced={
                          values.order_type_sales === ORDER_TYPE_SALES.Invoiced
                        }
                        allValues={values}
                      />
                    ))
                  ) : (
                    <tr className="border p-1">
                      <td className="p-2">
                        {meta.error && <ErrorBox msg={meta.error} />}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {heads.length ? (
                <div className="buttons flex w-full my-12 items-center justify-end gap-x-4">
                  <button
                    className="btn-primary  rounded  w-44 mx-3   focus:bg-sky-700 "
                    // disabled={isUpdating}
                    onClick={() => addGlobalDiscount()}
                    type="button"
                  >
                    {"Add Discounts "}
                  </button>
                </div>
              ) : (
                ""
              )}

              {shortItem ? (
                <div className="w-full flex items-center justify-center mb-10">
                  <button
                    onClick={handleShorting}
                    type="button"
                    className="btn-danger"
                    disabled={
                      (heads &&
                        heads.filter(
                          (i: any) => i.sale_qty + i.free_qty > i.quantity
                        ).length === 0) ||
                      isShorting
                    }
                  >
                    Short All Items
                  </button>
                </div>
              ) : null}
            </>
          );
        }}
      </FieldArray>
    </div>
  );
}

export default UpdateProductForm;
