import { Form, Formik } from "formik";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import useGetCompanies from "../../hooks/useGetCompanies";
import useGetHeads from "../../hooks/useGetHeads";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { AddItem } from "../../types";
import { ERROR, SUCCESS } from "../../types/constants";
import { getHeadId } from "../../utils/helpers";
import CustomSelect from "../FormikComponents/CustomSelect";
import HeadInput from "../FormikComponents/HeadInput";
import Input from "../FormikComponents/Input";
import SingleValueReactSelect from "../FormikComponents/SingleValueReactSelect";
import SubmitBtn from "../FormikComponents/SubmitBtn";

function AddItemModal(props: any) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [potencies, setPotencies] = useState<string[]>([]);
  const [sizes, setSizes] = useState<string[]>([]);
  const { dataCompany, isLoadingCompany } = useGetCompanies();

  const queryClient = useQueryClient();
  const { isLoadingHeads, allHeads, isSuccessHeads } = useGetHeads();

  const dispatch = useDispatch();

  const initialValues: AddItem = {
    company: {
      label: props?.company.name,
      value: props?.companyId || "",
    },
    head: props?.head?.head || "",
    potency: props?.head?.potency || "",
    size: props?.head?.size || "",
    product_name: props?.product_name || "",
  };

  const validationSchema = Yup.object({
    company: Yup.object().required("Company name is required"),
    head: Yup.string().required("Head is required"),
    potency: Yup.string().required("Potency is required"),
    size: Yup.string().required("Size is required"),
    product_name: Yup.string()
      .typeError("Item is not valid")
      .required("Item is required"),
  });

  const handleSubmit = (value: AddItem) => {
    const postData = {
      headId: getHeadId(value.head, value.potency, value.size, allHeads!),
      companyId: value.company.value,
      product_name: value.product_name,
    };

    setIsSubmitting(true);

    axiosInstance
      .patch(`items/${props.item_id}`, postData)
      .then((res) => {
        console.log(res);
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: "Item Updated successfully",
          })
        );
        queryClient.invalidateQueries("getItems");
        setIsSubmitting(false);

        dispatch(hideModal());
      })
      .catch((err) => {
        console.log(err);
        setIsSubmitting(false);

        dispatch(
          addToast({
            kind: ERROR,
            msg: "Unable to Update Item, try again",
          })
        );
      });
  };

  return (
    <div>
      {isLoadingHeads ||
      isLoadingCompany ||
      !isSuccessHeads ||
      allHeads?.length === 0 ? (
        <div
          className="px-6 py-4 mt-2"
          style={{
            minWidth: "360px",
            maxWidth: "760px",
          }}
        >
          Loading....
        </div>
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          <Form
            className="px-6 py-4 mt-2"
            style={{
              minWidth: "400px",
              maxWidth: "760px",
            }}
          >
            <div className="row-1 flex item-center gap-x-6 mb-3">
              <HeadInput
                setSizes={setSizes}
                setPotencies={setPotencies}
                dependencies={{
                  potency: "potency",
                  size: "size",
                }}
                classes="w-full"
                id="head"
                name="head"
                label="Head"
              />
              <CustomSelect
                options={dataCompany || []}
                id="company"
                name="company"
                label="Company"
                classes="w-full"
                isClearable
                isDisabled={dataCompany?.length === 0}
                placeholder="Select Company"
              />
            </div>

            <div className="row-2 flex item-center gap-x-6 mb-3">
              <SingleValueReactSelect
                id="potency"
                name="potency"
                label="Potency"
                isClearable
                isDisabled={potencies.length === 0}
                options={potencies}
                classes="w-full"
                placeholder="Select Potency"
              />

              <SingleValueReactSelect
                id="size"
                name="size"
                label="Size"
                isClearable
                placeholder="Select Size"
                isDisabled={sizes.length === 0}
                options={sizes}
              />
            </div>

            <div className="row-3 flex item-center gap-x-6 mb-3">
              <Input
                label="Item Name"
                id="product_name"
                name="product_name"
                type="text"
              />
            </div>

            <div className="buttons flex items-center w-full justify-center my-4">
              <SubmitBtn
                text={props.item_id ? "Update" : "Save"}
                isSubmitting={isSubmitting || allHeads?.length === 0}
                classes="text-sm h-10  mt-4"
              />
            </div>
          </Form>
        </Formik>
      )}
    </div>
  );
}

export default AddItemModal;
