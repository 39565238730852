import { Form, Formik } from "formik";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";

import * as Yup from "yup";
import { hideModal } from "../../redux/features/modalSlice";
import { FROM } from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";
type discounts = {
  d1: number | "";
  d2: number | "";
  d3: number | "";
  d4: number | "";
  d5: number | "";
};
function AddDiscountsModal({ items, orderId, addDiscounts, from }: any) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const handleClose = () => {
    dispatch(hideModal());
  };
  const initialValues: discounts = {
    d1: "",
    d2: "",
    d3: "",
    d4: "",
    d5: "",
  };
  const validationSchema = Yup.object({
    d1: Yup.number(),
    d2: Yup.number(),
    d3: Yup.number(),
    d4: Yup.number(),
    d5: Yup.number(),
  });

  const handleSubmit = (values: discounts, setSubmitting: Function) => {
    switch (from) {
      case FROM.CREATE_PURCHASE:
        addDiscounts(values);
        setSubmitting(false);
        handleClose();
        break;

      case FROM.CONFIRM_PURCHASE:
        addDiscounts(values);
        setSubmitting(false);
        handleClose();

        break;

      case FROM.CREATE_SALES_ORDER:
        addDiscounts(values);
        setSubmitting(false);
        handleClose();
        break;

      default:
        break;
    }
  };

  return (
    <div className="add-discounts-modal">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values, setSubmitting);
        }}
      >
        {(formik) => (
          <Form className="px-8 py-3 flex items-center gap-x-12">
            <div className="left">
              <div className="row-1 flex items-center gap-x-8 mb-6">
                <Input
                  id="d1"
                  name="d1"
                  label="Dis 1(%)"
                  type="number"
                  optional={true}
                  step="0.1"
                />
                <Input
                  id="d2"
                  name="d2"
                  label="Dis 2(%)"
                  type="number"
                  optional={true}
                  step="0.1"
                />
              </div>
              {!(from === FROM.CREATE_SALES_ORDER) && (
                <>
                  <div className="row-1 flex items-center gap-x-8 mb-6">
                    <Input
                      id="d3"
                      name="d3"
                      label="Dis 3(%)"
                      type="number"
                      optional={true}
                      step="0.1"
                    />
                    <Input
                      id="d4"
                      name="d4"
                      label="Dis 4(%)"
                      type="number"
                      optional={true}
                      step="0.1"
                    />
                  </div>

                  <div className="row-1 flex items-center gap-x-8 mb-6">
                    <Input
                      id="d5"
                      name="d5"
                      label="Dis 5(%)"
                      type="number"
                      optional={true}
                      step="0.1"
                    />
                  </div>
                </>
              )}
              <div className="row-1 flex items-center  justify-center mb-6">
                <SubmitBtn
                  classes="mt-2"
                  text={"Save"}
                  isSubmitting={formik.isSubmitting}
                  shouldNotValidate
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AddDiscountsModal;
