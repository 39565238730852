import { useField, useFormikContext } from "formik";
import { useRef } from "react";
import { HiOutlineRefresh } from "react-icons/hi";
import { useQueryClient } from "react-query";

import CreatableSelect from "react-select/creatable";
import { toast } from "react-toastify";
import useGetItems from "../../../hooks/useGetItems";
import useKeyDown from "../../../hooks/useKeyDown";
import queryKeys from "../../../types/queryKeys";
import { getStartAndEndDate } from "../../../utils/helpers";
import ReactSelectCustomStyle from "../../../utils/reactSelectCustomStyle";
import UtilityBtn from "../../UtilityBtn/UtilityBtn";
import ErrorBox from "../ErrorBox";

interface Props {
  label: string;
  id: string;
  name: string;
  selected?: string;
  inline?: boolean;
  classes?: string;

  searchParams: {
    head: string;
    potency?: string;
    size?: string;
    companyId: string;
  };
  dependencies: {
    head: "head";
    stock: "stock";
    potency: "potency";
    size: "size";
    company: "company";
    estimatedQty: "estimatedQty";
    saleQty: "saleQty";
    gst?: string;
    hsn_number?: string;
  };
}

const ItemsRemoteSelectWithFilter = ({
  label,
  id,
  name,

  searchParams,
  selected,
  classes,
  inline,
  dependencies,
  ...props
}: Props) => {
  const queryClient = useQueryClient();

  const refItem = useRef<any>(null);
  const handlerCtrlI = (e: any) => {
    if (e.ctrlKey) {
      refItem.current.focus();
    }
  };

  useKeyDown("i", handlerCtrlI);

  // props -> every props except label and options -> { name: 'value', id: 'value' }
  const { setFieldValue } = useFormikContext();
  const [field, meta, helpers] = useField(name);

  const { dataItems, isLoadingItems, errorItems, isFetchingItems } =
    useGetItems({
      companyId: searchParams.companyId,
      "$head.head$": searchParams.head,
      "$head.potency$": searchParams.potency,
      "$head.size$": searchParams.size,
      getSales: true,
      start_date: getStartAndEndDate(90).startDate.toISOString(),
      end_date: getStartAndEndDate(90).endDate.toISOString(),
    });

  return (
    <div className={`flex flex-col mb-6 ${classes}`}>
      <div
        className={`form-group flex ${
          inline ? " flex-row items-center" : "flex-col justify-center"
        }`}
      >
        <label
          className={`font-semibold ${inline ? "mr-4 w-28" : "mb-2"}`}
          htmlFor={id}
        >
          {label}
        </label>
        <div className="flex focus:bg-primary">
          <CreatableSelect
            ref={refItem}
            isClearable
            value={field.value}
            defaultValue={{ label: "Select an Item", value: "" }}
            openMenuOnFocus
            onChange={(op) => {
              setFieldValue("headId", "");

              if (!op) {
                helpers.setValue("");
                return;
              }
              const o = dataItems?.find((item) => item.item_id === op.value);
              console.log(o);

              if (o) {
                helpers.setValue({
                  label: o.product_name,
                  value: o.item_id,
                });

                setFieldValue("headId", o.headId);

                setFieldValue(dependencies.head, o.head ? o.head.head : "");

                setFieldValue(dependencies.saleQty, o.salesQuantity);

                setFieldValue(
                  dependencies.company,
                  o.company ? { label: o.company.name, value: o.companyId } : ""
                );

                if (dependencies.gst) {
                  setFieldValue(dependencies.gst, o.head.gst);
                }
                if (dependencies.hsn_number) {
                  setFieldValue(dependencies.hsn_number, o.head.hsn_number);
                }

                setFieldValue(dependencies.stock, o.stock ? o.stock : "");
                setFieldValue(
                  dependencies.estimatedQty,
                  o.stock && o.salesQuantity ? o.stock - o.salesQuantity : 0
                );
                setFieldValue(
                  dependencies.potency,
                  o.head.potency ? o.head.potency : ""
                );
                setFieldValue(
                  dependencies.size,
                  o.head.size ? o.head.size : ""
                );
              } else {
                if (op.__isNew__) {
                  toast.info("Adding a new Item!");
                  helpers.setValue({ label: op.label, value: op.value });
                } else helpers.setValue({ label: "Select an Item", value: "" });

                setFieldValue(dependencies.head, "");
                setFieldValue(dependencies.company, { value: "", label: "" });
                setFieldValue(dependencies.potency, "");
                setFieldValue(dependencies.size, "");
                setFieldValue(dependencies.stock, "");
              }
            }}
            styles={ReactSelectCustomStyle}
            options={dataItems?.map((o) => {
              return {
                label: o.product_name,
                value: o.item_id,
              };
            })}
            placeholder="Select an Item"
            isSearchable={true}
            isLoading={isLoadingItems}
            isDisabled={isLoadingItems && dataItems?.length === 0}
            className={`flex-1 ${
              meta.touched && meta.error
                ? "border border-red-400"
                : "border-coolGray-200 "
            } mr-2`}
          />

          <UtilityBtn
            Icon={HiOutlineRefresh}
            label="Retry"
            iconClasses={isFetchingItems ? "animate-spin" : ""}
            onClick={() => {
              queryClient.invalidateQueries(
                queryKeys.getItemsFromPurchaseOrder
              );
            }}
          />
        </div>
      </div>
      {errorItems ? (
        <ErrorBox msg={"Something went wrong, retry"} />
      ) : (
        meta.touched &&
        meta.error &&
        !isFetchingItems && <ErrorBox msg={meta.error} />
      )}
    </div>
  );
};

export default ItemsRemoteSelectWithFilter;
