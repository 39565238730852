import { QueryKey, useQuery } from "react-query";
import axiosInstance from "../../axiosInstance";
import { SupplierObj } from "../../types";
import queryKeys from "../../types/queryKeys";
type Params = {
  id?: string;
};
function useGetSuppliers(params: Params = {}) {
  const getSuppliers = async () => {
    const response = await axiosInstance.get("/suppliers", { params });

    const { data } = response.data;

    return data;
  };
  const querKeySuppliers = queryKeys.getSuppliers;
  const {
    data: dataSuppliers,
    isLoading: isLoadingSuppliers,
    isFetching: isFetchingSuppliers,
    isSuccess: isSuccessSuppliers,
    error: errorSuppliers,
  } = useQuery<SupplierObj[] | undefined, any, SupplierObj[], QueryKey>(
    [querKeySuppliers, params],
    getSuppliers
  );
  return {
    querKeySuppliers,
    dataSuppliers,
    isLoadingSuppliers,
    isFetchingSuppliers,
    isSuccessSuppliers,
    errorSuppliers,
    optionSuppliers: dataSuppliers?.map((data) => {
      return {
        label: data.name,
        value: data.id!,
      };
    }) || [
      {
        label: "",
        value: "",
      },
    ],
  };
}

export default useGetSuppliers;
