import React, { useEffect } from "react";
import { MdClose } from "react-icons/md";
import { useDispatch } from "react-redux";
import useEscClose from "../../hooks/useEscClose";
import { hideModal } from "../../redux/features/modalSlice";
import { ModalObj } from "../../types";
import {
  ADD_CUSTOMER_MODAL,
  ADD_DISCOUNTS_MODAL,
  ADD_ITEM_MODAL,
  ADD_MR_MODAL,
  ADD_RECEIPT_MODAL,
  ADD_SUPPLIER_MODAL,
  ADD_TO_CREDITORS,
  ADD_TO_DEBTORS,
  ADD_USER_MODAL,
  GET_CONFIRMATION,
  GET_SALE_QTY,
  MODALS,
  REMARK_MODAL,
  REPLACE_SHORTED_ITEMS,
  SHOW_ORDER_NO,
  SHOW_PRODUCT_LIST,
  UPDATE_BANK_ACCOUNT_MODAL,
  UPDATE_CITY_MODAL,
  UPDATE_COMPANY_MODAL,
  UPDATE_CONFIG_DISCOUNT_MODAL,
  UPDATE_HEAD_MODAL,
  UPDATE_ROOM_NO,
  UPDATE_TRANSPORT_MODAL,
} from "../../types/constants";
import AddReceiptForm from "../AddReceiptForm/AddReceiptForm";
import AddCustomerModal from "../ModalComponents/AddCustomerModal";
import AddDiscountsModal from "../ModalComponents/AddDiscountsModal";
import AddMRModal from "../ModalComponents/AddMRModal";
import AddSupplierModal from "../ModalComponents/AddSupplierModal";
import AddToCreditorModal from "../ModalComponents/AddToCreditorModal";
import AddToDebtorsModal from "../ModalComponents/AddToDebtorsModal";
import AddUserModal from "../ModalComponents/AddUserModal";
import GetConfirmationModal from "../ModalComponents/GetConfirmationModal";
import GetSaleQty from "../ModalComponents/GetSaleQty";
import RemarkModal from "../ModalComponents/RemarkModal";
import ReplaceShortedItemsModal from "../ModalComponents/ReplaceShortedItemsModal";
import ShowOrderNoModal from "../ModalComponents/ShowOrderNoModal";
import UpdateBankAccModal from "../ModalComponents/UpdateBankAccModal";
import UpdateCompanyModal from "../ModalComponents/UpdateCompanyModal";
import UpdateHeadModal from "../ModalComponents/UpdateHeadModal";
import AddItemModal from "../ModalComponents/UpdateItemModal";
import UpdateSateCityModal from "../ModalComponents/UpdateSateCityModal";
import UpdateTransportModal from "../ModalComponents/UpdateTransportModal";
import UpdateRoomNoModal from "../ModalComponents/config/UpdateRoomNoModal";
import ShowAllDiscountItemsModal from "../ModalComponents/discount/ShowAllDiscountItemsModal";
import UpdateDiscountConfig from "../ModalComponents/discount/UpdateDiscountConfig";
import ProductListModal from "../ModalComponents/sales/ProductListModal";

interface Props extends ModalObj {}

const Modal = ({
  modalType,
  modalProps,
  modalTitle,
  showCloseBtn,
  bgColor,
  textColor,
}: Props) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hideModal());
  };

  const ref = useEscClose(() => handleClose());

  let Component: React.FC<any> | null = null;

  switch (modalType) {
    case ADD_USER_MODAL:
      Component = AddUserModal;
      break;
    case ADD_SUPPLIER_MODAL:
      Component = AddSupplierModal;
      break;
    case ADD_MR_MODAL:
      Component = AddMRModal;
      break;
    case ADD_CUSTOMER_MODAL:
      Component = AddCustomerModal;
      break;
    case ADD_TO_DEBTORS:
      Component = AddToDebtorsModal;
      break;
    case ADD_TO_CREDITORS:
      Component = AddToCreditorModal;
      break;
    case REPLACE_SHORTED_ITEMS:
      Component = ReplaceShortedItemsModal;
      break;
    case REMARK_MODAL:
      Component = RemarkModal;
      break;
    case UPDATE_CONFIG_DISCOUNT_MODAL:
      Component = UpdateDiscountConfig;
      break;
    case ADD_RECEIPT_MODAL:
      Component = AddReceiptForm;
      break;
    case GET_SALE_QTY:
      Component = GetSaleQty;
      break;
    case GET_CONFIRMATION:
      Component = GetConfirmationModal;
      break;
    case SHOW_ORDER_NO:
      Component = ShowOrderNoModal;
      break;
    case UPDATE_BANK_ACCOUNT_MODAL:
      Component = UpdateBankAccModal;
      break;
    case UPDATE_TRANSPORT_MODAL:
      Component = UpdateTransportModal;
      break;
    case UPDATE_COMPANY_MODAL:
      Component = UpdateCompanyModal;
      break;
    case ADD_DISCOUNTS_MODAL:
      Component = AddDiscountsModal;
      break;
    case ADD_ITEM_MODAL:
      Component = AddItemModal;
      break;
    case UPDATE_CITY_MODAL:
      Component = UpdateSateCityModal;
      break;
    case UPDATE_HEAD_MODAL:
      Component = UpdateHeadModal;
      break;
    case SHOW_PRODUCT_LIST:
      Component = ProductListModal;
      break;
    case UPDATE_ROOM_NO:
      Component = UpdateRoomNoModal;
      break;
    case MODALS.showAllDiscountItems:
      Component = ShowAllDiscountItemsModal;
      break;

    default:
      Component = null;
  }

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflowX = "hidden";
      document.body.style.overflowY = "auto";
    };
  }, []);

  return (
    <div
      className="backdrop fixed top-0 bottom-0 left-0 right-0 z-40 flex items-center justify-center w-full h-full overflow-y-auto overflow-x-auto m-auto bg-black bg-opacity-60"
      style={{
        maxHeight: "100vh",
      }}
    >
      <div className="absolute top-16 flex flex-col items-center">
        <div
          className={`relative rounded modal flex flex-col`}
          style={{
            maxWidth: "90rem",
            background: bgColor ? bgColor : "#ffffff",
            color: textColor ? textColor : "inherit",
          }}
          ref={ref}
        >
          {showCloseBtn === true && (
            <button
              onClick={handleClose}
              type="button"
              className="modal__close-btn absolute right-2 z-10 top-3"
            >
              <MdClose color={textColor ? textColor : "inherit"} size={21} />
            </button>
          )}
          {modalTitle && (
            <div className="modal__title">
              <h3 className="text-2xl font-semibold  px-2 py-4 pl-6">
                {modalTitle}
              </h3>
            </div>
          )}

          <div className={`w-full z-50 ${showCloseBtn ? "mr-4" : ""}`}>
            {/* desctucturing undefinded value inside object, it will ignore */}

            {Component !== null && (
              <Component handleClose={handleClose} {...modalProps} />
            )}
          </div>
        </div>
        <div className="empty-space pb-14"></div>
      </div>
    </div>
  );
};

export default Modal;
