import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { HiTag, HiTrash } from "react-icons/hi";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import CustomSelect from "../../components/FormikComponents/CustomSelect";
import CustomSubmitBtn from "../../components/FormikComponents/CustomSubmitBtn";
import HeadInput from "../../components/FormikComponents/HeadInput";
import Input from "../../components/FormikComponents/Input";
import SingleValueReactSelect from "../../components/FormikComponents/SingleValueReactSelect";
import TableInstance from "../../components/Table/TableInstance";
import useGetCompanies from "../../hooks/useGetCompanies";
import useGetHeads from "../../hooks/useGetHeads";
import useGetItems from "../../hooks/useGetItems";
import { showModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { AddItem } from "../../types";
import {
  ADD_ITEM_MODAL,
  ERROR,
  FROM,
  GET_CONFIRMATION,
  SUCCESS,
} from "../../types/constants";
import { getHeadId, localestringToDate } from "../../utils/helpers";

function ItemConfiguration() {
  const dispatch = useDispatch();
  const [cPageSize, cSetPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [cSortBy, cSetSortBy] = useState("product_name");
  const [desc, setDesc] = useState(false);
  const [q, setQ] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const queryClient = useQueryClient();
  const [potencies, setPotencies] = useState<string[]>([]);
  const [sizes, setSizes] = useState<string[]>([]);
  const [selectedItemList, setSelectedItemList] = useState<AddItem[]>([]);
  const [searchParmas, setSearchParmas] = useState({
    "$head.head$": "",
    "$head.potency$": "",
    "$head.size$": "",
    "$head.hsn_number$": "",
    "$head.gst$": "",
    companyId: "",
    product_name: "",
  });

  const remove = (index: number, data: AddItem[]): void => {
    let items = data;
    items.splice(index, 1);
    setSelectedItemList([...items]);
  };

  const CONFIGURATION_TRANSPORT_COLUMNS = [
    // {
    //   Header: "Sr. No.",
    //   accessor: (_row: any, index: number) => index + 1,
    // },
    {
      Header: "Head",
      accessor: "head.head",
    },
    {
      Header: "Company",
      accessor: "company.name",
    },
    {
      Header: "Pot.",
      accessor: "head.potency",
    },
    {
      Header: "Size",
      accessor: "head.size",
    },
    {
      Header: "Item Name",
      accessor: "product_name",
    },
    {
      Header: "HSN Code",
      accessor: "head.hsn_number",
    },
    {
      Header: "GST %",
      accessor: "head.gst",
    },
    {
      Header: "Created At",
      accessor: "createdAt",
      Cell: ({ value }: any) => {
        return localestringToDate(value);
      },
    },
    {
      Header: "Last Update",
      accessor: "updatedAt",
      Cell: ({ value }: any) => {
        return localestringToDate(value);
      },
    },
    {
      Header: "",
      accessor: "editBtn",
      Cell: ({ row }: any) => {
        return (
          <div className="btns flex items-center justify-center gap-4">
            <button
              className="btn-primary flex items-center"
              onClick={() => {
                dispatch(
                  showModal({
                    modalType: ADD_ITEM_MODAL,
                    modalTitle: "Update Item",
                    modalProps: row.original,
                  })
                );
              }}
            >
              <span className="mr-1"> Edit</span>
            </button>
            <button
              className="btn-danger flex items-center"
              onClick={() => {
                dispatch(
                  showModal({
                    modalType: GET_CONFIRMATION,
                    modalTitle: "Delete Item",
                    modalProps: {
                      id: row.original.item_id,
                      from: FROM.ITEM_CONFIG,
                      value: "Are so sure you want to drop the item?",
                    },
                  })
                );
              }}
            >
              <span className=""> Delete</span>
            </button>
          </div>
        );
      },
    },
  ];

  const SELECTED_ITEMS_COLS = [
    {
      Header: "Name",
      accessor: "product_name",
    },
    {
      Header: "Company",
      accessor: "company.label",
    },
    {
      Header: "Head",
      accessor: "head",
    },
    {
      Header: "Potency",
      accessor: "potency",
    },
    {
      Header: "Size",
      accessor: "size",
    },

    {
      Header: "",
      accessor: "btn",
      Cell: ({ row, data }: any) => {
        return (
          <button
            type="button"
            className=" p-2 rounded-sm bg-red-500 text-white mb-6  focus:bg-red-700 "
            onClick={() => {
              remove(row.index, data);
            }}
          >
            <HiTrash />
          </button>
        );
      },
    },
  ];

  let component = null;
  const { dataItems, errorItems, isLoadingItems, isFetchingItems } =
    useGetItems(searchParmas);
  if (errorItems) {
    component = (
      <p className="mt-6 ml-4 text-center">
        An error has occurred: {errorItems.message}
      </p>
    );
  } else if (isLoadingItems) {
    component = <p className="mt-6 ml-4 text-center">Loading...</p>;
  } else {
    component = (
      <TableInstance
        tableData={dataItems || []}
        columnName={CONFIGURATION_TRANSPORT_COLUMNS}
        cPageSize={cPageSize}
        cSetPageSize={cSetPageSize}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageCount={-1} // do calculation here
        cSortBy={cSortBy}
        cSetSortBy={cSetSortBy}
        desc={desc}
        setDesc={setDesc}
        q={q}
        setQ={setQ}
        setSelectedRows={setSelectedRows}
      />
    );
  }

  const { dataCompany } = useGetCompanies();

  const { allHeads } = useGetHeads();

  const initialValues: AddItem = {
    company: {
      label: "",
      value: "",
    },
    head: "",
    potency: "",
    size: "",
    product_name: "",
  };

  const validationSchema = Yup.object({
    company: Yup.object().required("Company name is required"),
    head: Yup.string().required("Head is required"),
    potency: Yup.string().required("Potency is required"),
    size: Yup.string().required("Size is required"),
    product_name: Yup.string()
      .typeError("Item is not valid")
      .required("Item is required"),
  });

  const handleAddItem = (values: AddItem, setFieldValue: Function) => {
    setFieldValue("product_name", "");
    setSelectedItemList([values, ...selectedItemList]);
  };

  const handleSubmit = useCallback(
    (resetForm: Function) => {
      const postData = {
        data: selectedItemList.map((v) => {
          return {
            headId: getHeadId(v.head, v.potency, v.size, allHeads!),
            companyId: v.company.value,
            product_name: v.product_name,
          };
        }),
      };

      setIsSubmitting(true);

      axiosInstance
        .post("items", postData)
        .then((res) => {
          console.log(res.data.msg);
          dispatch(
            addToast({
              kind: SUCCESS,
              msg: "Items added successfully",
            })
          );
          queryClient.invalidateQueries("getItems");
          setSelectedItemList([]);
          resetForm();
          setIsSubmitting(false);
        })
        .catch((err) => {
          console.log(err);
          setIsSubmitting(false);
          const msg = err?.response?.data?.msg;

          dispatch(
            addToast({
              kind: ERROR,
              msg: msg || "Unable to add Items, try again",
            })
          );
        });
    },
    [selectedItemList.length, getHeadId, allHeads]
  );

  const searchItems = (values: any) => {
    console.log(values);
    setSearchParmas({
      "$head.head$": values.head,
      "$head.potency$": values.potency,
      "$head.size$": values.size,
      "$head.gst$": values.gst,
      "$head.hsn_number$": values.hsn_number,
      product_name: values.product_name,
      companyId: values.company?.value,
    });
  };

  const initialValuesSearch: any = {
    head: "",
    company: "",
    potency: "",
    size: "",
    hsn_number: "",
    gst: "",
    product_name: "",
  };

  const validationSchemaSearch = Yup.object({
    head: Yup.string(),
    company: Yup.object().nullable(),
    potency: Yup.string(),
    size: Yup.string(),
    product_name: Yup.string(),
    hsn_number: Yup.string(),
    gst: Yup.string(),
  });

  return (
    <div className="item-configuration">
      <div className="item-configuration-column mt-12 head-form flex flex-col justify-center px-4 py-4 rounded border border-gray-300 gap-x-4 relative mb-14">
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setFieldValue }) =>
              handleAddItem(values, setFieldValue)
            }
            enableReinitialize
          >
            {({ values, setFieldValue, resetForm }) => (
              <Form className="px-6 py-4 mt-2">
                <div className="row-1 flex item-center gap-x-6 mb-3">
                  <HeadInput
                    setSizes={setSizes}
                    setPotencies={setPotencies}
                    dependencies={{
                      potency: "potency",
                      size: "size",
                    }}
                    classes="w-full"
                    id="head"
                    name="head"
                    label="Head"
                  />
                  <CustomSelect
                    options={dataCompany || []}
                    id="company"
                    name="company"
                    label="Company"
                    classes="w-full"
                    isClearable
                    isDisabled={dataCompany?.length === 0}
                    placeholder="Select Company"
                  />
                </div>

                <div className="row-2 flex items-center gap-x-6 mb-3  ">
                  <SingleValueReactSelect
                    id="potency"
                    name="potency"
                    label="Potency"
                    isClearable
                    isDisabled={potencies.length === 0}
                    options={potencies}
                    classes="w-full"
                    placeholder="Select Potency"
                  />

                  <SingleValueReactSelect
                    classes="w-full"
                    id="size"
                    name="size"
                    label="Size"
                    isClearable
                    placeholder="Select Size"
                    isDisabled={sizes.length === 0}
                    options={sizes}
                  />
                  <Input
                    label="Item Name"
                    id="product_name"
                    name="product_name"
                    type="text"
                  />

                  <CustomSubmitBtn
                    text="Add"
                    enterToFocus
                    onSubmit={() => handleAddItem(values, setFieldValue)}
                  />
                </div>

                <div className="row-3 flex item-center gap-x-6 mb-3"></div>
                {selectedItemList.length > 0 && (
                  <>
                    <TableInstance
                      tableData={selectedItemList || []}
                      columnName={SELECTED_ITEMS_COLS}
                      cPageSize={cPageSize}
                      cSetPageSize={cSetPageSize}
                      pageIndex={pageIndex}
                      setPageIndex={setPageIndex}
                      pageCount={-1} // do calculation here
                      cSortBy={""}
                      cSetSortBy={cSetSortBy}
                      desc={desc}
                      setDesc={setDesc}
                      q={q}
                      setQ={setQ}
                      setSelectedRows={setSelectedRows}
                    />
                    <div className=" flex justify-center w-full">
                      <button
                        className="btn-primary my-8 w-full max-w-md "
                        disabled={
                          !selectedItemList.length ||
                          isSubmitting ||
                          allHeads?.length === 0
                        }
                        onClick={() => handleSubmit(resetForm)}
                      >
                        Save
                      </button>
                    </div>
                  </>
                )}
              </Form>
            )}
          </Formik>
        </div>

        <div className="label absolute right-5 -bottom-4 bg-gray-300 rounded text-sm flex items-center font-semibold p-1">
          <HiTag size={16} className="mr-1" />
          Add Items
        </div>
      </div>
      <div className="inventory-filter flex flex-col justify-center px-4 py-4 rounded border border-gray-300 gap-x-4 relative mb-14">
        <Formik
          initialValues={initialValuesSearch}
          validationSchema={validationSchemaSearch}
          onSubmit={(values) => searchItems(values)}
        >
          {(formik) => (
            <>
              <div className="row-1 flex items-center mb-2 gap-x-4">
                <HeadInput
                  setSizes={setSizes}
                  setPotencies={setPotencies}
                  dependencies={{
                    potency: "potency",
                    size: "size",
                  }}
                  classes="w-full"
                  id="head"
                  name="head"
                  label="Head"
                />

                <SingleValueReactSelect
                  id="potency"
                  name="potency"
                  label="Potency"
                  isClearable
                  isDisabled={potencies.length === 0}
                  options={potencies}
                  classes="w-full"
                  placeholder="Select Potency"
                />

                <SingleValueReactSelect
                  classes="w-full"
                  id="size"
                  name="size"
                  label="Size"
                  isClearable
                  placeholder="Select Size"
                  isDisabled={sizes.length === 0}
                  options={sizes}
                />
                <CustomSelect
                  options={dataCompany || []}
                  id="company"
                  name="company"
                  label="Company"
                  classes="w-full"
                  isClearable
                  isDisabled={dataCompany?.length === 0}
                  placeholder="Select Company"
                />
                <Input
                  id="product_name"
                  name="product_name"
                  label="Item Name"
                  type="text"
                />
                <Input
                  id="gst"
                  name="gst"
                  label="GST"
                  type="number"
                  step="0.1"
                />
                <Input
                  id="hsn_number"
                  name="hsn_number"
                  label="HSN Number"
                  type="text"
                />

                <CustomSubmitBtn
                  text="Search"
                  classes="w-44 my-3"
                  disabled={isLoadingItems || isFetchingItems}
                  onSubmit={(e) => {
                    e.preventDefault();

                    formik.handleSubmit();
                  }}
                />
              </div>

              <div className="label absolute right-5 -bottom-4 bg-gray-300 rounded text-sm flex items-center font-semibold p-1">
                <HiTag size={16} className="mr-1" />
                Filter Items
              </div>
            </>
          )}
        </Formik>
      </div>
      {component !== null && component}
    </div>
  );
}

export default ItemConfiguration;
