import { Formik } from "formik";
import { useState } from "react";
import { HiTag } from "react-icons/hi";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import CustomSelect from "../../components/FormikComponents/CustomSelect";
import CustomSubmitBtn from "../../components/FormikComponents/CustomSubmitBtn";
import HeadInput from "../../components/FormikComponents/HeadInput";
import Input from "../../components/FormikComponents/Input";
import ItemsRemoteSelectWithFilter from "../../components/FormikComponents/purchase/ItemsRemoteSelectWithFilter";
import SingleValueReactSelect from "../../components/FormikComponents/SingleValueReactSelect";
import useAddCategoryWiseDiscount from "../../hooks/discounts/categoryWise/useAddCategoryWiseDiscount";
import useAddCustomerSpecificDiscount from "../../hooks/discounts/customerWise/useAddCustomerSpecificDiscount";
import useGetCompanies from "../../hooks/useGetCompanies";
import { Option } from "../../types";

type formValues = {
  head: string;
  company?: Option;
  potency?: string;
  size?: string;
  item_name?: Option;
  discount?: number;
};

type Props = {
  isCustomerWise?: boolean;
};

function AddDiscountToCustomerForm({ isCustomerWise }: Props) {
  const params = useParams();

  const { dataCompany } = useGetCompanies();
  const [potencies, setPotencies] = useState<string[]>([]);
  const [sizes, setSizes] = useState<string[]>([]);

  const { mutate, isLoading: isSubmitting } = useAddCustomerSpecificDiscount();
  const { mutate: addToCategory, isLoading: isSubmittingToCategory } =
    useAddCategoryWiseDiscount();

  const handleSubmit = (value: formValues, resetForm: any) => {
    if (isCustomerWise) {
      mutate(
        {
          customerId: params.customerId!,
          value: value.discount!,
          itemItemId: value.item_name?.value,
          companyId: value.company?.value,
          head: value.head,
          size: value.size,
          potency: value.potency,
        },
        {
          onSuccess() {
            resetForm();
          },
        }
      );
    } else {
      addToCategory(
        {
          categoryId: params.categoryId!,
          value: value.discount!,
          itemItemId: value.item_name?.value,
          companyId: value.company?.value,
          head: value.head,
          size: value.size,
          potency: value.potency,
        },
        {
          onSuccess() {
            resetForm();
          },
        }
      );
    }
    // focusHead();
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
      >
        {(formik) => {
          return (
            <>
              <div className="head-form flex flex-col justify-center px-4 py-4 rounded border border-gray-300 gap-x-4 relative mb-14">
                <div className="row-1 flex items-center mb-2 gap-x-4">
                  <HeadInput
                    setSizes={setSizes}
                    setPotencies={setPotencies}
                    dependencies={{
                      potency: "potency",
                      size: "size",
                      item: "item_name",
                    }}
                    classes="w-full"
                    id="head"
                    name="head"
                    label="Head"
                  />
                  <CustomSelect
                    options={dataCompany || []}
                    id="company"
                    name="company"
                    label="Company"
                    classes="w-full"
                    isClearable
                    isDisabled={dataCompany?.length === 0}
                    placeholder="Select Company"
                  />

                  <SingleValueReactSelect
                    classes="w-full"
                    id="potency"
                    name="potency"
                    label="Potency"
                    options={potencies}
                    isOptional
                    isClearable
                  />
                  <SingleValueReactSelect
                    classes="w-full"
                    id="size"
                    name="size"
                    label="Size"
                    options={sizes}
                    isOptional
                    isClearable
                  />
                </div>

                <div className="row-2 flex items-center gap-x-4">
                  <ItemsRemoteSelectWithFilter
                    id="item_name"
                    name="item_name"
                    label="Item Name"
                    classes="w-full"
                    searchParams={{
                      companyId: formik.values?.company?.value!,
                      head: formik.values.head,
                      potency: formik.values.potency,
                      size: formik.values.size,
                    }}
                    dependencies={{
                      head: "head",
                      stock: "stock",
                      potency: "potency",
                      size: "size",
                      company: "company",
                      estimatedQty: "estimatedQty",
                      saleQty: "saleQty",
                      gst: "gst",
                      hsn_number: "hsn_number",
                    }}
                  />
                  <Input
                    id="discount"
                    name="discount"
                    label="Discount (%)"
                    type="number"
                    step="0.1"
                  />
                </div>

                <div className="buttons flex w-full items-center justify-center">
                  <CustomSubmitBtn
                    text={"Add"}
                    containerClasses="w-44 my-3"
                    enterToFocus
                    disabled={isSubmitting || isSubmittingToCategory}
                    onSubmit={(e) => {
                      formik.handleSubmit();
                    }}
                  />
                  <button
                    className="btn-danger  rounded  w-44 mx-3 focus:bg-red-700"
                    onClick={() => formik.resetForm()}
                    type="button"
                  >
                    Reset
                  </button>
                </div>

                <div className="label absolute right-5 -bottom-4 bg-gray-300 rounded text-sm flex items-center font-semibold p-1">
                  <HiTag size={16} className="mr-1" />
                  Discount Items
                </div>
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
}

export default AddDiscountToCustomerForm;

const initialValues: any = {
  head: "",
  company: "",
  potency: "",
  size: "",
  item_name: "",
  discount: "",
};

const validationSchema = Yup.object().shape(
  {
    head: Yup.string().when("company", {
      is: (g: Option) => !g?.value,
      then: Yup.string().required("Head is required"),
    }),
    company: Yup.object().when("head", {
      is: (h: string) => !h,
      then: Yup.object()
        .required("Company is required")
        .typeError("Company is required"),
      otherwise: Yup.object().nullable(),
    }),

    potency: Yup.string(),
    size: Yup.string(),
    item_name: Yup.object()
      .shape({
        // label: Yup.string().required(),
        value: Yup.string(),
      })
      .nullable(),

    discount: Yup.number()
      .positive()
      .required("Discount is Required")
      .min(0)
      .max(100),
  },
  [["head", "company"]]
);
