function focusHead() {
  document.dispatchEvent(
    new KeyboardEvent("keydown", {
      key: "h",
      ctrlKey: true,
      keyCode: 72,
    })
  );
}

export default focusHead;
