import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../axiosInstance";
import { RootState } from "../../redux/app";
import { logoutUser, setCurrentUser } from "../../redux/features/authSlice";
import { UserObj } from "../../types";
import { USER_ROLES } from "../../types/constants";
import AccountsLayout from "./AccountsLayout";
import AdminLayout from "./AdminLayout";
import FinanceLayout from "./FinanceLayout";
import MarketingLayout from "./MarketingLayout";
import SalesLayout from "./SalesLayout";

// deciding layout
const MainLayout = () => {
  const dispatch = useDispatch();

  const { user } = useSelector((state: RootState) => state.auth);

  // get current user info
  const getCurrentUser = async () => {
    const response = await axiosInstance.get(`/user`);
    const { data } = response.data;

    return data;
  };

  const { data, error } = useQuery<UserObj | undefined, any, UserObj, string[]>(
    ["getCurrentUser"],
    getCurrentUser
  );

  useEffect(() => {
    if (data) {
      dispatch(
        setCurrentUser({
          id: data.id,
          name: data.name,
          email: data.email,
          profile: data.profile,
          role: data.role.toUpperCase(),
          status: data.status,
        })
      );
    }
  }, [data]);

  if (user) {
    if (user.role === USER_ROLES.ADMIN) {
      return <AdminLayout />;
    } else if (user.role === USER_ROLES.FINANCE) {
      return <FinanceLayout />;
    } else if (user.role === USER_ROLES.MARKETING) {
      return <MarketingLayout />;
    } else if (user.role === USER_ROLES.SALES) {
      return <SalesLayout />;
    } else if (user.role === USER_ROLES.ACCOUNT) {
      return <AccountsLayout />;
    }
  }

  return (
    <div className="w-screen h-screen flex justify-center items-center">
      {/* {error ? "Something went wrong, please refresh the screen" : "Loading..."} */}
      {"Loading..."}
    </div>
  );
};

export default MainLayout;
