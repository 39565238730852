import { useQuery } from "react-query";
import axiosInstance from "../../axiosInstance";
import queryKeys from "../../types/queryKeys";
import {
  calculateTaxableAmount,
  convertDateToStringForForm,
} from "../../utils/helpers";

interface OrderObj {
  fullName: string;
  place: string;
  pincode: string;
  profession: string;
  contactNo: string;
  gstin: string;
  address: string;
  transport: string;
  heads: any[];
  total: number;
  sp_discount: string;
  // gst: string;
  gst_amount: number;
  netTotal: string;
  purchaseDate: string;
  orderBy: string;
  bal: string;
  cartons: string;
  gstType: string;
  date: string;
  remarks: string;
  discount: number;
  sale_qty: number;
  free_qty: number;
}

function useGetItemsFromSalesOrder(orderId: string) {
  const getItemsFromSalesOrder = async ({ queryKey }: any) => {
    const response = await axiosInstance.get(`/sales/${queryKey[1]}/items`);

    const { data } = response.data;

    const modified = data?.map((item: any) => {
      const taxable_amount = calculateTaxableAmount(
        item.product.mrp,
        item.sale_qty,
        { d1: item.d1, d2: item.d2 }
      );
      const gst_amount = (
        (Number(taxable_amount) * Number(item.product.head.gst)) /
        100
      ).toFixed(2);
      return {
        ...item,
        head: item.product.head,

        id: item.id,
        // head: item.head.head,
        // company: { value: item.company.id, label: item.company.name },
        // potency: item.head.potency,
        // size: item.head.size,
        product_name: {
          label: item.product.product_name,
          value: item.product.product_id,
        },
        company: {
          label: item.product.company.name,
          value: item.product.companyId,
        },
        batch_number: item.product.batch_number,
        quantity: item.product.quantity,
        mrp: item.product.mrp,
        mfg_date: convertDateToStringForForm(item.product.mfg_date),
        exp_date: convertDateToStringForForm(item.product.exp_date),
        d1: item.d1,
        d2: item.d2,
        orderId: item.orderId,
        taxable_amount,
        gst_amount,
        total: Number(Number(taxable_amount) + Number(gst_amount)).toFixed(2),
        sale_qty: item.sale_qty,
      };
    });

    return modified;
  };
  return useQuery<OrderObj[], any, OrderObj[], string[]>(
    [queryKeys.getItemsFromSalesOrder, orderId],
    getItemsFromSalesOrder
  );
}

export default useGetItemsFromSalesOrder;
