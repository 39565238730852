import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import queryKeys from "../../types/queryKeys";

function useDropProductFromSalesOrder() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const handleMutation = (id: string) => {
    return axiosInstance.delete(`/sales/items/${id}`);
  };

  return useMutation(handleMutation, {
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries(queryKeys.getItemsFromSalesOrder);
      dispatch(
        addToast({
          kind: SUCCESS,
          msg: "Product Dropped Successfully",
        })
      );
    },
    onError(data, variables, context) {
      dispatch(
        addToast({
          kind: ERROR,
          msg: "Unable to  Drop Product",
        })
      );
    },
  });
}

export default useDropProductFromSalesOrder;
