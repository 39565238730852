import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import AccountsOrdersNav from "../AccountsOrdersNav/AccountsOrdersNav";

const AdminAccountsOrdersLayout = () => {
  return (
    <div className="admin-accounts-orders-layout">
      <nav className="mb-10">
        <ul className="flex items-center gap-x-6">
          <AccountsOrdersNav
            path="/accounts/orders/pending-orders"
            end={true}
            text="Pending Orders"
            bgColor="bg-orange-400"
            textColor="text-orange-400"
          />
          <AccountsOrdersNav
            path="/accounts/orders/approved-orders"
            end={true}
            text="Approved Orders"
            bgColor="bg-green-500"
            textColor="text-green-500"
          />
          <AccountsOrdersNav
            path="/accounts/orders/declined-orders"
            end={true}
            text="Declined Orders"
            bgColor="bg-red-500"
            textColor="text-red-500"
          />
        </ul>
      </nav>

      <Outlet />
    </div>
  );
};

export default AdminAccountsOrdersLayout;
