import { Form, Formik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import CustomerInput from "../../components/FormikComponents/CustomerInput";
import HeadInput from "../../components/FormikComponents/HeadInput";
import Input from "../../components/FormikComponents/Input";
import SubmitBtn from "../../components/FormikComponents/SubmitBtn";
import TableInstance from "../../components/Table/TableInstance";
import useGetCompanies from "../../hooks/useGetCompanies";
import useGetHeads from "../../hooks/useGetHeads";
import { getHeadId, localestringToDate } from "../../utils/helpers";

import CustomSelect from "../../components/FormikComponents/CustomSelect";
import SingleValueReactSelect from "../../components/FormikComponents/SingleValueReactSelect";
import useGetShortItems from "../../hooks/Inventory/shortItems/useGetShortItems";

function OutOfStockItemsReport() {
  const [cPageSize, cSetPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [cSortBy, cSetSortBy] = useState(null);
  const [desc, setDesc] = useState(true);
  const [q, setQ] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [potencies, setPotencies] = useState<string[]>([]);
  const [sizes, setSizes] = useState<string[]>([]);

  const OUT_OF_STOCK_REPORT_COLUMNS = [
    {
      Header: "Sr",
      accessor: (_row: any, i: number) => i + 1,
    },
    {
      Header: "Item Name",
      accessor: "product.product_name",
    },
    {
      Header: "Head",
      accessor: "product.head.head",
    },

    {
      Header: "Potency",
      accessor: "product.head.potency",
    },
    {
      Header: "Size",
      accessor: "product.head.size",
    },
    {
      Header: "Company",
      accessor: "product.company.name",
    },
    {
      Header: "Quantity",
      accessor: "quantity",
    },
    {
      Header: "Customer",
      accessor: "customer.name",
    },
    {
      Header: "Date",
      accessor: "updatedAt",
      Cell: ({ value }: any) => localestringToDate(value),
    },
  ];

  const initialValues = {
    customer: "",
    company: "",
    head: "",
    potency: "",
    size: "",
    fromDate: "",
    toDate: "",
  };

  const validationSchema = Yup.object({
    company: Yup.object().required("Company is required"),
    customer: Yup.string().required("Customer is Required"),
    head: Yup.string().required("Head is Required"),
    potency: Yup.string().required("Potency is required"),
    size: Yup.string().required("Size is required"),
    fromDate: Yup.date()
      .required("From date is required")
      .max(new Date(), "From date cannot be a future date"),
    toDate: Yup.date()
      .required("To date is required")
      .min(Yup.ref("fromDate"), "To date must be after From date")
      .max(new Date(), "To date cannot be a future date"),
  });

  const { dataCompany } = useGetCompanies();

  const { allHeads } = useGetHeads();

  const { data: dataShortItems } = useGetShortItems();

  const handleSubmit = (values: any, resetForm: any) => {
    const postData = {
      customer: values.customer,
      company: values.company,
      head: getHeadId(values.head, values.potency, values.size, allHeads as []),
      fromDate: new Date(values.fromDate).toISOString(),
      toDate: new Date(values.toDate).toISOString(),
    };
    console.log("values", postData);
    resetForm();
  };

  return (
    <div className="sales">
      <header className="flex items-center justify-between mb-8"></header>

      <div className="sold-stock flex flex-col justify-center px-4 py-4 rounded border border-gray-300 gap-x-4 relative mb-14">
        {/* <div className="search-box mr-4">
        <SearchBox
          searchValue={q}
          setSearchValue={setQ}
          handleSearch={() => {}}
        />
      </div> */}
        <div className="filters">
          {" "}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) =>
              handleSubmit(values, resetForm)
            }
          >
            <Form className="">
              <div className="row-1 flex items-center mb-2 gap-x-4">
                <CustomerInput
                  label="Customer"
                  name="customer"
                  id="customer"
                  dependencies={{
                    bal: "bal",
                  }}
                />
                <CustomSelect
                  options={dataCompany || []}
                  id="company"
                  name="company"
                  label="Company"
                  classes="w-full"
                  isClearable
                  isDisabled={dataCompany?.length === 0}
                  placeholder="Select Company"
                />
                <HeadInput
                  setSizes={setSizes}
                  setPotencies={setPotencies}
                  dependencies={{
                    potency: "potency",
                    size: "size",
                    hsn_number: "hsn_number",
                    gst: "gst",
                    stock: "stock",
                  }}
                  classes="w-full"
                  id="head"
                  name="head"
                  label="Head"
                />
                <SingleValueReactSelect
                  id="potency"
                  name="potency"
                  label="Potency"
                  isClearable
                  isDisabled={potencies.length === 0}
                  options={potencies}
                  classes="w-full"
                  placeholder="Select Potency"
                />

                <SingleValueReactSelect
                  classes="w-full"
                  id="size"
                  name="size"
                  label="Size"
                  isClearable
                  placeholder="Select Size"
                  isDisabled={sizes.length === 0}
                  options={sizes}
                />
                <div className="from flex items-center  mr-2">
                  <Input
                    label="From"
                    name="fromDate"
                    id="fromDate"
                    type="date"
                  />
                </div>
                <div className="to  ">
                  <Input label="To" name="toDate" id="toDate" type="date" />
                </div>
                <div className="buttons flex items-center justify-center">
                  <SubmitBtn
                    text={"Search"}
                    isSubmitting={isSubmitting}
                    classes="font-medium "
                    isSubmittingClasses="font-medium w-24  h-10 !mb-0"
                  />
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      </div>

      <TableInstance
        tableData={dataShortItems || []}
        columnName={OUT_OF_STOCK_REPORT_COLUMNS}
        cPageSize={cPageSize}
        cSetPageSize={cSetPageSize}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageCount={-1} // do calculation here
        cSortBy={cSortBy}
        cSetSortBy={cSetSortBy}
        desc={desc}
        setDesc={setDesc}
        q={q}
        setQ={setQ}
        setSelectedRows={setSelectedRows}
        selectRow={false}
      />
    </div>
  );
}

export default OutOfStockItemsReport;
