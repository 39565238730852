import { useField, useFormikContext } from "formik";
import { useEffect } from "react";
import { SalesOrderObj } from "../../../types/sales.types";
import { numberToIndianSystem } from "../../../utils/helpers";
import { calTotalTaxAbleAndGSTAmount } from "../../../utils/salesHelper";
import ErrorBox from "../../FormikComponents/ErrorBox";

interface Props {
  fieldName: string;
}

const ViewProductTable = ({ fieldName }: Props) => {
  const [field, meta] = useField(fieldName);

  const { setFieldValue, values } = useFormikContext<SalesOrderObj>();

  useEffect(() => {
    calTotalTaxAbleAndGSTAmount(
      field.value,
      setFieldValue,
      values.order_type_sales
    );
  }, [field.value, values.order_type_sales]);

  useEffect(() => {
    const { taxable_amount, sp_discount, sales_gst_amount } = values;

    setFieldValue(
      "total",
      (
        Number(taxable_amount) -
        Number(sp_discount) +
        Number(sales_gst_amount)
      ).toFixed(2)
    );
  }, [values.taxable_amount, values.total, values.sp_discount]);

  const columns: {
    Header: string;
    className?: string;
  }[] = [
    { Header: "S No" },
    { Header: "Name" },
    { Header: "Batch No." },
    { Header: "Stock" },
    { Header: "Sale Quantity" },
    { Header: "Free Qty" },
    { Header: "MRP" },
    { Header: "EXP" },
    { Header: "Dis 1(%)" },
    { Header: "Dis 2(%)" },
    { Header: "Taxable Amt." },
    { Header: "GST %" },
    { Header: "GST ₹" },
    { Header: "Total" },
  ];

  return (
    <div>
      <table className="head-info w-full mb-8 border rounded border-collapse text-sm text-center">
        <thead className="border">
          <tr className="border p-1">
            {columns.map((c) => (
              <th className="p-1">{c.Header}</th>
            ))}
          </tr>
        </thead>
        <tbody
          className="border overflow-y-auto"
          style={{
            height: "100px",
          }}
        >
          {/* {console.log("Field", field.value)} */}
          {field.value && field.value.length > 0 ? (
            field.value.map((v: any, index: any) => (
              <tr key={v.product_name.value} className="border p-1">
                <td className="p-1">{index + 1 + "."}</td>
                <td className="p-1">{v.product_name.label}</td>
                <td className="p-1">{v.batch_number}</td>
                <td className="p-1">{v.quantity}</td>
                <td className="p-1">{v.sale_qty}</td>
                <td className="p-1">{v.free_qty}</td>
                <td className="p-1">{v.mrp}</td>
                {/* <td className="p-1">{v.mfg_date}</td> */}
                <td className="p-1">{v.exp_date}</td>
                <td className="p-1">{v.d1}</td>
                <td className="p-1">{v.d2}</td>
                <td className="p-1">{v.taxable_amount}</td>
                <td className="p-1">{v.head.gst}</td>
                <td className="p-1">{v.gst_amount}</td>
                <td className="p-1">{numberToIndianSystem(v.total)}</td>
              </tr>
            ))
          ) : (
            <div className="p-2">
              {meta.error && <ErrorBox msg={meta.error} />}
            </div>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ViewProductTable;
