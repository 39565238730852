import { forwardRef, useEffect, useState } from "react";
import useGetItemsFromPurchaseOrder from "../../../hooks/purchase/useGetItemsFromPurchaseOrder";
import { toMMYYYY } from "../../../utils/formatDate";
import { localestringToDate } from "../../../utils/helpers";

type Props = {
  data: any;
};

const PrintPurchaseOrder = forwardRef(({ data }: Props, ref: any) => {
  const [totals, setTotals] = useState({
    qty: 0,
    mrp: 0,
  });

  const { data: dataItems, isSuccess } = useGetItemsFromPurchaseOrder(
    data.id!,
    true
  );

  const details = [
    {
      label: "INVOICE NO.",
      value: data.invoice_number,
    },
    {
      label: "INVOICE DATE",
      value: data.invoice_date ? localestringToDate(data.invoice_date) : "",
    },
    {
      label: "TRANSPORT",
      value: data.invoice_date ? localestringToDate(data.invoice_date) : "",
    },
    {
      label: "NO. OF CARTON",
      value: data.invoice_date ? localestringToDate(data.invoice_date) : "",
    },
    {
      label: "ORDER NO",
      value: data.order_number,
    },
    {
      label: "ORDER DATE",
      value: data.date ? localestringToDate(data.date) : "",
    },
  ];

  const colums = [
    {
      label: "SR.",
    },
    {
      label: "Head",
    },
    {
      label: "Company",
    },
    {
      label: "Product Name",
    },
    {
      label: "Pot.",
    },
    {
      label: "Size",
    },
    {
      label: "BACH NO.",
    },
    {
      label: "QTY.",
    },
    {
      label: "M.R.P.",
    },
    {
      label: "MFG",
    },
    {
      label: "EXP",
    },
  ];

  useEffect(() => {
    if (dataItems?.length) {
      let total = 0,
        totalMrp = 0;
      dataItems.forEach((el) => {
        if (el.ordered_qty) total += Number(el.ordered_qty);
        if (el.mrp) totalMrp += Number(el.mrp);
      });
      setTotals({ qty: total, mrp: totalMrp });
    }
  }, [dataItems]);

  return (
    <div ref={ref} className="flex flex-col justify-center gap-10">
      <h1 className=" w-full text-xl font-bold ">
        ORDER_PURCHASE_ NEW LIFE HOMOEO DISTRIBUTORS_ GOODS
        <span className="ml-2 inline-flex">
          RECEIVED DATE:
          <span className="  border-b  border-black  w-32   "></span>
        </span>
      </h1>

      <div className="flex border-collapse uppercase">
        <div className="border border-black   p-1 w-2/3">
          supplier name: {data.supplier.name}
        </div>
        <div className="border border-black   p-1 w-1/3">
          {details.map((d, i) => (
            <div key={i}>
              <span>{d.label}</span>:<span className="ml-2">{d.value}</span>
            </div>
          ))}
        </div>
      </div>

      {dataItems?.length && (
        <table className="print-table  ">
          <thead>
            <tr>
              {colums.map((h, i) => (
                <th className="">{h.label}</th>
              ))}
            </tr>
          </thead>
          <tbody className="">
            {isSuccess &&
              dataItems.map((e: any, index: number) => (
                <tr key={index} className="">
                  <td className="">{index + 1}</td>
                  <td className="">{e.head}</td>
                  <td className="">{e.company.label}</td>
                  <td className="">{e.item_name.label}</td>
                  <td className="">{e.potency}</td>
                  <td className="">{e.size}</td>
                  <td className="">{e.batch_number}</td>
                  <td className="">{e.ordered_qty}</td>
                  <td className="">{e.mrp}</td>
                  <td className="">{toMMYYYY(e.mfg_date)}</td>
                  <td className="">{toMMYYYY(e.exp_date)}</td>
                </tr>
              ))}
            <tr className="font-bold">
              <td colSpan={7} className="">
                Total
              </td>
              <td className="">{totals.qty}</td>
              {/* <td className="">{totals.mrp}</td> */}
            </tr>
          </tbody>
        </table>
      )}
      <div>
        <div className="flex border-collapse font-bold break-inside-avoid">
          <div className="border border-black  h-28 p-1 w-2/3        ">
            REMARK :
          </div>
          <div className="border border-black  h-28 p-1 w-1/3        ">
            CHECKED & PURCHASED BY:
          </div>
        </div>
      </div>
    </div>
  );
});

export default PrintPurchaseOrder;
