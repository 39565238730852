import { useEffect, useState } from "react";
import { FROM } from "../../types/constants";
type props = {
  value: any;
  row: any;
  column: any;
  setOrderedItems?: any;
  orderedItems?: any;
  from: string;
};
const EditableCell = ({
  value: initialValue,
  row,
  column,
  setOrderedItems,
  orderedItems,
  from, // This is a custom function that we supplied to our table instance
}: props) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue);
  const onChange = (e: Event) => {
    //   @ts-ignore
    setValue(e.target.value);
  };
  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    // updateMyData(index, id, value);
    if (from === FROM.GENERATE_CHALLAAN) {
      if (value > 0) {
        setOrderedItems(
          orderedItems.set(row.original.product_id, {
            product: row.original,
            orderedQty: value,
          })
        );
      } else {
        orderedItems.delete(row.original.item_id);
        setOrderedItems(orderedItems);
      }
    }
  };
  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    if (from === FROM.GENERATE_CHALLAAN) {
      // if (value > 0) {
      //   console.log(row);
      //   setOrderedItems(
      //     orderedItems.set(row.original.product_id, {
      //       product: row.original,
      //       orderedQty: value,
      //     })
      //   );
      // } else {
      //   setOrderedItems(orderedItems.delete(row.original.product_id));
      // }
    } else {
      if (value > 0) {
        //if product does not exist in the list, add it

        if (
          orderedItems.find(
            (item: any) => item.product.item_id === row.original.item_id
          )
        ) {
          let newOrderedItems = orderedItems;
          //update the quantity of the product
          const index = newOrderedItems.findIndex(
            (item: any) => item.product.item_id === row.original.item_id
          );
          newOrderedItems[index].orderedQty = value;
          // console.log("exsiting-item-just-before", orderedItems);
          setOrderedItems(newOrderedItems);
        } else {
          let newOrderedItems = orderedItems;
          newOrderedItems.push({
            product: row.original,
            orderedQty: value,
          });
          // console.log("new-add-just-before", newOrderedItems);
          setOrderedItems(newOrderedItems);
        }
      } else {
        //remove from orderedItems
        let newOrderedItems = orderedItems;
        let index = newOrderedItems.findIndex(
          (x: any) => x.product.item_id === row.original.item_id
        );
        newOrderedItems.splice(index, 1);
        // console.log("else-remove-just-before", newOrderedItems);
        setOrderedItems(newOrderedItems);
      }
    }
  }, [value]);
  //   @ts-ignore
  let component = (
    <input
      className={` px-3 py-1  ${value > 0 ? "bg-blue-200" : "bg-slate-200"}`}
      value={value}
      type="number"
      min={0}
      //@ts-ignore
      onChange={onChange}
      onBlur={onBlur}
    />
  );
  switch (from) {
    case FROM.GENERATE_CHALLAAN:
      component = (
        <input
          className={` px-3 py-1  ${
            value > 0 ? "bg-blue-200" : "bg-slate-200"
          } ${value > row.original.quantity ? "bg-red-200" : ""}`}
          value={value}
          type="number"
          min={0}
          max={row.original.quantity}
          //@ts-ignore
          onChange={onChange}
          onBlur={onBlur}
        />
      );
      break;
    case FROM.CREATE_PURCHASE:
      component = (
        <input
          className={` px-3 py-1  ${
            value > 0 ? "bg-blue-200" : "bg-slate-200"
          } `}
          value={value}
          type="number"
          min={0}
          //@ts-ignore
          onChange={onChange}
          onBlur={onBlur}
        />
      );
      break;

    default:
      break;
  }

  return component;
};
export default EditableCell;
