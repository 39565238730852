import { MONTHS } from "../types/constants";

export function formatDate(date: string) {
  return new Date(date).toLocaleDateString();
}

export function toMMYYYY(date: string | Date): string {
  if (!date) return "";
  if (typeof date === "string") date = new Date(date);
  return MONTHS[date.getMonth()] + "-" + date.getFullYear();
}
