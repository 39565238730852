import { Form, Formik } from "formik";
import * as Yup from "yup";
import useMutateDiscountCategory from "../../hooks/discounts/useMutateDiscountCategory";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";
type postCategory = {
  name: string;
};
type Props = {
  categoryName?: string;
};

function AddDiscountCategoryForm({ categoryName }: Props) {
  const validationSchema = Yup.object({
    name: Yup.string().required("Category Name is Required").trim(),
  });
  const { mutate, isLoading: isSubmitting } = useMutateDiscountCategory();
  const handleSubmit = (values: postCategory, resetForm: Function) => {
    mutate(values, {
      onSuccess() {
        resetForm();
      },
    });
  };
  return (
    <div className="company-configuration-form-mt-12 w-1/2">
      <Formik
        initialValues={{
          name: categoryName || "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
      >
        <Form>
          <div className="flex flex-row gap-x-8">
            <Input
              inline={true}
              label="Category"
              id="name"
              name="name"
              type="text"
              classes="h-16"
              readonly={!!categoryName}
            />
            {categoryName ? null : (
              <SubmitBtn
                text="Create"
                isSubmitting={isSubmitting}
                containerClasses="text-base h-10 w-32"
              />
            )}
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default AddDiscountCategoryForm;
