import { Formik } from "formik";
import { useMemo } from "react";
import { HiTrash } from "react-icons/hi";
import { MdSearch } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import useAddProductInSalesOrder from "../../../hooks/sales/useAddProductInSalesOrder";
import useDropProductFromSalesOrder from "../../../hooks/sales/useDropProductFromSalesOrder";
import useUpdateProductInSalesOrder from "../../../hooks/sales/useUpdateProductInSalesOrder";
import { showModal } from "../../../redux/features/modalSlice";
import {
  FROM,
  GET_CONFIRMATION,
  REPLACE_SHORTED_ITEMS,
} from "../../../types/constants";
import { calculateTaxableAmount } from "../../../utils/helpers";
import CustomSubmitBtn from "../../FormikComponents/CustomSubmitBtn";
import Input from "../../FormikComponents/Input";
import ReadOnlyInputBox from "../../FormikComponents/ReadOnlyInputBox";
import HandleSave from "../../Save/HandleSave";
import UtilityBtn from "../../UtilityBtn/UtilityBtn";

type Props = {
  values: any;
  id: number;
  shortItem?: boolean;
  isInvoiced: boolean;
  allValues: any;
};

function SingleRowUpdateProduct({
  values,
  id,
  shortItem = false,
  isInvoiced,
  allValues,
}: Props) {
  const dispatch = useDispatch();
  const allProductIds = useMemo(
    () => allValues?.heads.map((p: any) => p.productProductId) || [],
    [allValues]
  );

  const { mutate, isLoading } = useUpdateProductInSalesOrder();
  // const { push, remove, form } = fiedlArrayprops;

  const { mutate: deleteItemFromList } = useDropProductFromSalesOrder();
  const { mutate: addItemsIntoList } = useAddProductInSalesOrder();

  const params = useParams();

  const replaceItem = (data: any) => {
    let itemsToAdd = data.map((i: any) => {
      return {
        productProductId: i.product_name.value,
        sale_qty: i.sale_qty,
        d1: i.d1 || 0,
        d2: i.d2 || 0,
      };
    });
    const postData = {
      items: itemsToAdd,
      orderId: params.id!,
    };
    addItemsIntoList(postData);
    deleteItemFromList(values.id);
  };

  const initialValues = {
    id: values.id,
    batch_number: values.batch_number,
    head: values.head,
    mrp: values.mrp,
    exp_date: values.exp_date,
    mfg_date: values.mfg_date,
    product: values.product,
    company: values.company,
    sale_qty: values.sale_qty,
    free_qty: values.free_qty,
    quantity: values.quantity,
    d1: values.d1 || "",
    d2: values.d2 || "",
    taxable_amount: values.taxable_amount,
    gst_amount: values.gst_amount,
  };

  const validationSchema = Yup.object({
    product: Yup.object().required("Product is required"),
    batch_number: Yup.string(),
    sale_qty: Yup.number()
      .required("Quantity is required")
      .min(1, "Quantity must be atleast 1"),
    mrp: Yup.string().required("MRP is required"),
    d1: Yup.string(),
    d2: Yup.string(),
    taxable_amount: Yup.number(),
  });
  const handleUpdate = (values: any, setSubmitting: Function) => {
    const postData = {
      items: [
        {
          id: values.id,
          d1: Number(values.d1) || undefined,
          d2: Number(values.d2) || undefined,
          sale_qty: parseInt(values.sale_qty),
          free_qty: parseInt(values.free_qty) || undefined,
        },
      ],
    };
    mutate(postData);

    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) =>
        handleUpdate(values, setSubmitting)
      }
      enableReinitialize={true}
    >
      {(formik) => {
        return (
          <tr
            key={id}
            className={
              "border p-1 " +
              (shortItem &&
              formik.values.quantity <
                formik.values.sale_qty + formik.values.free_qty
                ? "bg-red-200"
                : "")
            }
          >
            <td className="p-1 w-14">
              {/* {index + 1 + "."} */}
              <ReadOnlyInputBox name={`srno`} value={id + 1} />
            </td>
            <td className="p-1">
              <Input
                id="product_name"
                name={`product.product_name`}
                label=""
                type="text"
                classes="mb-0"
                readonly
              />
            </td>
            <td className="p-1">
              <Input
                id="head"
                name={`head.head`}
                label=""
                type="text"
                classes="mb-0"
                readonly
              />
            </td>
            <td className="p-1">
              <Input
                id="potency"
                name={`head.potency`}
                label=""
                type="text"
                classes="mb-0"
                readonly
              />
            </td>
            <td className="p-1">
              <Input
                id="size"
                name={`head.size`}
                label=""
                type="text"
                classes="mb-0"
                readonly
              />
            </td>
            <td className="p-1">
              <Input
                id="company"
                name={`company.label`}
                label=""
                type="text"
                classes="mb-0"
                readonly
              />
            </td>
            <td className="p-1">
              <Input
                id="batch_number"
                name={`batch_number`}
                label=""
                type="text"
                classes="mb-0"
                readonly
              />
            </td>
            <td className="p-1">
              <Input
                id="mrp"
                name={`mrp`}
                label=""
                type="number"
                classes="mb-0"
                readonly
              />
            </td>
            <td className="p-1">
              <Input
                label=""
                name={`exp_date`}
                id="exp_date"
                type="month"
                classes="mb-0"
                readonly
              />
            </td>

            <td className="p-1">
              <Input
                id="sale_qty"
                name={`sale_qty`}
                label=""
                type="number"
                classes="mb-0"
              />
            </td>
            <td className="p-1">
              <Input
                id="free_qty"
                name={`free_qty`}
                label=""
                type="number"
                classes="mb-0"
              />
            </td>
            <td className="p-1">
              <Input
                id="d1"
                name={`d1`}
                label=""
                type="number"
                step="0.1"
                classes="mb-0 w-22"
              />
            </td>
            <td className="p-1">
              <Input
                id="d2"
                name={`d2`}
                type="number"
                step="0.1"
                classes="mb-0 w-22"
              />
            </td>
            <td className="p-1">
              <ReadOnlyInputBox
                name={`taxable_amount`}
                value={calculateTaxableAmount(
                  formik.values.mrp,
                  formik.values.sale_qty,
                  { d1: formik.values.d1, d2: formik.values.d2 }
                )}
              />
            </td>
            <td className="p-1">
              <Input
                id="gst"
                name={`head.gst`}
                type="text"
                classes="mb-0"
                readonly
              />
            </td>
            <td className="p-1">
              <ReadOnlyInputBox
                name={`gst_amount`}
                value={(
                  (Number(formik.values.taxable_amount) *
                    Number(formik.values.head.gst)) /
                  100
                ).toFixed(2)}
              />
            </td>
            <td className="p-1">
              <ReadOnlyInputBox
                name={`total`}
                value={(
                  Number(formik.values.taxable_amount) +
                  (isInvoiced ? Number(formik.values.gst_amount) : 0)
                ).toFixed(2)}
                // value={heads[index].total}
              />
            </td>
            <HandleSave handleSave={formik.handleSubmit} />

            <td className="p-1">
              <CustomSubmitBtn
                text={"Update"}
                classes="w-24 mb-6  focus:bg-sky-700 "
                disabled={formik.isSubmitting || isLoading}
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                }}
              />
            </td>

            <td className="p-1  ">
              <button
                type="button"
                className=" p-2 rounded-sm bg-red-500 text-white mb-6  focus:bg-red-700 "
                onClick={() => {
                  dispatch(
                    showModal({
                      modalType: GET_CONFIRMATION,
                      modalTitle: "Drop Product",
                      modalProps: {
                        id: formik.values.id,
                        value: "Are you sure you want to drop the Product?",
                        from: FROM.DELETE_SALES_PRODUCT,
                      },
                    })
                  );
                }}
              >
                <HiTrash />
              </button>
            </td>

            {shortItem && (
              <td className="p-1 ">
                {formik.values.quantity <
                  formik.values.sale_qty + formik.values.free_qty && (
                  <UtilityBtn
                    onClick={() => {
                      const v = formik.values;
                      dispatch(
                        showModal({
                          modalType: REPLACE_SHORTED_ITEMS,
                          modalTitle: "Replace Shorted Items",
                          modalProps: {
                            id: v.head.id,
                            customerDiscount: v.d1,
                            sale_qty: v.sale_qty,
                            replaceItem: replaceItem,
                            allProductIds,
                          },
                        })
                      );
                    }}
                    label="Replace Item"
                    Icon={MdSearch}
                    classes=" mb-6 "
                    iconClasses="text-white bg-primary p-2 rounded"
                    iconSize={28}
                  />
                )}
              </td>
            )}
          </tr>
        );
      }}
    </Formik>
  );
}

export default SingleRowUpdateProduct;
