import { Form, Formik } from "formik";
import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import Input from "../../components/FormikComponents/Input";
import RemoteSelect from "../../components/FormikComponents/RemoteSelect";
import SubmitBtn from "../../components/FormikComponents/SubmitBtn";
import TableInstance from "../../components/Table/TableInstance";
import useGetMRs from "../../hooks/useGetMRs";
import { addToast } from "../../redux/features/toastSlice";
import { SalesObj } from "../../types";
import { ERROR } from "../../types/constants";

function CustomerOrdersReport() {
  const [cPageSize, cSetPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [cSortBy, cSetSortBy] = useState(null);
  const [desc, setDesc] = useState(true);
  const [q, setQ] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const CUSTOMER_ORDERS_COLUMN = [
    {
      Header: "Sr",
      disableSortBy: true,
      accessor: (_row: any, i: number) => i + 1,
    },
    {
      Header: "Customer",
      accessor: "customer.name",
    },
    {
      Header: "MR",
      accessor: "medrep.name",
    },
    {
      Header: "Address",
      accessor: "medrep.address",
    },
    {
      Header: "Contact No",
      accessor: "medrep.contactOne",
    },
    {
      Header: "Total Orders",
      accessor: "total",
    },
    {
      Header: "Last Order",
      accessor: "last_order",
    },
  ];

  const initialValues = {
    medrepId: "",
    days: "",
    fromDate: "",
    toDate: "",
  };
  const [searchQueries, setSearchQueries] = useState(initialValues);

  const getAllOrders = async ({ queryKey }: any) => {
    const { data } = await axiosInstance.get(
      `/sales?start_date=${queryKey[2]}&end_date=${queryKey[3]}&status=${queryKey[1]}&medrepId=${queryKey[4]}`
    );
    if (data.data.length == 0) {
      dispatch(addToast({ kind: ERROR, msg: "No Orders Found" }));
    }
    setIsSubmitting(false);
    return data.data;
  };
  const {
    data: salesApiRes,
    error,
    isLoading: salesIsLoading,
    isSuccess,
  } = useQuery<SalesObj[] | undefined, any, SalesObj[], any[]>(
    [
      "getAllOrders",
      "created",
      searchQueries.fromDate,
      searchQueries.toDate,

      searchQueries.medrepId,
    ],
    getAllOrders
  );

  const validationSchema = Yup.object({
    medrepId: Yup.string().required("MR is Required"),
    days: Yup.string().required("Days is Required"),
    fromDate: Yup.date()
      .required("From date is required")
      .max(new Date(), "From date cannot be a future date"),
    toDate: Yup.date()
      .required("To date is required")
      .min(Yup.ref("fromDate"), "To date must be after From date")
      .max(new Date(), "To date cannot be a future date"),
  });

  const { queryKeyMrs, isFetchingMRs, errorMRs, isLoadingMRs, optionMRs } =
    useGetMRs();

  const handleSubmit = (values: any, resetForm: any) => {
    setIsSubmitting(true);
    const postData = {
      medrepId: values.medrepId,
      days: values.days,
      fromDate: new Date(values.fromDate).toISOString(),
      toDate: new Date(values.toDate).toISOString(),
    };
    if (
      searchQueries.medrepId == postData.medrepId &&
      searchQueries.fromDate == postData.fromDate &&
      searchQueries.toDate == postData.toDate
    ) {
      queryClient.invalidateQueries([
        "getAllOrders",
        "created",
        searchQueries.fromDate,
        searchQueries.toDate,

        searchQueries.medrepId,
      ]);
    } else {
      setSearchQueries({ ...initialValues, ...postData });
    }
    console.log("values", postData);
    // resetForm();
  };

  return (
    <div className="sales">
      <header className="flex items-center justify-between mb-8"></header>

      <div className="sold-stock flex flex-col justify-center px-4 py-4 rounded border border-gray-300 gap-x-4 relative mb-14">
        {/* <div className="search-box mr-4">
        <SearchBox
          searchValue={q}
          setSearchValue={setQ}
          handleSearch={() => {}}
        />
      </div> */}
        <div className="filters">
          {" "}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) =>
              handleSubmit(values, resetForm)
            }
            validateOnMount
          >
            {({ values, errors }) => (
              <Form className="">
                <div className="row-1 flex items-center mb-2 gap-x-4">
                  <RemoteSelect
                    label="MR "
                    name="medrepId"
                    id="medrepId"
                    error={errorMRs}
                    isFetching={isFetchingMRs}
                    isLoading={isLoadingMRs}
                    options={optionMRs || []}
                    queryKey={[queryKeyMrs]}
                    classes="w-full"
                  />

                  <Input label="Days" name="days" id="days" type="number" />

                  <Input
                    label="From"
                    name="fromDate"
                    id="fromDate"
                    type="date"
                  />
                  <Input label="To" name="toDate" id="toDate" type="date" />
                  <div className="buttons flex items-center justify-center">
                    <SubmitBtn
                      text={"Search"}
                      isSubmitting={isSubmitting}
                      classes="font-medium "
                      isSubmittingClasses="font-medium w-24  h-10 !mb-0"
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <TableInstance
        tableData={salesApiRes || []}
        columnName={CUSTOMER_ORDERS_COLUMN}
        cPageSize={cPageSize}
        cSetPageSize={cSetPageSize}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageCount={-1} // do calculation here
        cSortBy={cSortBy}
        cSetSortBy={cSetSortBy}
        desc={desc}
        setDesc={setDesc}
        q={q}
        setQ={setQ}
        setSelectedRows={setSelectedRows}
        selectRow={false}
      />
    </div>
  );
}

export default CustomerOrdersReport;
