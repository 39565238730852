import { AxiosError } from "axios";
import { Form, Formik } from "formik";
import { useCallback } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR } from "../../types/constants";
import queryKeys from "../../types/queryKeys";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import InputDisplayBox from "../InputDisplayBox";
type FormHead = { head: string; hsn_number: string; gst: string };
function UpdateHeadModal(props: any) {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hideModal());
  };
  console.log(props);
  const initialValues: FormHead = {
    head: "",
    hsn_number: "",
    gst: "",
  };

  const validationSchema = Yup.object({
    head: Yup.string(),
    hsn_number: Yup.string(),
    gst: Yup.string(),
  });

  const handleSubmit = useCallback(
    (values: FormHead, setSubmitting: Function) => {
      console.log(values);
      const postData = {
        ...values,
        original_head: props.head,
        original_hsn_number: props.hsn_number,
      };
      axiosInstance
        .patch(`/heads/update`, postData)
        .then((response) => {
          console.log(response);

          //   const { msg } = response.data;
          setSubmitting(false);

          //   dispatch(
          //     addToast({
          //       kind: SUCCESS,
          //       msg: msg,
          //     })
          //   );
          queryClient.invalidateQueries(queryKeys.getAllHeads);
          //   resetForm();
          handleClose();
        })
        .catch((error: AxiosError) => {
          setSubmitting(false);
          console.log(error.response);
          if (error.response && error.response.data.message) {
            dispatch(
              addToast({
                kind: ERROR,
                msg: error.response.data.message,
              })
            );
          } else {
            dispatch(
              addToast({
                kind: ERROR,
                msg: `Error: ${error.message}`,
              })
            );
          }
        });
    },
    []
  );

  return (
    <div className="px-8 py-3 ">
      <div className="row flex gap-x-8">
        <InputDisplayBox label="Head" value={props.head} id="org_head" />
        <InputDisplayBox label="HSN No" value={props.hsn_number} id="org_hsn" />
        <InputDisplayBox label="GST" value={props.gst} id="org_gst" />
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values, setSubmitting);
        }}
        validateOnMount={true}
      >
        {({ isSubmitting }) => (
          <Form className=" items-center gap-x-12 " style={{ width: "60rem" }}>
            <div className="row flex gap-x-8">
              <Input
                label="New Head"
                id="head"
                name="head"
                type="text"
                classes="h-16"
              />
              <Input
                label="New HSN No"
                id="hsn_number"
                name="hsn_number"
                type="text"
                classes="h-16"
              />
              <Input
                label="New GST"
                id="gst"
                name="gst"
                type="text"
                classes="h-16"
              />
            </div>
            <div className="my-2">
              <SubmitBtn
                text="Update"
                isSubmitting={isSubmitting}
                classes="text-base h-10 w-full "
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default UpdateHeadModal;
