import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import axiosInstance from "../../../axiosInstance";
import queryKeys from "../../../types/queryKeys";
type postData = {
  headId: string;
  companyId: string;
  room_no: string;
};

function useAddRoomNo() {
  const queryClient = useQueryClient();

  return useMutation(
    (postData: postData) => axiosInstance.post("/room/add", postData),
    {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries(queryKeys.getRoomNo);
        toast.success("Room No added successfully");
      },
      onError(err: AxiosError, variables, context) {
        try {
          const { msg } = err?.response?.data;
          toast.error(msg);
        } catch (error) {
          toast.error("Something went wrong");
        }
      },
    }
  );
}

export default useAddRoomNo;
