import { useField } from "formik";
import Select from "react-select";
import { Option } from "../../types";
import ErrorBox from "./ErrorBox";

interface Props {
  label: string;
  id: string;
  name: string;
  options: string[];
  selected?: string;
  inline?: boolean;
  classes?: string;
  defaultValue?: any;
  placeholder?: string;
  isComapnies?: boolean;
  createAble?: true;
  isMulti?: true;
  isClearable?: true;
  isDisabled?: boolean;
  isOptional?: boolean;
}

const customStyles = {
  control: (provided: any, state: any) => {
    return {
      ...provided,
      backgroundColor: "#e2e8f0",
      boxShadow: "none",
      ":hover": {
        borderColor: "none",
        cursor: "text",
      },
      borderColor: "none",
    };
  },

  multiValueLabel: (provided: any, state: any) => {
    return {
      ...provided,
      color: "black",
      fontSize: "1rem",
      cursor: "pointer",
    };
  },
  clearIndicator: (provided: any, state: any) => {
    return {
      ...provided,

      cursor: "pointer",
      ":hover": {
        color: "red",
      },
    };
  },
};

function SingleValueReactSelect({
  inline,
  id,
  label,
  placeholder,
  classes,
  name,
  options,
  isClearable,
  defaultValue,
  isDisabled,
  isOptional,
  ...props
}: Props) {
  // props -> every props except label and options -> { name: 'value', id: 'value' }
  const [field, meta, helpers] = useField(name);

  return (
    <div
      className={`flex flex-col mb-6 ${
        isDisabled ? "disabled-custom cursor-not-allowed " : "cursor-pointer"
      }   w-full ${classes}`}
    >
      <div
        className={`form-group flex ${
          inline ? " flex-row items-center" : "flex-col justify-center"
        }`}
      >
        <label
          className={`font-semibold ${inline ? "mr-4 w-28" : "mb-2"}`}
          htmlFor={id}
        >
          {label}{" "}
          {isOptional && <span className="optional text-slate-400"> (O)</span>}
        </label>
        <div className="flex">
          <Select
            isClearable={isClearable}
            defaultValue={defaultValue}
            placeholder={placeholder}
            isDisabled={isDisabled}
            {...field}
            value={field.value && { label: field.value, value: field.value }}
            onBlur={() => helpers.setTouched(true)}
            onChange={(op) => {
              helpers.setTouched(true);
              helpers.setValue((op as Option)?.value);
            }}
            openMenuOnFocus
            styles={customStyles}
            options={options.map((v) => ({ label: v, value: v }))}
            isSearchable={true}
            className={`flex-1 w-full ${
              meta.touched && meta.error
                ? "border border-red-400"
                : "border-coolGray-200 "
            } mr-2`}
          />{" "}
        </div>
      </div>
      {/* @ts-ignore */}
      {meta.touched && meta.error && <ErrorBox msg={meta.error.label} />}
    </div>
  );
}

export default SingleValueReactSelect;
