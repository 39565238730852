import { NavLink, Outlet } from "react-router-dom";

function DiscountsLayout() {
  const sections = [
    {
      lable: "By Category",
      to: "category",
      isEnd: true,
    },
    {
      lable: "By Customer",
      to: "customer",
      isEnd: true,
    },
  ];
  return (
    <div className="users h-full w-full">
      <nav className="users-nav mb-12 noselect">
        <ul className="flex gap-x-6 items-center">
          {sections.map((s, i) => (
            <li key={i}>
              <NavLink
                className={({ isActive }) =>
                  `flex-1 text-lg rounded font-semibold px-2 py-1 shadow ${
                    isActive ? "bg-primary text-white" : "bg-white"
                  }`
                }
                end={s.isEnd}
                to={s.to}
              >
                {s.lable}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>

      <div className="content">
        <Outlet />
      </div>
    </div>
  );
}

export default DiscountsLayout;
