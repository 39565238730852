import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import queryKeys from "../../types/queryKeys";

function useMutateSuppliers() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const handleMutation = (postData: any) => {
    if (postData.id) {
      return axiosInstance.patch(`/suppliers/${postData.id}`, postData);
    }
    return axiosInstance.post(`/suppliers/`, postData);
  };

  return useMutation(handleMutation, {
    onSuccess(data, payload, context) {
      queryClient.invalidateQueries(queryKeys.getSuppliers);
      dispatch(
        addToast({
          kind: SUCCESS,
          msg: `Supplier ${payload.id ? "Updated" : "Created"} Successfully`,
        })
      );
    },
    onError(data, payload, context) {
      if (payload.id) {
        dispatch(
          addToast({
            kind: ERROR,
            msg: "Unable to  Update Supplier",
          })
        );
      } else {
        dispatch(
          addToast({
            kind: ERROR,
            msg: "Unable to Add Supplier",
          })
        );
      }
    },
  });
}

export default useMutateSuppliers;
