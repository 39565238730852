import { Form, Formik } from "formik";
import { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import axiosInstance from "../../../axiosInstance";
import CreditorTransactionsDetailsTable from "../../../components/AccountDetails/CreditorTransactionsDetailsTable";
import AddReceiptForm from "../../../components/AddReceiptForm/AddReceiptForm";
import Input from "../../../components/FormikComponents/Input";
import SubmitBtn from "../../../components/FormikComponents/SubmitBtn";
import useGetSuppliers from "../../../hooks/supplier/useGetSuppliers";
import { MONTHS } from "../../../types/constants";
import { convertDateToStringForForm } from "../../../utils/helpers";

const CreditorDetails = () => {
  const param = useParams();

  const now = new Date();

  const [searchDates, setSearchDates] = useState({
    fromDate: new Date(now.getFullYear(), now.getMonth(), 1).toISOString(),
    toDate: new Date(now.getFullYear(), now.getMonth() + 1, 0).toISOString(),
  });
  const initialValues = {
    fromDate: convertDateToStringForForm(
      new Date(now.getFullYear(), now.getMonth(), 1).toISOString()
    ),
    toDate: convertDateToStringForForm(
      new Date(now.getFullYear(), now.getMonth() + 1, 0).toISOString()
    ),
  };

  const { dataSuppliers, isLoadingSuppliers, isSuccessSuppliers } =
    useGetSuppliers({ id: param.id });

  const getTransaction = async ({ queryKey }: any) => {
    const { data } = await axiosInstance.get(
      `/credit?start_date=${queryKey[2]}&end_date=${queryKey[3]}&supplierId=${queryKey[1]}`
    );

    return data;
    // console.log(data)
  };

  const {
    data: transactionData,
    error: transactionError,
    isLoading: transactionIsLoading,
    isSuccess: transactionSuccess,
  } = useQuery<any>(
    ["getTransaction", param.id, searchDates.fromDate, searchDates.toDate],
    getTransaction
  );

  const validationSchema = Yup.object({
    fromDate: Yup.date()
      .required("From date is required")
      .max(new Date(), "From date cannot be a future date"),
    toDate: Yup.date()
      .required("To date is required")
      .min(Yup.ref("fromDate"), "To date must be after From date")
      .max(
        new Date(now.getFullYear(), now.getMonth() + 1, 0),
        "To date cannot be a future date"
      ),
  });

  const handleSubmit = (
    { fromDate, toDate }: { fromDate: string; toDate: string },
    resetForm: any
  ) => {
    let endOfToday = new Date(toDate);
    endOfToday.setUTCHours(23, 59, 59, 999);
    setSearchDates({
      fromDate: new Date(fromDate).toISOString(),
      toDate: new Date(endOfToday).toISOString(),
    });
  };

  // console.log(isLoading);

  return (
    <div className="account-detail-page">
      <table className="info-table table mb-12">
        <thead>
          <tr>
            <th>Name</th>
            <th>Contact Person</th>
            <th>Contact No.</th>
            <th>Address</th>
            <th>City</th>
            <th>State</th>
            <th>Email</th>
            <th>Balance</th>
            <th>Current Balance</th>
          </tr>
        </thead>
        <tbody>
          {!isLoadingSuppliers &&
            isSuccessSuppliers &&
            dataSuppliers?.map((e: any, index: number) => (
              <tr key={index}>
                <td>{e.name}</td>
                <td>{e.contact_person}</td>
                <td>{e.contact_number}</td>
                <td>{e.address}</td>
                <td>{e.city}</td>
                <td>{e.state}</td>
                <td>{e.email}</td>
                <td>{"----"}</td>
                <td>{"----"}</td>
                {/* <td>{customerBalance?.sum?.toFixed(2)}</td> */}
                {/* <td>{new Date(e.updatedAt).toLocaleString()}</td> */}
              </tr>
            ))}
        </tbody>
      </table>

      <div className="transaction-history mb-12">
        <div className="top flex items-center justify-between mb-4">
          <h3 className="font-semibold text-xl">
            Transaction for month:{" "}
            {`${MONTHS[new Date(searchDates.fromDate).getMonth()]} ${new Date(
              searchDates.fromDate
            ).getFullYear()} to ${
              MONTHS[new Date(searchDates.toDate).getMonth()]
            } ${new Date(searchDates.toDate).getFullYear()}`}
          </h3>

          <div className="date flex items-center gap-x-5">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values, { resetForm }) =>
                handleSubmit(values, resetForm)
              }
            >
              <Form className="">
                <div className="date flex items-center gap-x-5 mr-5">
                  <div className="from flex items-center  mr-2">
                    <Input
                      label="From"
                      name="fromDate"
                      id="fromDate"
                      type="date"
                    />
                  </div>
                  <div className="to  ">
                    <Input label="To" name="toDate" id="toDate" type="date" />
                  </div>
                  <div className="buttons flex items-center justify-center">
                    <SubmitBtn
                      text={"Search"}
                      isSubmitting={transactionIsLoading}
                      classes="font-medium "
                      shouldNotValidate
                    />
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
        <CreditorTransactionsDetailsTable
          transactionData={transactionData}
          transactionIsLoading={transactionIsLoading}
          transactionSuccess={transactionSuccess}
          fromDate={searchDates.fromDate}
        />
      </div>

      <AddReceiptForm />
    </div>
  );
};

export default CreditorDetails;
