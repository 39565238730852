import { forwardRef, useEffect, useState } from "react";
import { ResponseGetDebitByMR } from "../../../hooks/Accounts/debtors/useGetDebitByMR";
import { localestringToDate } from "../../../utils/helpers";

type Props = {
  data: ResponseGetDebitByMR;
};

const PrintCollectionReport = forwardRef(({ data }: Props, ref: any) => {
  const [totals, setTotals] = useState({
    qty: "0",
  });

  console.log(data);

  const colums = [
    {
      label: "SR.",
    },
    {
      label: "Received By",
    },
    {
      label: "Receipt No.",
    },
    {
      label: "Date",
    },
    {
      label: "Mode of Payment",
    },
    {
      label: "Bank / Cash",
    },
    {
      label: "Particular",
    },
    {
      label: "Customer",
    },
    {
      label: "City",
    },
    {
      label: "Amount",
    },
  ];

  useEffect(() => {
    if (data?.debits.length) {
      let qty = 0;

      data.debits.forEach((el) => {
        if (el.amount) qty += el.amount;
      });
      setTotals({ qty: Math.abs(qty).toFixed(2) });
    }
  }, [data]);

  return (
    <div ref={ref} className="flex flex-col justify-center gap-10">
      <div>
        <h1 className="text-center w-full text-xl font-bold ">
          PAYMENT COLLECTION REPORT
        </h1>
        <h4 className="text-center w-full text-xl mt-1  ">
          {localestringToDate(data.start_date)} To{" "}
          {localestringToDate(data.end_date)}
        </h4>
      </div>

      {data?.debits?.length && (
        <table className="print-table   ">
          <thead>
            <tr>
              {colums.map((h, i) => (
                <th className="">{h.label}</th>
              ))}
            </tr>
          </thead>
          <tbody className="">
            {data.debits.map((e, index: number) => (
              <tr key={index} className="">
                <td className="">{index + 1}</td>
                <td className="">{e.medrep.name}</td>
                <td className="">{e.transaction_id}</td>
                <td className="">{localestringToDate(e.transaction_date)}</td>
                <td className="">{e.mode}</td>
                <td className="">{e.bankaccount?.bank_name || "Cash"}</td>
                <td className="">{e.particulars}</td>
                <td className="">{`${e.customer?.name} (${e.customer.city})`}</td>
                <td className="">{e.customer.city}</td>
                <td className=" text-right">{Math.abs(e.amount).toFixed(2)}</td>
              </tr>
            ))}
            <tr className="font-bold bg-slate-200 ">
              <td colSpan={9} className="">
                Total
              </td>
              <td className="text-right">{totals.qty}</td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
});

export default PrintCollectionReport;
