import { AxiosError } from "axios";
import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ConfigurationCompanyObj } from "../../types";
import { ERROR, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";

function UpdateCompanyModal(props: any) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const initialValues: Omit<ConfigurationCompanyObj, "id"> = {
    name: props.name || "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Company name is required"),
  });

  const handleSubmit = useCallback(
    (value: Omit<ConfigurationCompanyObj, "id">, resetForm: any) => {
      setIsSubmitting(true);
      axiosInstance
        .patch(`/company/${props.id}`, value)
        .then((response) => {
          const { msg } = response.data;

          setIsSubmitting(false);

          queryClient.invalidateQueries(["getCompaniesConfiguration"]);

          dispatch(
            addToast({
              kind: SUCCESS,
              msg: msg,
            })
          );

          resetForm();
          dispatch(hideModal());
        })
        .catch((error: AxiosError) => {
          setIsSubmitting(false);

          if (error.response) {
            const response = error.response;
            const { msg } = response.data;

            switch (response.status) {
              // bad request or invalid format or unauthorized
              case 400:
              case 500:
                dispatch(
                  addToast({
                    kind: ERROR,
                    msg: msg,
                  })
                );
                break;
              default:
                dispatch(
                  addToast({
                    kind: ERROR,
                    msg: "Oops, something went wrong",
                  })
                );
                break;
            }
          } else if (error.request) {
            dispatch(
              addToast({
                kind: ERROR,
                msg: "Oops, something went wrong",
              })
            );
          } else {
            dispatch(
              addToast({
                kind: ERROR,
                msg: `Error: ${error.message}`,
              })
            );
          }
        });
    },
    []
  );

  return (
    <div className="">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values, resetForm);
        }}
        validateOnMount={true}
      >
        <Form className="px-8 py-3  items-center gap-x-12">
          <div className="flex flex-row gap-x-8 w-96 ">
            <Input
              inline={true}
              label="Company"
              id="name"
              name="name"
              type="text"
              classes="h-16"
            />
          </div>
          <div className="my-2 ">
            <SubmitBtn
              text="Update"
              isSubmitting={isSubmitting}
              classes="text-base h-10 w-full "
            />
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default UpdateCompanyModal;
