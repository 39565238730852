import { AxiosError } from "axios";
import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS, USER_ROLES_CREATION } from "../../types/constants";
import Input from "../FormikComponents/Input";
import Select from "../FormikComponents/Select";
import SubmitBtn from "../FormikComponents/SubmitBtn";

interface UserObj {
  name: string;
  email: string;
  password: string;
  // editAccess: string;
  role: string;
}

const AddUserModal = (props: any) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isSubmitting, setIsSubmitting] = useState(false);
  console.log("Update User props", props);

  const editAccessOptions = [
    {
      value: "Yes",
      label: "Yes",
    },
    {
      value: "No",
      label: "No",
    },
  ];
  const userRoleOptions = Object.values(USER_ROLES_CREATION).map(
    (r: string) => {
      return {
        label: r,
        value: r,
      };
    }
  );

  const initialValues: UserObj = {
    name: props?.name || "",
    email: props?.email || "",
    password: "",
    // editAccess: "",
    role: props?.role || "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Username is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().required("Password is required"),
    // editAccess: Yup.string()
    //   .oneOf(
    //     editAccessOptions.map((o) => o.value),
    //     "Invalid value for editAccess"
    //   )
    //   .required("Edit Access is required"),
    role: Yup.string()
      .oneOf(Object.values(USER_ROLES_CREATION), "Invalid value for user role")
      .required("User role is required"),
  });

  const handleSubmit = useCallback((user: UserObj) => {
    setIsSubmitting(true);

    axiosInstance
      .post(
        `/user/create`,
        {
          ...user,
          role: user.role.toLocaleLowerCase(),
        },
        {
          headers: {
            ContentType: "application/json",
          },
        }
      )
      .then((response) => {
        const { msg } = response.data;

        setIsSubmitting(false);

        queryClient.invalidateQueries(["getAllUsers"]);
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: msg,
          })
        );

        dispatch(hideModal());
      })
      .catch((error: AxiosError) => {
        setIsSubmitting(false);

        if (error.response) {
          const response = error.response;
          const { msg } = response.data;

          switch (response.status) {
            // bad request or invalid format or unauthorized
            case 400:
            case 500:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: msg,
                })
              );
              break;
            default:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: "Oops, something went wrong",
                })
              );
              break;
          }
        } else if (error.request) {
          dispatch(
            addToast({
              kind: ERROR,
              msg: "Oops, something went wrong",
            })
          );
        } else {
          dispatch(
            addToast({
              kind: ERROR,
              msg: `Error: ${error.message}`,
            })
          );
        }
      });
  }, []);
  const handleUpdate = (user: UserObj) => {
    setIsSubmitting(true);

    axiosInstance
      .post(
        `/user/create`,
        {
          ...user,
          role: user.role.toLocaleLowerCase(),
          id: props.id,
        },
        {
          headers: {
            ContentType: "application/json",
          },
        }
      )
      .then((response) => {
        const { msg } = response.data;

        setIsSubmitting(false);

        queryClient.invalidateQueries(["getAllUsers"]);
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: msg,
          })
        );

        dispatch(hideModal());
      })
      .catch((error: AxiosError) => {
        setIsSubmitting(false);

        if (error.response) {
          const response = error.response;
          const { msg } = response.data;

          switch (response.status) {
            // bad request or invalid format or unauthorized
            case 400:
            case 500:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: msg,
                })
              );
              break;
            default:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: "Oops, something went wrong",
                })
              );
              break;
          }
        } else if (error.request) {
          dispatch(
            addToast({
              kind: ERROR,
              msg: "Oops, something went wrong",
            })
          );
        } else {
          dispatch(
            addToast({
              kind: ERROR,
              msg: `Error: ${error.message}`,
            })
          );
        }
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) =>
        props ? handleUpdate(values) : handleSubmit(values)
      }
    >
      <Form
        className="px-6 py-4 mt-2"
        style={{
          minWidth: "360px",
          maxWidth: "760px",
        }}
      >
        <div className="flex item-center gap-x-6">
          <Input label="Name" id="name" name="name" type="text" />
          <Input label="Email" id="email" name="email" type="email" />
        </div>
        <Input label="Password" id="password" name="password" type="password" />
        {/* <Select
          label="Edit Access"
          id="editAccess"
          name="editAccess"
          options={editAccessOptions}
        /> */}
        <Select label="Role" id="role" name="role" options={userRoleOptions} />

        <div className="buttons flex items-center w-full justify-center my-4">
          <SubmitBtn
            text={props ? "Update" : "Save"}
            isSubmitting={isSubmitting}
            classes="text-sm"
          />
        </div>
      </Form>
    </Formik>
  );
};

export default AddUserModal;
