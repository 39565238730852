import { useQuery } from "react-query";
import axiosInstance from "../../../axiosInstance";
import queryKeys from "../../../types/queryKeys";
import { Debit } from "./useGetDebitTransactions";
type Params = {
  medrepId: string;
  start_date: string;
  end_date: string;
};

export interface ResponseGetDebitByMR {
  status: string;
  debits: Debit[];
  start_date: string;
  end_date: string;
}

function useGetDebitByMR(params: Params) {
  const getTransaction = async ({ queryKey }: any) => {
    const [_kay, params] = queryKey as [string, Params];
    const { data } = await axiosInstance.get(`/debit/mr`, {
      params: {
        ...params,
        filters: {
          amount: { lt: 0 },
        },
      },
    });

    return data as ResponseGetDebitByMR;
  };

  return useQuery([queryKeys.getDebitByMr, params], getTransaction, {
    enabled: !!(params.start_date || params.end_date),
  });
}

export default useGetDebitByMR;
