import React from 'react';
import { BsDiamondFill } from 'react-icons/bs';

const Logo = () => {
  return (
    <div className='logo flex items-center'>
      <div className='left mr-3'>
        <BsDiamondFill size={30} />
      </div>
      <div className='right'>
        <h3 className='font-bold text-xl leading-none'>NEW LIFE</h3>
        <span className='text-xs font-semibold'>Homeo Distributor</span>
      </div>
    </div>
  );
};

export default Logo;
