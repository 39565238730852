import pathnameRegex from "../types/pathnameRegex";

const getPageTitle = (pathname: string): string => {
  let title = "";
  // const confirm_purchase_title_regex =;

  switch (pathname) {
    case "/":
      title = "Dashboard";
      break;
    case "/users":
    case "/users/accounts":
    case "/users/sales":
    case "/users/purchases":
    case "/users/market":
      title = "Users";
      break;

    case "/inventory":
      title = "Available Stock";
      break;
    case "/accounts":
    case "/accounts/debtors":
    case "/accounts/creditors":
    case "/accounts/orders/pending-orders":
    case "/accounts/orders/approved-orders":
    case "/accounts/orders/declined-orders":
      title = "Accounts";
      break;
    case "/sales":
    case "/sales/created":
    case "/sales/create-order":
    case "/sales/challaans":
    case "/sales/invoices":
    case "/sales/initiated":
      title = "Sales";
      break;

    case "/sales/initiate-order":
      title = "Initiate Order";
      break;
    case "/purchases":
    case "/purchases/create":
    case "/purchases/created":
    case "/purchases/completed":
    case "/purchases/create-confirm":
      title = "Purchase";
      break;

    case "/mr":
      title = "M.R.";
      break;

    case "/customers":
      title = "Customer";
      break;

    case "/discount":
      title = "Discount";
      break;
    case "/supplier":
      title = "Supplier";
      break;
    case "/reports":
    case "/reports/gst":
    case "/reports/out-of-stock":
    case "/reports/customer-orders":
    case "/reports/collection":
      title = "Reports";
      break;
    default:
      title = "";
  }

  if (title) return title;

  if (pathnameRegex.confirmPurchase.test(pathname)) {
    title = "Purchase";
  } else if (pathnameRegex.sales.sales.test(pathname)) {
    title = "Sales";
  } else if (pathnameRegex.sales.editInitiatedSalesOrder.test(pathname)) {
    title = "Initiated Sales";
  } else if (pathnameRegex.reports.test(pathname)) {
    title = "Reports";
  } else if (pathnameRegex.debtors.test(pathname)) {
    title = "Debtors";
  } else if (pathnameRegex.creditors.test(pathname)) {
    title = "Creditors";
  } else if (pathnameRegex.configration.test(pathname)) {
    title = "Configuration";
  } else if (pathnameRegex.editPurchase.test(pathname)) {
    title = "Edit Purchase";
  } else if (pathnameRegex.discount.test(pathname)) {
    title = "Discounts";
  }

  return title;
};

export default getPageTitle;
